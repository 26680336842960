import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { AHEAD_MINT, GREY_1, GREY_3, MINT, WHITE } from '../../../../styles/colors';

export const Tr = styled.tr`
  height: 56px;

  :hover:not(.detail),
  &.active {
    background-color: ${MINT} !important;
    td {
      background-color: ${MINT} !important;
    }
  }

  td:first-of-type:not(.detail td) {
    position: -webkit-sticky;
    position: sticky;
    width: 90px;
    min-width: 90px;
    max-width: 90px;
    left: 0;
    background-color: ${WHITE};
  }
  td:nth-of-type(2) {
    position: -webkit-sticky;
    position: sticky;
    width: 101px;
    min-width: 101px;
    max-width: 101px;
    left: 90px;
    background-color: ${WHITE};
  }
`;

export const Td = styled.td`
  text-align: center;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: ${GREY_1};
  border-bottom: 1px solid ${GREY_3};
`;

export const Button = styled.button(
  ({ active }: { active: boolean }) => css`
    width: 79px;
    height: 35px;
    background-color: ${WHITE};
    border: 1px solid ${GREY_3};
    box-sizing: border-box;
    border-radius: 5px;
    margin-right: 8px;

    &:hover {
      font-weight: bold;
    }

    ${active &&
    css`
      border-color: ${AHEAD_MINT};
      color: ${AHEAD_MINT};
      font-weight: bold;
    `};
  `,
);

export const TdDetail = styled.td`
  padding: 24px;
  background-color: #f8f9fa;
  border-bottom: 1px solid ${GREY_3};
`;
