import { ReservationFieldKey, ReservationStatusType } from 'src/model/reservation';
import { TabMenuType } from 'src/model/TabMenu';
import { useGetReservations } from 'src/query/Reservation/useReservationQuery';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { selectReservationsParams } from '../../selectors';
import { reservationAction } from '../../features/reservation/reservationSlice';

const useReservationTabList = () => {
  const reservationsParams = useSelector(selectReservationsParams);
  const dispatch = useDispatch();

  const allCntParams = {
    ...reservationsParams,
    serviceStatus: ReservationStatusType.Applied,
  };

  const { data: applied } = useGetReservations(allCntParams);
  const { data: confirmed } = useGetReservations({
    ...reservationsParams,
    serviceStatus: ReservationStatusType.Confirmed,
  });

  const onChangeType = useCallback(
    (type: ReservationStatusType) => {
      dispatch(reservationAction.updateField({ key: ReservationFieldKey.ServiceStatus, value: type }));
    },
    [dispatch],
  );

  const tabList: TabMenuType[] = [
    {
      key: 0,
      text: `예약전체`,
      onClickHandler: () => onChangeType(ReservationStatusType.All),
    },
    {
      key: 1,
      text: `예약대기(${applied?.length || 0})`,
      onClickHandler: () => onChangeType(ReservationStatusType.Applied),
    },
    {
      key: 2,
      text: `예약확정(${confirmed?.length || 0})`,
      onClickHandler: () => onChangeType(ReservationStatusType.Confirmed),
    },
    {
      key: 3,
      text: '방문완료',
      onClickHandler: () => onChangeType(ReservationStatusType.Visited),
    },
    // {
    //   key: 4,
    //   text: '예약재요청',
    //   onClickHandler: () => onChangeType(ReservationStatusType.Holding),
    // },
    {
      key: 5,
      text: '예약취소',
      onClickHandler: () => onChangeType(ReservationStatusType.Cancelled),
    },
    {
      key: 6,
      text: '노쇼',
      onClickHandler: () => onChangeType(ReservationStatusType.NoShow),
    },
  ];

  return {
    tabList,
  };
};

export default useReservationTabList;
