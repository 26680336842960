import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { GREY_2, GREY_3, WHITE } from '../../../../styles/colors';

interface GridStylesProps {
  width?: string;
  isSort?: boolean;
}

export const Thead = styled.thead`
  height: 48px;
`;

export const TBody = styled.tbody``;

export const Tr = styled.tr`
  height: 48px;

  th:first-of-type {
    position: -webkit-sticky;
    position: sticky;
    background-color: white;
    width: 90px;
    min-width: 90px;
    max-width: 90px;
    left: 0;
  }
  th:nth-of-type(2) {
    position: -webkit-sticky;
    position: sticky;
    background-color: white;
    width: 101px;
    min-width: 101px;
    max-width: 101px;
    left: 90px;
  }
`;

export const Header = styled.th(
  ({ width = '150px', isSort = false }: GridStylesProps) => css`
    border-top: 1px solid ${GREY_3};
    border-bottom: 1px solid ${GREY_3};
    text-align: center;
    height: 100%;
    padding: 0 13px 0 13px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.02em;
    color: ${GREY_2};
    min-width: ${width};
    background-color: ${WHITE};

    cursor: ${isSort ? 'pointer' : 'default'};
  `,
);
