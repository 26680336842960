import styled from '@emotion/styled';
import { WHITE } from '../../../styles/colors';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${WHITE};
  border-radius: 10px 10px 10px 10px;
  padding: 48px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

export const Search = styled.div`
  margin-bottom: 12px;
`;

export const Table = styled.table`
  width: auto;
  border-spacing: 0;
`;

export const Div = styled.div`
  width: 100%;
  overflow: auto hidden;
`;

export const PaginationDiv = styled.div`
  margin-top: 1rem;
`;
