export const enum ModalType {
  Alert = 'Alert',
  Register = 'Register',
  Toast = 'Toast',
  DetailSlide = 'DetailSlide',
  Detail = 'Detail',
  Reject = 'Reject',
  Confirmed = '예약 확정', // 예약 확정
  ReservationDetail = '예약 상세', // 예약 상세
  // Holding = '예약 보류', // 예약 보류
  Cancelled = '예약 취소', // 예약 취소
  Visited = '방문 완료', // 방문 완료
  Update = '예약 변경', // 예약 변경
  Information = '안내 사항', // 안내 사항
  Memo = '메모', // 메모
}

export const ModalDuration = {
  Alert: 200,
  Register: 0,
  Toast: 200,
  Slide: 450,
};
export interface ToastParams {
  ToastMessage: string;
  duration?: number;
}

export interface AlertModalParams {
  title?: string;
  content?: string;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export interface RegisterModalParams {
  title: string;
  contents: React.ReactNode | JSX.Element;
  registerText?: string;
  cancelText?: string;
  onClose?: () => void;
  onRegister?: () => void;
  onCancel?: () => void;
}

export interface DetailModalParams {
  title: string;
  contents: React.ReactNode | JSX.Element;
  // registerText?: string;
  // cancelText?: string;
  onClose?: () => void;
  // onRegister?: () => void;
  // onCancel?: () => void;
  width?: number;
  height?: number;
}

export interface SimpleModalParams {
  title: string;
  contents: React.ReactNode;
}

export type ModalParams = {
  [ModalType.Alert]?: AlertModalParams;
  [ModalType.Register]?: RegisterModalParams;
  [ModalType.Detail]?: DetailModalParams;
  [ModalType.Toast]?: ToastParams;
  [ModalType.Confirmed]?: SimpleModalParams;
  [ModalType.Reject]?: SimpleModalParams;
  [ModalType.DetailSlide]?: SimpleModalParams;
  [ModalType.ReservationDetail]?: SimpleModalParams;
  [ModalType.Cancelled]?: SimpleModalParams;
  [ModalType.Update]?: SimpleModalParams;
  [ModalType.Memo]?: SimpleModalParams;
  [ModalType.Visited]?: SimpleModalParams;
  [ModalType.Information]?: SimpleModalParams;
};
