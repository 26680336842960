import styled from '@emotion/styled';
import { AHEAD_MINT, GREY_1, GREY_2, GREY_3, GREY_4, GREY_5, PASTEL_MINT, WHITE } from 'styles/colors';
import { css } from '@emotion/react';
import { Button } from '../../common/buttons/Button';
import { BasicButton } from '../../common/buttons/BasicButton';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Table = styled.table`
  border: 1px solid ${GREY_4};
  border-collapse: collapse;
  width: 100%;
`;

export const Tr = styled.tr`
  border: 1px solid ${GREY_4};
`;

export const Th = styled.th`
  border: 1px solid ${GREY_4};
  width: 160px;
  background-color: ${GREY_5};
  padding: 20px;
  text-align: left;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  vertical-align: top;
  color: ${GREY_1};
`;
export const Td = styled.td`
  border: 1px solid ${GREY_4};
  padding: 20px;
  text-align: left;
  font-weight: normal;
  font-style: normal;
  color: ${GREY_2};
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
`;

export const DayWrapper = styled.div`
  margin-bottom: 10px;
  :last-child {
    margin-bottom: 0;
  }
  .day {
    color: ${GREY_1};
    margin-right: 36px;
  }
  .time {
    //
  }
`;

export const TdText = styled.div`
  padding-bottom: 10px;
  :last-child {
    padding-bottom: 0px;
  }
`;

export const MemberTabWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  margin-bottom: 44px;
`;

export const UserButton = styled(Button)(
  () => css`
    display: flex;
    flex-direction: row;
    width: 112px;
    height: 38px;

    align-items: flex-start;
    padding: 12px 16px;
    background: ${WHITE};
    border: 1px solid ${GREY_3};
    box-sizing: border-box;
    border-radius: 5px;

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    letter-spacing: -0.02em;
    white-space: nowrap;
    color: ${GREY_1};

    &.active {
      /* Pastel Mint */
      background: ${PASTEL_MINT} !important;
      /* Main/Fiepet Mint */
      border: 1px solid ${AHEAD_MINT} !important;
      font-weight: bold;
      /* 구버전/Fitpet Mint */
      color: #2eb4bd !important;
    }

    &.inactive {
      /* Pastel Mint */
      background: ${GREY_5};
      /* Main/Fiepet Mint */
      border: 1px solid ${GREY_3};
      font-weight: bold;
      /* 구버전/Fitpet Mint */
      color: ${GREY_3};
      cursor: default;
    }
  `,
);

export const AddButton = styled(Button)(
  ({ radius = 20 }: { radius: number }) => css`
    border-radius: ${radius}px;
    background-color: ${AHEAD_MINT};
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 10px 16px;
    width: 128px;
    height: 36px;
    // 글씨
    color: ${WHITE};
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.02em;
  `,
);

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 36px;
`;

export const CancelButton = styled(BasicButton)`
  border: 1px solid ${GREY_3};
  color: ${GREY_1};
  background-color: ${WHITE};
  margin-right: 8px;
  font-weight: normal;
  border-radius: 5px;
`;

export const EditWrapper = styled.div`
  display: flex;
  flex-direction: column;
  div:first-of-type {
    margin-top: 0;
  }
`;

export const TempHolidayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 52px;
`;
export const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${GREY_1};
  margin-bottom: 20px;
`;

export const CheckBoxWrap = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    margin-left: 8px;
  }
`;

export const TempHoliday = styled.div`
  display: flex;
  margin-top: 18px;

  span.title {
    /* 휴무일 */

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;

    color: ${GREY_2};

    margin-right: 24px;
    margin-top: 17px;
  }
`;

export const SelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const TimeBetweenLabel = styled.span`
  color: ${GREY_2};
`;

export const RadioWrapper = styled.div`
  display: flex;
  gap: 90px;
`;

export const RadioLabel = styled.label`
  display: flex;
  cursor: pointer;
`;

export const RadioButton = styled.input`
  width: 18px;
  height: 18px;
  border: 1px solid ${GREY_3};
  border-radius: 50%;
  margin-right: 12px;
  cursor: pointer;

  &:checked {
    border: 6px solid ${AHEAD_MINT};
  }
`;
