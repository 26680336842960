import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _, { isEmpty } from 'lodash';
import * as Styles from './style';
import TextInput from '../../common/TextInput';
import { BasicButton } from '../../common/buttons/BasicButton';
import DateTime from '../../member/DateTime';
import BasicTextArea from '../../common/Textarea/BasicTextArea';
import PetSelect, { ADD_NEW_PET } from '../PetSelect';
import SelectBox, { OptionValueProps } from '../../common/SelectBox';
import { OperationType, OperationTypeToString, ReservationAvailableHour } from '../../../model/reservation';
import { scheduleAction } from '../../../features/schedule/scheduleSlice';
import {
  useAvailableDays,
  useAvailableHours,
  useGetMemberList,
  useSaveNonUser,
} from '../../../query/Member/useMemberQuery';
import { RootState } from '../../../reducers';
import CheckBoxIcon from '../../icons/CheckBoxIcon';
import { Pet, PetType } from '../../../model/pet';
import UserSearchInput from '../UserSearchInput';
import { UserInfoDto, UserType } from '../../../model/user';
import { useGetPetBreeds, useSavePet, useSaveSchedule } from '../../../query/Schedule/useScheduleQuery';
import SelectSearchBox from '../../common/SelectSearchBox';
import { ModalHandler } from '../../../utils/ModalHandler';
import { ModalType } from '../../../constants/modal';

interface IAddScheduleModalProps {
  isSchedulePage?: boolean;
}

const AddScheduleModal = ({ isSchedulePage = false }: IAddScheduleModalProps) => {
  const dispatch = useDispatch();
  const save = useSaveSchedule();
  const savePet = useSavePet();
  const saveNonUser = useSaveNonUser();
  const reservation = useSelector((state: RootState) => state.schedule.editReservation);
  const editPet = useSelector((state: RootState) => state.schedule.editPet);
  const editPetList = useSelector((state: RootState) => state.schedule.editPetList);
  const { data: memberList, isLoading: memberLoading } = useGetMemberList();
  const { data: petBreedList, isLoading: petBreedLoading } = useGetPetBreeds();

  const defaultTimesParams = useMemo(() => {
    if (!_.isEmpty(reservation.hospitalMember) && !_.isEmpty(reservation.reservationTime)) {
      return {
        hospitalMemberId: `${reservation.hospitalMember.id}`,
        desiredDate: moment(reservation.reservationTime).format('YYYY-MM-DD'),
      };
    }
    return { hospitalMemberId: '', desiredDate: '' };
  }, [reservation.hospitalMember, reservation.reservationTime]);
  const [timesParams, setTimeParams] = useState(defaultTimesParams);

  const { data: timeResponse, isLoading: timeLoading } = useAvailableHours(
    timesParams.hospitalMemberId,
    timesParams.desiredDate,
  );

  const availableTimes = useMemo(() => {
    if (!_.isEmpty(timeResponse) && !timeLoading) {
      return timeResponse as ReservationAvailableHour[];
    }
    // 기본 병원운영시간
    return [] as ReservationAvailableHour[];
  }, [timeResponse, timeLoading]);

  const [memberId, setMemberId] = useState(-1);
  const [calendarMonth, setCalendarMonth] = useState(moment().format('YYYY-MM'));
  const { data: dayResponse, isLoading: dayLoading } = useAvailableDays(memberId, calendarMonth);

  useEffect(() => {
    setMemberId(reservation.hospitalMember.id);
  }, [reservation.hospitalMember.id]);

  const onChangeMonthCalendar = useCallback((yyyyMM) => {
    setCalendarMonth(yyyyMM);
  }, []);

  const availableDays = useMemo(() => {
    if (!dayLoading) {
      // console.info('dayResponse', dayResponse);
      return dayResponse;
    }
    // 기본 병원운영시간
    return [];
  }, [dayResponse, dayLoading]);

  const isNewMember = useMemo(() => {
    return reservation.user.id === -1;
  }, [reservation.user.id]);

  // 펫 품종 목록
  const breedList = useMemo(() => {
    if (petBreedLoading) {
      return [] as OptionValueProps[];
    }
    return petBreedList
      ?.filter((breed) => {
        return breed.petType === editPet.petType;
      })
      .map((breed) => {
        return {
          value: breed.breedCode,
          label: breed.breedName,
        };
      }) as OptionValueProps[];
  }, [petBreedLoading, petBreedList, editPet]);

  const petList = useMemo(() => {
    if (isEmpty(editPetList)) {
      return [ADD_NEW_PET] as OptionValueProps[];
    }

    return [
      ...editPetList.map((pet) => {
        return {
          value: String(pet.id),
          label: pet.name,
        };
      }),
      ADD_NEW_PET,
    ];
  }, [editPetList]);

  const selectedPet = useMemo(() => {
    return petList.find((pet) => pet.value === String(reservation.pet.id));
    // const findPet = petList.find((pet) => pet.value === String(reservation.pet.id));
    // return findPet || { value: '', label: '' };
  }, [petList, reservation.pet.id]);

  const selectPetBreedOption = useMemo(() => {
    const item = breedList.find((breed) => breed.value === editPet.species);
    if (item) {
      const species = item.value;
      return {
        value: species,
        label: editPet.petBreedName,
      };
    }
    return { value: '', label: '' };
  }, [breedList, editPet]);

  // 담당자 지정 - 진료의사 목록
  const doctors = useMemo(() => {
    if (memberLoading || !memberList) return [];
    return memberList.map((m) => {
      return {
        value: String(m.id),
        label: `${m.name} 수의사`,
      } as OptionValueProps;
    });
  }, [memberList, memberLoading]);

  const selectedDoctor = useMemo(() => {
    return doctors?.find((doctor) => doctor.value === String(reservation.hospitalMember.id));
  }, [doctors, reservation.hospitalMember.id]);

  // 진료 서비스
  const operationTypeList = useMemo(() => {
    return Object.keys(OperationTypeToString).map(
      (key) =>
        ({
          value: key,
          label: OperationTypeToString[key as OperationType],
        } as OptionValueProps),
    );
  }, []);

  const operationType = useMemo(() => {
    const item = operationTypeList.find((op) => op.value === reservation.serviceType);
    return item || operationTypeList[0];
  }, [reservation.serviceType, operationTypeList]);

  // const [userPhone, setUserPhone] = useState('');
  const [activeMember, setActiveMember] = useState(false);
  const [activePet, setActivePet] = useState(false);
  const [addPetButton, setAddPetButton] = useState(false);

  const onSelectUser = useCallback(
    (user: UserInfoDto) => {
      dispatch(scheduleAction.updateEditUser(user));
      setActiveMember(true);
    },
    [dispatch],
  );

  const desiredDate = useMemo(() => {
    if (reservation.reservationTime) {
      return moment(reservation.reservationTime).format('YYYY-MM-DD');
    }
    return '';
  }, [reservation.reservationTime]);

  useEffect(() => {
    if (reservation.hospitalMember?.id && !_.isEmpty(desiredDate) && moment(desiredDate).isValid()) {
      setTimeParams({
        desiredDate,
        hospitalMemberId: String(reservation.hospitalMember.id),
      });
    }
  }, [reservation.hospitalMember, desiredDate]);

  const updateFields = useCallback(
    (key, value) => {
      dispatch(scheduleAction.updateEditField({ key, value }));
    },
    [dispatch],
  );

  const updateFetFields = useCallback(
    (key, value) => {
      // console.info('updateFetFields', key, value);
      dispatch(scheduleAction.updateEditFetField({ key, value }));
    },
    [dispatch],
  );

  const changePet = useCallback(
    (selectPetId) => {
      setActivePet(true);
      if (selectPetId === ADD_NEW_PET.value) {
        // onAddRequest 에서 처리
        dispatch(scheduleAction.updateEditField({ key: 'pet', value: { id: ADD_NEW_PET.value } }));
      } else {
        // 기존 반려동물 정보 set
        const findPet = editPetList.find((pet) => pet.id === Number(selectPetId));
        if (findPet) {
          dispatch(scheduleAction.updateEditFet(findPet));
          setAddPetButton(false);
        }
      }
    },
    [dispatch, editPetList],
  );

  const onAddPet = useCallback(() => {
    // validation
    let message = '';
    const petBirth = editPet.birthday.replace(/\D/g, '');
    const petNames = editPetList.map((pet) => pet.name);
    // 이름
    if (_.isEmpty(editPet.name)) {
      message = '반려동물의 이름을 입력하세요.';
    }
    // 반려동물 이름 중복
    else if (petNames.includes(editPet.name.trim())) {
      message = '동일한 반려동물의 이름이 있습니다.';
    }
    // 생년월일
    else if (petBirth.length !== 8) {
      message = '반려동물의 생년월일을 입력하세요.';
    }
    // 몸무게
    else if (_.isEmpty(editPet.weight.replace(/\D/g, ''))) {
      message = '반려동물의 몸무게를 입력하세요.';
    }
    // 몸무게
    else if (editPet.weight.replace(/\d/g, '').replace('.', '').length > 0 || Number(editPet.weight) <= 0) {
      message = '올바른 반려동물의 몸무게를 입력하세요.';
    }
    // 종류
    else if (_.isEmpty(editPet.petType)) {
      message = '반려동물의 종류를 선택하세요.';
    }
    // 품종
    else if (_.isEmpty(editPet.species)) {
      message = '반려동물의 품종을 입력하세요.';
    }
    // 성별
    else if (_.isEmpty(editPet.gender)) {
      message = '반려동물의 성별을 선택하세요.';
    }

    if (!isEmpty(message)) {
      ModalHandler.show(ModalType.Toast, {
        ToastMessage: message,
      });
      return;
    }
    const pet: Pet = {
      ...editPet,
      userId: reservation.user.id,
    };
    savePet.mutate({ data: _.omit(pet, ['id']) as Pet, userType: reservation.userType });
    setAddPetButton(false);
    // savePet.mutate(editPet, reservation.userType);
  }, [editPet, editPetList, reservation.user.id, reservation.userType, savePet]);

  const onAddUser = useCallback(() => {
    // validation
    let message = '';
    // 보호자 이름
    if (_.isEmpty(reservation.user.name)) {
      message = '보호자 이름을 입력하세요.';
    }
    // 가회원(N)은 생년월일 필수
    // else if (reservation.userType === UserType.NON_USER && reservation.user.birthday.trim().length !== 8) {
    //   message = '생년월일을 입력하세요.';
    // }

    if (!isEmpty(message)) {
      ModalHandler.show(ModalType.Toast, {
        ToastMessage: message,
      });
      return;
    }
    saveNonUser.mutate({
      name: reservation.user.name,
      tel: reservation.user.tel,
      birthday: reservation.user.birthday,
    });
  }, [reservation.user.birthday, reservation.user.name, reservation.user.tel, saveNonUser]);

  const onConfirm = useCallback(() => {
    // validation
    let message = '';
    // 보호자 이름
    if (_.isEmpty(reservation.user.name)) {
      message = '보호자 이름을 입력하세요.';
    }
    // 가회원(N)은 생년월일 필수
    // else if (reservation.userType === UserType.NON_USER && reservation.user.birthday.trim().length !== 8) {
    //   message = '생년월일을 입력하세요.';
    // }
    else if (reservation.pet.id === -1 || String(reservation.pet.id) === ADD_NEW_PET.value) {
      message = '반려동물을 선택해주세요';
    } else if (_.isEmpty(reservation.serviceType)) {
      message = '진료 서비스를 선택해주세요';
    } else if (reservation.hospitalMember.id === -1) {
      message = '담당자를 선택해주세요';
    } else if (_.isEmpty(reservation.reservationTime)) {
      message = '방문 희망 일시를 선택해주세요';
    } else if (moment(reservation.reservationTime).isBefore(moment())) {
      message = `예약 시간은 현재시간보다\n이후여야 합니다.`;
    }
    // else if (_.isEmpty(reservation.message)) {
    //   message = `안내사항을 입력해주세요.`;
    // }

    // 방문일시가 유효한지 의사시간이 비었는지 체크
    if (availableTimes.length > 0) {
      const hour = moment(reservation.reservationTime).format('HH:mm');
      const availableTime = availableTimes.find((t) => t.time === hour && t.isAvailable);
      if (!availableTime) {
        message = '예약가능한 시간을 선택해주세요';
      }
    } else {
      message = '예약가능한 시간을 선택해주세요';
    }

    if (!isEmpty(message)) {
      ModalHandler.show(ModalType.Toast, {
        ToastMessage: message,
      });
      return;
    }
    save.mutate(reservation);
  }, [reservation, availableTimes, save]);

  // 선택한 날짜에 담당자 선택 시 예약이 가능한지 체크 (isSchedulePage 값이 true 일때만 동작)
  const checkAvailableDoctor = useCallback(
    (checkedTimes: ReservationAvailableHour[]) => {
      const targetTime = moment(reservation.reservationTime).format('HH:mm');
      if (reservation.hospitalMember?.name && !checkedTimes?.find((item) => item.time === targetTime)?.isAvailable) {
        alert(`${reservation?.hospitalMember.name} 담당자는 해당 시간에 예약 건이 있어 예약이 불가능합니다.`);
        dispatch(scheduleAction.updateEditField({ key: 'hospitalMember', value: { id: -1 } }));
      }
    },
    [dispatch, reservation.hospitalMember.name, reservation.reservationTime],
  );

  useEffect(() => {
    if (!isSchedulePage) return;
    if (!_.isEmpty(timeResponse) && !timeLoading) {
      checkAvailableDoctor(timeResponse as ReservationAvailableHour[]);
    }
  }, [isSchedulePage, timeResponse, timeLoading, checkAvailableDoctor]);

  return (
    <>
      <Styles.Wrapper>
        <Styles.LeftWrapper>
          <Styles.ProtectorWrapper>
            <UserSearchInput onChange={(user) => onSelectUser(user)} />
            {/* {activeMember && ( */}
            {/*  <Styles.InfoLabel>해당 전화번호로 정보제공 동의가 된 핏펫회원이 존재합니다.</Styles.InfoLabel> */}
            {/* )} */}
            {isNewMember && (
              <p>
                * 애플로 로그인 및 가입하신 경우 확인이 어렵습니다.
                <br />
                핏펫 회원 가입이 필요한 고객님께서는 애플이 아닌
                <br /> 다른 방법으로 가입 안내 부탁드립니다.
              </p>
            )}
          </Styles.ProtectorWrapper>

          <Styles.AirBackWrapper need={!activeMember} />
          <Styles.BirthDayWrapper>
            <TextInput<string>
              name="보호자 이름"
              value={reservation.user.name}
              onChangeField={(key, value) => {
                const user = { ...reservation.user, name: value };
                updateFields('user', user);
              }}
              label="userName"
              placeholder="한글, 영문 최대 8자"
              limit={8}
              disabled={!activeMember}
              readOnly={reservation.userType === UserType.USER}
              required
            />
            {isNewMember && (
              <Styles.SmallActionButtonWrapper>
                <Styles.SmallActionButton onClick={() => onAddUser()}>추가하기</Styles.SmallActionButton>
              </Styles.SmallActionButtonWrapper>
            )}
          </Styles.BirthDayWrapper>

          <TextInput<string>
            name="생년월일"
            value={reservation.user.birthday}
            onChangeField={(key, value) => {
              const user = { ...reservation.user, birthday: value };
              updateFields('user', user);
            }}
            label="birthday"
            placeholder="YYYYMMDD"
            limit={8}
            disabled={!activeMember}
            readOnly={reservation.userType === UserType.USER}
            // required={reservation.userType === UserType.NON_USER}
          />

          <Styles.LabelWrapper disabled={!activeMember}>
            <span className="title">반려동물 이름 *</span>
            <PetSelect
              disabled={!activeMember || isNewMember}
              items={petList}
              onChange={(selectValue) => {
                changePet(selectValue);
              }}
              onAddRequest={() => {
                dispatch(scheduleAction.initEditPet());
                setAddPetButton(true);
              }}
              value={selectedPet}
            />
          </Styles.LabelWrapper>

          <Styles.LabelWrapper disabled={!activeMember}>
            <span className="title">진료 서비스 *</span>
            <SelectBox
              disabled={!activeMember || isNewMember}
              items={operationTypeList}
              value={operationType}
              onChange={(value) => updateFields('serviceType', value)}
            />
          </Styles.LabelWrapper>

          <Styles.LabelWrapper disabled={!activeMember}>
            <span className="title">담당자 지정 *</span>
            <SelectBox
              optionHeight={300}
              items={doctors}
              disabled={!activeMember || isNewMember}
              onChange={(value) => {
                const hospitalMember = memberList?.find((member) => member.id === Number(value));
                if (hospitalMember) {
                  updateFields('hospitalMember', hospitalMember);
                }
              }}
              value={selectedDoctor}
            />
          </Styles.LabelWrapper>

          <Styles.LabelWrapper disabled={!activeMember}>
            <span className="title">방문 희망 일시 *</span>
            <DateTime
              disabled={!activeMember || isNewMember || reservation.hospitalMember.id === -1}
              disabledTime={
                _.isEmpty(reservation.reservationTime) ||
                !reservation.hospitalMember.id ||
                reservation.hospitalMember.id === -1
              }
              dateTop
              minDate={moment().format('YYYY-MM-DD')}
              initDate={moment(reservation.reservationTime).format('YYYY-MM-DD')}
              initTime={moment(reservation.reservationTime).format('HH:mm')}
              availableDays={availableDays}
              availableTimes={availableTimes}
              onChangeDate={(dateStr, timeStr) => {
                updateFields('reservationTime', `${dateStr} ${timeStr}`);
              }}
              onChangeTime={(timeStr, dateStr) => {
                updateFields('reservationTime', `${dateStr} ${timeStr}`);
              }}
              onChangeMonth={(yyyyMM) => {
                onChangeMonthCalendar(yyyyMM);
              }}
              optionHeight={287}
            />
          </Styles.LabelWrapper>

          <Styles.TextAreaWrapper disabled={!activeMember}>
            <span className="title">안내사항</span>
            <BasicTextArea
              disabled={!activeMember || isNewMember}
              placeholder="보호자에게 안내할 내용을 입력해 주세요."
              value={reservation.message || ''}
              onChange={(str) => updateFields('message', str)}
              size={60}
            />
          </Styles.TextAreaWrapper>
        </Styles.LeftWrapper>

        <Styles.RightWrapper>
          <Styles.PetTitleWrapper>
            <Styles.PetTitle>반려동물 정보</Styles.PetTitle>
            <Styles.PetLine />
          </Styles.PetTitleWrapper>

          <Styles.PetDetailWrapper>
            <TextInput<string>
              disabled={!activePet}
              required
              name="이름"
              value={editPet.name}
              // onChangeField={(key, value) => setPetName(value)}
              onChangeField={(key, value) => updateFetFields(key, value)}
              label="name"
              placeholder="한글, 영문 최대 8자"
              limit={8}
              readOnly={!addPetButton}
            />
            <TextInput<string>
              disabled={!activePet}
              required
              name="생년월일"
              value={editPet.birthday}
              onChangeField={(key, value) => updateFetFields(key, value)}
              label="birthday"
              placeholder="YYYYMMDD"
              limit={8}
              readOnly={!addPetButton}
            />

            <TextInput<string>
              disabled={!activePet}
              required
              name="몸무게 (kg)"
              value={editPet.weight}
              onChangeField={(key, value) => updateFetFields(key, value)}
              label="weight"
              placeholder="0.0"
              limit={8}
              readOnly={!addPetButton}
            />

            <Styles.RadioButtonWrapper disabled={!activePet}>
              <Styles.TitleLabel>종류 *</Styles.TitleLabel>
              <BasicButton
                disabled={!activePet}
                className={`${editPet.petType === PetType.DOG ? 'active' : ''}`}
                onClick={() => {
                  if (addPetButton) {
                    updateFetFields('petType', PetType.DOG);
                    updateFetFields('species', '');
                  }
                }}>
                강아지
              </BasicButton>
              <BasicButton
                disabled={!activePet}
                className={`${editPet.petType === PetType.CAT ? 'active' : ''}`}
                onClick={() => {
                  if (addPetButton) {
                    updateFetFields('petType', PetType.CAT);
                    updateFetFields('species', '');
                  }
                }}>
                고양이
              </BasicButton>
            </Styles.RadioButtonWrapper>

            <Styles.LabelWrapper disabled={!activePet}>
              <span className="title">품종 *</span>
              <SelectSearchBox
                disabled={!activePet}
                items={breedList}
                onChange={(value, label) => {
                  updateFetFields('species', value);
                  updateFetFields('petBreedName', label);
                }}
                value={selectPetBreedOption}
                optionHeight={250}
                readOnly={!addPetButton}
              />
            </Styles.LabelWrapper>

            <Styles.RadioButtonWrapper disabled={!activePet}>
              <Styles.TitleLabel>성별 *</Styles.TitleLabel>
              <BasicButton
                disabled={!activePet}
                className={`${editPet.gender === 'M' ? 'active' : ''}`}
                onClick={() => {
                  if (addPetButton) {
                    updateFetFields('gender', 'M');
                  }
                }}>
                수컷
              </BasicButton>
              <BasicButton
                disabled={!activePet}
                className={`${editPet.gender === 'F' ? 'active' : ''}`}
                onClick={() => {
                  if (addPetButton) {
                    updateFetFields('gender', 'F');
                  }
                }}>
                암컷
              </BasicButton>
            </Styles.RadioButtonWrapper>

            <Styles.CheckBoxWrap
              disabled={!activePet}
              role="button"
              onClick={() => {
                if (activePet && addPetButton) {
                  updateFetFields('isNeutralized', !editPet.isNeutralized);
                }
              }}>
              <CheckBoxIcon checked={activePet && editPet.isNeutralized} />
              <span>중성화를 했어요</span>
            </Styles.CheckBoxWrap>
            {addPetButton && (
              <Styles.SmallActionButtonWrapper>
                <Styles.SmallActionButton disabled={!activePet} onClick={() => onAddPet()}>
                  추가하기
                </Styles.SmallActionButton>
              </Styles.SmallActionButtonWrapper>
            )}
          </Styles.PetDetailWrapper>
        </Styles.RightWrapper>
      </Styles.Wrapper>
      <Styles.ButtonWrapper>
        <Styles.InfoMessage>※ 핏펫회원의 회원정보 변경은 핏펫앱에서만 가능합니다.</Styles.InfoMessage>
        <BasicButton disabled={!activeMember || isNewMember} onClick={() => onConfirm()}>
          예약생성
        </BasicButton>
      </Styles.ButtonWrapper>
    </>
  );
};

export default AddScheduleModal;
