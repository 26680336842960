import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import * as Styles from './styles';
import { ReservationFieldKey } from '../../../model/reservation';
import SearchIcon from '../../icons/SearchIcon';
import ReservationGrid, { ReservationGridHeader } from '../ReservationGrid';
import { selectReservationsParams } from '../../../selectors';
import { reservationAction } from '../../../features/reservation/reservationSlice';
import ChoiceCalendarButton from '../ChoiceCalendarButton';
import { useGetReservations } from '../../../query/Reservation/useReservationQuery';
import { SortType } from '../../icons/GridSortArrowIcon';
import useSchedule from '../../../hooks/schedule/useSchedule';

const initialHeaders = [
  { name: '보호자 이름', value: ReservationFieldKey.UserName },
  { name: '보호자 휴대폰', value: ReservationFieldKey.Tel },
  { name: '담당 수의사', value: ReservationFieldKey.HospitalMemberName },
  { name: '예약 종류', value: ReservationFieldKey.ServiceType },
  { name: '반려동물 이름', value: ReservationFieldKey.PetName },
  { name: '반려동물 종류', value: ReservationFieldKey.PetBreed },
  { name: '진료 예정일', value: ReservationFieldKey.ReservationTime, sort: true },
  { name: '예약 생성일', value: ReservationFieldKey.CreatedAt, sort: true },
  { name: '예약 상태', value: ReservationFieldKey.ServiceStatus },
  { name: '', value: ReservationFieldKey.Buttons },
];

const Reservation = () => {
  const reservationsParams = useSelector(selectReservationsParams);
  const dispatch = useDispatch();

  const { openAddSchedule } = useSchedule();
  const { data: reservationTotalList } = useGetReservations(reservationsParams);
  const [searchText, setSearchText] = useState('');
  const [headers, setHeaders] = useState<ReservationGridHeader[]>(initialHeaders);

  const sortValue = useMemo(() => reservationsParams.sort?.split('|')[0], [reservationsParams.sort]);
  const sortType = useMemo(() => reservationsParams.sort?.split('|')[1], [reservationsParams.sort]);

  useEffect(() => {
    setHeaders((prev) => {
      prev.forEach((header) => {
        if (header.sort) {
          header.sortType = header.value === sortValue ? (sortType as SortType) : SortType.NORMAL;
        }
      });
      return [...prev];
    });
  }, [sortValue, sortType]);

  // 페이지 변경 이벤트
  const onPageChange = useCallback(
    (pageNum: number) => {
      dispatch(reservationAction.updateField({ key: ReservationFieldKey.PageNumber, value: pageNum }));
    },
    [dispatch],
  );

  // 정렬 변경 이벤트
  const onSortChange = useCallback(
    (value: ReservationFieldKey, sType: SortType) => {
      dispatch(reservationAction.updateField({ key: ReservationFieldKey.Sort, value: `${value}|${sType}` }));
    },
    [dispatch],
  );

  // 날짜 선택 이벤트
  const onChangeDate = useCallback(
    (date: string) => {
      dispatch(reservationAction.updateField({ key: ReservationFieldKey.DesiredDate, value: date }));
      onPageChange(0);
    },
    [dispatch],
  );

  // 검색 버튼 이벤트
  const doSearch = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        dispatch(reservationAction.updateField({ key: ReservationFieldKey.Search, value: searchText.trim() }));
        onPageChange(0);
      }
    },
    [dispatch, searchText],
  );
  // 검색 초기화 버튼 이벤트
  const doSearchReset = useCallback(() => {
    setSearchText('');
    dispatch(reservationAction.updateField({ key: ReservationFieldKey.Search, value: '' }));
    onPageChange(0);
  }, [dispatch]);

  const addSchedulePopup = useCallback(() => {
    const time = moment().add(1, 'hour').format(`YYYY-MM-DD HH:00`);
    openAddSchedule(moment(time), true);
  }, [openAddSchedule]);

  return (
    <Styles.Wrapper>
      <Styles.TopDiv>
        <Styles.TextWrapper>
          <Styles.SearchInput
            type="text"
            value={searchText}
            placeholder="고객명 또는 휴대폰번호"
            onKeyPress={doSearch}
            onChange={(e) => setSearchText(e.currentTarget.value)}
          />
          <Styles.IconWrapper onClick={() => doSearch({ key: 'Enter' })}>
            <SearchIcon />
          </Styles.IconWrapper>

          <Styles.TextRightWrapper>
            <Styles.ResetButton onClick={doSearchReset}>검색 초기화</Styles.ResetButton>
            {reservationsParams.search && (
              <Styles.SearchDesc>{`"${reservationsParams.search}"에 대한 검색결과입니다.`}</Styles.SearchDesc>
            )}
          </Styles.TextRightWrapper>
        </Styles.TextWrapper>

        <Styles.RightWraper>
          <Styles.ResetButton onClick={addSchedulePopup}>예약 생성</Styles.ResetButton>
          <ChoiceCalendarButton selectDate={reservationsParams.desiredDate} onChangeDate={onChangeDate} />
        </Styles.RightWraper>
      </Styles.TopDiv>
      {reservationTotalList && (
        <Styles.BodyDiv>
          <ReservationGrid
            headers={headers}
            pageNumber={reservationsParams.pageNumber || 0}
            sort={reservationsParams.sort || ''}
            totalList={reservationTotalList}
            onPageChange={onPageChange}
            onSortChange={onSortChange}
          />
        </Styles.BodyDiv>
      )}
    </Styles.Wrapper>
  );
};

export default Reservation;
