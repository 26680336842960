import React from 'react';
import { WHITE, GREY_4, AHEAD_MINT } from 'styles/colors';

interface CheckboxIconProps {
  checked: boolean;
}

const CheckBoxIcon = ({ checked }: CheckboxIconProps) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    {checked ? (
      <rect width="20" height="20" rx="3" fill={AHEAD_MINT} />
    ) : (
      <rect x="0.5" y="0.5" width="19" height="19" rx="2.5" fill="white" stroke="#D1D1D1" />
    )}
    <path
      d="M16.7834 6.33533L15.2795 4.86769C15.0798 4.67106 14.7473 4.67106 14.5344 4.86769L8.10671 11.4067L5.32553 8.7073C5.12584 8.51066 4.79335 8.51066 4.58048 8.7073L3.24987 10.0046C3.05017 10.2012 3.05017 10.5286 3.24987 10.7253L7.69415 15.0626C7.81376 15.1807 7.97359 15.22 8.13341 15.2067C8.29323 15.2197 8.45271 15.1804 8.57267 15.0626L16.7834 7.06898C16.9828 6.85937 16.9828 6.53197 16.7834 6.33533Z"
      fill={checked ? WHITE : GREY_4}
    />
  </svg>
);
export default CheckBoxIcon;
