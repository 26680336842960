import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { selectHospitalId } from '../../selectors';
import { QueryKey } from '../../constants/queryKey';
import { BASE_URL, HTTP } from '../../utils/api/http';
import { IReservationStatusDto } from '../../model/statistic';

export const useGetReservationStatus = (dayType: string, ids: number[]) => {
  const hospitalId = useSelector(selectHospitalId);

  const { data, error, isLoading } = useQuery(
    [QueryKey.RESERVATION_STATUS, hospitalId, dayType, ids],
    async () => {
      if (ids.length > 0) {
        // const query = ids.map((id) => String(id).replace(/,/g, `&hospitalMemberIds=`)).join('&');
        const query = ids
          .map((id) => String(id))
          .join(',')
          .replace(/,/g, `&hospitalMemberIds=`);
        const response = await HTTP.get<IReservationStatusDto[]>(
          `${BASE_URL}/api/hospitals/statistic/reservation-status/${hospitalId}?dayType=${dayType}&hospitalMemberIds=${query}`,
        );

        return response.data;
      }
      const response = await HTTP.get<IReservationStatusDto[]>(
        `${BASE_URL}/api/hospitals/statistic/reservation-status/${hospitalId}?dayType=${dayType}`,
      );

      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: hospitalId !== 0,
    },
  );

  return {
    data,
    error,
    isLoading,
  };
};
