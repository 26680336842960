import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { BarDatum, ResponsiveBar } from '@nivo/bar';
import { RootState } from 'src/reducers';
import { PetType } from 'src/model/pet';
import { AHEAD_MINT, GREY_6 } from 'styles/colors';
import BarChartLabel from './BarChartLabel';
import * as StatisticStyles from '../styles';

interface BreedStatisticProps {
  petType: PetType;
}

export default function BreedStatistic({ petType }: BreedStatisticProps) {
  const [petTypeData, setPetTypeData] = useState<BarDatum[]>([]);
  const { statisticData } = useSelector((state: RootState) => state.statistic);
  const chartData = useMemo(() => statisticData?.[petType === PetType.DOG ? 'dog' : 'cat'], [statisticData, petType]);

  useEffect(() => {
    if (!chartData) return;
    const dumpChartData = [...chartData];

    const isOnlyActive = chartData.filter((item) => item.petCount === chartData[0].petCount).length === 1;
    const data = dumpChartData.reverse().map((item) => ({
      id: item.breedName,
      value: item.petCount,
      percentage: item.percentage,
      color: item.petCount === chartData[0].petCount && isOnlyActive ? AHEAD_MINT : GREY_6,
    }));
    setPetTypeData(data);
  }, [chartData]);

  return (
    <StatisticStyles.Article>
      <h4>품종</h4>
      {chartData && (
        <div style={{ height: 40 * petTypeData.length + 20 }}>
          <ResponsiveBar
            data={petTypeData}
            tooltip={() => null}
            keys={['value']}
            indexBy="id"
            margin={{
              left: 16,
              right: 124,
            }}
            colors={({ data }) => data.color as string}
            enableGridY={false}
            enableGridX={false}
            label=""
            layout="horizontal"
            animate={false}
            layers={[
              'axes',
              'bars',
              'markers',
              'legends',
              'annotations',
              ({ bars }) => BarChartLabel({ bars }, 'type'),
            ]}
            axisBottom={null}
            padding={0.5}
            axisLeft={{
              tickSize: 0,
              format: (x) => `${petTypeData.length - petTypeData.findIndex((d) => d.id === x)}`,
            }}
          />
        </div>
      )}
    </StatisticStyles.Article>
  );
}
