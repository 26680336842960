import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { customerAction } from 'src/features/customer/customerSlice';
import { RootState } from 'src/reducers';
import * as Styles from './styles';
import { ICustomerHeader, customerHeaders, CustomerFieldKey } from '../../../model/customer';
import GridSortArrowIcon, { SortType } from '../../icons/GridSortArrowIcon';

const CustomerHeader = () => {
  const dispatch = useDispatch();
  const { customerParams } = useSelector((state: RootState) => state.customer);
  const [headers, setHeaders] = useState<ICustomerHeader[]>(customerHeaders);
  const sortTarget = useMemo(() => customerParams.sort?.split(',')[0], [customerParams.sort]);
  const sortType = useMemo(() => customerParams.sort?.split(',')[1], [customerParams.sort]);

  const onSortChanged = (header: ICustomerHeader) => {
    if (!header.sort) return;

    const headerSortType = header.sortType || SortType.NORMAL;
    const target: string = header.value;
    let type: string = '';

    switch (headerSortType) {
      case SortType.NORMAL:
        type = SortType.ASC;
        break;
      case SortType.ASC:
        type = SortType.DESC;
        break;
      default:
        type = SortType.NORMAL;
        break;
    }

    const value = type ? `${target},${type}` : '';
    dispatch(customerAction.updateField({ key: CustomerFieldKey.Sort, value }));
  };

  useEffect(() => {
    setHeaders((prev: ICustomerHeader[]) => {
      prev.forEach((header: ICustomerHeader) => {
        if (header.sort) {
          header.sortType = header.value === sortTarget ? (sortType as SortType) : SortType.NORMAL;
        }
      });
      return [...prev];
    });
  }, [sortTarget, sortType]);

  return (
    <Styles.Thead>
      <Styles.Tr>
        {headers.map((header, index) => (
          <Styles.Header
            key={header.name}
            width={index === customerHeaders.length - 1 ? '100%' : `150px`}
            isSort={header.sort}
            onClick={() => onSortChanged(header)}>
            {header.name}
            {header.sort && <GridSortArrowIcon sortType={header.sortType} />}
          </Styles.Header>
        ))}
      </Styles.Tr>
    </Styles.Thead>
  );
};

export default CustomerHeader;
