import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { AHEAD_MINT, GREY_1, WHITE, PASTEL_MINT } from 'styles/colors';

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const CheckBoxWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 36px;
  justify-content: flex-start;
`;

export const Type = styled.div`
  margin-top: 56px;
  font-size: 14px;
  font-weight: 700;
  color: ${GREY_1};
`;

export const Week = styled.ul`
  display: flex;
  min-width: 500px;
  flex-wrap: nowrap;
`;

export const Day = styled.li(
  ({ checked }: { checked: boolean }) => css`
    box-sizing: border-box;
    margin-right: 4px;
    padding: 12px 11px;
    border-radius: 5px;
    font-size: 14px;
    color: ${checked ? AHEAD_MINT : GREY_1};
    background-color: ${checked ? PASTEL_MINT : WHITE};
    font-weight: ${checked ? 700 : 400};
  `,
  'cursor: pointer;',
);

export const Checked = styled.div`
  font-size: 14px;
  width: fit-content;
  display: flex;
  align-items: center;
  margin-top: 22px;
  margin-bottom: 12px;
  margin-right: 8px;
  cursor: pointer;
  span {
    margin-left: 8px;
  }
`;

export const TimeSection = styled.div`
  display: flex;
`;
