import moment from 'moment';
import React from 'react';
import { DisplayDateTimeFormat } from 'src/constants/workHours';
import { ILastReservation, ReservationDateToString, ReservationStatusToString } from 'src/model/customer';
import * as CustomerStyles from '../styles';
import * as Styles from './styles';

interface CustomerLastReservationHistoryProps {
  lastReservationList: ILastReservation[];
}

export default function CustomerLastReservationHistory({ lastReservationList }: CustomerLastReservationHistoryProps) {
  return (
    <CustomerStyles.Article>
      <h3>지난 예약 내역</h3>

      <Styles.HistoryList>
        {lastReservationList.map((reservation, index) => (
          <li key={`${reservation.serviceStatus}-${reservation.reservationTime}`}>
            <span>{index + 1}</span>
            <span>{ReservationStatusToString[reservation.serviceStatus]}</span>
            <span>{ReservationDateToString[reservation.serviceStatus]}</span>
            <span>{moment(reservation.reservationTime).locale('ko').format(DisplayDateTimeFormat)}</span>
          </li>
        ))}
      </Styles.HistoryList>
    </CustomerStyles.Article>
  );
}
