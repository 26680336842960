import React from 'react';
import { BarDatum, ComputedBarDatum } from '@nivo/bar';
import { AHEAD_MINT } from 'styles/colors';

export default function BarChartLabel({ bars }: { bars: ComputedBarDatum<BarDatum>[] }, target: string) {
  const isTypeChart = target === 'type';

  return (
    <g>
      {bars.map(({ width, height, y, data }) => {
        const chartLabel = data.data.id as string;
        // 띄어쓰기 포함 8글자까지 보이고 자르기
        const ellipsisLabel = chartLabel.length > 8 && isTypeChart ? `${chartLabel.slice(0, 8)}...` : chartLabel;
        const isActiveLabel = data.data.color === AHEAD_MINT;

        return (
          <text
            key={chartLabel}
            transform={`translate(${width + 10}, ${y + height / 2})`}
            textAnchor="left"
            dominantBaseline="central"
            fill="#495057"
            style={{
              fontSize: '10px',
              lineHeight: '12px',
            }}>
            <tspan style={{ fontWeight: isActiveLabel ? 'bold' : 'normal' }}>{ellipsisLabel}</tspan>{' '}
            <tspan style={{ fontWeight: 'bold' }}>{data.value ?? 0}</tspan>({data.data.percentage || 0}%)
          </text>
        );
      })}
    </g>
  );
}
