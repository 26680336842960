import React from 'react';
import * as Styles from './styles';

export interface LoadingProps {
  position?: string;
}

const Loading = ({ position = 'fixed' }: LoadingProps) => (
  <Styles.LoadingWrapper position={position}>
    <Styles.Loading>
      <div className="space ball" />
      <div className="space ball" />
      <div className="space ball" />
    </Styles.Loading>
  </Styles.LoadingWrapper>
);

export default Loading;
