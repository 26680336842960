import { Pet } from './pet';
import { MemberInfoDto } from './member';
import { UserDto, UserType } from './user';

export enum ReservationStatusType {
  /** 전체 */
  All = 'ALL',
  /** 예약대기 */
  Applied = 'APPLIED',
  /** 예약확정 */
  Confirmed = 'CONFIRMED',
  /** 방문완료 */
  Visited = 'VISITED',
  /** 예약취소 */
  Cancelled = 'CANCELLED',
  /** 노쇼 */
  NoShow = 'NO_SHOW',
  /** 블락 스케줄 (수의사) */
  Block = 'BLOCK',
  /** 예약취소 */
  // Holding = 'HOLDING',
}

export const ReservationStatusTypeToString: Partial<Record<ReservationStatusType, string>> = {
  [ReservationStatusType.All]: '전체',
  [ReservationStatusType.Applied]: '예약대기',
  [ReservationStatusType.Confirmed]: '예약확정',
  [ReservationStatusType.Visited]: '방문완료',
  [ReservationStatusType.Cancelled]: '예약취소',
  [ReservationStatusType.NoShow]: '노쇼',
  [ReservationStatusType.Block]: '예약 불가',
  // [ReservationStatusType.Holding]: '예약취소',
};

export const ReservationBadgeDescToString: Partial<Record<ReservationStatusType, string>> = {
  [ReservationStatusType.All]: '',
  [ReservationStatusType.Applied]: '고객이 기다리지 않도록 예약을 확정해 주세요!',
  [ReservationStatusType.Confirmed]: '확정된 예약입니다.',
  [ReservationStatusType.Visited]: '고객이 방문을 완료한 예약입니다.',
  [ReservationStatusType.Cancelled]: '취소된 예약입니다.',
  [ReservationStatusType.NoShow]: '고객이 방문을 하지 않은 예약입니다.',
  // [ReservationStatusType.Holding]: '취소된 예약입니다.',
};

export enum OperationType {
  /** 일반진료 */
  Diagnosis = 'DIAGNOSIS',
  /** 건강검진 */
  HealthScreening = 'HEALTH_SCREENING',
  /** 종합백신 */
  ComprehensiveVaccine = 'COMPREHENSIVE_VACCINE',
  /** 심장사상충 */
  HeartwormPrevention = 'HEARTWORM_PREVENTION',
  /** 광견병접종 */
  RabiesPrevention = 'RABIES_PREVENTION',
  /** 구충 */
  Deworming = 'DEWORMING',
  /** 기타백신 */
  OtherVaccines = 'OTHER_VACCINES',
  /** 상담진료 */
  Consulting = 'CONSULTING',
}

export const OperationTypeToString: Partial<Record<OperationType, string>> = {
  [OperationType.Diagnosis]: '일반진료',
  [OperationType.HealthScreening]: '건강검진',
  [OperationType.ComprehensiveVaccine]: '종합백신',
  [OperationType.HeartwormPrevention]: '심장사상충',
  [OperationType.RabiesPrevention]: '광견병접종',
  [OperationType.Deworming]: '구충',
  [OperationType.OtherVaccines]: '기타백신',
  [OperationType.Consulting]: '상담진료',
};

export enum ReservationFieldKey {
  ServiceStatus = 'serviceStatus',
  ServiceType = 'serviceType',
  Search = 'search',
  ReservationDate = 'reservationDate',
  ReservationTime = 'reservationTime',
  HospitalMember = 'hospitalMember',
  HospitalMemberName = 'hospitalMemberName',
  Reason = 'reason',
  DesiredDate = 'desiredDate',
  PageNumber = 'pageNumber',
  Sort = 'sort',
  Buttons = 'buttons',
  UserName = 'userName',
  Tel = 'tel',
  PetName = 'petName',
  PetBreed = 'petBreed',
  CreatedAt = 'createdAt',
}

export interface DropboxItemType {
  id: number;
  name: string;
}

export interface ReservationsParams {
  serviceStatus?: ReservationStatusType;
  search?: string;
  desiredDate?: string;
  startDate?: string;
  endDate?: string;
  hospitalMemberId?: number;
  pageNumber?: number;
  pageSize?: number;
  sort?: string;
}

export interface Reservation extends BlockReservation {
  /** 예약 ID */
  id: number;
  /** 예약자 명 */
  userName: string;
  /** 예약자 전화번호 */
  tel: string;
  /** 펫 이름 */
  petName: string;
  /** 펫 종류 */
  petBreedName: string;
  /** 서비스 타입 */
  serviceType: OperationType;
  /** 예약 상태 */
  serviceStatus: ReservationStatusType;
  /** 예약 시간 */
  reservationTime: string;
  /** 예약 생성 시간 */
  createdAt: string;
  /** 유저 타입: U("회원", 0) | N("가회원", 1) */
  userType: UserType;
  /** 병원 맴버 정보 (담당의사 ID) */
  hospitalMemberId: number;
  /** 병원 멤버 이름 */
  hospitalMemberName: string;
  /** 진료 타입: 일반진료 | 예방접종 | 건강검진 | 상담진료 */
  reserveType: string;
}

export interface ReservationDetail {
  /** 예약 ID */
  id: number;
  /** 유저 정보 */
  user: UserDto;
  /** 펫 정보 */
  pet: Pet;
  /** 병원 맴버 정보 */
  hospitalMember: MemberInfoDto;
  /** 병원 ID */
  hospitalId: number;
  /** 서비스 타입 */
  serviceType: OperationType;
  /** 예약 번호 */
  reservationNo: String;
  /** 예약 상태 */
  serviceStatus: ReservationStatusType;
  /** 비회원 예약 시간 */
  reservationTime: string;
  /** 예약시 요청사항 */
  userRequest?: string;
  /** 메모 */
  memo?: string;
  /** 예약 생성 시간 */
  createdAt?: string;
  /** 사유 */
  reason?: string;
  /** 전달사항 */
  message?: string;
  /** 유저 타입: U("회원", 0) | N("가회원", 1) */
  userType: UserType;
  /** 진료 타입: 일반진료 | 예방접종 | 건강검진 | 상담진료 */
  reserveType: string;
}

export interface ReservationStatusChangeParams {
  /** 예약 ID */
  id: number;
  /** 예약 상태 */
  serviceStatus: ReservationStatusType;
  /** 변경 사유 */
  reason?: string;
  /** 안내 사항 */
  message?: string;
  /** 유저 타입: U("회원", 0) | N("가회원", 1) */
  userType: UserType;
}

export interface ReservationAvailableHour {
  time: string; // HH:mm
  isAvailable: boolean;
}

export interface BlockReservation {
  closedTimeStart?: string;
  closedTimeEnd?: string;
  closedType?: 'VET';
  hospitalMember?: MemberInfoDto;
}

export interface BlockReservationDTO {
  memberId: number;
  closedTimeStart: string;
  closedTimeEnd: string;
}
