import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { LocalStorageKey } from 'src/constants/localStorage';
import { LocalStorage } from '../LocalStorage';
import { HttpCode } from '../../constants/http';

export const { CancelToken } = axios;

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const { BASE_IMAGE_URL } = process.env;
export const USE_MOCK_API = process.env.MOCK_API === 'true';

const accessToken = LocalStorage.getValue(LocalStorageKey.ACCESSTOKEN);
// const isAutoLogin = LocalStorage.getValue(LocalStorageKey.AUTOLOGIN) === 'true';
export const instance = axios.create({ headers: { 'content-type': 'application/json' } });

export const refreshTokenInstance = axios.create({ headers: { 'content-type': 'application/json' } });

if (accessToken) {
  instance.defaults.headers.common.Authorization = `${accessToken}`;
}

refreshTokenInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    return config;
  },
  (error: AxiosError) => Promise.reject(error),
);

refreshTokenInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response) {
      const { status } = error.response;

      // if (isAutoLogin && status === (HttpCode.NoAuthentication || HttpCode.NotValidToken)) {
      if (status === (HttpCode.NoAuthentication || HttpCode.NotValidToken)) {
        LocalStorage.remove(LocalStorageKey.ACCESSTOKEN);
        LocalStorage.remove(LocalStorageKey.REFRESHTOKEN);
        LocalStorage.remove(LocalStorageKey.ISLOGIN);
        LocalStorage.remove(LocalStorageKey.HOSPITAL_ID);
        window.location.reload();
      }
    }
    return Promise.reject(error);
  },
);

export const HTTP = {
  get: <ResponseType,>(url: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ResponseType>> =>
    instance.get(url, options),
  post: <ParamType, ResponseType>(
    url: string,
    param?: ParamType,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<ResponseType>> => instance.post(url, param, options),
  patch: <ParamType, ResponseType>(
    url: string,
    param: ParamType,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<ResponseType>> => instance.patch(url, param, options),
  delete: <ResponseType,>(url: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ResponseType>> =>
    instance.delete(url, options),
  put: <ParamType, ResponseType>(
    url: string,
    param: ParamType,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<ResponseType>> => instance.put(url, param, options),
};
