import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStatisticDto } from 'src/model/statistic';

export interface IStatisticState {
  statisticData?: IStatisticDto;
}

const initialState: IStatisticState = {
  statisticData: undefined,
};

const StatisticSlice = createSlice({
  name: 'statistic',
  initialState,
  reducers: {
    setStatistic: {
      prepare: (data: IStatisticDto) => ({
        payload: data,
      }),
      reducer: (state, action: PayloadAction<IStatisticDto>) => {
        state.statisticData = action.payload;
      },
    },
    resetField(state) {
      Object.assign(state, initialState);
    },
  },
});

export const { actions: statisticAction } = StatisticSlice;
export default StatisticSlice;
