import dayjs from 'dayjs';
// import { OccupationType } from 'src/constants/member';
// import { MemberForList } from 'src/model/member';

// export const flatList = <T>(key: string, data?: { [key: string]: any }[]): T | undefined => {
//   if (!data) {
//     return undefined;
//   }
//   return data.flatMap((item) => item[key]) as unknown as T;
// };

export const getAge = (age: number) => (age > 13 ? `${Math.floor(age / 12)}년 ${age % 12}개월` : `${age}개월`);

// export const RandomizationMember = (id: string, list: MemberForList[]) => {
//   const findId = list.filter(
//     (item) => item.occupation === (OccupationType.Veterinarian || OccupationType.Technician),
//   )[0];
//
//   if (id.length > 0) {
//     return id;
//   }
//
//   return findId.id.toString();
// };

export const EarliestDate = (availableDays: string[]) => {
  const today = dayjs().format('YYYY-MM-DD');
  const possibleDays = availableDays.filter((day) => dayjs(day).isAfter(today));

  if (possibleDays.length > 0) {
    return possibleDays[0];
  }

  return '';
};
