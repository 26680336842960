import { PetType } from '../model/pet';
import DefaultDogIcon from '../components/icons/DefaultDogIcon';
import DefaultCatIcon from '../components/icons/DefaultCatIcon';

export enum AnimalType {
  DOG = 'DOG',
  CAT = 'CAT',
  ETC = '',
}

export const defaultPetIcon = (petType: PetType) => {
  if (petType === 'D') {
    return DefaultDogIcon();
  }

  return DefaultCatIcon();
};

export const convertPetTypeForTracker = (animal: PetType) => {
  if (animal === PetType.DOG) {
    return AnimalType.DOG;
  }
  if (animal === PetType.CAT) {
    return AnimalType.CAT;
  }
  return AnimalType.ETC;
};
