import React, { useMemo } from 'react';
import { RoutesIconProps } from '../../model/route';
import { AHEAD_MINT, GREY_2 } from '../../../styles/colors';

export default function StatisticIcon({ isActive }: RoutesIconProps) {
  const iconColor: string = useMemo(() => (isActive ? AHEAD_MINT : GREY_2), [isActive]);

  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path d="m3 4v16h18" stroke={iconColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
      <path
        d="m7 14 3.5962-3.3333 2.75 2.5 4.6538-4.1667"
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.8"
      />
      <g fill={iconColor}>
        <circle cx="6.5" cy="14.5" r="1.5" />
        <circle cx="18.5" cy="8.5" r="1.5" />
      </g>
    </svg>
  );
}
