import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/reducers';
import type { ICustomer } from '../../../model/customer';
import CustomerListRow from './CustomerListRow';

const CustomerList = () => {
  const { customerList } = useSelector((state: RootState) => state.customer);

  return (
    <>
      {customerList.length > 0 && (
        <tbody>
          {customerList.map((item: ICustomer) => (
            <CustomerListRow key={item.userId} item={item} />
          ))}
        </tbody>
      )}
    </>
  );
};

export default CustomerList;
