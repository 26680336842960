import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetCustomerList } from 'src/query/Customer/useCustomerQuery';
import { customerAction } from 'src/features/customer/customerSlice';
import Pagination from 'src/components/reservation/Pagination';
import CustomerSearch from '../../components/customer/CustomerSearch';
import CustomerHeader from '../../components/customer/CustomerHeader';
import CustomerList from '../../components/customer/CustomerList';
import EmptyList from '../../components/customer/EmptyList';
import { CustomerFieldKey, customerHeaders } from '../../model/customer';
import { RootState } from '../../reducers';
import * as Styles from './styles';

const CustomerPage = () => {
  const dispatch = useDispatch();
  const { customerParams } = useSelector((state: RootState) => state.customer);
  const { data: customerData, isLoading } = useGetCustomerList(customerParams);

  useEffect(() => {
    if (!isLoading && customerData?.content?.length) {
      dispatch(customerAction.setCustomerList(customerData.content));
    }
  }, [customerData, dispatch, isLoading]);

  const handlePage = (selectedPage: number) => {
    dispatch(customerAction.updateField({ key: CustomerFieldKey.Page, value: selectedPage }));
  };

  useEffect(() => {
    return () => {
      dispatch(customerAction.resetField());
    };
  }, [dispatch]);

  return (
    <Styles.Wrapper>
      <Styles.Search>
        <CustomerSearch />
      </Styles.Search>
      <Styles.Div>
        <Styles.Table>
          <CustomerHeader />
          {!customerData || customerData?.content?.length === 0 ? (
            <EmptyList colSpan={customerHeaders.length} />
          ) : (
            <CustomerList />
          )}
        </Styles.Table>
      </Styles.Div>
      {customerData && customerData.totalElements > 0 && (
        <Styles.PaginationDiv>
          <Pagination
            pageNumber={customerParams.page || 0}
            totalCnt={customerData.totalElements}
            pageSize={customerParams.size || 10}
            onPageChange={handlePage}
          />
        </Styles.PaginationDiv>
      )}
    </Styles.Wrapper>
  );
};

export default CustomerPage;
