import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import { ModalDuration } from 'src/constants/modal';
import { GREY_1, WHITE } from 'styles/colors';
import { ZIndex } from 'styles/zIndex';

interface ModalContainerParams {
  isShow: boolean;
}

export const ModalContent = styled.div`
  background: ${GREY_1};
  color: ${WHITE};
  text-align: center;
  font-size: 13px;
  line-height: 15px;
  padding: 15px 30px;
  border-radius: 5px;
  min-width: 172px;
  white-space: pre-line;
`;

export const ModalContainer = styled.div(
  ({ isShow }: ModalContainerParams) => css`
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    width: 300px;
    //height: 45px;
    z-index: ${ZIndex.ToastContent};
    overflow: hidden;
    ${!isShow
      ? css`
          animation: ${ModalDuration.Alert}ms ${hideModal} forwards;
        `
      : css`
          animation: ${ModalDuration.Alert}ms ${showModal};
        `};
  `,
);

const showModal = keyframes`
  0% {
    transform: translate(-50%, 20%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
`;

const hideModal = keyframes`
  0% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, 20%);
    opacity: 0;
  }
`;
