import React, { useCallback } from 'react';
import { ModalHandler } from 'src/utils/ModalHandler';
import * as Styles from './styles';
import { Reservation, ReservationStatusType } from '../../../model/reservation';
import { ModalType } from '../../../constants/modal';
import { Button } from './styles';
import ReservationDetailModal from '../ReservationDetailModal';
import ReservationRejectModal from '../ReservationRejectModal';
import ReservationUpdateModal from '../ReservationUpdateModal';
import { usePutReservationStatusChange } from '../../../query/Reservation/useReservationQuery';

interface ReservationButtonsProps {
  reservation: Reservation;
}

const buttonList = {
  [ReservationStatusType.All]: [],
  [ReservationStatusType.Applied]: [ModalType.ReservationDetail], // 예약대기 -> 예약 확정 / 예약 상세 / 예약 변경 / 예약 보류
  [ReservationStatusType.Confirmed]: [ModalType.ReservationDetail], // 예약확정 -> 방문 완료 / 예약 상세 / 예약 변경 / 예약 보류
  [ReservationStatusType.Visited]: [ModalType.ReservationDetail], // 방문완료 -> 예약 상세 / 안내 사항
  [ReservationStatusType.Cancelled]: [ModalType.ReservationDetail], // 예약취소 -> 예약 상세
  [ReservationStatusType.NoShow]: [ModalType.ReservationDetail], // 노쇼 -> 예약 상세  / 메모
  [ReservationStatusType.Block]: [],
  // [ReservationStatusType.Holding]: [ModalType.ReservationDetail],   // 예약취소 -> 예약 상세
};

const ReservationButtons = ({ reservation }: ReservationButtonsProps) => {
  const { mutate } = usePutReservationStatusChange();

  const openPopup = useCallback(
    (type: ModalType) => {
      let component;
      let title;

      if (ModalType.Confirmed === type) {
        ModalHandler.show(ModalType.Alert, {
          title: '고객님이 신청한 예약을 확정할까요?',
          confirmText: '예',
          cancelText: '아니오',
          onConfirm: async () => {
            mutate({
              id: reservation.id,
              serviceStatus: ReservationStatusType.Confirmed,
              userType: reservation.userType,
            });
          },
        });
      } else if (ModalType.Visited === type) {
        ModalHandler.show(ModalType.Alert, {
          title: '고객님이 방문완료 했나요?',
          confirmText: '예',
          cancelText: '아니오',
          onConfirm: async () => {
            mutate({
              id: reservation.id,
              serviceStatus: ReservationStatusType.Visited,
              userType: reservation.userType,
            });
          },
        });
      } else if ([ModalType.ReservationDetail, ModalType.Information, ModalType.Memo].includes(type)) {
        title = '예약 상세내역';
        component = <ReservationDetailModal reservation={reservation} />;
      } else if (ModalType.Cancelled === type) {
        title = '예약 취소';
        component = <ReservationRejectModal reservation={reservation} />;
      } else if (ModalType.Update === type) {
        title = '예약 변경';
        component = <ReservationUpdateModal reservation={reservation} />;
      }

      if (title && component) {
        ModalHandler.show(ModalType.Detail, {
          title,
          width: 500,
          contents: <ReservationDetailModal reservation={reservation} />,
        });
      }
    },
    [reservation, mutate],
  );

  return (
    <Styles.Wrapper>
      {reservation.serviceStatus &&
        buttonList[reservation.serviceStatus].map((modalType) => (
          <Button
            key={modalType}
            type="button"
            isConfirmed={modalType === ModalType.Confirmed}
            onClick={() => openPopup(modalType)}>
            {modalType}
          </Button>
        ))}
    </Styles.Wrapper>
  );
};

export default ReservationButtons;
