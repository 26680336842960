import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MemberInfoDto, MemberType } from '../../model/member';
import { FieldKeyValue } from '../../model/common';

export interface MemberState {
  memberList: MemberInfoDto[];
  editMember: MemberType;
  viewMember: MemberType;
  isLoading: boolean;
}
const initMember: MemberType = {
  name: '',
  major: '',
  licenseNo: '',
  workDays: [],
  isTemporaryHoliday: false,
  temporaryHolidayStart: '',
  temporaryHolidayEnd: '',
  isTodayReservationAvailable: false,
};
const initialState = {
  memberList: [] as MemberInfoDto[],
  editMember: initMember,
  viewMember: initMember,
  isLoading: false,
};
const memberSlice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    initEditMember: (state) => {
      state.editMember = { ...initMember };
      return state;
    },
    updateEditField: {
      prepare: (payload) => ({ payload }),
      reducer: (state, action: PayloadAction<FieldKeyValue>) => {
        const { key, value } = action.payload;
        state.editMember = { ...state.editMember, [key]: value };
      },
    },
    setMemberList: {
      prepare: (memberList: MemberInfoDto[]) => ({
        payload: memberList,
      }),
      reducer: (state, action: PayloadAction<MemberInfoDto[]>) => {
        state.memberList = action.payload;
      },
    },
    setEditMember: {
      prepare: (member: MemberType) => ({
        payload: member,
      }),
      reducer: (state, action: PayloadAction<MemberType>) => {
        state.editMember = action.payload;
      },
    },
    setViewMember: (state, action: PayloadAction<MemberType>) => {
      state.viewMember = action.payload;
      return state;
    },
  },
});

export default memberSlice;
export const { actions: memberAction } = memberSlice;
