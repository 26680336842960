import React from 'react';
import * as StatisticStyles from '../styles';
import CustomerStatistics from './CustomerStatistics';
import ReservationMetrics from './ReservationMetrics';

export default function ReservationVisitStatistics() {
  return (
    <StatisticStyles.Section>
      <h3>예약 방문 통계</h3>

      <StatisticStyles.Grid>
        <CustomerStatistics />
        <ReservationMetrics />
      </StatisticStyles.Grid>
    </StatisticStyles.Section>
  );
}
