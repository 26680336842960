import React from 'react';

const MaleIcon = () => (
  <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="6.83013"
      cy="11.9073"
      r="4.25"
      transform="rotate(30 6.83013 11.9073)"
      stroke="#31BEC8"
      strokeWidth="1.5"
    />
    <path d="M10.9238 4.81641L8.92383 8.28051" stroke="#31BEC8" strokeWidth="1.5" />
    <path
      d="M8.69189 4.68225L11.4239 3.9502L12.156 6.68225"
      stroke="#31BEC8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MaleIcon;
