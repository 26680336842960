import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PetStatistics from 'src/components/statistic/Pet';
import ReservationVisitStatistics from 'src/components/statistic/ReservationVisit';
import { statisticAction } from 'src/features/statistic/statisticSlice';
import { useGetStatistic } from 'src/query/Statistic/useStatisticQuery';
import { RootState } from 'src/reducers';
import * as Styles from './styles';

export default function StatisticPage() {
  const dispatch = useDispatch();
  const { hospital } = useSelector((state: RootState) => state.account);
  const { data, isLoading } = useGetStatistic();

  useEffect(() => {
    if (!isLoading && data) {
      dispatch(statisticAction.setStatistic(data));
    }
  }, [data, dispatch, isLoading]);

  useEffect(() => {
    return () => {
      dispatch(statisticAction.resetField());
    };
  }, [dispatch]);

  return (
    <Styles.Wrapper>
      <Styles.Title>
        <h2>{hospital.name}의 통계 정보</h2>
        <span>{moment().format('YYYY년 MM월 DD일')} 기준</span>
      </Styles.Title>

      <ReservationVisitStatistics />
      <PetStatistics />
    </Styles.Wrapper>
  );
}
