import React, { useMemo } from 'react';
import { PieCustomLayerProps, ResponsivePie } from '@nivo/pie';
import { AHEAD_MINT, PASTEL_BLUE, PASTEL_GREEN, PASTEL_SKYBLUE } from 'styles/colors';
import { useSelector } from 'react-redux';
import * as StatisticStyles from '../styles';
import { NeutralizationType, PetGender, PetType } from '../../../model/pet';
import { RootState } from '../../../reducers';
import { IGender } from '../../../model/statistic';
import { neutralizationPercentage, setGenderData } from '../../../utils/StatisticUtil';

interface IPieData {
  id: string;
  value: number;
  color: string;
}

const commonLegends: any = {
  anchor: 'bottom',
  direction: 'row',
  justify: false,
  translateX: 0,
  itemsSpacing: 0,
  itemWidth: 100,
  itemHeight: 12,
  itemTextColor: '#434343',
  itemDirection: 'left-to-right',
  itemOpacity: 1,
  symbolSize: 8,
  symbolShape: 'square',
};

interface GenderStatisticProps {
  petType: PetType;
}

export default function GenderStatistic({ petType }: GenderStatisticProps) {
  const { statisticData } = useSelector((state: RootState) => state.statistic);
  const typeData = useMemo(() => {
    if (statisticData) {
      return statisticData?.gender && statisticData.gender.filter((pet) => pet.petType === petType);
    }

    return [] as IGender[];
  }, [petType, statisticData]);

  const genderData = useMemo(
    () => [
      {
        id: '수컷',
        value: setGenderData(typeData, 'petCount', PetGender.MALE, NeutralizationType.F),
        percentage: setGenderData(typeData, 'percentage', PetGender.MALE, NeutralizationType.F),
        color: PASTEL_BLUE,
      },
      {
        id: '수컷(중성화)',
        value: setGenderData(typeData, 'petCount', PetGender.MALE, NeutralizationType.T),
        percentage: setGenderData(typeData, 'percentage', PetGender.MALE, NeutralizationType.T),
        color: PASTEL_SKYBLUE,
      },
      {
        id: '암컷',
        value: setGenderData(typeData, 'petCount', PetGender.FEMALE, NeutralizationType.F),
        percentage: setGenderData(typeData, 'percentage', PetGender.FEMALE, NeutralizationType.F),
        color: AHEAD_MINT,
      },
      {
        id: '암컷(중성화)',
        value: setGenderData(typeData, 'petCount', PetGender.FEMALE, NeutralizationType.T),
        percentage: setGenderData(typeData, 'percentage', PetGender.FEMALE, NeutralizationType.T),
        color: PASTEL_GREEN,
      },
    ],
    [typeData],
  );

  const CenteredMetric = ({ centerX, centerY }: PieCustomLayerProps<IPieData>) => {
    return (
      <>
        <text
          x={centerX}
          y={centerY - 15}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: '12px',
            lineHeight: '14px',
          }}>
          중성화
        </text>
        <text
          x={centerX}
          y={centerY + 5}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: '18px',
            lineHeight: '22px',
            fontWeight: 600,
          }}>
          {neutralizationPercentage(typeData) || 0}%
        </text>
      </>
    );
  };

  return (
    <StatisticStyles.Article>
      <h4>성별</h4>

      <div style={{ height: 190 }}>
        <ResponsivePie
          data={genderData}
          margin={{
            bottom: 52,
          }}
          innerRadius={0.8}
          layers={['arcs', 'legends', CenteredMetric]}
          colors={({ data }) => data.color}
          startAngle={-90}
          legends={[
            {
              ...commonLegends,
              translateY: 36,
              data: genderData.slice(0, 2).map((item) => {
                return {
                  color: item.color,
                  id: item.id,
                  label: `${item.id} ${item.value} (${item.percentage.toFixed(2)}%)`,
                };
              }),
            },
            {
              ...commonLegends,
              translateY: 52,
              data: genderData.slice(2, 4).map((item) => {
                return {
                  color: item.color,
                  id: item.id,
                  label: `${item.id} ${item.value} (${item.percentage.toFixed(2)}%)`,
                };
              }),
            },
          ]}
        />
      </div>
    </StatisticStyles.Article>
  );
}
