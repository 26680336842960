import React, { useCallback, useMemo } from 'react';
import { ClosedTime, OperationFieldKey } from 'src/model/operations';
import useOperation from 'src/hooks/operation/useOperation';
import { TimeType } from 'src/constants/workHours';
import TextInputCalendar from 'src/components/common/TextInputCalendar';
import { BasicButton } from 'src/components/common/buttons/BasicButton';
import DeleteFilledIcon from 'src/components/icons/DeleteFilledIcon';
import { DEFAULT_TEMP_HOLIDAY_DATE, DEFAULT_TEMP_HOLIDAY_DATES } from 'src/constants/operation';
import * as Styles from './styles';

const MAXIMUM_HOLIDAY_COUNT = 5;

interface DateMultiSelectionProps {
  dates: ClosedTime[];
  onChangeField: (type: OperationFieldKey, closedTimes: ClosedTime[]) => void;
}

const DateMultiSelection = ({ dates: _dates, onChangeField }: DateMultiSelectionProps) => {
  const { convertClosedDateTimes2CloseDates, setClosedTimesPayload } = useOperation();
  // 초기값 설정
  const dates = convertClosedDateTimes2CloseDates(_dates);
  const dateList = useMemo(() => (dates.length < 1 ? DEFAULT_TEMP_HOLIDAY_DATES : dates), [dates]);

  // 휴무일 추가
  const onAddDate = () => {
    dateList.push(DEFAULT_TEMP_HOLIDAY_DATE);
    onChangeField(OperationFieldKey.ClosedTimes, setClosedTimesPayload(dateList));
  };

  // 날짜 변경 이벤트
  const onChangeDate = useCallback(
    (date: string, index: number, type: TimeType) => {
      if (date) {
        const changeDay = {
          closedTimeStart: dates[index].closedTimeStart,
          closedTimeEnd: dates[index].closedTimeEnd,
        };

        if (type === TimeType.Start) {
          changeDay.closedTimeStart = date;
        } else if (type === TimeType.End) {
          changeDay.closedTimeEnd = date;
        }
        const updated = dates.map((day: ClosedTime, idx: number) => (index === idx ? changeDay : day));
        onChangeField(OperationFieldKey.ClosedTimes, setClosedTimesPayload(updated));
      }
    },
    [JSON.stringify(dates)],
  );

  const handleDeleteHolidayRow = useCallback(
    (index: number) => {
      dateList.splice(index, 1);
      onChangeField(OperationFieldKey.ClosedTimes, setClosedTimesPayload(dateList));
    },
    [JSON.stringify(dates)],
  );

  return (
    <Styles.DateWrapper>
      {dateList.map((day: ClosedTime, index: number) => (
        <Styles.TimeWrapper key={`${day.closedTimeStart}-${day.closedTimeEnd}`}>
          <Styles.Label>휴무일 {index + 1}</Styles.Label>
          <TextInputCalendar
            enablePrevDate={false}
            selectDate={day.closedTimeStart}
            onChange={(date) => onChangeDate(date, index, TimeType.Start)}
            max={day.closedTimeEnd}
          />
          <Styles.Divison>~</Styles.Divison>
          <TextInputCalendar
            selectDate={day.closedTimeEnd}
            enablePrevDate={false}
            onChange={(date) => onChangeDate(date, index, TimeType.End)}
            min={day.closedTimeStart}
          />
          <Styles.DeleteFilledIconWrapper
            onClick={() => {
              handleDeleteHolidayRow(index);
            }}>
            <DeleteFilledIcon />
          </Styles.DeleteFilledIconWrapper>
        </Styles.TimeWrapper>
      ))}
      {dateList.length < MAXIMUM_HOLIDAY_COUNT && (
        <BasicButton type="button" onClick={onAddDate}>
          추가
        </BasicButton>
      )}
    </Styles.DateWrapper>
  );
};

export default DateMultiSelection;
