import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { ModalDuration } from 'src/constants/modal';
import { AHEAD_MINT, GREY_1, WHITE } from 'styles/colors';
import { ZIndex } from 'styles/zIndex';

interface ModalContainerParams {
  isShow: boolean;
}

interface ModalButtonParams {
  backgroundColor?: string;
  color?: string;
  borderColor?: string;
}

export const ModalContainer = styled.div(
  ({ isShow }: ModalContainerParams) => css`
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    width: 316px;
    //height: 188px;
    min-height: 188px;
    border-radius: 10px;
    overflow: hidden;
    z-index: ${ZIndex.ModalContent};
    ${!isShow
      ? css`
          animation: ${ModalDuration.Alert}ms ${hideModal} forwards;
        `
      : css`
          animation: ${ModalDuration.Alert}ms ${showModal};
        `};
  `,
);

export const ModalTitle = styled.p`
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: ${GREY_1};
  //margin-bottom: 12px;
`;

export const ModalContent = styled.p`
  margin-top: 12px;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  /* or 138% */

  text-align: center;
  letter-spacing: -0.24px;
  color: ${GREY_1};
`;

export const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  word-break: keep-all;
  padding: 43px 28px;
  color: ${GREY_1};
  background-color: ${WHITE};
`;

export const ModalButtons = styled.div`
  display: flex;
  overflow: hidden;
  align-self: flex-end;
  justify-self: flex-end;
  width: 100%;
  height: 50px;
  border-radius: 0 0 4px 4px;
`;

export const ModalButton = styled.button(
  ({ backgroundColor, color, borderColor }: ModalButtonParams) => css`
    width: 100%;
    height: 100%;
    background-color: ${backgroundColor || AHEAD_MINT};
    color: ${color || WHITE};
    border-top: 1px solid ${borderColor || 'transparent'};
    font-size: 16px;
    font-weight: ${backgroundColor ? 'normal' : 'bold'};
    cursor: pointer;

    &:active {
      filter: brightness(90%);
    }
  `,
);

const showModal = keyframes`
  0% {
    transform: translate(-50%, 20%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
`;

const hideModal = keyframes`
  0% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, 20%);
    opacity: 0;
  }
`;
