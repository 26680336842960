import styled from '@emotion/styled';
import { GREY_1, GREY_2, GREY_4, GREY_5 } from 'styles/colors';
import { ContentWrapper } from '../../../../styles/layout';

export const Wrapper = styled.div`
  ${ContentWrapper}
`;

export const Title = styled.p`
  line-height: 18px;
  font-weight: bold;
  color: ${GREY_1};
  margin-bottom: 28px;
`;

export const Table = styled.table`
  border: 1px solid ${GREY_4};
  border-collapse: collapse;
  width: 100%;
`;

export const Tr = styled.tr`
  border: 1px solid ${GREY_4};
`;

export const Th = styled.th`
  border: 1px solid ${GREY_4};
  width: 150px;
  background-color: ${GREY_5};
  padding: 20px;
  text-align: left;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  vertical-align: top;
  color: ${GREY_1};
`;
export const Td = styled.td`
  border: 1px solid ${GREY_4};
  padding: 20px;
  text-align: left;
  font-weight: normal;
  font-style: normal;
  color: ${GREY_2};
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
`;

export const TdText = styled.div`
  margin-bottom: 10px;
  :last-child {
    margin-bottom: 0;
  }
  .day {
    color: ${GREY_1};
    margin-right: 36px;
  }
`;

export const BottomWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin-top: 36px;
`;

export const NotiText = styled.p`
  margin-top: 8px;
`;
