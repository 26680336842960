import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import OperationDetail from 'src/components/operation/OperationDetail';
import OperationUpdate from 'src/components/operation/OperationUpdate';
import { OperationMode } from 'src/model/operations';
import { useGetOperationDetail } from '../../query/Operation/useGetOperationDetail';

const OperationPage = () => {
  const { data, isLoading } = useGetOperationDetail();

  const [mode, setMode] = useState(OperationMode.DETAIL);

  useEffect(() => {
    // 최초 접속시 설정이 없으면 등록 화면으로 이동
    if (!isLoading && _.isEmpty(data)) {
      setMode(OperationMode.ADD);
    }
  }, [data, mode, isLoading]);

  return (
    <>
      {!isLoading &&
        data &&
        (mode === OperationMode.DETAIL ? (
          <OperationDetail operationData={data} setMode={setMode} />
        ) : (
          <OperationUpdate operationData={data} setMode={setMode} mode={mode} />
        ))}
    </>
  );
};

export default OperationPage;
