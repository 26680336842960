import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import * as Styles from './styles';
import {
  ReservationBadgeDescToString,
  ReservationDetail,
  ReservationStatusTypeToString,
} from '../../../model/reservation';
import ReservationPet from '../ReservationPet';
import { DateFormat, DisplayDateTimeFormat } from '../../../constants/workHours';
import { ModalHandler } from '../../../utils/ModalHandler';
import { ModalType } from '../../../constants/modal';

interface ReservationDetailCardProps {
  reservationDetail: ReservationDetail;
}

const ReservationDetailCard = ({ reservationDetail }: ReservationDetailCardProps) => {
  const history = useHistory();
  const phoneNumber = useMemo(() => {
    return reservationDetail.user.tel && reservationDetail.user.tel !== ''
      ? reservationDetail.user.tel.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
      : '번호없음';
  }, [reservationDetail.user.tel]);

  const goSchedule = useCallback((date: string) => {
    ModalHandler.hide(ModalType.Detail);
    history.push(`/${moment(date).format(DateFormat)}`);
  }, []);

  return (
    <Styles.InfoSection>
      <Styles.BadgeSection>
        <Styles.Badge status={reservationDetail.serviceStatus}>
          {ReservationStatusTypeToString[reservationDetail.serviceStatus]}
        </Styles.Badge>
        <Styles.BadgeDesc status={reservationDetail.serviceStatus}>
          {ReservationBadgeDescToString[reservationDetail.serviceStatus]}
        </Styles.BadgeDesc>
      </Styles.BadgeSection>
      <Styles.DetailInfoSection>
        <Styles.Info>보호자: {`${reservationDetail.user.name}(${phoneNumber})`}</Styles.Info>
        <Styles.Info>진료종류: {reservationDetail.reserveType}</Styles.Info>
        <Styles.Info>
          담당자: {reservationDetail?.hospitalMember.name ? reservationDetail.hospitalMember.name : '담당자 지정안함'}
        </Styles.Info>
        <Styles.Info>
          진료 희망 일시:{' '}
          <Styles.DateClick onClick={() => goSchedule(reservationDetail.reservationTime)}>
            {moment(reservationDetail.reservationTime).locale('ko').format(DisplayDateTimeFormat)}
          </Styles.DateClick>
        </Styles.Info>
        <Styles.Info>
          요청사항{reservationDetail.userRequest ? `: ` : ''}
          <Styles.InfoDesc>{reservationDetail.userRequest}</Styles.InfoDesc>
        </Styles.Info>
        <ReservationPet pet={reservationDetail.pet} />
      </Styles.DetailInfoSection>
    </Styles.InfoSection>
  );
};

export default ReservationDetailCard;
