import { injectGlobal } from '@emotion/css';
import { css, Global } from '@emotion/react';
import fonts from 'styles/fonts';
import React from 'react';
import { GREY_1, GREY_3, GREY_5 } from 'styles/colors';

export const inheritFont = css`
  color: inherit;
  letter-spacing: inherit;
  font-family: inherit;
`;

export const resetAppearance = css`
  appearance: none;
  background: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
`;

export const resetSpacing = css`
  margin: 0;
  padding: 0;
`;

export const a11y = css`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  border-radius: 0;
  clip: rect(0, 0, 0, 0);
`;

export const fontFamily = [
  'Pretendard',
  'Spoqa Han Sans',
  'Apple SD Gothic Neo',
  '"돋움"',
  'Dotum',
  'Helvetica Neue',
  'arial',
  'sans-serif',
].join(', ');

export const resetFont = css`
  color: ${GREY_1};
  // font-family 어떻게 할지
  font-family: ${fontFamily};
  font-weight: 400;
  letter-spacing: -0.02em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const ResetStyles = css`
  html {
    ${css([resetFont])}
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-size: 14px;
    line-height: 18px;
    height: 100%;
  }

  body {
    ${css([resetSpacing])};
    height: 100%;
  }

  #root {
    width: 100%;
    height: 100%;
  }
  hr {
    ${css([resetSpacing])};
  }
  p {
    ${css([resetSpacing])};
  }
  button {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    ${css([inheritFont, resetAppearance, resetSpacing])};
    * {
      position: relative;
    }
    &:focus {
      outline: none;
    }
  }
  input {
    ${inheritFont};
    ${resetAppearance};
    ${resetSpacing};

    &::-ms-clear {
      display: none;
    }
    &::-webkit-search-cancel-button {
      display: none;
    }
    &:focus {
      outline: none;
    }
  }
  ul {
    list-style-type: none;
    ${css([resetSpacing])};
  }
  li {
    line-height: initial;
  }
  em {
    font-style: normal;
  }

  a {
    ${inheritFont};
    &:link,
    &:visited {
      text-decoration: none;
    }
    cursor: pointer;
  }

  h1,
  h2,
  h3,
  h4,
  button,
  input,
  p,
  span {
    letter-spacing: -0.02em;
  }

  * {
    .a11y {
      ${css([a11y])};
    }
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -ms-overflow-style: none;
    font-size: inherit;
  }

  textarea {
    ${inheritFont};
  }

  select {
    /* 셀렉트 박스 화살표 처리 */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6L11 1' stroke='%23434343'/%3E%3C/svg%3E%0A");
    background-size: 10px;
    background-position: calc(100% - 1em) center;
    background-repeat: no-repeat;
    color: ${GREY_1};

    ::-ms-expand {
      display: none;
    }

    &:disabled {
      background-color: ${GREY_5};
      border: 1px solid ${GREY_3};
      color: ${GREY_3};
    }

    &:focus {
      outline: none;
    }
  }

  // 캘린더 타임 틱 높이
  .fc-timegrid-slot {
    height: 3em !important; // 1.5em by default
    border-bottom: 0 !important;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
injectGlobal`
${fonts}
`;

const GlobalStyles = () => (
  <>
    <Global styles={ResetStyles} />
  </>
);

export default GlobalStyles;
