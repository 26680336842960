import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { AHEAD_MINT, GREY_2, WHITE } from 'styles/colors';
import { css } from '@emotion/react';

export const Wrapper = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const Menu = styled.div(
  ({ isActive }: { isActive: boolean }) => css`
    width: 100%;
    display: flex;
    margin-bottom: 36px;
    align-items: center;
    color: ${isActive ? `${AHEAD_MINT}` : `${GREY_2}`};
    border-right: ${isActive ? '3px solid #31BEC8' : 'none'};
    font-weight: ${isActive ? 'bold' : 'normal'};
    cursor: pointer;

    span {
      &:last-child {
        margin-left: 10px;
      }
    }
  `,
);

export const MenuWrapper = styled.div`
  margin-top: 36px;
`;

export const CustomLink = styled(Link)`
  text-decoration: none;
  color: ${GREY_2};
`;

export const ReservationWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-left: -10px;
  gap: 8px;
  align-items: baseline;
`;

export const ReservationTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: -0.02em;
  font-size: 16px;

  /* Main/Fiepet Mint */
  color: ${AHEAD_MINT};
`;

export const CountBadge = styled.div`
  display: flex;
  width: 28px;
  height: 28px;
  background-color: ${AHEAD_MINT};
  color: ${WHITE};
  border-radius: 50%;
  justify-content: center;
  align-items: center;

  span {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    color: ${WHITE};
    padding-top: 1px;
  }
`;
