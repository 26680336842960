import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CommonState {
  isLoading: boolean;
  notUseLoading: boolean;
}

const initialState = {
  isLoading: false, // 로딩바 show / hide 여부
  notUseLoading: false, // 로딩바 사용 여부
};

let loadingCnt: number = 0;

const CommonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.isLoading = action.payload;
        loadingCnt += 1;
      } else {
        loadingCnt -= 1;

        // 로딩바 hide시 로딩바 사용 여부 원복
        if (loadingCnt <= 0) {
          loadingCnt = 0;
          state.isLoading = false;
          state.notUseLoading = initialState.notUseLoading;
        }
      }

      return state;
    },
    setNotLoading: (state) => {
      state.notUseLoading = true;
      return state;
    },
  },
});

export const { actions: commonAction } = CommonSlice;
export default CommonSlice;
