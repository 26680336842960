import React from 'react';

const LogoutIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0044 20.5794H4.28683C3.81349 20.5794 3.42936 20.1952 3.42936 19.7219V4.28688C3.42936 3.81353 3.81353 3.4294 4.28683 3.4294H12.0044C12.4785 3.4294 12.8618 3.04611 12.8618 2.57192C12.8618 2.09772 12.4785 1.71436 12.0044 1.71436H4.28683C2.86854 1.71436 1.71436 2.86858 1.71436 4.28688V19.7218C1.71436 21.1401 2.86854 22.2943 4.28683 22.2943H12.0044C12.4785 22.2943 12.8618 21.911 12.8618 21.4368C12.8618 20.9626 12.4785 20.5794 12.0044 20.5794Z"
      fill="#434343"
      stroke="#434343"
      strokeWidth="0.4"
    />
    <path
      d="M22.1074 11.3941L16.8938 6.24907C16.5576 5.91635 16.014 5.92066 15.6813 6.25766C15.3486 6.59465 15.352 7.13744 15.6899 7.47015L19.4157 11.1471H9.43182C8.95763 11.1471 8.57434 11.5304 8.57434 12.0046C8.57434 12.4788 8.95763 12.8621 9.43182 12.8621H19.4157L15.6899 16.539C15.352 16.8717 15.3494 17.4145 15.6813 17.7515C15.8493 17.9213 16.0706 18.007 16.2918 18.007C16.5096 18.007 16.7274 17.9247 16.8938 17.7601L22.1074 12.6151C22.2703 12.4539 22.3629 12.2343 22.3629 12.0045C22.3629 11.7748 22.2711 11.5561 22.1074 11.3941Z"
      fill="#434343"
      stroke="#434343"
      strokeWidth="0.4"
    />
  </svg>
);

export default LogoutIcon;
