import React from 'react';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import GlobalStyles from 'styles/GlobalStyle';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AppRouter } from './routes';
import { EventTrackingProvider } from './context/EventTrackingProvider';

const queryCache = new QueryCache();
const queryClient = new QueryClient({ queryCache });

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <EventTrackingProvider>
        <Router>
          <GlobalStyles />
          <AppRouter />
        </Router>
      </EventTrackingProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
