import { DayType } from '../constants/workHours';

export enum OperationFieldKey {
  id = 'id',
  WorkingDays = 'workingDays',
  LunchTimeStart = 'lunchTimeStart',
  LunchTimeEnd = 'lunchTimeEnd',
  ClosedTimes = 'closedTimes',
  AlimtalkNumber = 'alimtalkNumber',
  IsAlimtalk = 'isAlimtalk',
  // IsLunchAvailable = 'isLunchAvailable',
  // IsHolidayAvailable = 'isHolidayAvailable',
  // TodayReservationAvailable = 'todayReservationAvailable',
}

export enum OperationMode {
  ADD = 'add',
  UPDATE = 'update',
  DETAIL = 'detail',
}

export interface OperationWorkDayType {
  /** 운영 요일 */
  workDay: DayType;
  /** 운영 시작 시간 */
  workTimeStart: string;
  /** 운영 종료 시간 */
  workTimeEnd: string;
}

// 운영 관리
export interface Operation {
  id?: number;
  /** 요일별 설정 */
  workingDays: OperationWorkDayType[];
  /** 임시 휴무일 목록 */
  closedTimes: ClosedTime[];
  /** 카카오 알림 받을 연락처 */
  alimtalkNumber?: string;
  /** 카카오 알림 사용여부 */
  isAlimtalk?: boolean;
  /** 최초 설정 여부 */
  // isFirst?: boolean;
  /** 점심시간 여부 */
  // isLunchAvailable: boolean;
  /** 점심시간 시작 시간 */
  // lunchTimeStart: string;
  /** 점심시간 종료 시간 */
  // lunchTimeEnd: string;
  /** 임시휴무일 여부 */
  // isHolidayAvailable: boolean;
  /** 당일 예약 가능 여부 */
  // todayReservationAvailable: boolean | undefined;
}

// 임시 휴무일
export interface ClosedTime {
  /** 시작 시간 */
  closedTimeStart: string;
  /** 종료 시간 */
  closedTimeEnd: string;
  /** 종료 타입 */
  // closedType: 'HOLIDAY';
}

// export interface ResponseOperationGet {
//   operation: Operation;
// }

// export enum OperationType {
//   Vaccination = 'vaccination',
//   HealthScreening = 'health_screening',
//   Diagnosis = 'diagnosis',
//   Surgery = 'surgery',
//   Grooming = 'grooming',
// }
//
// export const OperationTypeToString: Partial<Record<OperationType, string>> = {
//   [OperationType.Vaccination]: '예방접종',
//   [OperationType.HealthScreening]: '건강검진',
//   [OperationType.Diagnosis]: '일반진료',
//   [OperationType.Surgery]: '수술',
//   [OperationType.Grooming]: '미용',
// };

// export interface Operations {
//   id: number;
//   operationType: OperationType;
// }
//
// export interface OperationDetail extends Operation {
//   id: number;
// }
// export interface ResponseOperations {
//   operations: Operations[];
// }
//
// export interface RequestUpdateOperation extends Operation {
//   operationId: number;
// }
