import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { QueryKey } from 'src/constants/queryKey';
import { IStatisticDto } from 'src/model/statistic';
import { BASE_URL, HTTP } from 'src/utils/api/http';
import { selectHospitalId } from '../../selectors';

export const useGetStatistic = () => {
  const hospitalId = useSelector(selectHospitalId);
  const { data, error, isLoading } = useQuery(
    [QueryKey.CUSTOMER_LIST, hospitalId],
    async () => {
      if (hospitalId === 0) return undefined;
      const response = await HTTP.get<IStatisticDto>(`${BASE_URL}/api/hospitals/statistic/${hospitalId}`);
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  return {
    data,
    error,
    isLoading,
  };
};
