import styled from '@emotion/styled';
import { css } from '@emotion/react';
import _ from 'lodash';
import { GREY_1, GREY_3, GREY_5, WHITE } from '../../../../styles/colors';
import { ZIndex } from '../../../../styles/zIndex';

interface TextInputCalendarStylesProps {
  width?: number;
  height?: number;
  disabled?: boolean;
  isTop?: boolean;
  positionX?: string;
  positionY?: string;
}
export const Wrapper = styled.div(
  ({ width, height }: TextInputCalendarStylesProps) => css`
    display: flex;
    width: ${width}px;
    height: ${height}px;
    position: relative;
  `,
);

export const Input = styled.input(
  ({ disabled }: TextInputCalendarStylesProps) => css`
    /* Greyscale/White */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;

    background: ${disabled ? GREY_5 : WHITE};
    /* Greyscale/Grey 3 */

    border: 1px solid ${GREY_3};
    box-sizing: border-box;
    border-radius: 4px;

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;

    color: ${disabled ? GREY_3 : GREY_1};
    padding: 16px 0 16px 12px;

    cursor: ${disabled ? 'default' : 'pointer'};
  `,
);

export const IconWrapper = styled.div(
  ({ disabled }: TextInputCalendarStylesProps) => css`
    position: absolute;
    right: 19px;
    top: calc((100% - 18px) / 2);
    cursor: ${disabled ? 'default' : 'pointer'};
  `,
);

export const CalendarWrapper = styled.div(
  ({ height = 48, isTop = false, positionX = '', positionY = '' }: TextInputCalendarStylesProps) => css`
    position: absolute;
    //top: ${height + 5}px;
    left: 0;
    ${!isTop &&
    css`
      top: ${height + 5}px;
    `}
    ${isTop &&
    css`
      bottom: ${height + 5}px;
    `}
    ${!_.isEmpty(positionX) &&
    css`
      left: ${positionX}px;
    `}
    ${!_.isEmpty(positionY) &&
    css`
      top: ${positionY}px;
    `}
    height: 400px;
    background-color: ${WHITE};
    border: 1px solid ${GREY_3};
    border-radius: 4px;
    padding: 20px 5px 0 5px;
    box-shadow: 2px 2px 4px ${GREY_3};
    z-index: ${ZIndex.CalendarModal};
  `,
);
