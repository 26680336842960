import React, { useCallback, useMemo, useState } from 'react';
import { WorkingDayType, WorkingDayTypeForUI } from 'src/model/workingDay';
import _ from 'lodash';
import * as Styles from './styles';
import { dayMap, DayType, HourPlusMinute } from '../../../constants/workHours';
import CheckBoxIcon from '../../icons/CheckBoxIcon';
import SelectBox from '../../common/SelectBox';

type WorkDayProps = {
  // workDays: WorkingDayType[];
  workDays: WorkingDayTypeForUI[];
  updateWorkDays: (workDays: WorkingDayType[]) => void;
};
const WorkDay = ({ workDays, updateWorkDays }: WorkDayProps) => {
  const [selectDay, setSelectDay] = useState(DayType.Mon);

  const timeList = Object.keys(HourPlusMinute)
    .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
    .filter((key) => {
      return !!key;
    })
    .map((key) => {
      return {
        label: key,
        value: HourPlusMinute[key],
      };
    });

  const item = useMemo(() => {
    const findDay = workDays.find((day) => day.workDay === selectDay);
    // 세팅한 값이 있는 경우
    if (findDay) {
      return {
        ...findDay,
        reservationAvailable: _.has(findDay, 'reservationAvailable') ? findDay.reservationAvailable : true,
        // reservationAvailable: true,
      };
    }
    // 기본값
    return {
      workDay: selectDay,
      workTimeStart: '09:00',
      workTimeEnd: '18:00',
      isLunchTime: false,
      lunchTimeStart: '12:00',
      lunchTimeEnd: '13:00',
      reservationAvailable: false,
    };
  }, [workDays, selectDay]);

  const onChangeWorkFlag = useCallback(
    (type) => {
      // console.info('onChangeWorkFlag', type);
      const workday = { ...item };
      if (type === 'reservationAvailable') {
        workday.reservationAvailable = !workday.reservationAvailable;
      } else if (type === 'lunch') {
        workday.reservationAvailable = true;
        workday.isLunchTime = !workday.isLunchTime;
      }

      const param = [
        ...workDays
          .filter((d) => d.workDay !== selectDay)
          .map((d) => {
            return { ...d, reservationAvailable: true };
          }),
      ];
      if (workday.reservationAvailable) {
        param.push(workday as WorkingDayTypeForUI);
      }

      updateWorkDays(
        param
          .filter((w) => w.reservationAvailable)
          .map((w) => {
            return _.omit(w, 'reservationAvailable');
          }),
      );
    },
    [item, workDays, updateWorkDays, selectDay],
  );

  const onChangeTime = useCallback(
    (timeType, value) => {
      updateWorkDays([
        ...workDays.filter((d) => d.workDay !== selectDay),
        {
          ...item,
          workTimeStart: timeType === 'start' ? value : item.workTimeStart,
          workTimeEnd: timeType === 'end' ? value : item.workTimeEnd,
        },
      ]);
    },
    [updateWorkDays, workDays, item, selectDay],
  );

  const onChangeLunchTime = useCallback(
    (timeType, value) => {
      updateWorkDays([
        ...workDays.filter((d) => d.workDay !== selectDay),
        {
          ...item,
          lunchTimeStart: timeType === 'start' ? value : item.lunchTimeStart,
          lunchTimeEnd: timeType === 'end' ? value : item.lunchTimeEnd,
        },
      ]);
    },
    [updateWorkDays, workDays, item, selectDay],
  );

  return (
    <Styles.Wrapper>
      <Styles.Title>근무일시</Styles.Title>
      <Styles.Week>
        {Object.keys(dayMap).map((day) => (
          <Styles.Day
            key={day}
            className={selectDay === (day as DayType) ? 'active' : ''}
            onClick={() => setSelectDay(day as DayType)}>
            {dayMap[day as DayType]}
          </Styles.Day>
        ))}
      </Styles.Week>
      <Styles.CheckBoxWrap role="button" onClick={() => onChangeWorkFlag('reservationAvailable')}>
        <CheckBoxIcon checked={item.reservationAvailable} />
        <span>근무 요일 입니다.</span>
      </Styles.CheckBoxWrap>
      <Styles.TimeSelectorWrap>
        <span>시작 시간</span>
        <SelectBox
          width={116}
          optionHeight={130}
          placeholder="시간선택"
          disabled={!item.reservationAvailable}
          onChange={(value) => {
            onChangeTime('start', value);
          }}
          items={timeList}
          value={{ value: item.workTimeStart, label: item.workTimeStart }}
          reservationAvailable={item.reservationAvailable}
        />
        <span>종료 시간</span>
        <SelectBox
          width={116}
          optionHeight={130}
          placeholder="시간선택"
          disabled={!item.reservationAvailable}
          onChange={(value) => {
            onChangeTime('end', value);
          }}
          items={timeList}
          value={{ value: item.workTimeEnd, label: item.workTimeEnd }}
          reservationAvailable={item.reservationAvailable}
        />
      </Styles.TimeSelectorWrap>

      <Styles.CheckBoxWrap role="button" onClick={() => onChangeWorkFlag('lunch')}>
        <CheckBoxIcon checked={!!item.isLunchTime} />
        <span>점심시간</span>
      </Styles.CheckBoxWrap>
      <Styles.TimeSelectorWrap>
        <span>시작 시간</span>
        <SelectBox
          width={116}
          optionHeight={130}
          placeholder="시간선택"
          disabled={!item.isLunchTime}
          onChange={(value) => {
            onChangeLunchTime('start', value);
          }}
          items={timeList}
          value={{ value: item.lunchTimeStart, label: item.lunchTimeStart }}
          reservationAvailable={item.reservationAvailable}
        />
        <span>종료 시간</span>
        <SelectBox
          width={116}
          optionHeight={130}
          placeholder="시간선택"
          disabled={!item.isLunchTime}
          onChange={(value) => {
            onChangeLunchTime('end', value);
          }}
          items={timeList}
          value={{ value: item.lunchTimeEnd, label: item.lunchTimeEnd }}
          reservationAvailable={item.reservationAvailable}
        />
      </Styles.TimeSelectorWrap>
    </Styles.Wrapper>
  );
};

export default WorkDay;
