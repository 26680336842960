import styled from '@emotion/styled';
import { GREY_3 } from 'styles/colors';

export const Article = styled.article`
  padding-top: 8px;
  border-top: 1px solid ${GREY_3};

  h3 {
    font-size: 14px;
    line-height: 17px;
    margin: 0;

    + p {
      margin-top: 8px;
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 24px;
    }
  }
`;
