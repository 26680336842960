import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Text } from 'src/components/common/TextInput/styles';
import { GREY_1, GREY_3, GREY_5, WHITE } from '../../../../styles/colors';

export const Wrapper = styled.div`
  display: flex;
  gap: 8px;
`;
const boxStyle = css`
  background-color: ${WHITE};
  border: 1px solid ${GREY_3};
  box-sizing: border-box;
  border-radius: 4px;
`;
export const Date = styled(Text)`
  cursor: pointer;

  :disabled {
    background-color: ${GREY_5};
    color: ${GREY_3};
  }
`;

export const Time = styled.select`
  ${boxStyle};
  width: 116px;
  height: 48px;
  border: 1px solid ${GREY_3};
  box-sizing: border-box;
  border-radius: 4px;

  margin-left: 8px;
  padding: 15px 12px;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  color: ${GREY_1};

  /* 셀렉트 박스 화살표 처리 */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6L11 1' stroke='%23434343'/%3E%3C/svg%3E%0A");
  background-size: 10px;
  background-position: calc(100% - 1.3em) center;
  background-repeat: no-repeat;
  ::-ms-expand {
    display: none;
  }

  :disabled {
    background-color: ${GREY_5};
    color: ${GREY_3};
  }
`;

export const Option = styled.option`
  width: 100%;
`;
