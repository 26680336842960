import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { QueryKey } from '../constants/queryKey';
import { ReservationApi } from '../api/reservation';

export const useGetNewReservation = (hospitalId: number) => {
  const router = useHistory();

  const { data, isLoading, error, refetch } = useQuery(
    [QueryKey.NEW_RESERVATION, hospitalId],
    async () => {
      const result = await ReservationApi.getNewReservation(hospitalId);
      return result;
    },
    {
      enabled: !!hospitalId,
      refetchOnWindowFocus: false,
      refetchInterval: 1000 * 60 * 60,
      onSuccess: (result) => {
        if (!isEmpty(result)) {
          window.confirm('신규 예약이 등록되었습니다.');
          router.push('/reservations');
        }
      },
    },
  );

  return { data, isLoading, error, refetch };
};
