import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import * as Styles from './styles';
import ArrowIcon, { ForwardType } from '../../icons/ArrowIcon';
import { ArrowIconWrapper } from './styles';
import { DateFormat } from '../../../constants/workHours';

export interface CalendarBasicProps {
  selectDate?: string; // 선택한 날짜
  enablePrevDate?: boolean; // 과거날짜 선택가능 여부
  availableDate?: string[]; // 선택 가능한 날짜
  notAvailableDate?: string[]; // 선택 뷸가능한 날짜
  onChange?: (selected: string) => void;
  onChangeMonth?: (viewDate: string) => void;
  disabled?: boolean;
  min?: string;
  max?: string;
}

const CalendarBasic = ({
  selectDate = '',
  enablePrevDate = true,
  availableDate,
  onChange = () => {},
  onChangeMonth = () => {},
  notAvailableDate = [],
  disabled = false,
  min = '',
  max = '',
}: CalendarBasicProps) => {
  // viewDate - 현재 보여지고 있는 달력
  // selectDate - 선택한 날짜
  const selectDateInit = useMemo(() => (_.isEmpty(selectDate) ? moment(null) : moment(selectDate)), [selectDate]);
  const [selectDateObj, setSelectDateObj] = useState(selectDateInit);
  const [viewDate, setViewDate] = useState(selectDateInit);
  // 이전달 마지막일 계산 - 영역잡아줘야함
  const prevMonthDate = useMemo(() => {
    if (!viewDate.isValid()) return [];
    const date = moment(viewDate).startOf('month').add(-1, 'day');
    const day = date.get('day'); // 요일
    const prev = [];
    for (let i = 0; i <= day; i += 1) {
      prev.push(moment(date).add(-i, 'day'));
    }
    return prev.reverse();
  }, [viewDate]);

  // 이번달 날짜
  const currentMonthDate = useMemo(() => {
    if (!viewDate.isValid()) return [];
    const date = moment(viewDate).startOf('month');
    return Array.from(Array(moment(viewDate).endOf('month').get('date')).keys()).map((idx) =>
      moment(date).date(idx + 1),
    );
  }, [viewDate]);

  // 달력 네비게이션
  const handleMoveMonth = useCallback(
    (month) => {
      if (month === 0) {
        setViewDate(moment());
        onChangeMonth(moment().format('YYYY-MM'));
      } else {
        const addDate = moment(viewDate).add(month, 'month');
        setViewDate(addDate);
        onChangeMonth(addDate.format('YYYY-MM'));
      }
    },
    [viewDate, onChangeMonth],
  );

  // 달력 선택
  const handleSelectDate = useCallback(
    (day) => {
      setSelectDateObj(day);
      onChange(day.format(DateFormat));
    },
    [onChange],
  );

  // 초기값이 설정된 값이 없을 경우 오늘날짜 화면으로 선택
  useEffect(() => {
    if (!viewDate.isValid()) {
      setViewDate(moment());
    }
  }, [viewDate]);

  useEffect(() => {
    setSelectDateObj(selectDateInit);
  }, [disabled, selectDateInit]);

  return (
    <Styles.Wrapper disabled={disabled}>
      <Styles.ControlWrapper>
        <ArrowIconWrapper onClick={() => handleMoveMonth(-1)}>
          <ArrowIcon forward={ForwardType.LEFT} disabled={disabled} />
        </ArrowIconWrapper>

        <Styles.DateLabel onClick={() => handleMoveMonth(0)}>{viewDate.format('YYYY.MM')}</Styles.DateLabel>

        <ArrowIconWrapper onClick={() => handleMoveMonth(1)}>
          <ArrowIcon forward={ForwardType.RIGHT} disabled={disabled} />
        </ArrowIconWrapper>
      </Styles.ControlWrapper>

      <Styles.DayLabelWrapper>
        <div>일</div>
        <div>월</div>
        <div>화</div>
        <div>수</div>
        <div>목</div>
        <div>금</div>
        <div>토</div>
      </Styles.DayLabelWrapper>

      <Styles.DayWrapper>
        {/* 이전달 */}
        {prevMonthDate.length !== 7 &&
          prevMonthDate.map((day) => (
            <Styles.DayContainer key={day.format('YYYYMMDD')}>
              <span className="prev">{day.format('D')}</span>
            </Styles.DayContainer>
          ))}

        {/* 이번달 */}
        {currentMonthDate.map((day) => {
          const isToday = moment().format('YYYYMMDD') === day.format('YYYYMMDD');
          const isSelected = selectDateObj?.format('YYYYMMDD') === day.format('YYYYMMDD');
          const isSunday = day.get('day') === 0;
          const isSaturday = day.get('day') === 6;

          let isDisabled = !isToday && !enablePrevDate && day.isBefore(moment());

          if (notAvailableDate?.length > 0) {
            isDisabled = notAvailableDate?.includes(day.format('YYYY-MM-DD'));
          }
          if (min !== '' && !isDisabled) {
            isDisabled = day.isBefore(moment(min));
          }
          if (max !== '' && !isDisabled) {
            isDisabled = day.isAfter(moment(max));
          }

          return (
            <Styles.DayContainer
              key={day.format('YYMMDD')}
              onClick={() => !isDisabled && handleSelectDate(day)}
              className={`${isDisabled ? 'disabled' : ''}`}>
              <span
                className={`text 
                ${isSunday ? ' sunday' : ''}
                ${isSaturday ? ' saturday' : ''}
                ${isToday ? ' today' : ''}
                ${isSelected ? ' selected' : ''}
                ${isDisabled ? ' disabled' : ''}
                `}>
                {day.format('D')}
              </span>
            </Styles.DayContainer>
          );
        })}
      </Styles.DayWrapper>

      <Styles.LegendWrapper>
        <span className="today">오늘</span>
        <span>선택됨</span>
        <span className="disabled">선택불가</span>
      </Styles.LegendWrapper>
    </Styles.Wrapper>
  );
};

export default CalendarBasic;
