import styled from '@emotion/styled';
import { GREY_1, GREY_3, GREY_5, WHITE } from '../../../../styles/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  div.active {
    display: block;
  }
`;
export const OptionWrapper = styled.div`
  position: absolute;
  top: 45px;
  left: 120px;
  width: 240px;
  //width: 184px;
  background-color: ${WHITE};
  border: 1px solid ${GREY_3};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  //overflow: hidden;
  z-index: 2;
  display: none;
  ul {
    flex-direction: column;
    li:last-of-type {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
`;
export const Item = styled.li`
  padding: 12px 12px;
  font-style: normal;
  font-weight: normal;
  //font-size: 11px;
  font-size: 14px;
  line-height: 17px;
  color: ${GREY_1};
  cursor: pointer;

  &:hover {
    background-color: ${GREY_5};
  }
`;
