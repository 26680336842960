import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReservationFieldKey, ReservationsParams, ReservationStatusType } from '../../model/reservation';
import { FieldKeyValue } from '../../model/common';

export interface ReservationState {
  reservationsParams: ReservationsParams;
}

// 탭별 정렬 기준 셋팅
const sortByStatus: Partial<Record<ReservationStatusType, string>> = {
  [ReservationStatusType.All]: `${ReservationFieldKey.ReservationTime}|ASC`, // 전체 정렬기준: 방문희망일 ASC (가장 과거날짜가 상단노출)
  [ReservationStatusType.Applied]: `${ReservationFieldKey.ReservationTime}|ASC`, // 예약 대기: 정렬기준: 방문희망일 ASC (가장 과거날짜가 상단노출)
  [ReservationStatusType.Confirmed]: `${ReservationFieldKey.ReservationTime}|ASC`, // 예약 확정: 정렬기준: 방문희망일 ASC (가장 과거날짜가 상단노출)
  [ReservationStatusType.Visited]: `${ReservationFieldKey.ReservationTime}|DESC`, // 방문완료 탭의 경우 방문일시 DESC
  // [ReservationStatusType.Holding]: `${ReservationFieldKey.ReservationTime}|DESC`, // 예약보류 필터의 경우 방문희망일시 DESC (가장 미래날짜가 상단 노출)
  [ReservationStatusType.Cancelled]: `${ReservationFieldKey.ReservationTime}|DESC`, // 예약취소 필터의 경우 방문희망일시 DESC (가장 미래날짜가 상단 노출)
  [ReservationStatusType.NoShow]: `${ReservationFieldKey.ReservationTime}|DESC`, // 노쇼 필터의 경우 방문희망일시 DESC (가장 미래날짜가 상단 노출)
};

const initialState = {
  reservationsParams: {
    serviceStatus: ReservationStatusType.Applied, // 최초 예약 대기 기본값
    search: '',
    desiredDate: '',
    startDate: '',
    endDate: '',
    hospitalMemberId: 0,

    pageSize: 10,
    pageNumber: 0,
    sort: sortByStatus[ReservationStatusType.Applied], // 방문 희망일 ASC 기본값
  },
};

const ReservationSlice = createSlice({
  name: 'reservation',
  initialState,
  reducers: {
    updateField: {
      reducer: (state, action: PayloadAction<FieldKeyValue>) => {
        const updateState = { ...state.reservationsParams, [action.payload.key]: action.payload.value };

        if (
          [ReservationFieldKey.ServiceStatus, ReservationFieldKey.ReservationTime, ReservationFieldKey.Search].includes(
            action.payload.key,
          )
        ) {
          // 검색하거나 날짜변경 또는 탭 변경시 첫 페이지 셋팅
          updateState.pageNumber = 0;

          // 탭별 정렬 기준 셋팅
          if (action.payload.key === ReservationFieldKey.ServiceStatus) {
            updateState.sort = sortByStatus[action.payload.value as ReservationStatusType] || '';
          }
        }

        state.reservationsParams = updateState;
      },
      prepare: ({ key, value }: FieldKeyValue) => ({ payload: { key, value } }),
    },
  },
});

export const { actions: reservationAction } = ReservationSlice;
export default ReservationSlice;
