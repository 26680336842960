import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import { ModalDuration } from 'src/constants/modal';
import { WHITE } from 'styles/colors';
import { ZIndex } from 'styles/zIndex';
import { Button } from 'src/components/common/buttons/Button';

interface ModalContainerParams {
  isShow: boolean;
}

export const ModalContainer = styled.div(
  ({ isShow }: ModalContainerParams) => css`
    display: flex;
    position: absolute;
    flex-direction: column;
    right: 0;
    width: 500px;
    height: 813px;
    filter: drop-shadow(-10px 10px 20px rgba(0, 0, 0, 0.08));

    ${!isShow
      ? css`
          animation: ${ModalDuration.Slide}ms ${slideOut} forwards;
        `
      : css`
          animation: ${ModalDuration.Slide}ms ${slideIn};
        `};
  `,
);

export const ModalHead = styled.div`
  box-sizing: border-box;
  background: ${WHITE};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  height: 58px;
  z-index: ${ZIndex.ModalContent};
  box-shadow: 0px 7px 12px rgba(231, 231, 231, 0.3);
`;

export const Title = styled.h2`
  font-size: 16px;
  line-height: 19px;
`;

export const CloseButton = styled(Button)`
  width: 24px;
  height: 24px;
  margin: 0 0 0 auto;
`;

export const IconWrapper = styled.div`
  width: 17px;
  height: 17px;
`;

export const ModalContent = styled.div`
  background: ${WHITE};
  height: 100%;
`;

const slideIn = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
`;

const slideOut = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
`;
