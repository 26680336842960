import { useMutation, useQueryClient } from 'react-query';
import { UserApi } from 'src/api/user';
import { LocalStorageKey } from 'src/constants/localStorage';
import { QueryKey } from 'src/constants/queryKey';
import { RequestSign } from 'src/model/loginUser';
import { instance } from 'src/utils/api/http';
import { LocalStorage } from 'src/utils/LocalStorage';
import { handleMutate } from 'src/utils/query/mutateUtils';
import { useDispatch } from 'react-redux';
import { ModalHandler } from '../../utils/ModalHandler';
import { ModalType } from '../../constants/modal';
import { accountAction } from '../../features/account/accountSlice';

export const usePostSignIn = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const queryKey = QueryKey.USER_SING_IN;
  const onMutate = handleMutate({ queryClient, queryKey });

  return useMutation((data: RequestSign) => UserApi.postSignIn(data), {
    onMutate,
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
    onSuccess: (data) => {
      const { accesstoken, refreshtoken } = data.headers;
      const { hospital, message } = data.data;
      if (hospital) {
        instance.defaults.headers.common.Authorization = `${accesstoken}`;
        LocalStorage.setValue(LocalStorageKey.REFRESHTOKEN, `${refreshtoken}`);
        LocalStorage.setValue(LocalStorageKey.ACCESSTOKEN, `${accesstoken}`);
        LocalStorage.setValue(LocalStorageKey.ISLOGIN, 'true');

        const hospitalId = String(hospital.id);
        LocalStorage.setValue(LocalStorageKey.HOSPITAL_ID, hospitalId);
        // queryClient.refetchQueries([QueryKey.USER], { active: true });
        dispatch(accountAction.updateHospitalId(Number(hospitalId)));
      } else if (message) {
        // 토스트 에러
        ModalHandler.show(ModalType.Toast, {
          ToastMessage: message,
        });
      } else {
        console.info('예외', data);
      }
    },
    onError: (error) => {
      ModalHandler.show(ModalType.Toast, {
        ToastMessage: JSON.stringify(error),
      });
    },
  });
};
