import React, { useCallback, useState } from 'react';
import MainLogo from 'src/components/icons/MainLogo';
import { LocalStorageKey } from 'src/constants/localStorage';
import { usePostSignIn } from 'src/query/Login/usePostSignIn';
import { LocalStorage } from 'src/utils/LocalStorage';
import { useDispatch } from 'react-redux';
import * as Styles from './styles';
import { accountAction } from '../../features/account/accountSlice';

function Login() {
  const dispatch = useDispatch();
  const hospitalId = LocalStorage.getValue(LocalStorageKey.HOSPITAL_ID);
  dispatch(accountAction.updateHospitalId(Number(hospitalId)));
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const { mutate: LoginMutate } = usePostSignIn();

  const onChangeId = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.currentTarget.value);
  }, []);

  const onChangePwd = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
  }, []);

  const onClickHandler = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (username.length > 0) {
        LoginMutate({ username, password });
      }
    },
    [username, password, LoginMutate],
  );

  return (
    <Styles.Wrapper>
      {/* {isLoading && <Loading />} */}
      <Styles.Header>
        <MainLogo />
        <span>핏펫 동물병원 예약관리</span>
      </Styles.Header>
      <Styles.LoginForm>
        <form onSubmit={onClickHandler}>
          <Styles.Input placeholder="아이디 입력" onChange={onChangeId} />
          <Styles.Input placeholder="패스워드 입력" autoComplete="off" type="password" onChange={onChangePwd} />
          <Styles.SubmitButton type="submit">들어가기</Styles.SubmitButton>
        </form>
      </Styles.LoginForm>
    </Styles.Wrapper>
  );
}

export default Login;
