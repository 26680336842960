import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { QueryKey } from '../../constants/queryKey';
import { handleMutate } from '../../utils/query/mutateUtils';
import { Operation } from '../../model/operations';
import { ModalHandler } from '../../utils/ModalHandler';
import { ModalType } from '../../constants/modal';
import { selectHospitalId } from '../../selectors';
import { OperationApi } from '../../api/operation';

/** 운영관리 수정 */
export const usePutOperationDetail = () => {
  const hospitalId = useSelector(selectHospitalId);
  const queryClient = useQueryClient();
  const queryKey = QueryKey.OPERATIOIN_UPDATE;
  const onMutate = handleMutate({ queryClient, queryKey });

  return useMutation(
    async (data: Operation) => {
      const response = await OperationApi.putOperationDetail(hospitalId, data);
      return response as Operation;
    },
    {
      onMutate,
      onSuccess: () => {
        ModalHandler.show(ModalType.Toast, {
          ToastMessage: `내용을 저장하였습니다.`,
        });
        queryClient.refetchQueries([QueryKey.OPERATIOIN_GET], { active: true });
      },
      onError: (error: AxiosError) => {
        ModalHandler.show(ModalType.Toast, {
          ToastMessage: error?.response?.data?.message || `운영관리 수정에 실패했습니다.`,
        });
      },
    },
  );
};
