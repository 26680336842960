import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { QueryKey } from '../../constants/queryKey';
import { BASE_URL, HTTP } from '../../utils/api/http';
import { MemberDetail, MemberInfoDto, MemberType, ResponseMember } from '../../model/member';
import { ModalHandler } from '../../utils/ModalHandler';
import { ModalType } from '../../constants/modal';
import { handleMutate } from '../../utils/query/mutateUtils';
import { selectHospitalId } from '../../selectors';
import { UserDto, UserInfoDto, UserType } from '../../model/user';
import { ReservationAvailableHour } from '../../model/reservation';
import { HospitalDto } from '../../features/account/accountSlice';
import { scheduleAction } from '../../features/schedule/scheduleSlice';
import { NonUserSaveDto } from '../../model/nonUser';

/** 병원 상세 정보 */
export const useGetHospital = (hospitalId: number) => {
  const { data, error, isLoading } = useQuery(
    [QueryKey.HOSPITAL_INFO, hospitalId],
    async () => {
      if (hospitalId === 0) {
        return {};
      }
      const response = await HTTP.get<HospitalDto>(`${BASE_URL}/api/hospitals/${hospitalId}`);
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      notifyOnChangeProps: 'tracked',
    },
  );
  return { data, error, isLoading };
};

/** 멤버목록 조회하기 */
export const useGetMemberList = () => {
  const hospitalId = useSelector(selectHospitalId);
  const { data, error, isLoading } = useQuery(
    [QueryKey.MEMBER_LIST, hospitalId],
    async () => {
      const response = await HTTP.get<MemberInfoDto[]>(`${BASE_URL}/api/hospitals/${hospitalId}/members`);

      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      notifyOnChangeProps: 'tracked',
    },
  );
  return { data, error, isLoading };
};

/** 멤버상세 조회 */
export const useGetMember = (id: number) => {
  const hospitalId = useSelector(selectHospitalId);
  const { data, error, isLoading } = useQuery(
    [QueryKey.MEMBER_DETAIL, hospitalId, id],
    async () => {
      const response = await HTTP.get<MemberType>(`${BASE_URL}/api/hospitals/${hospitalId}/members/${id}`);

      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      notifyOnChangeProps: 'tracked',
    },
  );
  return { data, error, isLoading };
};

/** 멤버 추가 */
export const useSaveMember = () => {
  const hospitalId = useSelector(selectHospitalId);
  const queryClient = useQueryClient();
  const queryKey = QueryKey.MEMBER_ADD;
  const onMutate = handleMutate({ queryClient, queryKey });

  return useMutation(
    async (data: MemberType) => {
      const response = await HTTP.post<MemberType, ResponseMember>(
        `${BASE_URL}/api/hospitals/${hospitalId}/members`,
        data,
      );
      return response.data;
    },
    {
      onMutate,
      onSuccess: () => {
        ModalHandler.hide(ModalType.Register);
        ModalHandler.show(ModalType.Toast, {
          ToastMessage: `구성원이 등록되었습니다.`,
        });
        queryClient.refetchQueries([QueryKey.MEMBER_LIST], { active: true });
      },
      onError: (error: AxiosError) => {
        console.info('error', error);
        ModalHandler.show(ModalType.Toast, {
          ToastMessage: `구성원 등록에 실패했습니다.`,
        });
      },
    },
  );
};

/** 멤버 삭제 */
export const useDeleteMember = () => {
  const hospitalId = useSelector(selectHospitalId);
  const queryClient = useQueryClient();
  return useMutation(
    QueryKey.MEMBER_DELETE,
    (memberId: number) => HTTP.delete(`${BASE_URL}/api/hospitals/${hospitalId}/members/${memberId}`),
    {
      onSuccess: () => {
        ModalHandler.show(ModalType.Toast, {
          ToastMessage: `구성원이 삭제되었습니다.`,
        });
        queryClient.refetchQueries([QueryKey.MEMBER_LIST], { active: true });
      },
      onError: () => {
        ModalHandler.show(ModalType.Toast, {
          ToastMessage: `구성원 삭제에 실패했습니다.`,
        });
      },
    },
  );
};

/** 멤버 수정 */
export const useUpdateMember = () => {
  const hospitalId = useSelector(selectHospitalId);
  const queryClient = useQueryClient();
  const queryKey = QueryKey.MEMBER_UPDATE;

  const onMutate = handleMutate({
    queryClient,
    queryKey,
  });

  return useMutation(
    (member: MemberDetail) =>
      HTTP.put<MemberDetail, MemberDetail>(`${BASE_URL}/api/hospitals/${hospitalId}/members/${member.id}`, member),
    {
      onMutate,
      onSuccess: ({ data }) => {
        ModalHandler.show(ModalType.Toast, {
          ToastMessage: `구성원의 정보가 수정되었습니다.`,
        });
        queryClient.refetchQueries([QueryKey.MEMBER_LIST], { active: true });
        queryClient.refetchQueries([QueryKey.MEMBER_DETAIL, hospitalId, data.id]);
      },
      onError: () => {
        ModalHandler.show(ModalType.Toast, {
          ToastMessage: `구성원의 정보 수정에 실패했습니다.`,
        });
      },
    },
  );
};

/** 회원여부 판단하기 - 예약 생성시 체크용 */
export const useCheckMember = (userPhone: string) => {
  const hospitalId = useSelector(selectHospitalId);

  const { data, error, isLoading, isError } = useQuery(
    [QueryKey.MEMBER_CHECK, userPhone],
    async () => {
      if (_.isEmpty(userPhone)) {
        return [];
      }
      const phone = userPhone.match(/\d+/g);
      if (userPhone && phone) {
        // 10자리 미만 번호는 콜하지 않음
        // if (phone.join('').length < 10) {
        //   return false;
        // }
        const response = await HTTP.get<UserInfoDto[]>(
          `${BASE_URL}/api/hospitals/${hospitalId}/user-info?tel=${phone.join('')}`,
        );
        return response.data;
      }

      return [];
    },
    {
      refetchOnWindowFocus: false,
      notifyOnChangeProps: 'tracked',
    },
  );
  return { data, error, isLoading, isError };
};

/**
 * 병원 관리자가 특정 구성원의 이용 가능한 시간을 조회합니다.
 * /api/hospitals/{hospitalId}/available-hours/{memberId}
 */
export const useAvailableHours = (memberId: string, desiredDate: string) => {
  const hospitalId = useSelector(selectHospitalId);

  const { data, error, isLoading, isError } = useQuery(
    [QueryKey.MEMBER_CHECK_AVAILABLE_HOURS, hospitalId, memberId, desiredDate],
    async () => {
      if (memberId === '-1' || _.isEmpty(desiredDate)) {
        return [];
      }

      // ?hospitalId=0&desiredDate=2022-01-19&memberId=0
      const response = await HTTP.get<ReservationAvailableHour[]>(
        `${BASE_URL}/api/hospitals/${hospitalId}/available-hours/${memberId}?desiredDate=${desiredDate}`,
      );
      const now = moment();
      return response.data.map((hours) => {
        return {
          ...hours,
          // 현재시간보다 이전 시간은 설정 금지
          isAvailable: moment(`${desiredDate} ${hours.time}`).isBefore(now) ? false : hours.isAvailable,
        };
      });
    },
    {
      refetchOnWindowFocus: false,
    },
  );
  return { data, error, isLoading, isError };
};

/**
 * 병원 관리자가 특정 구성원의 이용 가능한 날짜을 조회합니다.
 * /hospital-management/{hospitalId}/available-days/{memberId}
 */
export const useAvailableDays = (memberId: number, yearMonth: string) => {
  const hospitalId = useSelector(selectHospitalId);

  const { data, error, isLoading, isError } = useQuery(
    [QueryKey.MEMBER_CHECK_AVAILABLE_DAYS, hospitalId, memberId, yearMonth],
    async () => {
      if (memberId === -1) {
        return [];
      }

      const params = {
        hospitalId,
        memberId,
        startDate: moment(`${yearMonth}-01`).format('YYYY-MM-DD'),
        endDate: moment(`${yearMonth}-01`).add(1, 'months').format('YYYY-MM-DD'),
      };

      const response = await HTTP.get<string[]>(`${BASE_URL}/api/hospitals/${hospitalId}/available-days/${memberId}`, {
        params,
      });

      const now = moment();
      return response.data.filter((dateStr) => {
        return !moment(dateStr).isBefore(now);
      });
    },
    {
      refetchOnWindowFocus: false,
    },
  );
  return { data, error, isLoading, isError };
};

/** 가회원 추가 */
export const useSaveNonUser = () => {
  const hospitalId = useSelector(selectHospitalId);
  const queryClient = useQueryClient();
  const queryKey = QueryKey.NON_USER_ADD;

  const onMutate = handleMutate({ queryClient, queryKey });
  const dispatch = useDispatch();

  return useMutation(
    async (data: NonUserSaveDto) => {
      const param = {
        ...data,
        tel: data.tel.replaceAll('-', ''),
        birthday: data.birthday.replace(/^(\d{4})(\d{2})(\d{2})$/, `$1-$2-$3`),
      };

      const response = await HTTP.post<NonUserSaveDto, UserDto>(
        `${BASE_URL}/api/hospitals/non-user/${hospitalId}/user`,
        param,
      );
      const user: UserInfoDto = {
        ...response.data,
        pets: [],
        userType: UserType.NON_USER,
      };
      dispatch(scheduleAction.updateEditUser(user));
      return response.data;
    },
    {
      onMutate,
      onSuccess: () => {
        ModalHandler.show(ModalType.Toast, {
          ToastMessage: `보호자가 등록되었습니다.`,
        });
      },
      onError: (error: AxiosError) => {
        console.info('error', error);
        ModalHandler.show(ModalType.Toast, {
          ToastMessage: `보호자 등록에 실패했습니다.`,
        });
      },
    },
  );
};
