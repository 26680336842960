import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { GREY_3, GREY_5 } from '../../../../styles/colors';

export interface BasicTextAreaProps {
  value: string;
  onChange: (str: string) => void;
  placeholder?: string;
  size?: number;
  disabled?: boolean;
  width?: number;
  height?: number;
}
const BasicTextArea = ({
  value,
  onChange,
  placeholder = '',
  size = 0,
  disabled = false,
  width = 308,
  height = 100,
}: BasicTextAreaProps) => {
  const onChangeText = useCallback(
    (str) => {
      if (size === 0) {
        onChange(str);
      } else {
        // const limit = Math.min(size, value.length);
        onChange(str.substr(0, size));
      }
    },
    [onChange],
  );
  return (
    <TextAreaWrapper width={width} height={height}>
      <textarea
        disabled={disabled}
        className="textarea"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChangeText(e.currentTarget.value)}
      />
      {size !== 0 && (
        <span className="size">
          {value.length}/{size}
        </span>
      )}
    </TextAreaWrapper>
  );
};

export default BasicTextArea;

export const TextAreaWrapper = styled.div(
  ({ width, height }: { width?: number; height?: number }) => css`
    display: flex;

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;

    /* Greyscale/Grey 3 */

    //color: ${GREY_3};

    textarea {
      //position: absolute;
      padding: 12px;
      width: ${width}px;
      height: ${height}px;
      resize: none;
      outline: none;

      /* Greyscale/White */

      background: #ffffff;
      /* Greyscale/Grey 3 */

      border: 1px solid #d1d1d1;
      box-sizing: border-box;
      border-radius: 4px;
      &:disabled {
        color: ${GREY_3};
        background-color: ${GREY_5};
        cursor: default;
      }
      &:disabled::placeholder {
        color: ${GREY_3};
      }
      &::placeholder {
        color: ${GREY_3};
      }
    }

    span.size {
      position: absolute;
      align-self: flex-end;
      width: ${width}px;
      text-align: right;
      padding-right: 10px;
      margin-bottom: 10px;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 14px;
      text-align: right;

      color: ${GREY_3};
    }
  `,
);
