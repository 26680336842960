import React, { useCallback, useMemo, useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { CHART_DATES, IReservationStatusDto, ReservationStatusColor } from 'src/model/statistic';
import DoctorList from './DoctorList';
import * as StatisticStyles from '../styles';
import * as Styles from './styles';
import { useGetReservationStatus } from '../../../query/Statistic/useGetReservationStatus';

export default function ReservationMetrics() {
  const [selectedDate, setSelectedDate] = useState(CHART_DATES[0].key);
  const [selectedDoctors, setSelectedDoctors] = useState<number[]>([]); // 선택된 수의사 리스트
  const { data: reservationStatus } = useGetReservationStatus(selectedDate, selectedDoctors);

  const statusData = useMemo(() => {
    if (reservationStatus) {
      const data: IReservationStatusDto[] = reservationStatus?.reduce((acc, cur) => {
        acc.push({
          id: cur.id,
          data: cur.data,
          color: ReservationStatusColor[cur.id],
        });
        return acc;
      }, [] as Array<IReservationStatusDto>);
      return data;
    }

    return [];
  }, [reservationStatus]);

  const handleDateChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(e.target.value);
  }, []);

  return (
    <StatisticStyles.Article hasTwo>
      <h4>예약 지표</h4>
      <Styles.Header>
        <DoctorList selectedDoctors={selectedDoctors} setSelectedDoctors={setSelectedDoctors} />

        <Styles.DateTabs>
          {CHART_DATES.map((date) => (
            <label htmlFor={`chart-${date.key}`} key={date.key}>
              <input
                id={`chart-${date.key}`}
                type="radio"
                name="date"
                value={date.key}
                checked={date.key === selectedDate}
                onChange={handleDateChange}
              />
              <span>{date.label}</span>
            </label>
          ))}
        </Styles.DateTabs>
      </Styles.Header>

      <Styles.ChartWrapper style={{ height: 220 }}>
        <ResponsiveLine
          margin={{
            top: 8,
            left: 30,
            right: 40,
            bottom: 48,
          }}
          data={statusData}
          colors={(data) => data.color}
          useMesh
          enablePoints={false}
          enableSlices="x"
          axisLeft={{
            tickValues: 4,
            tickSize: 0,
            tickPadding: 16,
          }}
          axisBottom={{
            tickSize: 0,
            tickPadding: 10,
          }}
          legends={[
            {
              anchor: 'bottom',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: 48,
              itemsSpacing: 0,
              itemWidth: 100,
              itemHeight: 12,
              itemTextColor: '#434343',
              itemDirection: 'left-to-right',
              itemOpacity: 1,
              symbolSize: 8,
              symbolShape: 'square',
            },
          ]}
        />
      </Styles.ChartWrapper>
    </StatisticStyles.Article>
  );
}
