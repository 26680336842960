import { WorkingDayType } from './workingDay';

export enum MemberTypeKey {
  Name = 'name',
  DisplayName = 'displayName',
  Occupation = 'occupation',
  Major = 'major',
  PhoneNo = 'phoneNo',
  LicenseNo = 'licenseNo',
  workDays = 'workDays',
  TemporaryHolidayStart = 'temporaryHolidayStart',
  TemporaryHolidayEnd = 'temporaryHolidayEnd',
  IsTemporaryHoliday = 'isTemporaryHoliday',
}

// 구성원 목록
export interface MemberInfoDto {
  id: number;
  name: string;
  major: string;
  licenseNo?: string;
  // displayName: string;
  // occupation: OccupationType;
}

// 구성원 상세
export interface MemberType {
  /** 병원 구성원 이름 */
  name: string;
  /** 전문 분야 */
  major: string;
  /** 면허 번호 (Integer) */
  licenseNo: string;
  /** 병원 구성원 근무 일시 */
  workDays: WorkingDayType[];
  /** 임시 휴무일 여부 */
  isTemporaryHoliday: boolean;
  /** 임시 휴무일 시작 일시 */
  temporaryHolidayStart?: string;
  /** 임시 휴무일 종료 일시 */
  temporaryHolidayEnd?: string;
  /** 당일 예약 가능 여부 */
  isTodayReservationAvailable?: boolean;
  // workingDay: WorkingDayType[];
  // displayName?: string;
  // occupation?: OccupationType;
  // phoneNo?: string;
}

// export interface WorkingDayType {
//   workDay: string;
//   workTimeStart: string;
//   workTimeEnd: string;
// }

export interface DateType {
  startDate: string;
  endDate: string;
}

export interface DateTimeType extends DateType {
  startTime: string;
  endTime: string;
}

export interface MemberDetail extends MemberType {
  id: number;
}

export interface ResponseMemberList {
  members: MemberInfoDto[];
}

export interface ResponseMember {
  memberId: number;
}

export interface ResponseMemberDetail {
  member: MemberDetail;
}

export interface ResponseMemberUpdate {
  memberId: number;
}

export interface ResponseUserDetail {
  member: MemberType;
}
