import React, { useEffect, useMemo, useState } from 'react';
import { MemberInfoDto } from 'src/model/member';
import { useGetMemberList } from 'src/query/Member/useMemberQuery';
import TabArrowIcon from '../../icons/TabArrowIcon';
import * as Styles from './styles';

const PAGE_SIZE = 6;

interface DoctorListProps {
  selectedDoctors: number[];
  setSelectedDoctors: React.Dispatch<React.SetStateAction<number[]>>;
}

export default function DoctorList({ selectedDoctors, setSelectedDoctors }: DoctorListProps) {
  const { data: doctors } = useGetMemberList();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pagingDoctors, setPagingDoctors] = useState<MemberInfoDto[]>(); // 페이징 처리된 수의사 리스트
  const [isCheckedAll, setIsCheckedAll] = useState<boolean>(true); // 전체 선택 여부
  const totalPage: number = useMemo(() => Math.ceil((doctors?.length ?? 0) / PAGE_SIZE), [doctors]);

  const handlePage = (type: string) => {
    if (type === 'prev') {
      setCurrentPage((state) => state - 1);
    }

    if (type === 'next') {
      setCurrentPage((state) => state + 1);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (doctors?.length === 1) return; // 등록된 수의사 한명일 때 handleChange 막기

    const handlingDoctors = new Set(selectedDoctors);
    if (e.target.checked) {
      handlingDoctors.add(+e.target.value);
    } else {
      handlingDoctors.delete(+e.target.value);
    }
    setIsCheckedAll(false);
    setSelectedDoctors(Array.from(handlingDoctors));
  };

  const handleCheckAll = () => {
    setIsCheckedAll(true);
    setSelectedDoctors([]);
  };

  useEffect(() => {
    if (!doctors) return;
    if (doctors.length > 0) {
      const startIndex: number = (currentPage - 1) * 6;
      const endIndex: number = PAGE_SIZE * currentPage;
      setPagingDoctors(doctors.slice(startIndex, endIndex));
    }
  }, [doctors, currentPage]);

  return (
    <Styles.DoctorTabs>
      {currentPage !== 1 && (
        <button type="button" className="arrow" onClick={() => handlePage('prev')} aria-label="Prev Button">
          <TabArrowIcon direction="left" />
        </button>
      )}

      {currentPage === 1 && pagingDoctors && pagingDoctors.length > 1 && (
        <label htmlFor="all">
          <input id="all" type="checkbox" checked={isCheckedAll} onChange={handleCheckAll} />
          <span>전체</span>
        </label>
      )}
      {pagingDoctors?.map((doctor) => (
        <label key={doctor.id} htmlFor={`doctor-${doctor.id}`}>
          <input
            id={`doctor-${doctor.id}`}
            type="checkbox"
            value={doctor.id}
            checked={doctors?.length === 1 || selectedDoctors.some((id) => id === doctor.id)}
            onChange={handleChange}
          />
          <span>{doctor.name} 수의사</span>
        </label>
      ))}

      {currentPage < totalPage && (
        <button type="button" className="arrow" onClick={() => handlePage('next')} aria-label="Next Button">
          <TabArrowIcon direction="right" />
        </button>
      )}
    </Styles.DoctorTabs>
  );
}
