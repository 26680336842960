import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import { ModalDuration } from 'src/constants/modal';
import { ZIndex } from 'styles/zIndex';
import { Button } from 'src/components/common/buttons/Button';
import { WHITE } from 'styles/colors';

interface ModalContainerParams {
  isShow: boolean;
}

export const Title = styled.h2`
  font-size: 16px;
  font-weight: 400;
`;

export const CloseButton = styled(Button)`
  width: 24px;
  height: 24px;
  margin: 0 0 0 auto;
`;

export const IconWrapper = styled.div`
  width: 17px;
  height: 17px;
`;

export const ModalHead = styled.div`
  box-sizing: border-box;
  background: ${WHITE};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  height: 58px;
  z-index: ${ZIndex.ModalContent};
  box-shadow: 0px 7px 12px rgba(231, 231, 231, 0.3);
`;

export const ModalBottom = styled.div`
  box-sizing: border-box;
  background: ${WHITE};
  height: 92px;
  min-height: 92px;
  z-index: ${ZIndex.ModalContent};
  box-shadow: 0px -7px 12px rgba(231, 231, 231, 0.3);
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  right: 36px;
  bottom: 24px;

  button {
    display: inline-block;
  }

  button + button {
    margin-left: 4px;
  }
`;

export const ModalContent = styled.div`
  background: ${WHITE};
  //overflow: scroll;
  overflow: auto;
`;

export const ModalContainer = styled.div(
  ({ isShow }: ModalContainerParams) => css`
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    width: 520px;
    //height: 800px;
    max-height: 800px;
    border-radius: 10px;
    z-index: ${ZIndex.ModalContent};
    overflow: hidden;
    ${!isShow
      ? css`
          animation: ${ModalDuration.Register}ms ${hideModal} forwards;
        `
      : css`
          animation: ${ModalDuration.Register}ms ${showModal};
        `};
  `,
);

const showModal = keyframes`
  0% {
    transform: translate(-50%, 20%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
`;

const hideModal = keyframes`
  0% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, 20%);
    opacity: 0;
  }
`;
