import { SortType } from '../components/icons/GridSortArrowIcon';
import { Pet } from './pet';

export enum CustomerFieldKey {
  Name = 'name',
  Tel = 'tel',
  IsMember = 'isMember',
  Visit = 'visitedCount',
  Cancel = 'cancelledCount',
  NoShow = 'noShowCount',
  Date = 'lastReservation',
  Buttons = 'buttons',
  Page = 'page',
  KeyWord = 'keyWord',
  Sort = 'sort',
}

export interface ICustomerHeader {
  name?: string;
  value: CustomerFieldKey;
  width?: number;
  fixed?: boolean; // 고정시키려면 width 필수
  sort?: boolean;
  sortType?: SortType;
}

export interface CustomerListModel {
  id?: number;
  name: string;
  tel: string;
  isMember: boolean;
  visit: string;
  cancel: string;
  noshow: string;
  date: string;
}

export const customerHeaders = [
  { name: '보호자 이름', value: CustomerFieldKey.Name },
  { name: '보호자 휴대폰', value: CustomerFieldKey.Tel },
  { name: '핏펫 회원 여부', value: CustomerFieldKey.IsMember },
  { name: '방문 완료', value: CustomerFieldKey.Visit, sort: true, sortType: SortType.NORMAL },
  { name: '예약 취소', value: CustomerFieldKey.Cancel, sort: true, sortType: SortType.NORMAL },
  { name: '노쇼', value: CustomerFieldKey.NoShow, sort: true, sortType: SortType.NORMAL },
  { name: '최근 방문일', value: CustomerFieldKey.Date, sort: true, sortType: SortType.NORMAL },
  { name: '', value: CustomerFieldKey.Buttons },
];

export interface ILastReservation {
  reservationTime: string;
  serviceStatus: TCustomerReservationStatus;
}

export interface ICustomer {
  /** 취소 카운트 */
  cancelledCount: number;
  /** 종합백신 카운트 */
  comprehensiveVaccinationCount: number;
  /** 상담진료 카운트 */
  consultingCount: number;
  /** 구충 카운트 */
  dewormingCount: number;
  /** 일반진료 카운트 */
  diagnosisCount: number;
  /** 건강검진 카운트 */
  healthScreeningCount: number;
  /** 심장사상충 카운트 */
  heartwormPreventionCount: number;
  /** 예방접종 카운트 */
  vaccinationCount: number;
  /** 방문 카운트 */
  visitedCount: number;
  /** 노쇼 카운트 */
  noShowCount: number;
  /** 기타백신 카운트 */
  otherVaccinesCount: number;
  /** 광견병접종 카운트 */
  rabiesPreventionCount: number;
  lastReservation: string;
  lastReservationList: ILastReservation[];
  petInfoDtoList: Pet[];
  tel: string;
  userId: number;
  userName: string;
  userType: 'N' | 'U';
}

interface IPageable {
  sort: any; // 정보 필요
  offset: number;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  unpaged: boolean;
}
export interface ICustomerListDto {
  content: ICustomer[];
  empty: boolean;
  first: boolean;
  hasContent: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: IPageable;
  size: number;
  totalElements: number;
  totalPages: number;
}

export const ReservationStatusToString = {
  VISITED: '방문 완료',
  CANCELLED: '예약 취소',
  NO_SHOW: '노쇼',
} as const;

export const ReservationDateToString = {
  VISITED: '진료 희망 일시',
  CANCELLED: '취소 처리 일시',
  NO_SHOW: '노쇼 처리 일시',
} as const;

export type TCustomerReservationStatus = keyof typeof ReservationStatusToString;

export interface ICustomerParams {
  sort?: string;
  keyWord?: string;
  size?: number;
  page?: number;
}
