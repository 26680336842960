import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { AHEAD_MINT, GREY_1, GREY_11, GREY_3, WHITE } from 'styles/colors';

export const Section = styled.section`
  padding-top: 8px;
  border-top: 1px solid ${GREY_3};

  &:last-child {
    margin-top: 40px;
  }

  h3 {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 8px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 36px;
`;

export const Article = styled.article(
  ({ hasTwo }: { hasTwo?: boolean }) => css`
    h4 {
      font-size: 12px;
      line-height: 14px;
    }

    ${hasTwo &&
    css`
      grid-column: 2 / 4;
    `};
  `,
);

export const PetTypeTabs = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 16px 0 16px 0;

  button {
    border-radius: 5px;
    background-color: ${GREY_11};
    color: ${GREY_1};
    font-size: 14px;
    line-height: 17px;
    padding: 4px 8px;
  }

  .active {
    background-color: ${AHEAD_MINT};
    color: ${WHITE};
    font-weight: 700;
  }
`;
