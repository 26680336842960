import styled from '@emotion/styled';
import { GREY_2, GREY_4, WHITE } from 'styles/colors';

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  font-size: 14px;
  font-weight: 400;
  color: ${GREY_2};
  margin-right: 24px;
  width: 61px;
`;

export const TimeWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;
export const Time = styled.select`
  box-sizing: border-box;
  border-radius: 5px;
  width: 140px;
  height: 48px;
  font-size: 14px;
  padding: 12px;
  background-color: ${WHITE};
  border: 1px solid ${GREY_4};
  margin: 4px 123px 4px 0px;
`;

export const Option = styled.option`
  width: 100%;
`;

export const Checked = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-top: 18px;
  margin-bottom: 12px;
  margin-left: 80px;
  span {
    margin-left: 8px;
  }
`;

export const TimeSection = styled.div`
  display: flex;
`;
