import styled from '@emotion/styled';
import { GREY_1, GREY_5, WHITE } from '../../../../styles/colors';

export const Search = styled.div`
  display: flex;
  position: relative;
`;

export const SearchInput = styled.input`
  padding-left: 12px;
  width: 204px;
  height: 38px;
  background-color: ${GREY_5};
  display: flex;
  flex-direction: row;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 8px;
  left: 170px;
  cursor: pointer;
`;

export const TextRightWrapper = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: row;
`;

export const ResetButton = styled.div`
  cursor: pointer;
  border: 1px solid ${GREY_1};
  background-color: ${WHITE};
  color: ${GREY_1};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  padding: 10px;
  letter-spacing: -0.02em;
  border-radius: 5px;
  height: 38px;
  width: 98px;
`;

export const SearchDesc = styled.div`
  margin-left: 10px;
  color: ${GREY_1};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: -0.02em;
  border-radius: 5px;
  height: 38px;
  line-height: 38px;
`;
