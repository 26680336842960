import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { AHEAD_MINT, CORAL, GREY_1, GREY_3, WHITE } from '../../../../styles/colors';
import { BasicButton } from '../../common/buttons/BasicButton';

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 36px;
  gap: 28px;
`;
export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 55%;
  gap: 8px;
`;
export const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 45%;

  div:first-of-type {
    margin-top: 0;
  }
`;

export const PetTitleWrapper = styled.div`
  display: flex;
  gap: 11px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;
export const PetTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: ${GREY_1};
  flex-shrink: 0;
`;
export const PetLine = styled.div`
  //background-color: red;
  //display: inline-block;
  //width: 100%;
  //height: 17px;
  border: none;
  border-top: 1px dashed #dcdcdc;

  color: #fff;
  background-color: #fff;
  height: 2px;
  width: 100%;
`;
export const ProtectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  div:first-of-type {
    margin-top: 0;
  }

  p {
    margin-left: 120px;
  }
`;

export const AirBackWrapper = styled.div(
  ({ need }: { need: boolean }) => css`
    content: '';
    margin-top: ${need ? '20px' : '0'};
  `,
);

export const InfoLabel = styled.div`
  margin-left: 120px;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.02em;
  /* Main/Fiepet Mint */

  color: ${AHEAD_MINT};
`;

export const PetDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  //16 24
  padding: 0 24px 0 16px;
`;

export const SmallActionButtonWrapper = styled.div`
  display: flex;
  //justify-content: flex-end;
  justify-content: right;
  margin-top: 34px;
  gap: 8px;
`;
export const SmallActionButton = styled(BasicButton)`
  width: 82px;
  height: 36px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
`;

export const CheckBoxWrap = styled.div(
  ({ disabled = false }: { disabled?: boolean }) => css`
    display: flex;
    align-items: center;
    cursor: ${disabled ? 'default' : 'pointer'};
    margin-left: 120px;
    margin-top: 18px;

    span {
      margin-left: 8px;
      ${disabled &&
      css`
        color: ${GREY_3};
      `}
    }
  `,
);

export const ButtonWrapper = styled.div`
  display: flex;
  //justify-content: flex-end;
  justify-content: space-between;
  padding: 24px 36px;
`;

export const InfoMessage = styled.div`
  display: flex;
  align-items: center;
  color: ${CORAL};
`;

export const LabelWrapper = styled.div(
  ({ disabled = false }: { disabled?: boolean }) => css`
    display: flex;
    align-items: center;
    margin-top: 16px;

    span.title {
      display: block;
      font-size: 14px;
      font-weight: bold;
      color: ${GREY_1};
      width: 120px;
      ${disabled &&
      css`
        color: ${GREY_3};
      `}
    }
  `,
);

export const TextAreaWrapper = styled.div(
  ({ disabled = false }: { disabled?: boolean }) => css`
    display: flex;
    align-items: baseline;
    margin-top: 16px;
    //gap: 8px;

    span.title {
      display: block;
      font-size: 14px;
      font-weight: bold;
      color: ${disabled ? GREY_3 : GREY_1};
      width: 120px;
    }
    position: relative;
  `,
);

export const TitleLabel = styled.span`
  display: block;
  font-size: 14px;
  font-weight: bold;
  //color: ${GREY_1};
  width: 120px;
`;

export const RadioButtonWrapper = styled.div(
  ({ disabled = false }: { disabled?: boolean }) => css`
    display: flex;
    margin-top: 16px;
    color: ${disabled ? GREY_3 : GREY_1};
    button {
      width: 92px;
      border-radius: 0;
      background-color: ${WHITE};
      border: 1px solid ${GREY_3};
      color: ${GREY_3};
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
    }
    button.active {
      border: 1px solid ${GREY_1};
      color: ${GREY_1};
    }
    button:disabled {
      border: 1px solid ${GREY_3} !important;
      color: ${GREY_3};
    }
    button:first-of-type {
      border-right-width: 0;
      //margin-right: -1px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    button.active:first-of-type,
    button:disabled:first-of-type {
      border-right-width: 1px;
      margin-right: -1px;
      z-index: 1;
    }
    button:last-of-type {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  `,
);

export const BirthDayWrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;
`;
