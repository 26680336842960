import _ from 'lodash';
import { ClosedTime, Operation, OperationWorkDayType } from 'src/model/operations';
import { ModalHandler } from 'src/utils/ModalHandler';
import { getStrDateInRange, strDate2strDatetime, strDatetime2strDate } from 'src/utils/DateUtils';
import { ModalType } from 'src/constants/modal';
import { StartTime, End24Time, End24TimeDisplay } from 'src/constants/workHours';

const useOperation = () => {
  const isOperating24Hours = (workingDay: OperationWorkDayType) => {
    const { workTimeStart, workTimeEnd } = workingDay;
    return workTimeStart === StartTime && workTimeEnd === End24Time;
  };

  const displayWorkTimeEnd = (workTimeEnd: string) => {
    return workTimeEnd === End24Time ? End24TimeDisplay : workTimeEnd;
  };

  const convertClosedDateTimes2CloseDates = (closedDateTimes: ClosedTime[]): ClosedTime[] => {
    return closedDateTimes.map((x) => {
      return {
        closedTimeStart: strDatetime2strDate(x.closedTimeStart),
        closedTimeEnd: strDatetime2strDate(x.closedTimeEnd),
      };
    });
  };

  const convertClosedDates2ClosedDateTimes = (closedDates: ClosedTime[]) => {
    return closedDates.map((x) => {
      return {
        closedTimeStart: strDate2strDatetime(x.closedTimeStart),
        closedTimeEnd: strDate2strDatetime(x.closedTimeEnd),
      };
    });
  };

  const setClosedTimesPayload = (closedDates: ClosedTime[]) => {
    return convertClosedDates2ClosedDateTimes(closedDates).map((x) => {
      return { ...x, closedType: 'HOLIDAY' };
    });
  };

  const validateOperationData = (data: Operation): boolean => {
    let message;
    const { closedTimes } = data;
    if (!validateObjectDuplicate(closedTimes)) {
      message = '임시휴무일에 중복된 날짜가 있습니다.';
    }
    if (!message && !validateEmptyCloseTime(closedTimes)) {
      message = '임시휴무일에 입력되지 않은 날짜가 있습니다';
    }

    if (message) {
      ModalHandler.show(ModalType.Toast, {
        ToastMessage: message,
        duration: 300,
      });
      return false;
    }
    return true;
  };

  const validateEmptyCloseTime = (data: Array<ClosedTime>): boolean => {
    let isValidate = true;
    if (!_.isArray(data) || data.length <= 0) return isValidate;
    data.forEach((x: ClosedTime) => {
      const { closedTimeStart, closedTimeEnd } = x;
      if (!closedTimeStart || !closedTimeEnd) {
        isValidate = false;
      }
    });
    return isValidate;
  };

  // 여러 곳에서 사용 시 util로 빼기
  const validateObjectDuplicate = (data: Array<ClosedTime>): boolean => {
    if (!_.isArray(data) || data.length <= 0) return true;
    let dates: string[] = [];
    data.forEach((x: ClosedTime) => {
      const { closedTimeStart, closedTimeEnd } = x;
      dates = dates.concat(getStrDateInRange(closedTimeStart, closedTimeEnd));
    });
    return dates.length === new Set(dates).size;
  };

  return {
    isOperating24Hours,
    displayWorkTimeEnd,
    convertClosedDateTimes2CloseDates,
    setClosedTimesPayload,
    validateOperationData,
  };
};

export default useOperation;
