import { LocalStorageKey } from 'src/constants/localStorage';

export class LocalStorage {
  static getValue(key: LocalStorageKey) {
    return localStorage?.getItem(key);
  }

  static setValue(key: LocalStorageKey, value: string) {
    localStorage?.setItem(key, value);
  }

  static remove(key: LocalStorageKey) {
    localStorage?.removeItem(key);
  }
}
