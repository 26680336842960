import React from 'react';
import styled from '@emotion/styled';

export const PlusIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 0C6.51675 0 6.125 0.391751 6.125 0.875V6.125H0.875C0.391751 6.125 0 6.51675 0 7C0 7.48325 0.391751 7.875 0.875 7.875H6.125V13.125C6.125 13.6082 6.51675 14 7 14C7.48325 14 7.875 13.6082 7.875 13.125V7.875H13.125C13.6082 7.875 14 7.48325 14 7C14 6.51675 13.6082 6.125 13.125 6.125H7.875V0.875C7.875 0.391751 7.48325 0 7 0Z"
      fill="white"
    />
  </svg>
);

export const DefaultPlusIcon = () => (
  <IconWrapper>
    <PlusIcon />
  </IconWrapper>
);

export const TimePlusIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 5C11.5168 5 11.125 5.39175 11.125 5.875V11.125H5.875C5.39175 11.125 5 11.5168 5 12C5 12.4832 5.39175 12.875 5.875 12.875H11.125V18.125C11.125 18.6082 11.5168 19 12 19C12.4832 19 12.875 18.6082 12.875 18.125V12.875H18.125C18.6082 12.875 19 12.4832 19 12C19 11.5168 18.6082 11.125 18.125 11.125H12.875V5.875C12.875 5.39175 12.4832 5 12 5Z"
      fill="#434343"
    />
  </svg>
);

const IconWrapper = styled.div`
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
