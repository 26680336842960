import styled from '@emotion/styled';
import { WHITE, GREY_5, GREY_1 } from 'styles/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${WHITE};
  border-radius: 0 10px 10px 10px;
  padding: 48px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

export const TopDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const SearchInput = styled.input`
  padding-left: 12px;
  width: 204px;
  height: 38px;
  background-color: ${GREY_5};

  display: flex;
  flex-direction: row;
`;

export const BodyDiv = styled.div`
  //background-color: blue;
`;

export const TextWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const RightWraper = styled.div`
  display: flex;
  gap: 10px;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 8px;
  //right: 8px;
  left: 170px;
  cursor: pointer;
`;

export const TextRightWrapper = styled.div`
  //position: absolute;
  //top: 8px;
  //right: 8px;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
`;

export const ResetButton = styled.div`
  cursor: pointer;
  border: 1px solid ${GREY_1};
  background-color: ${WHITE};
  color: ${GREY_1};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  //padding: 11px 10px 11px 34px;
  padding: 10px;
  //margin-right: 10px;
  letter-spacing: -0.02em;
  border-radius: 5px;
  height: 38px;
  width: 98px;
`;

export const SearchDesc = styled.div`
  margin-left: 10px;
  color: ${GREY_1};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: -0.02em;
  border-radius: 5px;
  height: 38px;
  line-height: 38px;
`;
