import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BasicButton } from 'src/components/common/buttons/BasicButton';
import {
  Reservation,
  ReservationDetail,
  ReservationStatusChangeParams,
  ReservationStatusType,
} from 'src/model/reservation';
import moment from 'moment';
import * as Styles from './styles';
import ReservationDetailCard from '../ReservationDetailCard';
import BasicTextArea from '../../common/Textarea/BasicTextArea';
import {
  useGetReservationDetail,
  usePatchReservationMemo,
  usePatchReservationMessage,
  usePutReservationStatusChange,
} from '../../../query/Reservation/useReservationQuery';
import { ModalHandler } from '../../../utils/ModalHandler';
import { ModalType } from '../../../constants/modal';
import ReservationUpdateModal from '../ReservationUpdateModal';
import ReservationRejectModal from '../ReservationRejectModal';
import useKeydownEsc from '../../../hooks/dom/useKeydownEsc';

interface ReservationDetailModalProps {
  reservation: Reservation;
}

const ReservationDetailModal = ({ reservation }: ReservationDetailModalProps) => {
  const wrapperRef = useRef(null);
  const [memo, setMemo] = useState('');
  const [message, setMessage] = useState('');
  const { data: reservationDetail } = useGetReservationDetail(reservation.id, reservation.userType);
  const { mutate: statusChangeMutate } = usePutReservationStatusChange();
  const { mutate: updateMemoMutate } = usePatchReservationMemo();
  const { mutate: updateMessageMutate } = usePatchReservationMessage();

  const modalHide = () => {
    ModalHandler.hide(ModalType.Detail);
  };

  const onClickButton = useCallback(
    (type: string) => {
      if (type === 'update') {
        ModalHandler.show(ModalType.Detail, {
          title: '예약 변경',
          width: 500,
          contents: <ReservationUpdateModal reservation={reservation} />,
        });
      } else if (type === 'reject_popup') {
        ModalHandler.show(ModalType.Detail, {
          title: '예약 취소',
          width: 500,
          contents: <ReservationRejectModal reservation={reservation} />,
        });
      } else if (type === 'confirm') {
        ModalHandler.show(ModalType.Alert, {
          title: '고객님이 신청한 예약을 확정할까요?',
          confirmText: '예',
          cancelText: '아니오',
          onConfirm: async () => {
            const params = {
              id: reservation.id,
              serviceStatus: ReservationStatusType.Confirmed,
              userType: reservation.userType,
            } as ReservationStatusChangeParams;

            if (message) {
              params.message = message;
            }

            await statusChangeMutate(params);

            modalHide();
          },
        });
      } else if (type === 'noshow') {
        ModalHandler.show(ModalType.Alert, {
          title: '고객님이 오지 않았나요?',
          confirmText: '예',
          cancelText: '아니오',
          onConfirm: async () => {
            await statusChangeMutate({
              id: reservation.id,
              serviceStatus: ReservationStatusType.NoShow,
              userType: reservation.userType,
            });

            modalHide();
          },
        });
      } else if (type === 'visited') {
        let title = '고객님이 방문완료 했나요?';
        if (moment().isBefore(moment(reservation.reservationTime))) {
          title = '진료일정 이전입니다.\n보호자가 진료를 받으신 것이 맞나요?';
        }

        ModalHandler.show(ModalType.Alert, {
          title,
          confirmText: '예',
          cancelText: '아니오',
          onConfirm: async () => {
            await statusChangeMutate({
              id: reservation.id,
              serviceStatus: ReservationStatusType.Visited,
              userType: reservation.userType,
            });

            modalHide();
          },
        });
      } else if (type === 'memo_save') {
        ModalHandler.show(ModalType.Alert, {
          title: reservationDetail?.memo ? '이미 작성된 내용이 있습니다.\n수정하시겠어요?' : '저장하시겠습니까?',
          confirmText: '예',
          cancelText: '아니오',
          onConfirm: async () => {
            await updateMemoMutate({
              ...reservationDetail,
              memo,
            } as ReservationDetail);

            modalHide();
          },
        });
      } else if (type === 'info_save') {
        ModalHandler.show(ModalType.Alert, {
          title: '보호자에게 사전 안내사항을 발송하시겠습니까?',
          confirmText: '예',
          cancelText: '아니오',
          onConfirm: async () => {
            await updateMessageMutate({
              ...reservationDetail,
              message,
            } as ReservationDetail);

            modalHide();
          },
        });
      }
      // else if (type === 'canceled') {
      //   ModalHandler.show(ModalType.Alert, {
      //     title: '예약 취소하시겠습니까?',
      //     confirmText: '예',
      //     cancelText: '아니오',
      //     onConfirm: async () => {
      //       await statusChangeMutate({
      //         id: reservation.id,
      //         serviceStatus: ReservationStatusType.Cancelled,
      //         userType: reservation.userType,
      //       });

      //       modalHide();
      //     },
      //   });
      // }
    },
    [memo, message, reservation, reservationDetail, statusChangeMutate, updateMemoMutate, updateMessageMutate],
  );

  useKeydownEsc(wrapperRef, () => {
    modalHide();
  });

  useEffect(() => {
    if (reservationDetail?.memo) {
      setMemo(reservationDetail.memo);
    }
    if (reservationDetail?.message) {
      setMessage(reservationDetail.message);
    }
  }, [reservationDetail]);

  return (
    <Styles.Wrapper ref={wrapperRef}>
      {reservationDetail && (
        <>
          <ReservationDetailCard reservationDetail={reservationDetail} />
          {/* 
          {reservation.serviceStatus === ReservationStatusType.Holding && (
            <Styles.CommentWrapper>
              {`예약취소 사유: ${reservationDetail.reason || ''}`}
              <div>{reservationDetail.message}</div>
            </Styles.CommentWrapper>
          )} 
          */}

          {reservationDetail.serviceStatus === ReservationStatusType.Cancelled && (
            <Styles.CommentWrapper>{`예약취소 사유: ${reservationDetail.reason || ''}`}</Styles.CommentWrapper>
          )}

          {reservationDetail.serviceStatus === ReservationStatusType.NoShow && (
            <>
              <Styles.Dash />
              <Styles.InputWrapper>
                <Styles.InputTitle>메모</Styles.InputTitle>
                <Styles.InputDesc>
                  메모 다른 관리자에게 안내되어야 하는 내용을 입력해 주세요. 보호자에게는 노출되지 않습니다.
                </Styles.InputDesc>
                <BasicTextArea
                  placeholder="해당 예약건에 대한 참고 사항을 입력해 주세요."
                  value={memo}
                  onChange={(str) => setMemo(str)}
                  width={428}
                  height={98}
                  size={60}
                />
              </Styles.InputWrapper>
            </>
          )}

          {[ReservationStatusType.Applied, ReservationStatusType.Visited, ReservationStatusType.Confirmed].includes(
            reservationDetail.serviceStatus,
          ) && (
            <>
              <Styles.Dash />
              <Styles.InputWrapper>
                <Styles.InputTitle>보호자 안내사항</Styles.InputTitle>
                <Styles.InputDesc>
                  진료를 위해 보호자에게 사전 안내가 필요한 사항이 있다면 입력해 주세요.
                  <br />
                  {ReservationStatusType.Applied === reservationDetail.serviceStatus
                    ? `예약을 확정하면 보호자에게 알림이 전송됩니다.`
                    : `안내 사항을 발송하시면 보호자에게 알림이 전송됩니다.`}
                </Styles.InputDesc>
                <BasicTextArea
                  placeholder="복약처방, 수술 후 유의사항 등과 같이 보호자에게 안내가 필요한 사항을 입력해 주세요."
                  value={message}
                  onChange={(str) => setMessage(str)}
                  width={428}
                  height={98}
                  size={60}
                />
              </Styles.InputWrapper>
            </>
          )}

          {reservationDetail.serviceStatus === ReservationStatusType.Applied && (
            <Styles.ButtonWrapper>
              <BasicButton width={100} onClick={() => onClickButton('update')} canceled>
                예약 변경
              </BasicButton>
              <BasicButton width={100} onClick={() => onClickButton('reject_popup')} canceled>
                예약 취소
              </BasicButton>
              <BasicButton width={100} onClick={() => onClickButton('confirm')}>
                예약 확정
              </BasicButton>
            </Styles.ButtonWrapper>
          )}

          {reservationDetail.serviceStatus === ReservationStatusType.Confirmed && (
            <Styles.ButtonWrapper>
              <BasicButton width={79} onClick={() => onClickButton('info_save')}>
                안내 발송
              </BasicButton>
              <BasicButton width={80} onClick={() => onClickButton('update')} canceled>
                예약 변경
              </BasicButton>
              <BasicButton width={80} onClick={() => onClickButton('reject_popup')} canceled>
                예약 취소
              </BasicButton>
              <BasicButton width={80} onClick={() => onClickButton('noshow')} canceled>
                노쇼
              </BasicButton>
              <BasicButton width={80} onClick={() => onClickButton('visited')}>
                방문 완료
              </BasicButton>
            </Styles.ButtonWrapper>
          )}

          {reservationDetail.serviceStatus === ReservationStatusType.NoShow && (
            <Styles.SaveButtonWrapper>
              <BasicButton width={80} height={36} onClick={() => onClickButton('memo_save')}>
                저장
              </BasicButton>
            </Styles.SaveButtonWrapper>
          )}

          {reservationDetail.serviceStatus === ReservationStatusType.Visited && (
            <Styles.SaveButtonWrapper>
              <BasicButton width={80} height={36} onClick={() => onClickButton('info_save')}>
                안내 발송
              </BasicButton>
            </Styles.SaveButtonWrapper>
          )}
        </>
      )}
    </Styles.Wrapper>
  );
};

export default ReservationDetailModal;
