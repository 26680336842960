import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { GREY_5 } from 'styles/colors';

interface LogInLayoutProps {
  children: ReactNode;
}

const LogInLayout = ({ children }: LogInLayoutProps) => <Wrapper>{children}</Wrapper>;

export default LogInLayout;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${GREY_5};
  border-radius: 15px;
  box-sizing: border-box;
`;
