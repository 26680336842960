import { EventHandler } from './base';
import { BrazeHandler } from './braze';
import { EventType } from '../../model/tracker';

export class Tracker {
  private eventHandlers: Array<EventHandler>;

  constructor() {
    this.eventHandlers = [new BrazeHandler()];
  }

  initialize = () => {
    this.eventHandlers.forEach((handler) => handler.initialize());
  };

  setUserID = (userId: string) => {
    this.eventHandlers.forEach((handler) => handler.setUserID(userId));
  };

  handleEvent = (event: EventType, properties: Record<string, any>) => {
    this.eventHandlers.forEach((handler) => handler.handleEvent(event, properties));
  };
}
