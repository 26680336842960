import React from 'react';
import styled from '@emotion/styled';
import { GREY_1, GREY_3, WHITE } from 'styles/colors';
import { BasicButton } from '../common/buttons/BasicButton';

interface OperationBottomProps {
  onCancelHandler?: () => void;
  onSaveHandler: () => void;
  disabled?: boolean;
}

const OperationBottom = ({ onCancelHandler, onSaveHandler, disabled }: OperationBottomProps) => (
  <Wrapper>
    {onCancelHandler && (
      <CancelButton type="button" onClick={onCancelHandler}>
        돌아가기
      </CancelButton>
    )}
    <BasicButton type="button" onClick={onSaveHandler} disabled={disabled}>
      저장
    </BasicButton>
  </Wrapper>
);

export default OperationBottom;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 36px;
  justify-content: flex-end;
`;

const CancelButton = styled(BasicButton)`
  border: 1px solid ${GREY_3};
  color: ${GREY_1};
  background-color: ${WHITE};
  margin-right: 8px;
  font-weight: normal;
  border-radius: 5px;
`;
