import axios from 'axios';
import { QueryClient } from 'react-query';
import { QueryKey } from 'src/constants/queryKey';

export type MutateFn<Data, Varaiable> = (params: Varaiable) => Promise<Data>;

interface MutateParams<Variable, Context, QueryParam = any> {
  queryClient: QueryClient;
  queryKey: QueryKey;
  queryParam?: QueryParam;
  mutateCallback?: (variable: Variable, prevData: Context) => Context;
}

interface MutateErrorParams<Error = any> {
  queryClient: QueryClient;
  queryKey: QueryKey;
  errorCallback?: (e?: Error) => void;
}

interface MutateSettledParams<Variable, Context> {
  queryClient: QueryClient;
  queryKey: QueryKey;
  successCallback?: (varaible?: Variable, newData?: Context) => Promise<Context> | void;
}

const handleMutateData =
  <Variable, Context>(variable: Variable, mutateCallback?: (variable: Variable, prevData: Context) => Context) =>
  (prevData: Context | undefined) =>
    prevData && mutateCallback ? mutateCallback(variable, prevData) : prevData;

export const handleMutate =
  <Variable, Context, QueryParam = any>(params: MutateParams<Variable, Context, QueryParam>) =>
  (variable: Variable) => {
    const { queryClient, queryKey, queryParam, mutateCallback } = params;

    if (queryParam) {
      // Get previousQuery
      const previous = queryClient.getQueryData<Context>([queryKey, queryParam]);
      queryClient.setQueryData<Context | undefined>(
        [queryKey, queryParam],
        handleMutateData<Variable, Context>(variable, mutateCallback),
      );
      return previous as Context;
    }

    // Get previousQuery
    const previous = queryClient.getQueryData<Context>(queryKey);
    queryClient.setQueryData<Context | undefined>(
      queryKey,
      handleMutateData<Variable, Context>(variable, mutateCallback),
    );
    return previous as Context;

    // return 타입이 Context | undefined 이지만 useMutation 타입에서 onMutate return type 에 undefined가 추가되지 않아서 에러
    // https://github.com/tannerlinsley/react-query/pull/2089
  };

export const handleMutateError =
  <Error, Variable, Context>(params: MutateErrorParams<Error>) =>
  (error: Error, variables: Variable, context: Context | undefined) => {
    const { queryClient, queryKey, errorCallback } = params;
    // Query에서 에러 발생 시 axios cancel 처리
    if (axios.isCancel(error)) {
      return;
    }

    errorCallback?.(error);
    queryClient.setQueryData(queryKey, context);
  };

export const handleSuccess =
  <Data, Variable, Context>(params: MutateSettledParams<Variable, Context>) =>
  (data: Data, variable: Variable, context: Context | undefined) => {
    const { queryClient, queryKey, successCallback } = params;

    successCallback?.(variable, context);
    queryClient.invalidateQueries(queryKey);
  };
