import React from 'react';
import { GREY_1, GREY_3 } from 'styles/colors';

export enum ForwardType {
  LEFT,
  RIGHT,
}

interface ArrowIconProps {
  disabled?: boolean;
  forward?: ForwardType;
}

const ArrowIcon = ({ disabled = false, forward = ForwardType.LEFT }: ArrowIconProps) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    {forward === ForwardType.LEFT && (
      <path
        d="M12.0605 17.0703L5.01185 9.97694L12.1052 2.92825"
        stroke={disabled ? `${GREY_3}` : `${GREY_1}`}
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    )}
    {forward === ForwardType.RIGHT && (
      <path
        d="M7.93945 2.92969L14.9881 10.0231L7.89478 17.0718"
        stroke={disabled ? `${GREY_3}` : `${GREY_1}`}
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    )}
  </svg>
);
export default ArrowIcon;
