import styled from '@emotion/styled';
import { AHEAD_MINT, GREY_1, GREY_3, WHITE } from 'styles/colors';
import { css } from '@emotion/react';

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
`;

interface ButtonProps {
  isConfirmed?: boolean;
}

export const Button = styled.button(
  ({ isConfirmed = false }: ButtonProps) => css`
    width: 80px;
    height: 36px;
    border: 1px solid ${GREY_3};

    border-radius: 5px;
    margin-right: 8px;
    font-style: normal;
    font-weight: ${isConfirmed ? 'bold' : 'normal'};
    font-size: 14px;
    text-align: center;
    letter-spacing: -0.02em;
    cursor: pointer;

    background-color: ${WHITE};
    color: ${isConfirmed ? AHEAD_MINT : GREY_1};
    border: 1px solid ${isConfirmed ? AHEAD_MINT : GREY_1};
  `,
);
