import React, { useCallback, useMemo, useRef, useState } from 'react';
import TextInput from '../../common/TextInput';
import { useCheckMember } from '../../../query/Member/useMemberQuery';
import * as Styles from './style';
import { UserInfoDto, UserType } from '../../../model/user';
import { ModalHandler } from '../../../utils/ModalHandler';
import { ModalType } from '../../../constants/modal';

export const ADD_NEW_MEMBER: UserInfoDto = {
  birthday: '',
  id: -1,
  name: '',
  pets: [],
  tel: '',
  userType: UserType.NON_USER,
};
export interface UserSearchInputProps {
  onChange: (user: UserInfoDto) => void;
}
const UserSearchInput = ({ onChange }: UserSearchInputProps) => {
  const [phone, setPhone] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [active, setActive] = useState(false);
  // const [showResult, setShowResult] = useState(false);

  // const toggleActive = useCallback(() => {
  //   setActive(!active);
  // }, [active]);

  // const changeValue = useCallback((item) => {
  //   // setSelect(item);
  //   // setActive(!active);
  //   // onChange(item.value);
  //   // // onAddRequest(false);
  // }, []);

  const searchUser = useCallback(() => {
    if (phone.trim() === '') return;
    // console.info('보호자 휴대폰 검색 클릭', phone);
    let message = '';
    // validation
    // 숫자 카운팅 - 11자
    const numbers = phone.replace(/\D/g, '');

    // phone.replace(/[^-]/g,'').length === 0 || 2
    if (phone.replace(/[\d|^-]/g, '').length !== 0) {
      message = `올바른 전화번호가 아닙니다.\n(e.q. 010-####-#### 및 숫자 11자리)`;
    } else if (numbers.length !== 11) {
      message = `올바른 전화번호가 아닙니다.`;
    } else if (!numbers.startsWith('010')) {
      message = `010으로 시작하는 전화번호가 아닙니다.`;
    }

    if (message !== '') {
      ModalHandler.show(ModalType.Toast, {
        ToastMessage: message,
      });
      return;
    }

    setUserPhone(phone);
    setActive(true);
  }, [phone]);

  const { data, isLoading } = useCheckMember(userPhone);
  const userList = useMemo(() => {
    if (isLoading) {
      return [] as UserInfoDto[];
    }
    if (data?.length === 0) {
      return [{ ...ADD_NEW_MEMBER, tel: phone }] as UserInfoDto[];
    }
    return data as UserInfoDto[];
  }, [data, isLoading, phone]);

  // useEffect(() => {
  //   if (userList.length > 0) {
  //     setActive(true);
  //   }
  // }, [userList]);

  const onSelectUser = useCallback(
    (user: UserInfoDto) => {
      setActive(false);
      const tel = user.tel
        .replace(/[^0-9]/g, '')
        .replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, '$1-$2-$3')
        .replace('--', '-');
      setPhone(tel);
      setUserPhone('');
      onChange({ ...user, tel });
    },
    [onChange],
  );

  const wrapperRef = useRef(null);

  //
  return (
    <Styles.Wrapper ref={wrapperRef}>
      <TextInput<string>
        name="보호자 휴대폰"
        value={phone}
        // value={userPhone}
        onChange={(value) => {
          setActive(false);
          setPhone(value);
        }}
        placeholder="010-0000-0000"
        limit={13}
        icon="search"
        onClickIcon={() => searchUser()}
        onEnterKey={() => searchUser()}
        required
      />
      {active && !isLoading && userList && (
        <Styles.OptionWrapper className={active ? 'active' : ''}>
          <ul>
            {userList.map((user) => (
              <Styles.Item
                key={user.id}
                onClick={() => {
                  onSelectUser(user);
                }}>
                {/* // [핏펫회원] 홍길동 (010-123) */}
                {user.id === -1
                  ? '+ 신규회원 등록'
                  : `[${user.userType === UserType.USER ? '핏펫회원' : '병원'}] ${user.name} (${user.tel})`}
              </Styles.Item>
            ))}
            {/* <Styles.Item onClick={() => addUser()}>+ 신규회원 등록</Styles.Item> */}
          </ul>
        </Styles.OptionWrapper>
      )}
    </Styles.Wrapper>
  );
};

export default UserSearchInput;
