import { PetType } from './pet';

export const CHART_DATES = [
  { key: 'D', label: '일별' },
  { key: 'W', label: '주별' },
  { key: 'M', label: '월별' },
];

interface IReservationVisited {
  revisitingRate: number;
  visitingCnt: number;
  revisitingCnt: number;
}

interface IPetChart {
  breedName: string;
  petCount: number;
  percentage: number;
}

export const PetGradeToString = {
  puppy: '퍼피 (~ 1세)',
  adult: '어덜트 (1~7세)',
  senior: '시니어 (7세 ~)',
  kitten: '키튼 (~ 1세)',
} as const;

export type TPetGrade = keyof typeof PetGradeToString;

export const ReservationStatusColor = {
  방문완료: '#31BEC8',
  예약취소: '#868E96',
  노쇼: '#FF7369',
};

type TReservationStatusColor = keyof typeof ReservationStatusColor;

interface IGrade {
  grade: TPetGrade;
  petCount: number;
  percentage: number;
  petType: PetType;
}

export interface IGender {
  gender: 'female' | 'male';
  neutralization: 'true' | 'false';
  percentage: number;
  petCount: number;
  petType: PetType;
}

export interface IStatisticDto {
  userCnt: number;
  reservationVisited: IReservationVisited;
  dog: IPetChart[];
  cat: IPetChart[];
  grade: IGrade[];
  gender: IGender[];
}

export interface IReservationStatus {
  x: string;
  y: number;
}

export interface IReservationStatusDto {
  id: TReservationStatusColor;
  data: IReservationStatus[];
  color?: string;
}
