import React from 'react';
import NavigationMenu from 'src/components/layout/SideMenu/NavigationMenu';
import HospitalLogo from 'src/components/icons/HospitalLogo';
import { NavigationItems } from 'src/constants/navigation';
import { SideTabRoute } from 'src/model/route';
import * as Styles from './styles';

interface SideMenuProps {
  menu: Record<NavigationItems, SideTabRoute>;
}

const SideMenu = ({ menu }: SideMenuProps) => (
  <Styles.Wrapper>
    <Styles.Container>
      <Styles.Logo>
        <HospitalLogo />
      </Styles.Logo>
      <Styles.Menu>
        <NavigationMenu menu={menu} />
      </Styles.Menu>
    </Styles.Container>
  </Styles.Wrapper>
);

export default SideMenu;
