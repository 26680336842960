import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { AHEAD_MINT, GREY_1, WHITE } from '../../../../styles/colors';
import { Button } from '../../common/buttons/Button';
import { DefaultPlusIcon } from '../../icons/PlusIcon';
import useMember from '../../../hooks/member/useMember';

const EmptyUser = () => {
  const { openAddMemberModal } = useMember();

  return (
    <Wrapper>
      <Message>구성원을 추가하여 동물병원을 효과적으로 관리하세요.</Message>

      <RadiusButton radius={20} onClick={() => openAddMemberModal()}>
        <DefaultPlusIcon />
        <span>구성원 추가</span>
      </RadiusButton>
    </Wrapper>
  );
};

export default EmptyUser;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Message = styled.span`
  margin-bottom: 28px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: ${GREY_1};
`;

const RadiusButton = styled(Button)(
  ({ radius = 20 }: { radius: number }) => css`
    border-radius: ${radius}px;
    background-color: ${AHEAD_MINT};
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 10px 16px;
    width: 128px;
    height: 36px;
    // 글씨
    color: ${WHITE};
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.02em;
  `,
);
