import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { BarDatum, ResponsiveBar } from '@nivo/bar';
import { RootState } from 'src/reducers';
import { PetGradeToString } from 'src/model/statistic';
import { AHEAD_MINT, GREY_6 } from 'styles/colors';
import BarChartLabel from './BarChartLabel';
import * as StatisticStyles from '../styles';
import { PetType } from '../../../model/pet';

const chartIndex = ['senior', 'adult', 'puppy', 'kitten'];

interface AgeStatisticProps {
  petType: PetType;
}

export default function AgeStatistic({ petType }: AgeStatisticProps) {
  const [ageData, setAgeData] = useState<BarDatum[]>();
  const { statisticData } = useSelector((state: RootState) => state.statistic);
  const chartData = useMemo(
    () => statisticData?.grade && statisticData.grade.filter((pet) => pet.petType === petType),
    [petType, statisticData],
  );

  useEffect(() => {
    if (!chartData) return;

    const maxPetCount = Math.max(...chartData.map((item) => item.petCount));
    const isOnlyActive = chartData.filter((item) => item.petCount === maxPetCount).length === 1;

    const data: BarDatum[] = chartIndex.reduce((acc, curr) => {
      const targetData = chartData.find((item) => item.grade === curr);
      if (!targetData) return acc;

      acc.push({
        id: PetGradeToString[targetData.grade],
        value: targetData.petCount,
        percentage: targetData.percentage,
        color: targetData.petCount === maxPetCount && isOnlyActive ? AHEAD_MINT : GREY_6,
      });
      return acc;
    }, [] as Array<BarDatum>);

    setAgeData(data);
  }, [chartData]);

  return (
    <StatisticStyles.Article>
      <h4>나이</h4>

      {ageData && (
        <div style={{ height: 140 }}>
          <ResponsiveBar
            data={ageData}
            tooltip={() => null}
            keys={['value']}
            indexBy="id"
            margin={{
              left: 0,
              right: 112,
            }}
            colors={({ data }) => data.color as string}
            enableGridY={false}
            enableGridX={false}
            label=""
            layout="horizontal"
            animate={false}
            layers={['axes', 'bars', 'markers', 'legends', 'annotations', ({ bars }) => BarChartLabel({ bars }, 'age')]}
            axisBottom={null}
            padding={0.5}
            axisLeft={null}
          />
        </div>
      )}
    </StatisticStyles.Article>
  );
}
