import styled from '@emotion/styled';
import { BasicButton } from 'src/components/common/buttons/BasicButton';
import { AHEAD_MINT, GREY_1, GREY_3, GREY_4, WHITE } from 'styles/colors';

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 32px 36px 36px 36px;
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 14px;
`;

export const ReasonSection = styled.ul`
  box-sizing: border-box;
  margin-top: 22px;
`;

export const ReasonItem = styled.li`
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 20px;
`;
export const Label = styled.label`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
`;

export const RadioButton = styled.input`
  width: 18px;
  height: 18px;
  border: 1px solid ${GREY_3};
  border-radius: 50%;
  margin-right: 12px;
  cursor: pointer;

  &:checked {
    border: 6px solid ${AHEAD_MINT};
  }
`;

export const MessageSection = styled.div`
  margin-top: 18px;
`;

export const ContentWrapper = styled.div`
  position: relative;
  border: 1px solid ${GREY_4};
  padding: 12px 12px 8px 12px;
`;

export const Content = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  border: none;
  word-break: keep-all;
  height: 100px;
  resize: none;
  font-size: 12px;
  line-height: 14px;

  &::placeholder {
    color: ${GREY_3};
  }
`;

export const ContentLength = styled.span`
  position: absolute;
  bottom: 12px;
  right: 30px;
  color: ${GREY_3};
  font-size: 11px;
  line-height: 13px;
  z-index: 4;
`;

export const ButtonSection = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;
  margin-top: 48px;

  button + button {
    margin-left: 4px;
  }
`;

export const Button = styled(BasicButton)`
  border: 1px solid ${GREY_3};
  color: ${GREY_1};
  background-color: ${WHITE};
  font-weight: normal;
`;
