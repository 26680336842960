import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { GREY_1, GREY_3, GREY_5, WHITE } from '../../../../styles/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  div.active {
    display: block;
  }
`;

export const Input = styled.input`
  background: ${WHITE};
  border: 1px solid ${GREY_3};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px 42px 16px 10px;
  width: 184px;
  height: 48px;
  z-index: 1;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: ${GREY_1};

  cursor: pointer;

  /* 셀렉트 박스 화살표 처리 */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6L11 1' stroke='%23434343'/%3E%3C/svg%3E%0A");
  background-size: 10px;
  background-position: calc(100% - 1.3em) center;
  background-repeat: no-repeat;
  ::-ms-expand {
    display: none;
  }

  &:disabled {
    color: ${GREY_3};
    background-color: ${GREY_5};
    cursor: default;
  }
  &:disabled::placeholder {
    color: ${GREY_3};
  }
`;

export const OptionWrapper = styled.div(
  ({ optionHeight = 0 }: { optionHeight?: number }) => css`
    position: absolute;
    top: 45px;
    left: 0;
    width: 184px;

    ${optionHeight !== 0 &&
    css`
      max-height: ${optionHeight}px;
      overflow: auto;
    `};

    background-color: ${WHITE};
    border: 1px solid ${GREY_3};
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    //overflow: hidden;
    z-index: 2;
    display: none;
    ul {
      flex-direction: column;
      li:last-of-type {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  `,
);
export const Item = styled.li`
  padding: 12px 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: ${GREY_1};
  cursor: pointer;

  &:hover {
    background-color: ${GREY_5};
  }
`;
