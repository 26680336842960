import React, { Suspense, lazy, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { memberAction } from '../../features/member/memberSlice';
import { ModalHandler } from '../../utils/ModalHandler';
import { ModalType } from '../../constants/modal';
import { useDeleteMember, useSaveMember, useUpdateMember } from '../../query/Member/useMemberQuery';
import { MemberType } from '../../model/member';
import { FieldKeyValue } from '../../model/common';
import { dayMap } from '../../constants/workHours';

const AddUserModal = lazy(() => import('src/components/member/AddUserModal'));

const UseMember = () => {
  const dispatch = useDispatch();
  const remove = useDeleteMember();
  const update = useUpdateMember();
  const save = useSaveMember();

  const openAddMemberModal = useCallback(() => {
    dispatch(memberAction.initEditMember());
    ModalHandler.show(ModalType.Register, {
      title: '구성원 등록',
      registerText: '등록',
      contents: (
        <Suspense fallback={<div />}>
          <AddUserModal />
        </Suspense>
      ),
    });
  }, []);

  const checkMemberValidation = (member: MemberType) => {
    const { name, major, workDays } = member;
    const licenseNo = `${member.licenseNo}`;
    const license = licenseNo.match(/\d+/g);

    // 설정된 근무시간 시작시간 종료시간 체크
    const inValidWorkingDay = workDays.find((wDay) => {
      const start = Number(wDay.workTimeStart.replace(/\D/g, ''));
      const end = Number(wDay.workTimeEnd.replace(/\D/g, ''));
      return start >= end;
    });

    // 설정된 점심시간 시작시간 종료시간 체크
    const inValidLunchTimeDay = workDays
      .filter((wDay) => wDay.isLunchTime)
      .find((wDay) => {
        const start = Number(wDay.lunchTimeStart.replace(/\D/g, ''));
        const end = Number(wDay.lunchTimeEnd.replace(/\D/g, ''));
        return start >= end;
      });

    let message = '';
    if (isEmpty(name)) {
      message = `구성원의 이름을 입력해주세요.`;
    } else if (isEmpty(major)) {
      message = `구성원 전문 분야를\n입력해주세요.`;
    } else if (isEmpty(licenseNo)) {
      message = `면허번호(고유번호)를\n입력해주세요.`;
    } else if (license === null) {
      message = `올바른 면허번호(고유번호)를\n입력해주세요.`;
    } else if (license.join('').length !== licenseNo.length) {
      message = `올바른 면허번호(고유번호)를\n입력해주세요.`;
    } else if (license.join('').length > 7) {
      message = `숫자 최대 7자리까지 입력해주세요.`;
    } else if (inValidWorkingDay) {
      message = `${
        dayMap[inValidWorkingDay.workDay]
      } 근무일시 시작시간과 종료시간을\n확인해주세요.\n\n(시작시간이 종료시간보다 클 수 없습니다.)`;
    } else if (inValidLunchTimeDay) {
      message = `${
        dayMap[inValidLunchTimeDay.workDay]
      } 점심시간 시작시간과 종료시간을\n확인해주세요.\n\n(시작시간이 종료시간보다 클 수 없습니다.)`;
    } else if (member.isTemporaryHoliday) {
      if (isEmpty(member.temporaryHolidayStart) || isEmpty(member.temporaryHolidayEnd)) {
        message = `임시휴무일을 입력해주세요.`;
      } else if (moment(member.temporaryHolidayStart).isAfter(moment(member.temporaryHolidayEnd))) {
        message = `임시휴무일의 시작일과 종료일을\n확인해주세요.`;
      }
    }
    if (!isEmpty(message)) {
      ModalHandler.show(ModalType.Toast, {
        ToastMessage: message,
      });
      return false;
    }
    return true;
  };

  const saveMember = (member: MemberType) => {
    // validation
    if (!checkMemberValidation(member)) {
      return;
    }

    save.mutate(member);
  };

  const deleteMember = async (id: number) => {
    await remove.mutateAsync(id);
  };

  const updateMember = async (memberId: number, member: MemberType) => {
    //  console.info('updateMember', member);
    // validation
    if (!checkMemberValidation(member)) {
      return false;
    }
    await update.mutateAsync({ id: memberId, ...member });
    return true;
  };

  const updateEditField = (value: FieldKeyValue) => {
    dispatch(memberAction.updateEditField(value));
  };

  return { saveMember, deleteMember, updateMember, updateEditField, openAddMemberModal };
};

export default UseMember;
