import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ContentWrapper } from '../../../styles/layout';
import { AHEAD_MINT, FITPET_BLUE, GREY_1, GREY_3, MALL_BLUE, PASTEL_MINT, WHITE } from '../../../styles/colors';
import { Button } from '../../components/common/buttons/Button';

export const Wrapper = styled.div`
  ${ContentWrapper}
`;

export const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 44px;
  justify-content: space-between;
`;

export const CheckBoxWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  color: ${GREY_1};
  flex-shrink: 0;
  cursor: pointer;
  margin-left: 10px;
`;

export const UserWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
`;

export const AllButton = styled(Button)(
  () => css`
    display: flex;
    flex-direction: row;
    width: 58px;
    height: 38px;

    align-items: flex-start;
    padding: 12px 16px;
    background: ${WHITE};
    border: 1px solid ${GREY_3};
    box-sizing: border-box;
    border-radius: 5px;

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    letter-spacing: -0.02em;
    white-space: nowrap;
    color: ${GREY_1};

    &.active {
      /* Pastel Mint */
      background: ${PASTEL_MINT};
      /* Main/Fiepet Mint */
      border: 1px solid ${AHEAD_MINT};
      font-weight: bold;
      /* 구버전/Fitpet Mint */
      color: #2eb4bd;
    }
  `,
);

export interface UserButtonProps {
  colorChip: string;
  stringLength: number;
}
export const UserButton = styled(AllButton)(
  ({ colorChip, stringLength }: UserButtonProps) => css`
    width: ${stringLength * 10 + 100}px;
    position: relative;
    justify-content: flex-start;
    :before {
      position: absolute;
      top: 11px;
      right: 12px;
      width: 14px;
      height: 14px;
      border-radius: 7px;
      font-size: 1px;
      background-color: ${colorChip};
      content: ' ';
    }
  `,
);

// 달력 기본 색상 변경
export const CalendarWrapper = styled.div`
  height: 100%;
  .fc .fc-button-primary {
    background-color: ${MALL_BLUE};
    border: 0;
  }
  .fc .fc-button-primary:not(:disabled).fc-button-active {
    background-color: #10388e;
  }
  .fc .fc-button-primary:focus,
  .fc .fc-button-primary:not(:disabled):active:focus,
  .fc .fc-button-primary:not(:disabled).fc-button-active:focus {
    box-shadow: none;
  }
  // 클릭중일때 색상
  .fc .fc-button-primary:not(:disabled):active,
  .fc .fc-button-primary:not(:disabled).fc-button-active {
    background-color: #10388e;
  }
  // 가운데 타이틀
  .fc-header-toolbar .fc-toolbar-title {
    margin-bottom: 60px;
  }

  // 라벨
  position: relative;
  :before {
    position: absolute;
    top: 35px;
    right: 100px;

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    text-align: right;
    letter-spacing: -0.02em;

    /* Greyscale/Grey 1 */

    color: ${GREY_1};
    content: '빈 슬롯을 클릭하여 예약을 생성해 보세요.';
  }

  // 현재 시간 라인
  .nowIndicator {
    border-color: ${FITPET_BLUE};
    //border-top-color: transparent;
    //border-bottom-color: transparent;
  }
  // 왼쪽 축에 있는 값
  .fc-timegrid-now-indicator-arrow.nowIndicator {
    border-color: ${FITPET_BLUE};
    border-top-color: transparent;
    border-bottom-color: transparent;
  }

  .upIndex {
    z-index: 8;
  }

  .bg-none .fc-non-business {
    background: none;
  }
`;
