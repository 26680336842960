import React, { useCallback, useMemo, useState } from 'react';
import { DayType, dayMap, TimeType, StartTime, EndTime, End24Time } from 'src/constants/workHours';
import * as Styles from './styles';
import CheckBoxIcon from '../../icons/CheckBoxIcon';
import TimeBox from '../TimeBox';
import { ModalHandler } from '../../../utils/ModalHandler';
import { ModalType } from '../../../constants/modal';
import { addMinute2strTime } from '../../../utils/DateUtils';
import useOperation from '../../../hooks/operation/useOperation';
import { OperationWorkDayType } from '../../../model/operations';

interface WorkingDayProps {
  workingDay: OperationWorkDayType[];
  onChangeDay: (days: OperationWorkDayType[]) => void;
}

const WorkingDay = ({ workingDay, onChangeDay }: WorkingDayProps) => {
  const [selectDay, setSelectDay] = useState(DayType.Mon);

  const { isOperating24Hours, displayWorkTimeEnd } = useOperation();
  const hasWorkingDay = useMemo(() => workingDay.map((day) => day.workDay), [workingDay]);
  const isOperatingChecked = useMemo(() => hasWorkingDay.includes(selectDay), [hasWorkingDay, selectDay]);
  const currentDay = useMemo(
    () =>
      workingDay
        .filter((day: OperationWorkDayType) => day.workDay === selectDay)
        .reduce(
          (_: any, day: OperationWorkDayType) => ({
            workDay: day.workDay,
            workTimeStart: day.workTimeStart,
            workTimeEnd: day.workTimeEnd,
          }),
          {
            workDay: selectDay,
            workTimeStart: StartTime,
            workTimeEnd: EndTime,
          },
        ),
    [selectDay, workingDay],
  );
  const is24HoursChecked = useMemo(() => isOperating24Hours(currentDay), [currentDay]);

  // 운영 여부 변경 처리
  const onChangeOperatingChecked = useCallback(() => {
    if (isOperatingChecked) {
      onChangeDay(workingDay.filter((day: OperationWorkDayType) => day.workDay !== selectDay));
    } else {
      onChangeDay([...workingDay, currentDay] as OperationWorkDayType[]);
    }
  }, [currentDay, isOperatingChecked, onChangeDay, selectDay, workingDay]);

  const onChange24HoursChecked = useCallback(() => {
    onChangeDay(
      workingDay.map((x) => {
        return x.workDay === selectDay
          ? {
              workDay: selectDay,
              workTimeStart: StartTime,
              workTimeEnd: is24HoursChecked ? EndTime : End24Time,
            }
          : x;
      }),
    );
  }, [selectDay, workingDay]);

  // 시간 변경 처리
  const onChangeTime = useCallback(
    (type: TimeType, time: string) => {
      const timeStr = time.replace(':', '');
      const workTimeStart = currentDay.workTimeStart.replace(':', '');
      const workTimeEnd = currentDay.workTimeEnd.replace(':', '');
      const newWorkingDay = {
        workDay: selectDay,
        workTimeStart: currentDay.workTimeStart,
        workTimeEnd: currentDay.workTimeEnd,
      };
      const filteredDays = workingDay.filter((day: OperationWorkDayType) => day.workDay !== selectDay);

      if (type === TimeType.Start) {
        if (Number(workTimeEnd) <= Number(timeStr)) {
          ModalHandler.show(ModalType.Toast, {
            ToastMessage: `시작 시간은 종료 시간보다\n이전이여야 합니다.`,
          });
          // 시작시간을 1시간 앞으로 맞춰줌
          newWorkingDay.workTimeStart = addMinute2strTime(currentDay.workTimeEnd, -60);
        } else {
          newWorkingDay.workTimeStart = time;
        }
      } else if (type === TimeType.End) {
        if (Number(workTimeStart) >= Number(timeStr)) {
          ModalHandler.show(ModalType.Toast, {
            ToastMessage: `종료 시간은 시작 시간보다\n이후여야 합니다.`,
          });
          // 종료시간을 1시간 뒤로 맞춰줌
          newWorkingDay.workTimeEnd = addMinute2strTime(currentDay.workTimeStart, 60);
        } else {
          newWorkingDay.workTimeEnd = time;
        }
      }

      onChangeDay([...filteredDays, newWorkingDay] as OperationWorkDayType[]);
    },
    [currentDay.workTimeStart, currentDay.workTimeEnd, selectDay, workingDay, onChangeDay],
  );

  return (
    <Styles.Wrapper>
      <Styles.Week>
        {Object.keys(dayMap).map((day) => (
          <Styles.Day key={day} value={day} checked={day === selectDay} onClick={() => setSelectDay(day as DayType)}>
            {dayMap[day as DayType]}
          </Styles.Day>
        ))}
      </Styles.Week>

      <Styles.CheckBoxWrapper>
        <Styles.Checked role="button" onClick={onChangeOperatingChecked}>
          <CheckBoxIcon checked={isOperatingChecked} />
          <span>운영가능 요일입니다.</span>
        </Styles.Checked>
        {isOperatingChecked && (
          <Styles.Checked role="button" onClick={onChange24HoursChecked}>
            <CheckBoxIcon checked={is24HoursChecked} />
            <span>24시간 운영</span>
          </Styles.Checked>
        )}
      </Styles.CheckBoxWrapper>

      <Styles.TimeSection>
        <TimeBox
          name="시작 시간"
          timeType={TimeType.Start}
          initTime={currentDay.workTimeStart}
          disabled={!isOperatingChecked || is24HoursChecked}
          onChangeTime={onChangeTime}
        />
        <TimeBox
          name="종료 시간"
          timeType={TimeType.End}
          initTime={displayWorkTimeEnd(currentDay.workTimeEnd)}
          disabled={!isOperatingChecked || is24HoursChecked}
          onChangeTime={onChangeTime}
        />
      </Styles.TimeSection>
    </Styles.Wrapper>
  );
};

export default WorkingDay;
