import { css } from '@emotion/react';
import { WHITE } from './colors';

export const FixedStyle = css`
  position: fixed;
  width: 100%;
`;

export const ContentWrapper = css`
  display: flex;
  flex-direction: column;
  background-color: ${WHITE};
  padding: 36px;
  border-radius: 15px;
  height: 100%;
`;
