import { DayType } from 'src/constants/workHours';

export interface WorkingDayType {
  /** 운영 요일 */
  workDay: DayType;
  /** 운영 시작 시간 */
  workTimeStart: string;
  /** 운영 종료 시간 */
  workTimeEnd: string;
  /** 점심 시간 여부 */
  isLunchTime?: boolean;
  /** 점심 시작 시간 */
  lunchTimeStart: string;
  /** 점심 종료 시간 */
  lunchTimeEnd: string;
  // reservationAvailable: boolean;
}

export const DayTypeToNumber: Partial<Record<DayType, number>> = {
  [DayType.Sun]: 0,
  [DayType.Mon]: 1,
  [DayType.Tue]: 2,
  [DayType.Wed]: 3,
  [DayType.Thu]: 4,
  [DayType.Fri]: 5,
  [DayType.Sat]: 6,
};

export const DayTypeNumberToDay: Partial<Record<number, DayType>> = {
  0: DayType.Sun,
  1: DayType.Mon,
  2: DayType.Tue,
  3: DayType.Wed,
  4: DayType.Thu,
  5: DayType.Fri,
  6: DayType.Sat,
};

export interface WorkingDayTypeForUI extends WorkingDayType {
  reservationAvailable: boolean;
}

// export type WorkTimeType = Omit<WorkingDayType, 'workDay'>;
