import React, { useCallback, useEffect, useState } from 'react';
import {
  Reservation,
  ReservationFieldKey,
  ReservationStatusType,
  ReservationStatusTypeToString,
} from 'src/model/reservation';
import moment from 'moment';
import * as Styles from './styles';
import Pagination from '../Pagination';
import ReservationButtons from '../ReservationButtons';
import GridSortArrowIcon, { SortType } from '../../icons/GridSortArrowIcon';
import { DisplayDateTimeFormat } from '../../../constants/workHours';
import { ModalHandler } from '../../../utils/ModalHandler';
import { ModalType } from '../../../constants/modal';
import ReservationDetailModal from '../ReservationDetailModal';

export interface ReservationGridHeader {
  name?: string;
  value: ReservationFieldKey;
  width?: number;
  fixed?: boolean; // 고정시키려면 width 필수
  sort?: boolean;
  sortType?: SortType;
}

interface ReservationGridProps {
  totalList: Reservation[];
  headers: ReservationGridHeader[];
  pageNumber: number;
  sort: string;
  onPageChange: (pageNum: number) => void;
  onSortChange: (value: ReservationFieldKey, sortType: SortType) => void;
}

const ReservationGrid = ({
  headers,
  totalList,
  sort,
  pageNumber,
  onPageChange,
  onSortChange,
}: ReservationGridProps) => {
  const pageSize = 10;
  const [list, setList] = useState<Reservation[]>([]);

  useEffect(() => {
    const temp = [...totalList];
    if (sort) {
      const sortKey = sort.split('|')[0];
      const sortType = sort.split('|')[1];
      temp.sort((before: any, after: any) => {
        const beforeTime = moment(before[sortKey]).valueOf();
        const afterTime = moment(after[sortKey]).valueOf();
        if (sortType === 'ASC') {
          return beforeTime - afterTime;
        }
        return afterTime - beforeTime;
      });
    }
    const startIndex = pageNumber * pageSize;
    const endIndex = startIndex + pageSize;

    setList(temp.slice(startIndex, endIndex));
  }, [totalList, sort, pageNumber]);

  const onSortChanged = useCallback(
    (header: ReservationGridHeader) => {
      if (header.sort) {
        const sortType = header.sortType || SortType.NORMAL;
        if (sortType === SortType.NORMAL) {
          onSortChange(header.value, SortType.ASC);
        } else if (sortType === SortType.ASC) {
          onSortChange(header.value, SortType.DESC);
        } else {
          onSortChange(header.value, SortType.NORMAL);
        }
      }
    },
    [onSortChange],
  );

  const onClickRow = useCallback((reservation: Reservation) => {
    ModalHandler.show(ModalType.Detail, {
      title: '예약 상세내역',
      width: 500,
      contents: <ReservationDetailModal reservation={reservation} />,
    });
  }, []);

  return (
    <>
      <Styles.GridWrapper>
        <Styles.GridTable>
          {headers && (
            <Styles.Thead>
              <Styles.HeaderTr>
                {headers.map((header, index) => (
                  <Styles.Header
                    key={`${header.name}-${header.value}`}
                    width={index === headers.length - 1 ? '100%' : `150px`}
                    isSort={header.sort}
                    onClick={() => onSortChanged(header)}>
                    {header.name}
                    {header.sort && <GridSortArrowIcon sortType={header.sortType} />}
                  </Styles.Header>
                ))}
              </Styles.HeaderTr>
            </Styles.Thead>
          )}
          {headers && list.length > 0 && (
            <Styles.TBody>
              {list.map((reservation) => (
                <Styles.BodyTr key={reservation.id}>
                  {headers.map((header) => (
                    <Styles.Td key={`${header.name}-${header.value}`} onClick={() => onClickRow(reservation)}>
                      {(() => {
                        switch (header.value) {
                          case ReservationFieldKey.PetName:
                            return reservation.petName;
                          case ReservationFieldKey.PetBreed:
                            return reservation.petBreedName;
                          case ReservationFieldKey.Tel:
                            return reservation[header.value] && reservation[header.value] !== ''
                              ? reservation[header.value].replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
                              : '번호없음';
                          case ReservationFieldKey.ServiceType:
                            return reservation.reserveType;
                          case ReservationFieldKey.ServiceStatus:
                            return ReservationStatusTypeToString[reservation[header.value] as ReservationStatusType];
                          case ReservationFieldKey.ReservationTime:
                            return moment(reservation[header.value]).locale('ko').format(DisplayDateTimeFormat);
                          case ReservationFieldKey.CreatedAt:
                            return moment(reservation[header.value]).locale('ko').format(DisplayDateTimeFormat);
                          case ReservationFieldKey.Buttons:
                            return <ReservationButtons reservation={reservation} />;
                          case ReservationFieldKey.UserName:
                            return reservation.userName;
                          case ReservationFieldKey.HospitalMemberName:
                            return reservation.hospitalMemberName;
                          default:
                            return '';
                        }
                      })()}
                    </Styles.Td>
                  ))}
                </Styles.BodyTr>
              ))}
            </Styles.TBody>
          )}
          {headers && !list.length && (
            <Styles.TBody>
              <Styles.EmptyBodyTr>
                <Styles.Td colSpan={headers.length}>검색 결과가 없습니다.</Styles.Td>
              </Styles.EmptyBodyTr>
            </Styles.TBody>
          )}
        </Styles.GridTable>
      </Styles.GridWrapper>
      <Styles.PaginationDiv>
        <Pagination
          pageNumber={pageNumber}
          totalCnt={totalList.length}
          pageSize={pageSize}
          onPageChange={onPageChange}
        />
      </Styles.PaginationDiv>
    </>
  );
};

export default ReservationGrid;
