import React, { useCallback } from 'react';
import CloseIcon from 'src/components/icons/CloseIcon';
import { ModalDuration, ModalType, SimpleModalParams } from 'src/constants/modal';
import { ModalHandler } from 'src/utils/ModalHandler';
import { ModalWrapper } from '../ModalWrapper';
import * as Styles from './styles';

interface DetailModalProps {
  isModalOpen: boolean;
  modalParams?: SimpleModalParams;
  clearModalParams: () => void;
}

const DetailModal = ({ isModalOpen, modalParams, clearModalParams }: DetailModalProps) => {
  const clearModal = useCallback(() => {
    ModalHandler.hide(ModalType.DetailSlide);
  }, []);

  const handleClose = useCallback(() => {
    clearModal();
  }, [clearModal]);
  return (
    <ModalWrapper
      isModalOpen={isModalOpen}
      duration={ModalDuration.Slide}
      clearModalParam={clearModalParams}
      onClickBackground={handleClose}
      backgroundColor="rgba(200,200,200, 0.3)">
      <Styles.ModalContainer isShow={isModalOpen}>
        <Styles.ModalHead>
          <Styles.Title>{modalParams?.title}</Styles.Title>
          <Styles.CloseButton onClick={handleClose}>
            <Styles.IconWrapper>
              <CloseIcon />
            </Styles.IconWrapper>
          </Styles.CloseButton>
        </Styles.ModalHead>
        <Styles.ModalContent>{modalParams?.contents}</Styles.ModalContent>
      </Styles.ModalContainer>
    </ModalWrapper>
  );
};

export default DetailModal;
