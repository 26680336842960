import styled from '@emotion/styled';
import { AHEAD_MINT, MALL_BLUE, NAVY, PASTEL_MINT, WHITE } from 'styles/colors';

export const UserCount = styled.div`
  margin-top: 4px;
  margin-bottom: 42px;

  strong {
    color: ${AHEAD_MINT};
    font-size: 48px;
    line-height: 58px;
    font-weight: 700;
  }

  span {
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    margin-left: 2px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 32px;
`;

export const DoctorTabs = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 70%;

  input {
    display: none;
  }
  input + span {
    border: 1px solid #dee2e6;
    border-radius: 5px;
    font-size: 14px;
    line-height: 17px;
    padding: 10px 16px;
    cursor: pointer;
    display: block;
  }
  input:checked + span {
    border-color: ${AHEAD_MINT};
    color: ${AHEAD_MINT};
    font-weight: 700;
    background-color: ${PASTEL_MINT};
  }
`;

export const DateTabs = styled.div`
  flex: none;
  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;
  overflow: hidden;

  input {
    display: none;
  }

  span {
    background-color: ${NAVY};
    color: ${WHITE};
    padding: 11.5px 16px;
    font-size: 12px;
    line-height: 14px;
    display: block;
    cursor: pointer;
  }

  input:checked + span {
    background-color: ${MALL_BLUE};
  }
`;
export const ChartWrapper = styled.div`
  > p {
    text-align: right;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 8px;
  }
`;
