import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { instance } from 'src/utils/api/http';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import DefaultLayout from './layout/DefaultLayout/DefaultLayout';
import LogInLayout from './layout/LogInLayout';
import { RoutesProps } from './model/route';
import Login from './pages/login';
import ReservationsPage from './pages/reservations';
import SchedulePage from './pages/schedule';
import MemberPage from './pages/member';
import { ModalController } from './components/Modals/ModalController';
import { RootState } from './reducers';
import Loading from './components/common/Loading';
import { HttpCode } from './constants/http';
import { UpdateToken } from './api/user';
import { LocalStorage } from './utils/LocalStorage';
import { LocalStorageKey } from './constants/localStorage';
import { commonAction } from './features/common/commonSlice';
import { useGetHospital } from './query/Member/useMemberQuery';
import { accountAction, HospitalDto } from './features/account/accountSlice';
import CustomerPage from './pages/customer';
import StatisticPage from './pages/statistic';
import OperationPage from './pages/operation';

const RoutesMenus: RoutesProps[] = [
  {
    key: 'Operation',
    name: '운영관리',
    path: '/operation',
    component: OperationPage,
  },
  {
    key: 'Reservation',
    name: '예약관리',
    path: '/reservations',
    component: ReservationsPage,
  },
  {
    key: 'Member',
    name: '구성원관리',
    path: '/member',
    component: MemberPage,
  },
  {
    key: 'CustomerSearch',
    name: '고객관리',
    path: '/customer',
    component: CustomerPage,
  },
  {
    key: 'Statistic',
    name: '통계',
    path: '/statistic',
    component: StatisticPage,
  },
  {
    key: 'Schedule',
    name: '예약일정',
    path: '/:day?',
    component: SchedulePage,
  },
];

// 로그인 후 사용할 라우터
const LoginRouter = () => (
  <DefaultLayout>
    <Switch>
      {RoutesMenus.map((page) => (
        <Route exact path={page.path} key={page.key} component={page.component} />
      ))}
    </Switch>
  </DefaultLayout>
);
// 로그아웃 상태의 라우터
const LogoutRouter = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <LogInLayout>
      {!isLoading && (
        <Switch>
          <Route exact path="/" component={Login} />
          <Route path="*" render={() => <Login />} />
          {/* <Redirect from="*" to="/" /> */}
        </Switch>
      )}
    </LogInLayout>
  );
};

export const AppRouter = () => {
  const { hospitalId } = useSelector((state: RootState) => state.account);
  const { isLoading, notUseLoading } = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();
  const { data: hospitalResponse, isLoading: hospitalLoading } = useGetHospital(hospitalId);
  let isTokenRefreshing = false;
  let refreshSubscribers: any[] = [];

  const onTokenRefreshed = (token: string) => {
    // eslint-disable-next-line array-callback-return
    refreshSubscribers.map((callback) => {
      callback(token);
    });

    refreshSubscribers = [];
  };

  const subscribeTokenRefresh = (callback: any) => {
    refreshSubscribers.push(callback);
  };

  useEffect(() => {
    if (!hospitalLoading) {
      dispatch(accountAction.updateHospitalInfo(hospitalResponse as HospitalDto));
    }
  }, [hospitalResponse, hospitalLoading, dispatch]);

  useEffect(() => {
    instance.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        const isLongPolling = config.url?.includes('deferred-reservation');
        if (!notUseLoading && !isLongPolling) {
          dispatch(commonAction.setLoading(true));
        }

        return config;
      },
      (error: AxiosError) => Promise.reject(error),
    );

    instance.interceptors.response.use(
      (response: AxiosResponse) => {
        dispatch(commonAction.setLoading(false));

        return response;
      },
      (error: AxiosError) => {
        dispatch(commonAction.setLoading(false));
        if (error.response) {
          const {
            config,
            response: { status },
          } = error;
          const originalRequest = config;

          // const isAutoLogin = LocalStorage.getValue(LocalStorageKey.AUTOLOGIN) === 'true';
          if (status === (HttpCode.NoAuthentication || HttpCode.NotValidToken)) {
            if (!isTokenRefreshing) {
              // eslint-disable-next-line react-hooks/exhaustive-deps
              isTokenRefreshing = true;
              UpdateToken().then((response) => {
                const { accesstoken, refreshtoken } = response.headers;
                isTokenRefreshing = false;
                instance.defaults.headers.common.Authorization = accesstoken;
                LocalStorage.setValue(LocalStorageKey.REFRESHTOKEN, refreshtoken);
                LocalStorage.setValue(LocalStorageKey.ACCESSTOKEN, accesstoken);
                onTokenRefreshed(accesstoken);
              });
            }

            // noinspection UnnecessaryLocalVariableJS
            const retryOriginalRequest = new Promise((resolve) => {
              subscribeTokenRefresh((token: any) => {
                originalRequest.headers.Authorization = token;
                resolve(instance(originalRequest));
              });
            });

            return retryOriginalRequest;
          }
          return Promise.reject(error);
        }
        return Promise.reject(error);
      },
    );
  }, []);

  return (
    <Wrapper>
      <ModalController />
      {isLoading && <Loading />}
      <Switch>{hospitalId > 0 ? <LoginRouter /> : <LogoutRouter isLoading={false} />}</Switch>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;
