import { State } from 'src/reducers';

export const selectHospital = (state: State) => state.account;

export const selectHospitalId = (state: State) => state.account.hospitalId;

export const selectMember = (state: State) => state.member;

export const selectSchedule = (state: State) => state.schedule;

export const selectReservationsParams = (state: State) => state.reservation.reservationsParams;
