import styled from '@emotion/styled';
import { AHEAD_MINT, GREY_1, GREY_3, GREY_4, WHITE } from '../../../../styles/colors';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  ul {
    list-style: none;
  }

  li {
    width: 24px;
    height: 24px;
    float: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: -0.02em;
    color: ${GREY_1};
  }

  .disabled {
    color: ${GREY_3};
    cursor: default;
  }

  .currentPage {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: -0.02em;
    color: ${AHEAD_MINT};
    background: ${WHITE};
    border: 1px solid ${GREY_4};
    box-sizing: border-box;
    border-radius: 2px;
  }
`;

export const PageLi = styled.li`
  cursor: pointer;
  .disabled {
    cursor: default;
  }
`;
