import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { AHEAD_MINT, CORAL, FITPET_BLUE, GREY_1, GREY_2, GREY_3, GREY_4, PASTEL_MINT } from '../../../../styles/colors';

export const Wrapper = styled.div(
  ({ disabled }: { disabled: boolean }) => css`
    display: flex;
    width: 370px;
    //height: 383px; // 기본값을 지정하지 않으면 높이가 달력에 따라 달라질수 있음
    justify-content: flex-start;
    flex-direction: column;

    * {
      user-select: none;
    }

    ${disabled &&
    css`
      * {
        color: ${GREY_3} !important;
        pointer-events: none;
      }
    `}
  `,
);

export const ControlWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
`;

export const ArrowIconWrapper = styled.div`
  cursor: pointer;
`;

export const DateLabel = styled.div`
  width: 72px;
  height: 18px;
  cursor: pointer;
  user-select: none;

  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  //align-items: center;
  align-items: baseline;
  text-align: center;
  letter-spacing: -0.02em;

  color: ${GREY_1};

  margin: 0 25px;
`;

export const DayLabelWrapper = styled.div`
  display: flex;
  margin-top: 22px;
  div {
    user-select: none;
    color: ${GREY_1};
    width: calc(100% / 7);
    height: 32px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.02em;
  }
`;

export const DayWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const DayContainer = styled.div`
  color: ${GREY_1};
  width: calc(100% / 7);
  height: 32px;
  margin-bottom: 7px;
  //margin: 3px 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.02em;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  &.disabled {
    cursor: default;
  }

  .prev,
  .next {
    color: ${GREY_3};
    visibility: hidden;
  }

  span {
    user-select: none;
  }
  span.text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  span.today {
    background-color: ${PASTEL_MINT};
  }
  span.saturday {
    color: ${FITPET_BLUE};
  }
  span.sunday {
    color: ${CORAL};
  }
  span.selected {
    background-color: ${AHEAD_MINT} !important;
    color: ${GREY_1} !important;
  }

  span.disabled {
    color: ${GREY_3};
    background-color: ${GREY_4};
    //border-radius: 50%;
    cursor: default;
  }
`;

export const LegendWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  margin: 28px;

  span {
    display: flex;
    user-select: none;
    margin-left: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: -0.02em;

    /* Greyscale/Gray 2 */
    color: ${GREY_2};
  }

  span::before {
    content: '';
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background-color: ${AHEAD_MINT};
    margin-right: 4px;
  }
  span.today::before {
    background-color: ${PASTEL_MINT};
  }
  span.disabled::before {
    background-color: ${GREY_4};
  }
`;
