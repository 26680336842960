import React from 'react';
import CustomerPetInfo from './PetInfo';
import type { ICustomer } from '../../../model/customer';
import CustomerReservationHistory from './ReservationHistory';
import CustomerMedicalServiceHistory from './MedicalServiceHistory';
import CustomerLastReservationHistory from './LastReservationHistory';
import * as Styles from './styles';

interface ICustomerDetailProps {
  customer: ICustomer;
}

export default function CustomerDetail({ customer }: ICustomerDetailProps) {
  return (
    <Styles.Wrapper>
      <h2>{customer.userName} 고객님의 예약 정보</h2>

      <Styles.Grid>
        <CustomerPetInfo pets={customer.petInfoDtoList} />

        <CustomerReservationHistory customer={customer} />

        <CustomerMedicalServiceHistory customer={customer} />

        <CustomerLastReservationHistory lastReservationList={customer.lastReservationList} />
      </Styles.Grid>
    </Styles.Wrapper>
  );
}
