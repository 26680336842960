import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { Pet, PetGenderToString, PetTypeToString } from 'src/model/pet';
import PetTabs from './PetTabs';
import * as CustomerStyles from '../styles';
import * as Styles from './styles';

interface ICustomerPetInfoProps {
  pets: Pet[];
}

export default function CustomerPetInfo({ pets }: ICustomerPetInfoProps) {
  const [selectedPetId, setSelectedPetId] = useState<number>(pets?.[0]?.id);

  const pet = useMemo(() => {
    return pets.find((_pet) => _pet.id === selectedPetId) as Pet;
  }, [pets, selectedPetId]);

  const dDay = useMemo(() => {
    if (!pet) return '';
    if (!pet?.birthday) return '';
    const diff = moment().diff(pet.birthday, 'M');
    const years = Math.floor(diff / 12);
    const months = diff % 12;

    if (years > 0) {
      return `${years}년 ${months}개월`;
    }

    if (months > 0) {
      return `${months}개월`;
    }

    return '';
  }, [pet]);

  return (
    <CustomerStyles.Article>
      <h3>반려 동물 정보 총 {pets.length}마리</h3>

      {pets.length > 0 && <PetTabs pets={pets} selectedPetId={selectedPetId} setSelectedPetId={setSelectedPetId} />}
      {pet && (
        <>
          <Styles.Dl>
            <dt>생년월일</dt>
            <dd>
              {moment(pet.birthday).format('YYYY년 MM월 DD일')} {dDay && `(${dDay})`}
            </dd>

            <dt>몸무게</dt>
            <dd>{pet.weight ? `${pet.weight}kg` : '-'}</dd>

            <dt>종류</dt>
            <dd>{PetTypeToString[pet.petType]}</dd>

            <dt>품종</dt>
            <dd>{pet.petBreedName}</dd>

            <dt>성별</dt>
            <dd>{PetGenderToString[pet.gender]}</dd>

            <dt>중성화 유무</dt>
            <dd>{pet.isNeutralized ? '완료' : '미완료'}</dd>
          </Styles.Dl>
        </>
      )}
    </CustomerStyles.Article>
  );
}
