import React, { RefObject, useCallback, useEffect, useRef } from 'react';
import * as Styles from './styles';
import SearchIcon from '../../icons/SearchIcon';

interface TextInputProps<T> {
  name?: string;
  value: T;
  label?: any;
  placeholder?: string;
  limit?: number;
  onChangeField?: (label: any, value: string) => void;
  required?: boolean;
  readOnly?: boolean;
  icon?: string;
  onClickIcon?: () => void;
  disabled?: boolean;
  onEnterKey?: () => void;
  onChange?: (value: string) => void;
  width?: number;
}

const TextInput = <T extends string | number>({
  name,
  placeholder,
  value,
  label = undefined,
  onChangeField = () => {},
  required = false,
  disabled = false,
  limit = 10,
  readOnly = false,
  icon = '',
  onClickIcon = undefined,
  onEnterKey = () => {},
  onChange = () => {},
  width = 184,
}: TextInputProps<T>) => {
  const inputRef = useRef<HTMLInputElement>();
  const placeholderText = limit && placeholder ? placeholder : `한글 최대 ${limit}자까지 입력`;
  const handleKeyUp = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        onEnterKey();
      }
    },
    [onEnterKey],
  );

  const handleOnChange = useCallback(
    (e) => {
      e.preventDefault();
      onChange(e.target.value);
      if (label) {
        onChangeField(label, e.target.value);
      }
    },
    [onChange, label, onChangeField],
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = value as string;
    }
  }, [value]);

  return (
    <Styles.Wrapper disabled={disabled}>
      {name && (
        <Styles.Label htmlFor={`${name}`} disabled={disabled}>
          {name}
          {required && <Styles.Required>*</Styles.Required>}
          {!required && '(선택)'}
        </Styles.Label>
      )}

      <Styles.TextWrapper>
        <Styles.Text
          autoComplete="off"
          ref={inputRef as RefObject<HTMLInputElement>}
          id={`${name}`}
          placeholder={placeholderText}
          maxLength={limit}
          // onBlur={handleBlur}
          disabled={disabled}
          onKeyUp={(e) => handleKeyUp(e)}
          onChange={handleOnChange}
          readOnly={readOnly}
          // icon={icon}
          width={width}
        />
        {icon && (
          <Styles.IconWrapper
            onClick={() => {
              if (onClickIcon) onClickIcon();
            }}>
            {icon === 'search' && <SearchIcon />}
          </Styles.IconWrapper>
        )}
      </Styles.TextWrapper>
    </Styles.Wrapper>
  );
};

export default TextInput;
