import React from 'react';

const FemaleIcon = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    fill="#F66ACC"
    viewBox="0 0 611.998 611.998"
    xmlSpace="preserve">
    <g>
      <path
        d="M499.728,193.729C499.728,86.907,412.821,0,306,0S112.271,86.907,112.271,193.729c0,94.259,67.673,172.997,156.98,190.217
		v50.406h-58.798c-20.295,0-36.749,16.454-36.749,36.749c0,20.295,16.454,36.749,36.749,36.749h58.798v67.399
		c0,20.297,16.454,36.749,36.749,36.749s36.749-16.454,36.749-36.749v-67.399h58.798c20.295,0,36.749-16.454,36.749-36.749
		c0-20.295-16.454-36.749-36.749-36.749h-58.798v-50.406C432.056,366.726,499.728,287.988,499.728,193.729z M185.769,193.729
		c0-66.295,53.934-120.231,120.23-120.231s120.23,53.934,120.23,120.231c0,66.295-53.934,120.23-120.23,120.23
		C239.705,313.959,185.769,260.025,185.769,193.729z"
      />
    </g>
  </svg>
);

export default FemaleIcon;
