// 공통 색상
export const FITPET_BLUE = '#2B51A2';
export const MINT = '#E8FBFC';
export const AHEAD_MINT = '#31BEC8';
export const FITPET_MINT = '#2EB4BD';
export const SUB_MINT = '#C1EBEE';
export const BACKGROUND_MINT = '#EAF8FA';
export const SKY_BLUE = '#E0F5F7';
export const SKY_BLUE_2 = '#9EB4E4';
export const CORAL = '#F27272';
export const GREY_1 = '#434343';
export const GREY_2 = '#9D9D9D';
export const GREY_3 = '#D1D1D1';
export const GREY_4 = '#EFEFEF';
export const GREY_5 = '#F8F8F8';
export const GREY_6 = '#868E96';
export const GREY_11 = '#E9ECEF';
export const GREY_HASHTAG = '#AAAAAA';
export const WHITE = '#FFFFFF';
export const TRANSPARENT = 'transparent';
export const GREEN = '#25C870';
export const ERROR_RED = '#DD0202';
// RGB
export const GREY_1_RGB = '67, 67, 67';
export const MODAL_BACKGROUND_RGB = '82, 82, 82';
export const TOAST_BACKGROUND_RGB = 'rgba(255, 255, 255, 0.3)';

// 핏펫몰
export const MALL_BLUE_RGB = '22, 83, 210';
export const MALL_BLUE = '#1653d2';
export const NOTICE_BACKGROUND = '#F3F6FD';
export const TIME_SALE = '#565656';

// 병원예약
export const PASTEL_MINT = '#E8FBFC';
export const SCHEDULE_DOT_COLORS = [
  '#FFE071',
  '#65E0E9',
  '#77F5B9',
  '#F589D7',
  '#D4C1FD',
  '#F9A7A7',
  '#ADC9FF',
  '#FFCB9A',
  '#B8FBEF',
  '#EDF589',
];

// 통계
export const NAVY = '#10388E';
export const PASTEL_BLUE = '#1482FF';
export const PASTEL_SKYBLUE = '#94C6FF';
export const PASTEL_GREEN = '#92D9DE';