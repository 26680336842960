import React, { useEffect } from 'react';
import EmptyUser from 'src/components/member/EmptyUser';
import UserDetail from 'src/components/member/UserDetail';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import * as Styles from './styles';
import { MemberInfoDto } from '../../model/member';
import { useGetMemberList } from '../../query/Member/useMemberQuery';
import { memberAction } from '../../features/member/memberSlice';

const UserPage = () => {
  const dispatch = useDispatch();
  // 운영시간이 있는지 체크
  // const { isLoading } = useGetOperation(1);
  // const hasOperation = !!operationData?.id;
  const { data: memberList, isLoading } = useGetMemberList();

  useEffect(() => {
    if (!isLoading && memberList?.length) {
      dispatch(memberAction.setMemberList(memberList));
    }
  }, [memberList, isLoading]);

  return (
    <Styles.Wrapper>
      {!isLoading && isEmpty(memberList) && <EmptyUser />}
      {!isLoading && !isEmpty(memberList) && <UserDetail memberList={memberList as MemberInfoDto[]} />}
    </Styles.Wrapper>
  );
};

export default UserPage;
