import React, { createContext, useContext, useMemo } from 'react';
import { Tracker } from '../../utils/tracker';

type EventTrackingState = {
  tracker: Tracker;
};

const eventTracker = new Tracker();

export const EventContext = createContext<EventTrackingState>({ tracker: eventTracker });

eventTracker.initialize();

export const EventTrackingProvider = ({ children }: { children: React.ReactNode }) => {
  const tracker = useMemo(() => eventTracker, []);

  const value = useMemo(() => ({ tracker }), [tracker]);

  return <EventContext.Provider value={value}>{children}</EventContext.Provider>;
};

export const useTracker = () => {
  const { tracker } = useContext(EventContext);

  return tracker;
};
