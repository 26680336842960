import styled from '@emotion/styled';
import { GREY_2, WHITE } from 'styles/colors';

export const Wrapper = styled.div`
  padding: 36px;
  background-color: ${WHITE};
  border-radius: 10px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  h2 {
    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
  }

  span {
    font-size: 12px;
    line-height: 14px;
    color: ${GREY_2};
  }
`;
