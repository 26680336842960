import styled from '@emotion/styled';
import { AHEAD_MINT, GREY_1, GREY_2, PASTEL_MINT } from 'styles/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 52px;
  max-width: 433px;
`;

export const Title = styled.div`
  /* 근무일시 */
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;

  /* Greyscale/Grey 1 */
  color: ${GREY_1};
  margin-bottom: 16px;
`;

export const Week = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
`;

export const Day = styled.button`
  padding: 11px;
  border-radius: 5px;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.02em;

  /* Greyscale/Grey 1 */
  color: ${GREY_1};
  &.active {
    color: ${AHEAD_MINT};
    background: ${PASTEL_MINT};
    font-weight: bold;
  }
`;

export const CheckBoxWrap = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 18px;

  span {
    margin-left: 8px;
  }
`;

export const TimeSelectorWrap = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  color: ${GREY_2};
`;
