import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ResponsivePie, PieCustomLayerProps } from '@nivo/pie';
import { RootState } from 'src/reducers';
import { AHEAD_MINT, GREY_6 } from 'styles/colors';
import * as StatisticStyles from '../styles';
import * as Styles from './styles';

interface IPieData {
  id: string;
  value: number;
  color?: string;
}

export default function CustomerStatistics() {
  const { statisticData } = useSelector((state: RootState) => state.statistic);
  const userCountData = useMemo(
    () => [
      {
        id: '재방문 고객 수',
        value: statisticData?.reservationVisited.revisitingCnt ?? 0,
        color: AHEAD_MINT,
      },
      {
        id: '첫 방문 고객 수',
        value: statisticData?.reservationVisited.visitingCnt ?? 0,
        color: GREY_6,
      },
    ],
    [statisticData],
  );

  const CenteredMetric = ({ centerX, centerY }: PieCustomLayerProps<IPieData>) => {
    return (
      <>
        <text
          x={centerX}
          y={centerY - 15}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: '12px',
            lineHeight: '14px',
          }}>
          재방문
        </text>
        <text
          x={centerX}
          y={centerY + 5}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: '18px',
            lineHeight: '22px',
            fontWeight: 600,
          }}>
          {statisticData?.reservationVisited.revisitingRate ?? 0}%
        </text>
      </>
    );
  };

  return (
    <StatisticStyles.Article>
      <h4>등록된 전체 고객 수</h4>
      <Styles.UserCount>
        <strong>{statisticData?.userCnt}</strong>
        <span>명</span>
      </Styles.UserCount>
      <h4>고객 재방문 비율</h4>
      <div style={{ height: 190 }}>
        <ResponsivePie
          data={userCountData}
          tooltip={() => null}
          margin={{
            bottom: 46,
          }}
          innerRadius={0.8}
          layers={['arcs', 'legends', CenteredMetric]}
          colors={({ data }) => data.color}
          legends={[
            {
              anchor: 'bottom',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: 32,
              itemsSpacing: 0,
              itemWidth: 100,
              itemHeight: 12,
              itemTextColor: '#434343',
              itemDirection: 'left-to-right',
              itemOpacity: 1,
              symbolSize: 8,
              symbolShape: 'square',
              data: userCountData.map((item) => {
                return {
                  color: item.color,
                  id: item.id,
                  label: `${item.id} ${item.value}명`,
                };
              }),
            },
          ]}
        />
      </div>
    </StatisticStyles.Article>
  );
}
