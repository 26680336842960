import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Loading from 'src/components/common/Loading';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import * as Styles from './styles';
import { MemberInfoDto, MemberType, MemberTypeKey } from '../../../model/member';
import { DefaultPlusIcon } from '../../icons/PlusIcon';
import { dayMap, DayType } from '../../../constants/workHours';
import { BasicButton } from '../../common/buttons/BasicButton';
import { ModalHandler } from '../../../utils/ModalHandler';
import { ModalType } from '../../../constants/modal';
import TextInput from '../../common/TextInput';
import { selectMember } from '../../../selectors';
import { useGetMember } from '../../../query/Member/useMemberQuery';
import { memberAction } from '../../../features/member/memberSlice';
import useMember from '../../../hooks/member/useMember';
import WorkDay from '../WorkDay';
import { WorkingDayType, WorkingDayTypeForUI } from '../../../model/workingDay';
import CheckBoxIcon from '../../icons/CheckBoxIcon';
import DateTime from '../DateTime';

const UserDetail = ({ memberList }: { memberList: MemberInfoDto[] }) => {
  const dispatch = useDispatch();

  // for ui data
  const initId = useMemo(() => memberList[0].id, [memberList]);
  const [userId, setUserId] = useState(initId);
  const [editMode, setEditMode] = useState(false);

  // getter
  const memberState = useSelector(selectMember);
  const editMember = useMemo(() => memberState.editMember, [memberState.editMember]);
  const member = useMemo(() => memberState.viewMember, [memberState.viewMember]);

  // api
  const { data: userResponse, isLoading } = useGetMember(userId);

  useEffect(() => {
    const user = userResponse as MemberType;
    dispatch(memberAction.setViewMember(user));
    dispatch(memberAction.setEditMember(user));
  }, [dispatch, userResponse]);

  const { deleteMember, updateMember, updateEditField, openAddMemberModal } = useMember();

  const changeUser = useCallback(
    (id) => {
      if (!editMode) {
        setUserId(id);
      }
    },
    [editMode],
  );

  const onDeleteConfirm = useCallback(() => {
    ModalHandler.show(ModalType.Alert, {
      title: '해당 구성원을 정말 삭제하시겠습니까?',
      confirmText: '삭제',
      cancelText: '취소',
      onConfirm: async () => {
        await deleteMember(userId);
        const userList = memberList.filter((m) => m.id !== userId);
        if (userList.length > 0) {
          setUserId(userList[0].id);
        }
      },
    });
  }, [deleteMember, userId, memberList]);

  const onChangeEditMode = useCallback(
    (isEdit: boolean) => {
      dispatch(memberAction.setEditMember({ ...member }));
      setEditMode(isEdit);
    },
    [dispatch, member],
  );

  const onSaveUser = useCallback(async () => {
    const response = await updateMember(userId, editMember);
    if (response) {
      setEditMode(false);
    }
  }, [updateMember, userId, editMember]);

  const onChangeWorkType = useCallback(
    (workingDay: WorkingDayType[]) => {
      updateEditField({ key: MemberTypeKey.workDays, value: workingDay });
    },
    [updateEditField],
  );

  const onChangeHolidayFlag = useCallback(() => {
    updateEditField({ key: MemberTypeKey.IsTemporaryHoliday, value: !editMember.isTemporaryHoliday });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEditField]);

  // const onChangeHolidayDateTime = useCallback(
  //   (type: DateTimeType, value: string) => {
  //     console.info('모델링이 없음 개발 필요', type, value);
  //     const holidayDateTime = { ...editUser.holidayDateTime };
  //
  //     if (type === DateTimeType.START_DATE) {
  //       holidayDateTime.startDate = value;
  //     } else if (type === DateTimeType.END_DATE) {
  //       holidayDateTime.endDate = value;
  //     } else if (type === DateTimeType.START_TIME) {
  //       holidayDateTime.startTime = value;
  //     } else if (type === DateTimeType.END_TIME) {
  //       holidayDateTime.endTime = value;
  //     }
  //     setEditUser({ ...editUser, holidayDateTime });
  //   },
  //   [editUser, setEditUser],
  // );

  const onChangeHolidayDateTime = useCallback(
    (type: string, value: string) => {
      const startTime = editMember.temporaryHolidayStart
        ? moment(editMember.temporaryHolidayStart).format('HH:mm')
        : '';
      const endTime = editMember.temporaryHolidayEnd ? moment(editMember.temporaryHolidayEnd).format('HH:mm') : '';
      const startDate = editMember.temporaryHolidayStart
        ? moment(editMember.temporaryHolidayStart).format('YYYY-MM-DD')
        : '';
      const endDate = editMember.temporaryHolidayEnd ? moment(editMember.temporaryHolidayEnd).format('YYYY-MM-DD') : '';

      if (type === 'START_DATE') {
        updateEditField({
          key: MemberTypeKey.TemporaryHolidayStart,
          value: `${moment(value).format('YYYY-MM-DD')} ${startTime === '' ? '00:00' : startTime}`,
        });
      } else if (type === 'START_TIME') {
        updateEditField({ key: MemberTypeKey.TemporaryHolidayStart, value: `${startDate} ${value}` });
      } else if (type === 'END_DATE') {
        updateEditField({
          key: MemberTypeKey.TemporaryHolidayEnd,
          value: `${moment(value).format('YYYY-MM-DD')} ${endTime === '' ? '00:00' : endTime}`,
        });
      } else if (type === 'END_TIME') {
        updateEditField({ key: MemberTypeKey.TemporaryHolidayEnd, value: `${endDate} ${value}` });
      }
    },
    [editMember, updateEditField],
  );
  const getWorkTimeText = (day: string) => {
    if (member?.workDays) {
      const workDay = member?.workDays.find((d) => d.workDay === day);
      let label = '';
      if (workDay) {
        label = `${workDay.workTimeStart} ~ ${workDay.workTimeEnd}`;
        if (workDay.isLunchTime) {
          label += ` (점심시간 ${workDay.lunchTimeStart} ~ ${workDay.lunchTimeEnd})`;
        }
      }
      return workDay ? label : '휴무';
    }
    return '';
  };

  return (
    <>
      {isLoading && <Loading />}
      <Styles.Wrapper>
        <Styles.MemberTabWrapper>
          {memberList &&
            memberList.map((mem) => (
              <Styles.UserButton
                key={mem.id}
                onClick={() => changeUser(mem.id)}
                className={`
                ${editMode ? 'inactive' : ''}
                ${userId === mem.id ? 'active' : ''}
                `}>
                {mem.name} 수의사
              </Styles.UserButton>
            ))}
          {!editMode && (
            <Styles.AddButton radius={20} onClick={() => openAddMemberModal()}>
              <DefaultPlusIcon />
              <span>구성원 추가</span>
            </Styles.AddButton>
          )}
        </Styles.MemberTabWrapper>
        {member && !editMode && (
          <>
            <Styles.Table>
              <tbody>
                <Styles.Tr>
                  <Styles.Th>구성원 이름</Styles.Th>
                  <Styles.Td>{member.name}</Styles.Td>
                </Styles.Tr>
                <Styles.Tr>
                  <Styles.Th>구성원 전문 분야</Styles.Th>
                  <Styles.Td>{member.major}</Styles.Td>
                </Styles.Tr>
                <Styles.Tr>
                  <Styles.Th>면허번호(고유번호)</Styles.Th>
                  <Styles.Td>{member.licenseNo}</Styles.Td>
                </Styles.Tr>
                <Styles.Tr>
                  <Styles.Th>당일예약가능 여부</Styles.Th>
                  <Styles.Td>{member.isTodayReservationAvailable ? '당일예약 가능' : '당일예약 불가능'}</Styles.Td>
                </Styles.Tr>
                <Styles.Tr>
                  <Styles.Th>근무일시</Styles.Th>
                  <Styles.Td>
                    {Object.keys(dayMap).map((day) => (
                      <Styles.DayWrapper key={day}>
                        <span className="day">{dayMap[day as DayType]}</span>
                        <span className="time">{getWorkTimeText(day)}</span>
                      </Styles.DayWrapper>
                    ))}
                  </Styles.Td>
                </Styles.Tr>
                {/* <Styles.Tr>
                  <Styles.Th>임시 휴무 일시</Styles.Th>
                  <Styles.Td>
                    {member.isTemporaryHoliday && member.temporaryHolidayStart && member.temporaryHolidayEnd
                      ? `${member.temporaryHolidayStart} ~ ${member.temporaryHolidayEnd}`
                      : '-'}
                  </Styles.Td>
                </Styles.Tr> */}
              </tbody>
            </Styles.Table>

            <Styles.ButtonWrapper>
              <Styles.CancelButton type="button" onClick={() => onDeleteConfirm()}>
                이 구성원 삭제
              </Styles.CancelButton>
              <BasicButton type="button" onClick={() => onChangeEditMode(true)}>
                이 구성원 변경
              </BasicButton>
            </Styles.ButtonWrapper>
          </>
        )}
        {editMember && editMode && (
          <>
            <Styles.EditWrapper>
              <TextInput<string>
                name="구성원 이름"
                value={editMember.name}
                onChangeField={(label, value) => {
                  updateEditField({ key: label, value });
                }}
                label="name"
                limit={5}
              />
              <TextInput<string>
                name="구성원 전문 분야"
                value={editMember.major}
                onChangeField={(label, value) => {
                  updateEditField({ key: label, value });
                }}
                label="major"
                limit={10}
              />
              <TextInput<string>
                name="면허번호(고유번호)"
                value={editMember.licenseNo}
                onChangeField={(label, value) => {
                  updateEditField({ key: label, value });
                }}
                label="licenseNo"
                placeholder="숫자 최대 7자까지 입력"
                limit={7}
              />

              {/* 당일예약 가능 여부 */}
              <Styles.TempHolidayWrapper>
                <Styles.Title>당일예약 가능 여부</Styles.Title>
                <Styles.RadioWrapper>
                  <Styles.RadioLabel htmlFor="enableReservation">
                    <Styles.RadioButton
                      type="radio"
                      id="enableReservation"
                      name="isTodayReservationAvailable"
                      checked={editMember.isTodayReservationAvailable}
                      onChange={() => updateEditField({ key: 'isTodayReservationAvailable', value: true })}
                    />
                    당일예약 가능
                  </Styles.RadioLabel>
                  <Styles.RadioLabel htmlFor="disableReservation">
                    <Styles.RadioButton
                      type="radio"
                      id="disableReservation"
                      name="isTodayReservationAvailable"
                      checked={editMember.isTodayReservationAvailable === false}
                      onChange={() => updateEditField({ key: 'isTodayReservationAvailable', value: false })}
                    />
                    당일예약 불가
                  </Styles.RadioLabel>
                </Styles.RadioWrapper>
              </Styles.TempHolidayWrapper>

              {/* <TextInput<number> */}
              {/*    name="당일예약가능 여부" */}
              {/*    value={editMember.todayReservationAvailable} */}
              {/*    onChangeField={(label, value) => { */}
              {/*      updateEditField({ key: label, value }); */}
              {/*    }} */}
              {/*    label="todayReservationAvailable" */}
              {/*    placeholder="숫자 최대 7자까지 입력" */}
              {/*    limit={7} */}
              {/* /> */}

              {/* 모델이 없음 확인 필요 */}
              <WorkDay
                workDays={editMember.workDays as WorkingDayTypeForUI[]}
                updateWorkDays={(workingDays) => onChangeWorkType(workingDays)}
              />

              {/** 11/11 임시 휴무 화면에 안보이게 하기 */}
              {/* <Styles.TempHolidayWrapper>
                <Styles.Title>임시 휴무 일시</Styles.Title>

                <Styles.CheckBoxWrap role="button" onClick={() => onChangeHolidayFlag()}>
                  <CheckBoxIcon checked={editMember.isTemporaryHoliday} />
                  <span>임시 휴무일이 있습니다.</span>
                </Styles.CheckBoxWrap>

                <Styles.TempHoliday>
                  <span className="title">휴무일</span>
                  <Styles.SelectorWrapper>
                    <DateTime
                      dateTop
                      disabled={!editMember.isTemporaryHoliday}
                      initDate={
                        _.isEmpty(editMember.temporaryHolidayStart)
                          ? ''
                          : moment(editMember.temporaryHolidayStart).format('YYYY-MM-DD')
                      }
                      initTime={
                        _.isEmpty(editMember.temporaryHolidayStart)
                          ? ''
                          : moment(editMember.temporaryHolidayStart).format('HH:mm')
                      }
                      onChangeDate={(value) => onChangeHolidayDateTime('START_DATE', value)}
                      onChangeTime={(value) => onChangeHolidayDateTime('START_TIME', value)}
                    />
                    <Styles.TimeBetweenLabel>~</Styles.TimeBetweenLabel>
                    <DateTime
                      dateTop
                      disabled={!editMember.isTemporaryHoliday}
                      initDate={
                        _.isEmpty(editMember.temporaryHolidayEnd)
                          ? ''
                          : moment(editMember.temporaryHolidayEnd).format('YYYY-MM-DD')
                      }
                      initTime={
                        _.isEmpty(editMember.temporaryHolidayEnd)
                          ? ''
                          : moment(editMember.temporaryHolidayEnd).format('HH:mm')
                      }
                      onChangeDate={(value) => onChangeHolidayDateTime('END_DATE', value)}
                      onChangeTime={(value) => onChangeHolidayDateTime('END_TIME', value)}
                    />
                  </Styles.SelectorWrapper>
                </Styles.TempHoliday> */}
              {/** 여기까지 11/11 작업한 내용 아래는 원래 주석 처리 되어 있었음 */}

              {/* <Styles.TempHoliday> */}
              {/*  <span className="title">휴무일</span> */}
              {/*  <Styles.SelectorWrapper> */}
              {/*    <DateTime */}
              {/*      disabled={!editUser.hasHoliday} */}
              {/*      initDate={editUser.holidayDateTime.startDate} */}
              {/*      initTime={editUser.holidayDateTime.startTime} */}
              {/*      onChangeDate={(value) => onChangeHolidayDateTime(DateTimeType.START_DATE, value)} */}
              {/*      onChangeTime={(value) => onChangeHolidayDateTime(DateTimeType.START_TIME, value)} */}
              {/*    /> */}
              {/*    ~ */}
              {/*    <DateTime */}
              {/*      disabled={!editUser.hasHoliday} */}
              {/*      initDate={editUser.holidayDateTime.endDate} */}
              {/*      initTime={editUser.holidayDateTime.endTime} */}
              {/*      onChangeDate={(value) => onChangeHolidayDateTime(DateTimeType.END_DATE, value)} */}
              {/*      onChangeTime={(value) => onChangeHolidayDateTime(DateTimeType.END_TIME, value)} */}
              {/*    /> */}
              {/*  </Styles.SelectorWrapper> */}
              {/* </Styles.TempHoliday> */}
              {/* </Styles.TempHolidayWrapper> */}

              <Styles.ButtonWrapper>
                <Styles.CancelButton type="button" onClick={() => onChangeEditMode(false)}>
                  돌아가기
                </Styles.CancelButton>
                <BasicButton type="button" onClick={() => onSaveUser()}>
                  저장
                </BasicButton>
              </Styles.ButtonWrapper>
            </Styles.EditWrapper>
          </>
        )}
      </Styles.Wrapper>
    </>
  );
};
export default UserDetail;
