import React from 'react';

const SelectDropDownArrowIcon = () => (
  <svg width="11" height="6" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L6 6L11 1" stroke="#434343" />
  </svg>
  // <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  //   <path d="M7 10L12 15L17 10" stroke="#434343" />
  // </svg>
);

export default SelectDropDownArrowIcon;

//  xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6L11 1' stroke='%23434343'/%3E%3C/svg%3E%0A");
// background-image: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6L11 1' stroke='%23434343'/%3E%3C/svg%3E%0A");
