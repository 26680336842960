import React from 'react';
import { RoutesIconProps } from 'src/model/route';
import { AHEAD_MINT, GREY_2 } from 'styles/colors';

const ReservationStatusIcon = ({ isActive }: RoutesIconProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.3266 17.1649C10.4701 17.3085 10.6649 17.3891 10.868 17.3891C11.071 17.3891 11.2658 17.3085 11.4094 17.1649L14.8547 13.7196C15.1537 13.4206 15.1537 12.9358 14.8547 12.6368C14.5557 12.3379 14.0709 12.3379 13.7718 12.6368L10.8679 15.5408L9.8279 14.5007C9.52889 14.2016 9.04413 14.2017 8.74515 14.5007C8.44614 14.7996 8.4461 15.2844 8.74512 15.5834L10.3266 17.1649Z"
      fill={isActive ? `${AHEAD_MINT}` : `${GREY_2}`}
    />
    <path
      d="M20.8344 4.75625H17.1594V2.76562C17.1594 2.34277 16.8166 2 16.3937 2C15.9709 2 15.6281 2.34277 15.6281 2.76562V4.75625H7.97187V2.76562C7.97187 2.34277 7.6291 2 7.20625 2C6.78339 2 6.44062 2.34277 6.44062 2.76562V4.75625H2.76562C2.34277 4.75625 2 5.09902 2 5.52187V20.8344C2 21.2572 2.34277 21.6 2.76562 21.6H20.8344C21.2572 21.6 21.6 21.2572 21.6 20.8344V5.52187C21.6 5.09902 21.2572 4.75625 20.8344 4.75625ZM20.0687 20.0687H3.53125V9.73281H20.0687V20.0687ZM20.0687 8.20156H3.53125V6.2875H20.0687V8.20156Z"
      fill={isActive ? `${AHEAD_MINT}` : `${GREY_2}`}
    />
  </svg>
);

export default ReservationStatusIcon;
