import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { QueryKey } from 'src/constants/queryKey';
import { ICustomerListDto, ICustomerParams } from 'src/model/customer';
import { BASE_URL, HTTP } from 'src/utils/api/http';
import { selectHospitalId } from '../../selectors';

export const useGetCustomerList = (customerParams: ICustomerParams) => {
  const hospitalId = useSelector(selectHospitalId);

  const { data, error, isLoading } = useQuery(
    [QueryKey.CUSTOMER_LIST, hospitalId, customerParams],
    async () => {
      if (hospitalId === 0) return null;
      const params = { ...customerParams };

      if (!params.keyWord) {
        delete params.keyWord;
      }

      if (!params.sort) {
        delete params.sort;
      }

      const response = await HTTP.get<ICustomerListDto>(
        `${BASE_URL}/api/hospitals/${hospitalId}/reservations/resource`,
        {
          params,
        },
      );
      return response.data;
    },
    { refetchOnWindowFocus: false },
  );

  return { data, error, isLoading };
};
