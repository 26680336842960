import React, { ReactNode, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { GREY_1, GREY_4, GREY_5 } from 'styles/colors';
import SideMenu from 'src/components/layout/SideMenu';
import { NavigationItems } from 'src/constants/navigation';
import ReservationStatusIcon from 'src/components/icons/ReservationStatusIcon';
import ReservationScheduleIcon from 'src/components/icons/ReservationScheduleIcon';
import UserManagementIcon from 'src/components/icons/UserManagementIcon';
import { useSelector } from 'react-redux';
import { useGetRouterInfo } from '../../hooks/useGetRouterInfo';
import CustomerManagementIcon from '../../components/icons/CustomerManagementIcon';
import StatisticIcon from '../../components/icons/StatisticIcon';
import OperationManagementIcon from '../../components/icons/OperationManagementIcon';
import { useGetNewReservation } from '../../query/useGetNewReservation';
import { RootState } from '../../reducers';

interface DefaultLayoutProps {
  children: ReactNode;
}

const DefaultLayout = ({ children }: DefaultLayoutProps) => {
  const location = useLocation();
  const isMainPage: boolean = useMemo(() => location.pathname === '/', [location]);
  const { hospitalId } = useSelector((state: RootState) => state.account);

  const navigationMap = useMemo(
    () => ({
      [NavigationItems.ReservationSchedule]: {
        text: '예약일정',
        icon: (isActive: boolean) => <ReservationScheduleIcon isActive={isActive} />,
        path: '/',
        description: '예약일정을 관리할 수 있습니다.',
      },
      [NavigationItems.ReservationStatus]: {
        text: '예약현황',
        icon: (isActive: boolean) => <ReservationStatusIcon isActive={isActive} />,
        path: '/reservations',
        description: '예약내역을 관리할 수 있습니다.',
      },
      // [NavigationItems.CustomerService]: {
      //   text: '고객상담',
      //   icon: (isActive: boolean) => <CustomerServiceIcon isActive={isActive} />,
      //   path: '/service',
      //   description: '고객을 관리하고 상담합니다.',
      // },
      [NavigationItems.OperationManagement]: {
        text: '운영관리',
        icon: (isActive: boolean) => <OperationManagementIcon isActive={isActive} />,
        path: '/operation',
        description: '병원예약 운영방식을 설정할 수 있습니다.',
      },
      [NavigationItems.MemberManagement]: {
        text: '구성원관리',
        icon: (isActive: boolean) => <UserManagementIcon isActive={isActive} />,
        path: '/member',
        description: '구성원을 추가 / 삭제할 수 있습니다.',
      },
      [NavigationItems.CustomerManagement]: {
        text: '고객관리',
        icon: (isActive: boolean) => <CustomerManagementIcon isActive={isActive} />,
        path: '/customer',
        description: '고객 정보에 대한 확인을 할 수 있습니다.',
      },
      [NavigationItems.Statistic]: {
        text: '통계',
        icon: (isActive: boolean) => <StatisticIcon isActive={isActive} />,
        path: '/statistic',
        description: '고객님의 누적 정보를 확인할 수 있습니다.',
      },
      // [NavigationItems.Develop]: {
      //   text: '개발 가이드',
      //   icon: (isActive: boolean) => <UserManagementIcon isActive={isActive} />,
      //   path: '/dev',
      //   description: '개발에 필요한 컴포넌트 및 설명 적을예정 (실 운영서버에서는 빠짐)',
      // },
    }),
    [],
  );
  const { currentRoute } = useGetRouterInfo({ menu: navigationMap });
  useGetNewReservation(hospitalId);

  return (
    <RootWrapper>
      <Wrapper>
        {/* <ModalController /> */}
        <SideMenu menu={navigationMap} />
        <Main>
          {!isMainPage && (
            <>
              <h1 className="title">{currentRoute.text}</h1>
              <p className="description">{currentRoute.description}</p>
            </>
          )}
          <Content isMainPage={isMainPage}>{children}</Content>
        </Main>
      </Wrapper>
    </RootWrapper>
  );
};

export default DefaultLayout;

const RootWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Wrapper = styled.div`
  display: flex;
  background-color: ${GREY_5};
  border: 1px solid ${GREY_4};
  min-width: 1024px;
  min-height: 100%;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding: 48px;
  width: calc(100% - 227px);
  .title {
    font-size: 20px;
    line-height: 25px;
    font-weight: bold;
    color: ${GREY_1};
  }

  .description {
    font-size: 12px;
    line-height: 15px;
    color: ${GREY_1};
    margin-top: 12px;
  }
`;

const Content = styled.section`
  margin-top: ${({ isMainPage }: { isMainPage?: boolean }) => (isMainPage ? '0' : '36px')};
  height: 100%;
`;
