import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import { HourPlusMinute } from 'src/constants/workHours';
import _ from 'lodash';
import * as Styles from './style';
import TextInputCalendar from '../../common/TextInputCalendar';
import SelectBox from '../../common/SelectBox';
import { ReservationAvailableHour } from '../../../model/reservation';

export type DateTimeProps = {
  disabled?: boolean;
  disabledTime?: boolean;
  onChangeDate?: (dateStr: string, timeStr?: string) => void;
  onChangeTime?: (timeStr: string, dateStr?: string) => void;
  onChangeMonth?: (yyyyMM: string) => void;
  initDate?: string;
  initTime?: string;
  dateTop?: boolean;
  minDate?: string;
  maxDate?: string;
  optionHeight?: number;
  availableDays?: string[];
  availableTimes?: ReservationAvailableHour[];
};

const DateTime = ({
  disabled = false,
  disabledTime = false,
  initDate = moment().format('YYYY-MM-DD'),
  initTime = '',
  onChangeDate,
  onChangeTime,
  dateTop = false,
  minDate = '',
  maxDate = '',
  optionHeight = 130,
  availableDays = [],
  availableTimes = [],
  onChangeMonth = () => {},
}: DateTimeProps) => {
  // 속성으로 가능한 시간만 필터링해야함
  // const timeList = Object.keys(HourPlusMinute)
  //   .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
  //   .filter((key) => {
  //     // FIXME: 속성으로 예외처리할 항목 가져와야함 현재는 없어서 리턴 true
  //     return !!key;
  //   })
  //   .map((key) => {
  //     return {
  //       label: key,
  //       value: HourPlusMinute[key],
  //     };
  //   });

  const timeList = useMemo(() => {
    if (!_.isEmpty(availableTimes)) {
      return availableTimes?.map(({ time, isAvailable }) => {
        return {
          label: time,
          value: time,
          isAvailable,
        };
      });
    }
    return Object.keys(HourPlusMinute)
      .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
      .filter((key) => {
        // FIXME: 속성으로 예외처리할 항목 가져와야함 현재는 없어서 리턴 true
        return !!key;
      })
      .map((key) => {
        return {
          label: key,
          value: HourPlusMinute[key],
        };
      });
  }, [availableTimes]);

  // events
  const changeDate = useCallback(
    (dateStr) => {
      if (onChangeDate) {
        onChangeDate(dateStr, initTime);
      }
    },
    [onChangeDate, initTime],
  );

  const changeMonth = useCallback(
    (yyyyMM) => {
      onChangeMonth(yyyyMM);
    },
    [onChangeMonth],
  );

  const changeTime = useCallback(
    (timeStr) => {
      if (onChangeTime) {
        onChangeTime(timeStr, initDate);
      }
    },
    [onChangeTime, initDate],
  );

  return (
    <Styles.Wrapper>
      {/* 달력 */}
      <TextInputCalendar
        min={minDate}
        max={maxDate}
        disabled={disabled}
        selectDate={initDate}
        onChange={(selected) => changeDate(selected)}
        onChangeMonth={(yyyyMM) => changeMonth(yyyyMM)}
        top={dateTop}
        width={184}
        availableDate={availableDays}
      />
      {/* 시간 */}
      <SelectBox
        top={dateTop}
        width={116}
        optionHeight={optionHeight}
        placeholder="시간선택"
        disabled={disabled || disabledTime}
        onChange={(value) => {
          changeTime(value);
        }}
        items={timeList}
        value={{ value: initTime, label: initTime }}
      />
    </Styles.Wrapper>
  );
};

export default DateTime;
