import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/reducers';
import { customerAction } from 'src/features/customer/customerSlice';
import { CustomerFieldKey } from 'src/model/customer';
import SearchIcon from '../../icons/SearchIcon';
import * as Styles from './styles';

const CustomerSearch = () => {
  const dispatch = useDispatch();
  const { customerParams } = useSelector((state: RootState) => state.customer);
  const [value, setValue] = useState<string>('');

  const handleSearch = () => {
    dispatch(customerAction.updateField({ key: CustomerFieldKey.KeyWord, value: value.trim() }));
  };

  const handlePress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleReset = () => {
    setValue('');
    dispatch(customerAction.updateField({ key: CustomerFieldKey.KeyWord, value: '' }));
  };

  return (
    <>
      <Styles.Search>
        <Styles.SearchInput
          type="text"
          placeholder="고객명 또는 휴대폰번호"
          value={value}
          onKeyPress={handlePress}
          onChange={(e) => setValue(e.target.value)}
        />
        <Styles.IconWrapper onClick={handleSearch}>
          <SearchIcon />
        </Styles.IconWrapper>

        <Styles.TextRightWrapper>
          <Styles.ResetButton onClick={handleReset}>검색 초기화</Styles.ResetButton>
          {customerParams.keyWord && (
            <Styles.SearchDesc>{`"${customerParams.keyWord}"에 대한 검색결과입니다.`}</Styles.SearchDesc>
          )}
        </Styles.TextRightWrapper>
      </Styles.Search>
    </>
  );
};

export default CustomerSearch;
