import React, { Dispatch, useEffect, useMemo, useState } from 'react';
import { Pet } from '../../../model/pet';
import TabArrowIcon from '../../icons/TabArrowIcon';
import * as Styles from './styles';

interface PetTabsProps {
  pets: Pet[];
  selectedPetId?: number;
  setSelectedPetId: Dispatch<number>;
}

const pageSize = 5;

export default function PetTabs({ pets, selectedPetId, setSelectedPetId }: PetTabsProps) {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pagingPets, setPagingPets] = useState<Pet[]>();
  const totalPage = useMemo(() => Math.ceil(pets.length / pageSize), [pets]);

  const handlePage = (type: string) => {
    if (type === 'prev') {
      setCurrentPage((state) => state - 1);
    }

    if (type === 'next') {
      setCurrentPage((state) => state + 1);
    }
  };

  useEffect(() => {
    if (!pets) return;
    if (pets.length > 0) {
      const startIndex = (currentPage - 1) * 5;
      const endIndex = pageSize * currentPage;
      setPagingPets(pets.slice(startIndex, endIndex));
    }
  }, [pageSize, pets, currentPage]);

  return (
    <Styles.Tabs>
      {currentPage !== 1 && (
        <button type="button" className="arrow" onClick={() => handlePage('prev')} aria-label="Prev Button">
          <TabArrowIcon direction="left" />
        </button>
      )}

      {pagingPets?.map((pet) => (
        <button
          type="button"
          key={pet.id}
          title={pet.name}
          className={pet.id === selectedPetId ? 'active' : ''}
          onClick={() => setSelectedPetId(pet.id)}>
          {pet.name}
        </button>
      ))}

      {currentPage < totalPage && (
        <button type="button" className="arrow" onClick={() => handlePage('next')} aria-label="Next Button">
          <TabArrowIcon direction="right" />
        </button>
      )}
    </Styles.Tabs>
  );
}
