import styled from '@emotion/styled';
import { CORAL, FITPET_BLUE, FITPET_MINT, GREY_2, GREY_4, GREY_5, WHITE } from 'styles/colors';
import { css } from '@emotion/react';
import { ReservationStatusType } from '../../../model/reservation';

export const DetailInfoSection = styled.div`
  width: 100%;
  margin-top: 18px;
  border: none;
  background-color: ${GREY_5};
  padding: 20px;
`;

export const Date = styled.p`
  font-size: 12px;
  line-height: 15px;
  color: ${GREY_2};
  margin-bottom: 20px;
`;

export const Info = styled.p`
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 10px;
`;

export const InfoDesc = styled.span`
  height: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: ${GREY_2};
  word-break: break-all;
  //margin-left: 12px;
`;

export const InfoSection = styled.div`
  padding: 0 36px;
`;

export const BadgeSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Badge = styled.div(
  ({ status }: { status: ReservationStatusType }) => css`
    width: 62px;
    height: 28px;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-style: normal;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: -0.02em;

    font-weight: ${[ReservationStatusType.Applied, ReservationStatusType.Confirmed].includes(status)
      ? 'bold'
      : 'normal'};
    background-color: ${status === ReservationStatusType.NoShow ? CORAL : GREY_4};
    color: ${GREY_2};
    ${status === ReservationStatusType.NoShow &&
    css`
      color: ${WHITE} !important;
    `};
    ${[ReservationStatusType.Applied, ReservationStatusType.Confirmed].includes(status) &&
    css`
      color: ${FITPET_MINT} !important;
    `};
  `,
);

export const BadgeDesc = styled.div(
  ({ status }: { status: ReservationStatusType }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;

    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;

    font-weight: ${[ReservationStatusType.Applied, ReservationStatusType.Confirmed].includes(status)
      ? 'bold'
      : 'normal'};
    color: ${[ReservationStatusType.Applied, ReservationStatusType.Confirmed].includes(status) ? FITPET_MINT : GREY_2};
  `,
);

export const DateClick = styled.span`
  color: ${FITPET_BLUE};
  cursor: pointer;
  font-weight: bold;
`;
