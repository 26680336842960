import styled from '@emotion/styled';
import { AHEAD_MINT, GREY_1, GREY_3 } from 'styles/colors';
import { ContentWrapper } from '../../../../styles/layout';

export const Wrapper = styled.div`
  ${ContentWrapper}
`;

export const Title = styled.p`
  line-height: 17px;
  color: ${GREY_1};
  margin-bottom: 36px;
  font-weight: bold;
`;

export const Title2 = styled.p`
  line-height: 17px;
  color: ${GREY_1};
  margin-top: 55px;
  margin-bottom: 26px;
  font-weight: bold;
`;

export const Title3 = styled.p`
  line-height: 17px;
  color: ${GREY_1};
  margin-top: 55px;
  margin-bottom: 16px;
  font-weight: bold;
`;

export const TypeSection = styled.ul`
  box-sizing: border-box;
`;

export const TypeItem = styled.li`
  height: 42px;
  font-size: 14px;
  line-height: 20px;
  color: ${GREY_1};
  display: inline-block;
  margin-right: 100px;
`;

export const Label = styled.label`
  display: flex;
  cursor: pointer;
`;
export const RadioButton = styled.input`
  width: 18px;
  height: 18px;
  border: 1px solid ${GREY_3};
  border-radius: 50%;
  margin-right: 12px;
  cursor: pointer;

  &:checked {
    border: 6px solid ${AHEAD_MINT};
  }
`;

export const Checked = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-top: 18px;
  margin-bottom: 12px;
  margin-left: 80px;
  span {
    margin-left: 8px;
  }
`;

export const CheckWrapper = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  span {
    margin-left: 8px;
  }
`;

export const NotiText = styled.span`
  line-height: 17px;
  color: ${GREY_1};
  margin-top: 8px;
`;

export const TextInputWrapper = styled.div`
  display: flex;
  align-items: center;
`;
