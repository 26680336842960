import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Styles from './styles';
import useClickOutside from '../../../hooks/dom/useClickOutside';

interface OptionValueProps {
  value: string;
  label: string;
}
interface PetSelectProps {
  value?: OptionValueProps;
  items?: OptionValueProps[];
  onChange: (selectValue: string) => void;
  onAddRequest: () => void;
  disabled?: boolean;
}
export const ADD_NEW_PET: OptionValueProps = {
  value: 'ADD_NEW_PET',
  label: '+ 반려동물 추가',
};
const PetSelect = ({
  onChange,
  onAddRequest,
  value = { value: '', label: '' },
  items = [],
  disabled = false,
}: PetSelectProps) => {
  const [select, setSelect] = useState(value);
  const [active, setActive] = useState(false);

  const toggleActive = useCallback(() => {
    setActive(!active);
  }, [active]);

  const changeValue = useCallback(
    (item) => {
      if (item.value === ADD_NEW_PET.value) {
        console.info('ADD_NEW_PET!');
        addPet();
      } else {
        setSelect(item);
        setActive(false);
        onChange(item.value);
      }

      // onAddRequest(false);
    },
    [active, onChange],
  );
  const addPet = useCallback(() => {
    setSelect(ADD_NEW_PET);
    setActive(!active);
    onChange(ADD_NEW_PET.value);
    onAddRequest();
  }, [active]);

  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => {
    setActive(false);
  });

  useEffect(() => {
    if (items?.length === 2) {
      changeValue(items[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  return (
    <Styles.Wrapper ref={wrapperRef}>
      <Styles.Input
        readOnly
        placeholder="선택해 주세요"
        value={select.value ? select?.label : ''}
        onClick={toggleActive}
        disabled={disabled}
      />
      <Styles.OptionWrapper className={active ? 'active' : ''} optionHeight={250}>
        <ul>
          {items.map((item) => (
            <Styles.Item key={item.value} onClick={() => changeValue(item)}>
              {item.label}
            </Styles.Item>
          ))}
          {/* <Styles.Item onClick={() => addPet()}>+ 반려동물 추가</Styles.Item> */}
        </ul>
      </Styles.OptionWrapper>
    </Styles.Wrapper>
  );
};

export default PetSelect;
