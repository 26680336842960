import React, { useCallback, useMemo, useState } from 'react';
import { ModalHandler } from 'src/utils/ModalHandler';
import { ModalType } from 'src/constants/modal';
import { Operation, OperationFieldKey, OperationMode, OperationWorkDayType } from 'src/model/operations';
import OperationBottom from 'src/components/operation/OperationBottom';
import DateMultiSelection from 'src/components/operation/DateMultiSelection';
import WorkingDay from 'src/components/operation/WorkingDay';
import useOperation from 'src/hooks/operation/useOperation';
import { DEFAULT_TEMP_HOLIDAY_DATES } from 'src/constants/operation';
import { usePutOperationDetail } from 'src/query/Operation/usePutOperationDetail';
import * as Styles from './styles';
import CheckBoxIcon from '../../icons/CheckBoxIcon';
import TextInput from '../../common/TextInput';
import { phoneNumberValidation } from '../../../utils/numberUtils';

interface OperationUpdateProps {
  operationData: Operation;
  mode: OperationMode;
  setMode: React.Dispatch<React.SetStateAction<OperationMode>>;
}

const OperationUpdate = ({ operationData: _operationData, mode, setMode }: OperationUpdateProps) => {
  const [operationData, setOperationData] = useState<Operation>(_operationData);

  const { validateOperationData } = useOperation();

  // 필드 변경 처리
  const setUpdateField = useCallback(
    (key: OperationFieldKey, value: any) => {
      setOperationData((prev) => ({
        ...prev,
        [key]: value,
      }));
    },
    [setOperationData],
  );

  // 등록시 최초 설정된값이 없으면 저장버튼 비활성화 처리
  const isDisabled = useMemo(() => {
    if (operationData) {
      return operationData.workingDays.length < 1 && operationData.closedTimes.length < 1;
    }
    return true;
  }, [operationData]);

  // 운영관리 생성/수정
  const { mutate: updateOperationMutate } = usePutOperationDetail();

  // 병원 운영시간 등록은 idempotent하므로 put만 사용
  const onUpdateOperation = useCallback(async () => {
    await updateOperationMutate(operationData);
  }, [operationData, updateOperationMutate]);

  const onSaveConfirm = useCallback(() => {
    if (validateOperationData(operationData)) {
      ModalHandler.show(ModalType.Alert, {
        title: '저장하시겠습니까?',
        confirmText: '저장',
        cancelText: '취소',
        onConfirm: onUpdateOperation,
      });
    }
  }, [onUpdateOperation, operationData, validateOperationData]);

  const onClickUpdate = useCallback(() => {
    if (validateOperationData(operationData)) {
      if (operationData.isAlimtalk && !phoneNumberValidation(operationData?.alimtalkNumber || '')) {
        ModalHandler.show(ModalType.Toast, {
          ToastMessage: '휴대폰 번호를 확인해주세요',
          duration: 300,
        });
      } else {
        ModalHandler.show(ModalType.Alert, {
          title: '수정하시겠습니까?',
          confirmText: '수정',
          cancelText: '취소',
          onConfirm: onUpdateOperation,
        });
      }
    }
  }, [onUpdateOperation, operationData, validateOperationData]);

  // 상세 화면으로 이동
  const onClickBack = useCallback(() => {
    setMode(OperationMode.DETAIL);
  }, [setMode]);

  return (
    <Styles.Wrapper>
      {operationData && (
        <>
          <Styles.Title>운영 요일 및 시간을 설정해 주세요.</Styles.Title>
          <WorkingDay
            workingDay={operationData.workingDays}
            onChangeDay={(days: OperationWorkDayType[]) => setUpdateField(OperationFieldKey.WorkingDays, days)}
          />

          <Styles.Title2>특정 / 임시 휴무일을 설정해 주세요. (최대 5건)</Styles.Title2>
          <DateMultiSelection
            dates={operationData.closedTimes || DEFAULT_TEMP_HOLIDAY_DATES}
            onChangeField={setUpdateField}
          />
          <Styles.Title3>고객 예약 시 카카오 알림을 받을 휴대폰 번호를 입력해주세요.</Styles.Title3>
          <Styles.CheckWrapper
            role="button"
            onClick={() => {
              setUpdateField(OperationFieldKey.IsAlimtalk, !operationData.isAlimtalk);
            }}>
            <CheckBoxIcon checked={!operationData.isAlimtalk} />
            <span>사용안함</span>
          </Styles.CheckWrapper>
          <Styles.TextInputWrapper>
            <TextInput<string>
              width={350}
              value={operationData.alimtalkNumber || ''}
              onChangeField={(label, value) => {
                setUpdateField(OperationFieldKey.AlimtalkNumber, value);
              }}
              label="alimtalkNumber"
              placeholder={`'-' 없이 숫자만 입력하세요.`}
              limit={11}
              disabled={!operationData.isAlimtalk}
            />
          </Styles.TextInputWrapper>
          <Styles.NotiText>* 번호 입력 후 저장 시 입력한 번호로 알림톡이 발송됩니다.</Styles.NotiText>
        </>
      )}

      {mode === OperationMode.ADD ? (
        <OperationBottom onSaveHandler={onSaveConfirm} disabled={isDisabled} />
      ) : (
        <OperationBottom onCancelHandler={onClickBack} onSaveHandler={onClickUpdate} />
      )}
    </Styles.Wrapper>
  );
};

// const TodayReservation = ({
//   todayReservationAvailable,
//   setUpdateField,
// }: {
//   todayReservationAvailable?: boolean;
//   setUpdateField: Function;
// }) => {
//   return (
//     <>
//       <Styles.Title2>예약가능 유형을 선택해 주세요.</Styles.Title2>
//       <Styles.TypeSection>
//         <Styles.TypeItem>
//           <Styles.Label htmlFor="radio-button1">
//             <Styles.RadioButton
//               id="radio-button1"
//               type="radio"
//               name="todayReservationAvailable"
//               checked={todayReservationAvailable}
//               onChange={() => setUpdateField(OperationFieldKey.TodayReservationAvailable, true)}
//             />
//             당일예약 가능
//           </Styles.Label>
//         </Styles.TypeItem>
//         <Styles.TypeItem>
//           <Styles.Label htmlFor="radio-button2">
//             <Styles.RadioButton
//               id="radio-button2"
//               type="radio"
//               name="todayReservationAvailable"
//               checked={!todayReservationAvailable}
//               onChange={() => setUpdateField(OperationFieldKey.TodayReservationAvailable, false)}
//             />
//             당일예약 불가능
//           </Styles.Label>
//         </Styles.TypeItem>
//       </Styles.TypeSection>
//     </>
//   )
// }

// const OperationLunch = ({
//   startTime,
//   endTime,
//   setUpdateField,
// }: {
//   startTime: string;
//   endTime: string;
//   setUpdateField: Function;
// }) => {
//   return (
//     <>
//       <Styles.Title2>점심 시간을 설정해 주세요.</Styles.Title2>
//       <TimeSelection title="점심" startTime={startTime} endTime={endTime} onChangeField={setUpdateField} />
//     </>
//   );
// };

export default OperationUpdate;
