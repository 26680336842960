import React, { useState } from 'react';
import moment from 'moment';
import { DisplayDateTimeFormat } from 'src/constants/workHours';
import { CustomerFieldKey, customerHeaders } from '../../../model/customer';
import type { ICustomerHeader, ICustomer } from '../../../model/customer';
import CustomerDetail from '../CustomerDetail';
import * as Styles from './styles';

interface ICustomerListRowProps {
  item: ICustomer;
}
export default function CustomerListRow({ item }: ICustomerListRowProps) {
  const [visible, setVisible] = useState<boolean>(false);

  const openDetail = () => {
    if (visible) {
      setVisible(false);
      return;
    }

    setVisible((state) => !state);
  };

  return (
    <>
      <Styles.Tr className={visible ? 'active' : ''}>
        {customerHeaders.map((header: ICustomerHeader) => (
          <Styles.Td key={header.name}>
            {(() => {
              switch (header.value) {
                case CustomerFieldKey.Name:
                  return item.userName;
                case CustomerFieldKey.Tel:
                  return item.tel ? item.tel.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`) : '번호 없음';
                case CustomerFieldKey.IsMember:
                  return item.userType === 'U' ? 'Y' : 'N';
                case CustomerFieldKey.Visit:
                  return item.visitedCount;
                case CustomerFieldKey.Cancel:
                  return item.cancelledCount;
                case CustomerFieldKey.NoShow:
                  return item.noShowCount;
                case CustomerFieldKey.Date:
                  return moment(item.lastReservation).locale('ko').format(DisplayDateTimeFormat);
                case CustomerFieldKey.Buttons:
                  return (
                    <Styles.Button type="button" onClick={openDetail} active={visible}>
                      상세 {visible ? '닫기' : '보기'}
                    </Styles.Button>
                  );

                default:
                  return null;
              }
            })()}
          </Styles.Td>
        ))}
      </Styles.Tr>
      {visible && (
        <Styles.Tr className="detail">
          <Styles.TdDetail colSpan={8}>
            <CustomerDetail customer={item} />
          </Styles.TdDetail>
        </Styles.Tr>
      )}
    </>
  );
}
