import moment from 'moment';
import { DateFormat, DateTimeFormat } from '../constants/workHours';

const WEEKDAY = ['일', '월', '화', '수', '목', '금', '토'];

export const getDayOfWeek = (day: number) => WEEKDAY[day];

export const string2date = (day: string, format: string) => {
  if (day && format) {
    return moment(day, format).toDate();
  }
  return '';
};

export const addMinute2strTime = (time: string, minute: number = 0) => {
  try {
    if (moment(time, 'HH:mm', true).isValid()) {
      return moment(time, 'HH:mm').add(minute, 'minutes').format('HH:mm');
    }
    return time;
  } catch (error) {
    return time;
  }
};

export const strDatetime2strDate = (dateTime: string) => {
  if (!dateTime) return '';
  return moment(dateTime).format(DateFormat);
};

export const strDate2strDatetime = (date: string) => {
  if (!date) return '';
  return moment(date).format(DateTimeFormat);
};

export const getStrDateInRange = (start: string, end: string) => {
  const arr = [];
  for (const date = new Date(start); date < new Date(end); date.setDate(date.getDate() + 1)) {
    arr.push(moment(date).format(DateFormat));
  }
  return arr;
};
