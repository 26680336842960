import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BasicButton } from 'src/components/common/buttons/BasicButton';
import { Reservation, ReservationDetail, ReservationFieldKey } from 'src/model/reservation';
import moment from 'moment';
import * as Styles from './styles';
import { ModalHandler } from '../../../utils/ModalHandler';
import { ModalType } from '../../../constants/modal';
import { useGetReservationDetail, usePutReservationUpdate } from '../../../query/Reservation/useReservationQuery';
import ReservationDetailCard from '../ReservationDetailCard';
import TextInputCalendar from '../../common/TextInputCalendar';
import { useAvailableDays, useAvailableHours, useGetMemberList } from '../../../query/Member/useMemberQuery';
import BasicTextArea from '../../common/Textarea/BasicTextArea';
import SelectBox, { OptionValueProps } from '../../common/SelectBox';
import useKeydownEsc from '../../../hooks/dom/useKeydownEsc';

const ReservationUpdateModal = ({ reservation }: { reservation: Reservation }) => {
  const { data: reservationDetail } = useGetReservationDetail(reservation.id, reservation.userType);
  const [params, setParams] = useState<ReservationDetail>();
  const { mutate } = usePutReservationUpdate();

  const { data: members } = useGetMemberList();

  const memberList = useMemo(() => {
    const arr = members
      ? members.map((member) => {
          return {
            value: String(member.id),
            label: member.name,
          };
        })
      : [];

    return arr as OptionValueProps[];
  }, [members]);

  const selectMember = useMemo(() => {
    const item = memberList.find((op) => op.value === String(params?.hospitalMember.id));
    return item || memberList[0];
  }, [params?.hospitalMember.id, memberList]);

  const selectDate = useMemo(
    () => (params?.reservationTime ? params.reservationTime.split('T')[0] : ''),
    [params?.reservationTime],
  );

  // 예약 가능한 시간 목록
  const { data: availableHours } = useAvailableHours(
    params?.hospitalMember.id ? String(params?.hospitalMember.id) : '',
    selectDate,
  );
  const availableHourList = useMemo(() => {
    if (availableHours) {
      return availableHours.map(({ time, isAvailable }) => {
        return {
          value: time,
          label: time,
          isAvailable,
        };
      }) as OptionValueProps[];
    }
    return [];
  }, [availableHours]);
  const validTime = useMemo(
    () => availableHourList.filter((option) => option.isAvailable).length > 0,
    [availableHourList],
  );
  // 예약 가능한 날짜 목록
  const [calendarMonth, setCalendarMonth] = useState(moment(params?.reservationTime).format('YYYY-MM'));

  const { data: dayResponse, isLoading: dayLoading } = useAvailableDays(
    params?.hospitalMember.id ? params?.hospitalMember.id : -1,
    calendarMonth,
  );
  const onChangeMonthCalendar = useCallback((yyyyMM) => {
    setCalendarMonth(yyyyMM);
  }, []);
  const availableDays = useMemo(() => {
    if (!dayLoading) {
      // console.info('dayResponse', dayResponse);
      return dayResponse;
    }
    // 기본 병원운영시간
    return [];
  }, [dayResponse, dayLoading]);

  const selectTime = useMemo(() => {
    const defaultValue = availableHourList[0] || { value: '', label: '' };
    if (params?.reservationTime) {
      const time = params?.reservationTime.split('T')[1].substr(0, 5);
      return time ? { value: time, label: time } : defaultValue;
    }

    return defaultValue;
  }, [availableHourList, params?.reservationTime]);

  useEffect(() => {
    if (reservationDetail) {
      setParams(reservationDetail);
    }
  }, [reservationDetail]);

  useEffect(() => {
    if (params?.reservationTime) {
      setCalendarMonth(moment(params?.reservationTime).format('YYYY-MM'));
    }
  }, [params?.reservationTime]);

  // 예약 변경 클릭
  const onClickChange = useCallback(() => {
    // validaion 시간, 변경 사유
    let ToastMessage = '';
    const isNoneTime = selectTime.value.trim().length === 0;

    const reason = params?.reason;
    if (!selectTime.value || isNoneTime || !validTime) {
      ToastMessage = `예약 가능한 시간을 선택해주세요.`;
    } else if (!reason) {
      ToastMessage = `변경 사유를 입력해주세요.`;
    } else if (moment(params?.reservationTime).isBefore(moment())) {
      ToastMessage = `예약 시간은 현재시간보다\n이후여야 합니다.`;
    }

    if (ToastMessage) {
      ModalHandler.show(ModalType.Toast, {
        ToastMessage,
      });
      return;
    }

    ModalHandler.show(ModalType.Alert, {
      title: '고객님이 신청한 예약을 변경하나요?',
      confirmText: '예',
      cancelText: '아니오',
      onConfirm: async () => {
        if (params) {
          await mutate(params);

          onClickClose();
        }
      },
    });
  }, [mutate, params, selectTime.value, validTime]);

  // 멤버, 날짜, 시간, 변경 사유 변경시 처리
  const updateField = useCallback(
    (key: ReservationFieldKey, value: any) => {
      if (key === ReservationFieldKey.HospitalMember) {
        const hospitalMember = members?.find((member) => String(member.id) === value);

        setParams((prev: any) => ({
          ...prev,
          hospitalMember,
        }));
      } else if (key === ReservationFieldKey.ReservationDate) {
        setParams((prev: any) => ({
          ...prev,
          reservationTime: `${value}T `,
        }));
      } else if (key === ReservationFieldKey.ReservationTime) {
        setParams((prev: any) => ({
          ...prev,
          reservationTime: `${prev.reservationTime.substr(0, 10)}T${value}`,
        }));
      } else {
        setParams((prev: any) => ({
          ...prev,
          [key]: value,
        }));
      }
    },
    [members],
  );

  const onClickClose = () => {
    ModalHandler.hide(ModalType.Detail);
  };

  const wrapperRef = useRef(null);
  useKeydownEsc(wrapperRef, () => {
    onClickClose();
  });

  return (
    <Styles.Wrapper ref={wrapperRef}>
      {params && (
        <>
          <Styles.ScrollContent>
            {reservationDetail && <ReservationDetailCard reservationDetail={reservationDetail} />}

            <Styles.Dash />

            <Styles.UpdateWrapper>
              <Styles.RowDiv>
                <Styles.Label>담당자 지정</Styles.Label>
                {memberList.length && (
                  <SelectBox
                    items={memberList || []}
                    width={160}
                    height={48}
                    optionHeight={199}
                    value={selectMember}
                    onChange={(value) => updateField(ReservationFieldKey.HospitalMember, value)}
                  />
                )}
              </Styles.RowDiv>
              <Styles.RowDiv>
                <Styles.Label>방문 희망 일시</Styles.Label>
                <Styles.DateWrapper>
                  {params?.reservationTime && (
                    <TextInputCalendar
                      enablePrevDate={false}
                      selectDate={selectDate}
                      onChange={(date: string) => updateField(ReservationFieldKey.ReservationDate, date)}
                      positionX="-100"
                      positionY="-145"
                      onChangeMonth={(yyyyMM) => onChangeMonthCalendar(yyyyMM)}
                      availableDate={availableDays}
                    />
                  )}
                  <Styles.MarginDiv />
                  {params?.reservationTime && (
                    <SelectBox
                      items={availableHourList || []}
                      width={140}
                      height={48}
                      optionHeight={300}
                      value={selectTime}
                      top
                      disabled={!validTime}
                      onChange={(value) => updateField(ReservationFieldKey.ReservationTime, value)}
                    />
                  )}
                </Styles.DateWrapper>
              </Styles.RowDiv>
              <Styles.RowDiv>
                <Styles.Label>변경 사유</Styles.Label>
                <BasicTextArea
                  placeholder="자유롭게 작성해 주세요."
                  value={params.reason || ''}
                  onChange={(str) => updateField(ReservationFieldKey.Reason, str)}
                  width={308}
                  height={100}
                  size={60}
                />
              </Styles.RowDiv>
            </Styles.UpdateWrapper>
          </Styles.ScrollContent>
          <Styles.BottomButton>
            <BasicButton width={100} onClick={onClickChange}>
              예약 변경
            </BasicButton>
          </Styles.BottomButton>
        </>
      )}
    </Styles.Wrapper>
  );
};

export default ReservationUpdateModal;
