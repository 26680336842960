import React, { useCallback } from 'react';
import _ from 'lodash';
import { dayMap, DayType, End24Time, StartTime } from 'src/constants/workHours';
import { Operation, OperationMode } from 'src/model/operations';
import { BasicButton } from 'src/components/common/buttons/BasicButton';
import * as Styles from './styles';
import useOperation from '../../../hooks/operation/useOperation';

interface OperationDetailProps {
  operationData: Operation;
  setMode: React.Dispatch<React.SetStateAction<OperationMode>>;
}

const OperationDetail = ({ operationData, setMode }: OperationDetailProps) => {
  const { convertClosedDateTimes2CloseDates } = useOperation();
  const { workingDays, closedTimes: tempClosedTimes } = operationData;
  const closedTimes = convertClosedDateTimes2CloseDates(tempClosedTimes);

  const getWorkTimeText = (day: string) => {
    if (!_.isEmpty(workingDays)) {
      const workDay = workingDays.find((d) => d.workDay === day);
      if (workDay) {
        if (workDay.workTimeStart === StartTime && workDay.workTimeEnd === End24Time) {
          return '24시간 운영';
        }
        return `${workDay.workTimeStart} ~ ${workDay.workTimeEnd}`;
      }
      return '휴무';
    }
    return '휴무';
  };

  const getAlimtalkNumber = useCallback(() => {
    if (operationData?.isAlimtalk) {
      if (operationData?.alimtalkNumber) {
        return operationData?.alimtalkNumber;
      }
      return '휴대폰 번호 미입력';
    }
    return '알림톡 미사용';
  }, [operationData?.alimtalkNumber, operationData?.isAlimtalk]);

  return (
    <Styles.Wrapper>
      <Styles.Title>우리 동물병원의 운영 정보</Styles.Title>

      <Styles.Table>
        <tbody>
          <Styles.Tr>
            <Styles.Th>운영 시간</Styles.Th>
            <Styles.Td>
              {Object.keys(dayMap).map((day) => (
                <Styles.TdText key={day}>
                  <span className="day">{dayMap[day as DayType]}</span>
                  <span className="time">{getWorkTimeText(day)}</span>
                </Styles.TdText>
              ))}
            </Styles.Td>
          </Styles.Tr>

          <Styles.Tr>
            <Styles.Th>임시 휴무일</Styles.Th>
            <Styles.Td>
              {closedTimes.map((closedTime) => (
                <Styles.TdText key={`${closedTime.closedTimeStart}-${closedTime.closedTimeEnd}`}>
                  {`${closedTime.closedTimeStart} ~ ${closedTime.closedTimeEnd}`}
                </Styles.TdText>
              ))}
              {closedTimes.length < 1 && '설정하지 않음'}
            </Styles.Td>
          </Styles.Tr>
          <Styles.Tr>
            <Styles.Th>알림톡 번호</Styles.Th>
            <Styles.Td>
              <Styles.TdText>{getAlimtalkNumber()}</Styles.TdText>
            </Styles.Td>
          </Styles.Tr>
        </tbody>
      </Styles.Table>
      <Styles.NotiText>* 알림톡 번호는 예약 알림을 위한 용도이며, 앱에서 노출되지 않습니다.</Styles.NotiText>

      <Styles.BottomWrapper>
        <BasicButton type="button" onClick={() => setMode(OperationMode.UPDATE)}>
          변경하러 가기
        </BasicButton>
      </Styles.BottomWrapper>
    </Styles.Wrapper>
  );
};

export default OperationDetail;
