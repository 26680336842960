import styled from '@emotion/styled';
import { BasicButton } from 'src/components/common/buttons/BasicButton';
import { GREY_1, GREY_3, GREY_4, GREY_5, WHITE } from 'styles/colors';
import { ZIndex } from 'styles/zIndex';

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 0 0 0;
  position: relative;
`;

export const ScrollContent = styled.div`
  background: ${WHITE};

  &::-webkit-scrollbar {
    width: 10px;
    background: ${GREY_5};
  }

  &::-webkit-scrollbar-thumb {
    width: 6px;
    height: 276px;
    border-radius: 30px;
    background-color: ${GREY_3};
  }
`;

export const Dash = styled.div`
  margin: 40px 0;
  border: 1px dashed ${GREY_3};
`;

export const CalendarWrapper = styled.div`
  margin-top: 56px;
`;

export const BottomButton = styled.div`
  display: flex;
  justify-content: right;
  padding: 24px 36px;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  background: ${WHITE};
  height: 92px;
  width: 100%;

  box-shadow: 0px -7px 12px rgba(231, 231, 231, 0.3);

  z-index: ${ZIndex.ModalBottom};
  //box-shadow: 0px -7px 12px rgba(231, 231, 231, 0.3);

  button + button {
    margin-left: 4px;
  }
`;

export const Button = styled(BasicButton)`
  border: 1px solid ${GREY_3};
  color: ${GREY_1};
  background-color: ${WHITE};
  font-weight: normal;
`;

export const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
`;

export const Label = styled.span`
  height: 16px;
  width: 105px;
  //margin-right: 32px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: ${GREY_1};
`;

export const DateWrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const MarginDiv = styled.div`
  width: 8px;
`;

export const UpdateWrapper = styled.div`
  padding: 0px 36px 24px 36px;
`;

export const ContentWrapper = styled.div`
  position: relative;
  width: 308px;
  height: 100px;
  border: 1px solid ${GREY_4};
  padding: 12px 12px 8px 12px;
`;
