import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WorkingDayType } from '../../model/workingDay';
import type { Holiday } from '../../model/calendar';

export interface AccountState {
  hospitalId: number;
  hospital: HospitalDto;
  availableHours: string[];
  holiday: Holiday[];
}

const initHospital = {
  name: '',
  isReservationAvailable: true,
  addr: '',
  specialtyTags: [],
  serviceTags: [],
  workingDay: [],
  holidayDay: '',
  isParking: false,
  tel: '',
};

const initialState: AccountState = {
  hospitalId: 0,
  hospital: initHospital,
  availableHours: [],
  holiday: [],
};

const AccountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    updateHospitalId: (state, action: PayloadAction<number>) => {
      state.hospitalId = action.payload;
    },
    updateHospitalInfo: (state, action: PayloadAction<HospitalDto>) => {
      state.hospital = { ...initialState.hospital, ...action.payload };
    },
    setHospitalHoliday: (state, action: PayloadAction<Holiday[]>) => {
      state.holiday = action.payload;
      return state;
    },
    resetHospitalInfo: (state) => {
      state.hospitalId = 0;
      state.hospital = initHospital;
      state.availableHours = [];
    },
  },
});

export const { actions: accountAction } = AccountSlice;
export default AccountSlice;

export interface HospitalDto {
  // id: number;
  name: string;
  isReservationAvailable: boolean;
  addr: string;
  specialtyTags: string[];
  serviceTags: string[];
  workingDay: WorkingDayType[];
  holidayDay: string;
  isParking: boolean;
  tel: string;
}
