import React from 'react';

const DefaultDogIcon = () => (
  <svg width="89" height="89" viewBox="0 0 89 89" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="44.5" cy="44.5" r="44" fill="#F8F8F8" stroke="#EFEFEF" />
    <path
      d="M43.8986 53.0754C40.9762 53.0754 38.3945 53.9074 38.3945 55.3917C38.3945 57.603 41.792 60.4141 43.8986 60.4141C46.0052 60.4141 49.4026 57.603 49.4026 55.3917C49.4026 53.9074 46.821 53.0754 43.8986 53.0754Z"
      fill="#E0E0E0"
    />
    <circle cx="32.8901" cy="43.9021" r="3.66936" fill="#E0E0E0" />
    <circle cx="54.9062" cy="43.9017" r="3.66936" fill="#E0E0E0" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.8975 54.9099C44.3028 54.9099 44.6313 55.2385 44.6313 55.6438L44.6313 65.1841C44.6313 65.5894 44.3028 65.918 43.8975 65.918C43.4922 65.918 43.1636 65.5894 43.1636 65.1841L43.1636 55.6438C43.1636 55.2385 43.4922 54.9099 43.8975 54.9099Z"
      fill="#E0E0E0"
    />
    <path
      d="M70.511 44.192C70.138 38.9475 65.2521 19.8099 54.7343 23.1565C52.2626 21.3876 47.7017 20.9286 45.4882 20.8091C45.3878 20.8043 45.2874 20.7995 45.187 20.7947C44.5846 20.7661 44.2165 20.7661 44.2165 20.7661C44.2165 20.7661 43.8484 20.7661 43.246 20.7947C43.1456 20.7995 43.0452 20.8043 42.9448 20.8091C40.7313 20.9286 36.1704 21.3876 33.6987 23.1565C23.1809 19.8099 18.2949 38.9475 17.922 44.192C17.444 50.8852 18.2328 55.3648 22.7268 56.6126C23.1044 56.7177 23.4869 56.7942 23.8646 56.842C27.4024 65.3519 35.0087 71.2753 43.8579 71.4331V71.4426C43.9249 71.4426 43.987 71.4379 44.0492 71.4379C44.1065 71.4379 44.1591 71.4426 44.2165 71.4426C44.2739 71.4426 44.3265 71.4379 44.3838 71.4379C44.4508 71.4379 44.5129 71.4426 44.5751 71.4426V71.4331C53.4243 71.2753 61.0306 65.3519 64.5684 56.842C64.9461 56.7942 65.3286 56.7177 65.7062 56.6126C70.2002 55.3648 70.989 50.8852 70.511 44.192Z"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
);
export default DefaultDogIcon;
