import React from 'react';
import * as Styles from './styles';

interface EmptyListProps {
  colSpan: number;
}
const EmptyList = ({ colSpan }: EmptyListProps) => {
  return (
    <tbody>
      <Styles.Tr>
        <Styles.Td colSpan={colSpan}>검색 결과가 없습니다.</Styles.Td>
      </Styles.Tr>
    </tbody>
  );
};

export default EmptyList;
