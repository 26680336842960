import React from 'react';

const SearchIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5 11.0996C18.5 14.9656 15.366 18.0996 11.5 18.0996C7.63401 18.0996 4.5 14.9656 4.5 11.0996C4.5 7.23362 7.63401 4.09961 11.5 4.09961C15.366 4.09961 18.5 7.23362 18.5 11.0996ZM16.592 17.9062C15.1728 18.9697 13.4099 19.5996 11.5 19.5996C6.80558 19.5996 3 15.794 3 11.0996C3 6.40519 6.80558 2.59961 11.5 2.59961C16.1944 2.59961 20 6.40519 20 11.0996C20 13.3434 19.1306 15.3841 17.7103 16.9032L20.8157 20.0087C21.1086 20.3016 21.1086 20.7764 20.8157 21.0693C20.5228 21.3622 20.0479 21.3622 19.755 21.0693L16.592 17.9062Z"
      fill="#434343"
    />
  </svg>
);

export default SearchIcon;
