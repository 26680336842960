import { Pet } from './pet';

export enum UserType {
  /** 회원 */
  USER = 'U',
  /** 가회원 */
  NON_USER = 'N',
}

export const UserTypeToUrl: Partial<Record<UserType, string>> = {
  [UserType.USER]: 'user',
  [UserType.NON_USER]: 'non-user',
};

export interface UserDto {
  id: number;
  // (description = "비회원 이름")
  name: string;
  // (description = "비회원 전화번호")
  tel: string;
  // (description = "비회원 생년월일")
  birthday: string;
}

// 예약생성 - 전화번호 검색시 사용
export interface UserInfoDto extends UserDto {
  pets: Pet[];
  userType: UserType;
}
