import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { GREY_1, GREY_2, GREY_4, WHITE } from 'styles/colors';

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

export const TabMenuBar = styled.ul`
  width: 100%;
  list-style: none;
  display: flex;
  align-items: center;
`;

export const TabMenuItem = styled.li(
  ({ isActive }: { isActive: boolean }) => css`
    cursor: pointer;
    background-color: ${isActive ? WHITE : GREY_4};
    font-weight: ${isActive ? 'bold' : 'normal'};
    color: ${isActive ? GREY_1 : GREY_2};
    box-sizing: border-box;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 136px;
    height: 54px;
    margin-right: 4px;
  `,
);
