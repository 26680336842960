// import {
//   Reservation,
//   ReservationChangeParams,
//   ReservationHoldParams,
//   ReservationsParams,
//   ReservationStatusChangeParams,
//   ReservationType,
//   ResponsePatchReservation,
//   ResponseReservations,
// } from 'src/model/reservation';
// import { BASE_URL, HTTP, USE_MOCK_API } from 'src/utils/api/http';
//
// const HOST_URL = () => (USE_MOCK_API ? 'http://localhost:7001' : BASE_URL);
//
// export const ReservationApi = {
//   getReservations: async (params: ReservationsParams) => {
//     const response = await HTTP.get<ResponseReservations>(`${HOST_URL()}/api/hospital/{hospitalId}/reservations`, {
//       params,
//     });
//     return response.data;
//   },
//
//   getReservation: async ({ id, status }: ReservationStatusChangeParams) => {
//     const response = await HTTP.get<Reservation>(
//       `${BASE_URL}/api/hospital-reservation/reservations/${id}?status=${status}`,
//     );
//     return response.data;
//   },
//
//   patchReservationStatusChange: async ({ id, status }: ReservationStatusChangeParams) => {
//     let urlStatus = status === ReservationType.Confirmed ? 'confirm' : 'visit';
//     if (status === ReservationType.NoShow) {
//       urlStatus = 'no-show';
//     }
//
//     const response = await HTTP.patch<null, ResponsePatchReservation>(
//       `${BASE_URL}/api/hospital-reservation/reservations/${id}/${urlStatus}`,
//       null,
//     );
//
//     return response.data;
//   },
//
//   patchReservationChange: async ({ id, ...params }: ReservationChangeParams) => {
//     const response = await HTTP.patch<ReservationChangeParams, ResponsePatchReservation>(
//       `${BASE_URL}/api/hospital-reservation/reservations/${id}/change`,
//       {
//         ...params,
//       },
//     );
//
//     return response.data;
//   },
//
//   patchReservationHold: async ({ id, ...params }: ReservationHoldParams) => {
//     const response = await HTTP.patch<ReservationHoldParams, ResponsePatchReservation>(
//       `${BASE_URL}/api/hospital-reservation/reservations/${id}/hold`,
//       {
//         ...params,
//       },
//     );
//
//     return response.data;
//   },
// };

import { BASE_URL, HTTP } from '../utils/api/http';

export const ReservationApi = {
  getNewReservation: async (hospitalId: number) => {
    const response = await HTTP.get(`${BASE_URL}/api/hospitals/${hospitalId}/reservations/deferred-reservation `);
    return response.data;
  },
};
