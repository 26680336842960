import { MutableRefObject, useEffect } from 'react';

const useKeydownEsc = (ref: MutableRefObject<any>, callBackFn: Function) => {
  useEffect(() => {
    function handleKeydownEsc(event: KeyboardEvent) {
      if (ref.current && !ref.current.contains(event.target) && event.key === 'Escape') {
        callBackFn();
      }
    }

    // Bind the event listener
    document.addEventListener('keydown', handleKeydownEsc);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keydown', handleKeydownEsc);
    };
  }, [ref]);
};

export default useKeydownEsc;
