import React from 'react';
import { BarItemProps, ResponsiveBar } from '@nivo/bar';
import { ICustomer } from 'src/model/customer';
import * as CustomerStyles from '../styles';

const CustomBarComponent = ({
  bar: { x, width, height, color, data },
}: BarItemProps<{ type: string; value: number }>) => {
  return (
    <g>
      <text x={x + width / 2.3} y={-10} style={{ fontSize: '10px', lineHeight: '12px' }}>
        {data.value}회
      </text>
      <rect x={width / 1.7} width={16} height={height} fill={color} />
    </g>
  );
};

interface ICustomerMedicalServiceHistoryProps {
  customer: ICustomer;
}

export default function CustomerMedicalServiceHistory({ customer }: ICustomerMedicalServiceHistoryProps) {
  const medicalData = [
    { type: '일반진료', value: customer.diagnosisCount || 0 },
    { type: '예방접종', value: customer.vaccinationCount || 0 },
    { type: '건강검진', value: customer.healthScreeningCount || 0 },
    { type: '종합백신', value: customer.comprehensiveVaccinationCount || 0 },
    { type: '심장사상충', value: customer.heartwormPreventionCount || 0 },
    { type: '광견병접종', value: customer.rabiesPreventionCount || 0 },
    { type: '구충', value: customer.dewormingCount || 0 },
    { type: '기타백신', value: customer.otherVaccinesCount || 0 },
    { type: '상담진료', value: customer.consultingCount || 0 },
  ];
  const totalCount = medicalData.reduce((sum, item) => sum + item.value, 0);

  return (
    <CustomerStyles.Article>
      <h3>진료 서비스 내역</h3>
      <p>전체 진료 서비스 내역 총 {totalCount}회</p>
      <div style={{ height: 190 }}>
        <ResponsiveBar
          data={medicalData}
          tooltip={() => null}
          keys={['value']}
          indexBy="type"
          margin={{ top: 20, bottom: 40 }}
          colors="#868E96"
          enableGridY={false}
          label=""
          barComponent={CustomBarComponent}
          layers={['axes', 'bars', 'markers', 'legends', 'annotations']}
          axisBottom={{ tickSize: 0 }}
          axisLeft={null}
          theme={{ axis: { domain: { line: { stroke: '#DEE2E6', strokeWidth: 1 } } } }}
        />
      </div>
    </CustomerStyles.Article>
  );
}
