export enum PetType {
  DOG = 'D',
  CAT = 'C',
}

export enum NeutralizationType {
  F = 'false',
  T = 'true',
}

export const PetTypeToString: Partial<Record<PetType, string>> = {
  [PetType.DOG]: '강아지',
  [PetType.CAT]: '고양이',
};

export const PetGenderToString = {
  UNKNOWN: '알 수 없음',
  M: '수컷',
  F: '암컷',
} as const;

export type TPetGender = keyof typeof PetGenderToString;

export interface Pet {
  /** 펫 ID */
  id: number;
  /** 유저 ID */
  userId: number;
  /** 펫 이름 */
  name: string;
  /** 펫 생년월일 */
  birthday: string;
  /** 펫 몸무게 */
  weight: string;
  /** 펫 성별 */
  gender: TPetGender;
  /** 펫 타입 */
  petType: PetType;
  /** 펫 종류 (코드) */
  species: string;
  /** 펫 종류 (한글 라벨) */
  petBreedName: string;
  /** 펫 중성화 여부 */
  isNeutralized: boolean;
  /** 펫 이미지 url */
  petImageUrl?: string;
  /** 펫 타입 */
  // type: PetType;
}

export interface PetRegisterDto {
  petName: string;
  petType: PetType;
  breedCode: string;
  breedName: string;
  birthday: string;
  gender: TPetGender;
  weight: number;
  neutralization: boolean;
  userId: number;
}

export interface PetBreeds {
  breedCode: string;
  petType: PetType;
  breedName: string;
}

export enum PetGender {
  MALE = 'male',
  FEMALE = 'female',
}
