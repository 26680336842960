import React, { useCallback, useMemo, useRef, useState } from 'react';
import { BasicButton } from 'src/components/common/buttons/BasicButton';
import * as Styles from './styles';
import { Reservation, ReservationStatusChangeParams, ReservationStatusType } from '../../../model/reservation';
import { usePutReservationStatusChange } from '../../../query/Reservation/useReservationQuery';
import { ModalHandler } from '../../../utils/ModalHandler';
import { ModalType } from '../../../constants/modal';
import BasicTextArea from '../../common/Textarea/BasicTextArea';
import useKeydownEsc from '../../../hooks/dom/useKeydownEsc';

const reasonList = ['보호자 요청', '병원 휴진 / 휴가', '병원 식사 시간', '진료 종료', '기타 병원 사정'];

const ReservationRejectModal = ({ reservation }: { reservation: Reservation }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { mutate } = usePutReservationStatusChange();
  const [params, setParams] = useState<ReservationStatusChangeParams>({
    id: reservation.id,
    serviceStatus: ReservationStatusType.Cancelled,
    reason: reasonList[0],
    message: '',
    userType: reservation.userType,
  });
  const isDisabled = useMemo(() => params.reason !== reasonList[4], [params.reason]);

  /** 라디오 변경 */
  const setUpdateRadio = useCallback((reason: string) => {
    setParams((prev) => ({ ...prev, reason, message: '' }));
  }, []);

  /** 안내 메세지 변경 */
  const setMessage = useCallback((message: string) => {
    setParams((prev) => ({ ...prev, message }));
  }, []);

  /** 예약 보류 클릭 */
  const onClickReject = useCallback(() => {
    if (!isDisabled && !params.message) {
      ModalHandler.show(ModalType.Toast, { ToastMessage: `예약취소 사유를 작성해 주세요.` });
      return;
    }

    ModalHandler.show(ModalType.Alert, {
      title: '고객님이 신청한 예약을 취소할까요?',
      confirmText: '예',
      cancelText: '아니오',
      onConfirm: async () => {
        const tempParams = { ...params };

        if (!isDisabled) {
          tempParams.reason = tempParams.message;
        }

        delete tempParams.message;

        mutate(tempParams);
        onClickClose();
      },
    });
  }, [isDisabled, mutate, params]);

  /** 돌아가기 버튼 */
  const onClickClose = () => {
    ModalHandler.hide(ModalType.Detail);
  };

  useKeydownEsc(wrapperRef, () => {
    onClickClose();
  });

  return (
    <Styles.Wrapper ref={wrapperRef}>
      <Styles.Title>예약취소 사유 선택</Styles.Title>
      <Styles.ReasonSection>
        {reasonList.map((reason, index) => (
          <Styles.ReasonItem key={reason}>
            <Styles.Label htmlFor={`reason${index}`}>
              <Styles.RadioButton
                id={`reason${index}`}
                type="radio"
                checked={params.reason === reason}
                onChange={() => setUpdateRadio(reason)}
              />
              {reason}
            </Styles.Label>
          </Styles.ReasonItem>
        ))}
      </Styles.ReasonSection>
      <Styles.MessageSection>
        <Styles.Title>보호자 안내 메세지를 입력해 주세요.</Styles.Title>
        <BasicTextArea
          placeholder="60자 이내로 작성해 주세요."
          value={params.message || ''}
          disabled={isDisabled}
          onChange={(str) => setMessage(str)}
          width={428}
          height={100}
          size={60}
        />
      </Styles.MessageSection>
      <Styles.ButtonSection>
        <BasicButton onClick={onClickClose} canceled>
          돌아가기
        </BasicButton>
        <BasicButton onClick={onClickReject}>예약 취소</BasicButton>
      </Styles.ButtonSection>
    </Styles.Wrapper>
  );
};

export default ReservationRejectModal;
