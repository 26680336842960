import styled from '@emotion/styled';
import { FITPET_MINT, GREY_1, GREY_2, GREY_3, WHITE } from 'styles/colors';

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 24px 0 0 0;
`;

export const ContentWrapper = styled.div`
  background: ${WHITE};
`;

export const CommentWrapper = styled.div`
  height: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: ${GREY_1};
  margin: 32px 0px 110px 36px;

  div {
    margin-top: 10px;
    color: ${GREY_2};
  }
`;

export const InputWrapper = styled.div`
  padding: 0 36px 0 36px;
`;
export const InputTitle = styled.div`
  height: 16px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: ${FITPET_MINT};
  margin-bottom: 23px;
`;

export const InputDesc = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: ${GREY_1};
  margin-bottom: 12px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  height: 92px;
  align-items: center;
  padding-right: 36px;

  button {
    margin-left: 8px;
  }
`;

export const SaveButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 12px 0px 36px 36px;
`;

export const Dash = styled.div`
  margin: 40px 0;
  border: 1px dashed ${GREY_3};
`;
