import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { AHEAD_MINT, GREY_1, GREY_2, PASTEL_MINT, WHITE } from 'styles/colors';

export const Wrapper = styled.section`
  width: 100%;
  overflow: hidden;
`;

export const Week = styled.ul`
  display: flex;
  min-width: 500px;
  flex-wrap: nowrap;
`;

export const Day = styled.li(
  ({ checked }: { checked: boolean }) => css`
    box-sizing: border-box;
    margin-right: 4px;
    padding: 12px 11px;
    border-radius: 5px;
    font-size: 14px;
    color: ${checked ? AHEAD_MINT : GREY_1};
    background-color: ${checked ? PASTEL_MINT : WHITE};
    font-weight: ${checked ? 700 : 400};
  `,
);

export const Checked = styled.div`
  font-size: 14px;
  width: fit-content;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;
  span {
    margin-left: 8px;
  }
`;

export const DateWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TimeSection = styled.div`
  display: flex;
`;

export const Title = styled.p`
  line-height: 17px;
  color: ${GREY_1};
  margin-top: 28px;
  margin-bottom: 10px;
`;

export const DateWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const TimeWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-right: 10px;
  margin-bottom: 10px;
`;

export const Label = styled.label`
  width: 56px;
  height: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: ${GREY_2};
  margin-right: 24px;

  position: relative;
  top: 17px;
`;

export const Divison = styled.div`
  margin-left: 8px;
  margin-right: 8px;
  height: 48px;
  position: relative;
  top: 17px;
`;

export const DeleteFilledIconWrapper = styled.div`
  margin-left: 10px;
  margin-top: 8px;
  width: 30px;
  height: 30px;
`;
