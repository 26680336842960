import React from 'react';
import styled from '@emotion/styled';
import { GREY_6, WHITE } from 'styles/colors';

interface ITabArrowIconProps {
  className?: string; // Zero) emotion 에서 생성해주는 className 받기 위함
  active?: boolean;
  direction: 'left' | 'right';
}

function Icon({ className, active, direction }: ITabArrowIconProps) {
  return (
    <svg
      className={`${className} ${active ? 'active' : ''}`}
      fill="none"
      height="25"
      viewBox="0 0 25 25"
      width="25"
      xmlns="http://www.w3.org/2000/svg">
      {direction === 'left' ? (
        <>
          <circle cx="12.5" cy="12.5" r="12" stroke="#dee2e6" transform="matrix(-1 0 0 -1 25 25)" />
          <path d="m14.5 7.5-5 5 5 5" stroke="#495057" />
        </>
      ) : (
        <>
          <circle cx="12.5" cy="12.5" r="12" stroke="#dee2e6" />
          <path d="m10.5 17.5 5-5-5-5" stroke="#495057" />
        </>
      )}
    </svg>
  );
}

const TabArrowIcon = styled(Icon)`
  &.active {
    circle {
      stroke: ${GREY_6};
      fill: ${GREY_6};
    }

    path {
      stroke: ${WHITE};
    }
  }
`;

export default TabArrowIcon;
