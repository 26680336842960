import React, { useCallback } from 'react';
import LogoutIcon from 'src/components/icons/LogoutIcon';
import { LocalStorageKey } from 'src/constants/localStorage';
import { NavigationItems } from 'src/constants/navigation';
import { useGetRouterInfo } from 'src/hooks/useGetRouterInfo';
import { SideTabRoute } from 'src/model/route';
import { LocalStorage } from 'src/utils/LocalStorage';
import moment from 'moment';
import * as Styles from './styles';
import { useGetTodayAppliedReservations } from '../../../../query/Reservation/useReservationQuery';
import { ReservationStatusType } from '../../../../model/reservation';
import { DateFormat } from '../../../../constants/workHours';

interface NavigationMenuProps {
  menu: Record<NavigationItems, SideTabRoute>;
}

const NavigationMenu = ({ menu }: NavigationMenuProps) => {
  const { routeName, currentPath } = useGetRouterInfo({ menu });

  // 5분마다 갱신 처리
  const { data: now } = useGetTodayAppliedReservations({ serviceStatus: ReservationStatusType.Applied });
  const { data: today } = useGetTodayAppliedReservations({
    serviceStatus: ReservationStatusType.Confirmed,
    desiredDate: moment().format(DateFormat),
  });
  const startDate = moment(moment().startOf('isoWeek').valueOf()).format(DateFormat);
  const endDate = moment(startDate).add(6, 'days').format(DateFormat);
  const { data: week } = useGetTodayAppliedReservations({
    serviceStatus: ReservationStatusType.Confirmed,
    startDate,
    endDate,
  });

  const onClickLogOut = useCallback(() => {
    LocalStorage.remove(LocalStorageKey.ACCESSTOKEN);
    LocalStorage.remove(LocalStorageKey.REFRESHTOKEN);
    LocalStorage.remove(LocalStorageKey.ISLOGIN);
    LocalStorage.remove(LocalStorageKey.HOSPITAL_ID);
    LocalStorage.setValue(LocalStorageKey.AUTOLOGIN, 'false');
    window.location.reload();
  }, []);

  return (
    <Styles.Wrapper>
      <div>
        <Styles.ReservationWrapper>
          <Styles.ReservationTitle>현재 미확정 예약&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Styles.ReservationTitle>
          <Styles.CountBadge>{now && <span>{`${now.length || 0}`}</span>}</Styles.CountBadge>
        </Styles.ReservationWrapper>
        <Styles.ReservationWrapper>
          <Styles.ReservationTitle>오늘 예정된 진료&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Styles.ReservationTitle>
          <Styles.CountBadge>{today && <span>{`${today.length || 0}`}</span>}</Styles.CountBadge>
        </Styles.ReservationWrapper>
        <Styles.ReservationWrapper>
          <Styles.ReservationTitle>이번 주 예정된 진료 </Styles.ReservationTitle>
          <Styles.CountBadge>{week && <span>{`${week.length || 0}`}</span>}</Styles.CountBadge>
        </Styles.ReservationWrapper>
        <Styles.MenuWrapper>
          {routeName.map((item) => {
            const route = menu[item as NavigationItems];
            const isActive = currentPath === route.path;

            return (
              <Styles.CustomLink key={route.text} to={route.path}>
                <Styles.Menu isActive={isActive}>
                  <span>{route.icon(isActive)}</span>
                  <span style={{ fontSize: '18px' }}>{route.text}</span>
                </Styles.Menu>
              </Styles.CustomLink>
            );
          })}
        </Styles.MenuWrapper>
      </div>
      <div>
        <Styles.Menu role="button" isActive={false}>
          <span>
            <a href="/guide/병원예약서비스_병원매뉴얼_v1.0.pdf" download>
              매뉴얼 다운로드
            </a>
          </span>
        </Styles.Menu>
        <Styles.Menu role="button" isActive={false} onClick={onClickLogOut}>
          <span>{LogoutIcon()}</span>
          <span>프로그램 종료</span>
        </Styles.Menu>
      </div>
    </Styles.Wrapper>
  );
};

export default NavigationMenu;
