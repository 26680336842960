import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Button } from '../../common/buttons/Button';
import { AHEAD_MINT, GREY_1, GREY_3, WHITE } from '../../../../styles/colors';
import { ZIndex } from '../../../../styles/zIndex';

interface TextInputCalendarStylesProps {
  width?: number;
  height?: number;
  disabled?: boolean;
}

export const Wrapper = styled.div(
  ({ width, height }: TextInputCalendarStylesProps) => css`
    display: flex;
    width: ${width ? `${width}px` : 'fit-content'};
    height: ${height}px;
    position: relative;
  `,
);

export const CalendarButtonWrapper = styled.div`
  position: relative;
  cursor: pointer;

  display: flex;
  flex-direction: row;

  svg {
    position: absolute;
    top: 9px;
    left: 10px;
  }
`;

export const CalendarButton = styled(Button)(
  () => css`
    //position: absolute;
    border: 1px solid ${GREY_1};
    background-color: ${WHITE};
    color: ${GREY_1};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    //padding: 11px 10px 11px 34px;
    padding: 10px;
    margin-right: 10px;
    letter-spacing: -0.02em;
    border-radius: 5px;
    height: 38px;
    width: 98px;
    //width: fit-content;
  `,
);

export const CalendarWrapper = styled.div(
  ({ height = 48 }: TextInputCalendarStylesProps) => css`
    position: absolute;
    top: ${height}px;
    left: -210px;
    height: fit-content;
    background-color: ${WHITE};
    border: 1px solid ${GREY_3};
    border-radius: 10px;
    //padding: 20px 5px 0 5px;
    box-shadow: 2px 2px 4px ${GREY_3};
    z-index: ${ZIndex.CalendarModal};
    //display: flex;
    //justify-items: center;
  `,
);

export const Title = styled.div`
  width: 100%;
  height: 58px;
  padding: 20px 0px 20px 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  justify-content: end;
  letter-spacing: -0.24px;
  color: ${GREY_1};
`;

export const Close = styled.div`
  cursor: pointer;
  margin-right: 20px;
  color: ${GREY_1};
`;

export const Body = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const LeftDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  //align-items: first;
  width: 162px;
`;

export const RightDiv = styled.div`
  height: fit-content;
  width: 392px;
  padding-left: 10px;
`;

export const ButtonDiv = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  height: 56px;
  padding-right: 28px;
  //padding: 24px 28px 24px 0;
  text-align: right;
`;

export const Label = styled.label`
  display: flex;
  cursor: pointer;
  margin-top: 26px;
  margin-left: 28px;
`;

export const RadioButton = styled.input`
  width: 18px;
  height: 18px;
  border: 1px solid ${GREY_3};
  border-radius: 50%;
  margin-right: 12px;
  cursor: pointer;

  &:checked {
    border: 6px solid ${AHEAD_MINT};
  }
`;
