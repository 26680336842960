import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Styles from './styles';
import CalendarBasic, { CalendarBasicProps } from '../CalendarBasic';
import SelectDropDownArrowIcon from '../../icons/SelectDropDownArrowIcon';
import useClickOutside from '../../../hooks/dom/useClickOutside';

export interface TextInputCalendarProps extends CalendarBasicProps {
  width?: number;
  height?: number;
  disabled?: boolean;
  placeholder?: string;
  onChange?: (selected: string) => void;
  onChangeMonth?: (changeMonth: string) => void;
  // enablePrevDate?: boolean; // 과거날짜 선택가능 여부
  // availableDate?: string[]; // 선택 가능한 날짜
  top?: boolean; // 팝업창 위치
  positionX?: string; // 레이어 팝업 좌표 X
  positionY?: string; // 레이어 팝업 좌표 Y
}
const TextInputCalendar = ({
  width = 160,
  height = 48,
  disabled = false,
  placeholder = '날짜선택',
  selectDate = '',
  enablePrevDate = true,
  availableDate = [],
  notAvailableDate = [],
  onChange = () => {},
  onChangeMonth = () => {},
  min = '',
  max = '',
  top = false,
  positionX = '',
  positionY = '',
}: TextInputCalendarProps) => {
  const [date, setDate] = useState(selectDate);
  const [isShow, setShow] = useState(false);

  useEffect(() => {
    setDate(selectDate);
  }, [selectDate]);

  const handleOnClick = useCallback(() => {
    if (disabled) return;
    setShow(!isShow);
  }, [isShow, disabled]);

  const handleOnCalendarChange = useCallback(
    (dateStr) => {
      setDate(dateStr);
      onChange(dateStr);
      setShow(false);
    },
    [onChange],
  );

  const handleOnChangeMonth = useCallback(
    (dateStr) => {
      onChangeMonth(dateStr);
    },
    [onChangeMonth],
  );

  const calendarWrapperRef = useRef(null);
  useClickOutside(calendarWrapperRef, () => {
    setShow(false);
  });

  return (
    <Styles.Wrapper width={width} height={height} disabled={disabled} ref={calendarWrapperRef}>
      <Styles.Input placeholder={placeholder} readOnly onClick={handleOnClick} value={date} disabled={disabled} />
      <Styles.IconWrapper onClick={handleOnClick} disabled={disabled}>
        <SelectDropDownArrowIcon />
      </Styles.IconWrapper>
      {isShow && (
        <Styles.CalendarWrapper height={height} isTop={top} positionX={positionX} positionY={positionY}>
          <CalendarBasic
            selectDate={date}
            onChange={handleOnCalendarChange}
            onChangeMonth={handleOnChangeMonth}
            availableDate={availableDate}
            notAvailableDate={notAvailableDate}
            enablePrevDate={enablePrevDate}
            min={min}
            max={max}
          />
        </Styles.CalendarWrapper>
      )}
    </Styles.Wrapper>
  );
};

export default TextInputCalendar;
