import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import * as Styles from './styles';
import useClickOutside from '../../../hooks/dom/useClickOutside';
import CalendarBasic from '../../common/CalendarBasic';
import { BasicButton } from '../../common/buttons/BasicButton';
import CloseIcon from '../../icons/CloseIcon';
import { DateFormat, DisplayDateFormat } from '../../../constants/workHours';

export interface ChoiceCalendarProps {
  selectDate?: string;
  onChangeDate?: (selected: string) => void;
}
const ChoiceCalendarButton = ({ selectDate = '', onChangeDate = () => {} }: ChoiceCalendarProps) => {
  const [date, setDate] = useState('');
  const [isShow, setIsShow] = useState(false);

  // 날짜 선택팝업
  const showCalendar = useCallback(() => {
    setDate(selectDate === '' ? moment().format(DateFormat) : selectDate);
    setIsShow(!isShow);
  }, [isShow]);

  const handleOnCalendarChange = useCallback(
    (dateStr) => {
      setDate(dateStr);
    },
    [date],
  );

  useEffect(() => {
    if (date === 'all') {
      doConfirm();
    }
  }, [date]);

  const doConfirm = useCallback(() => {
    onChangeDate(date === 'all' ? '' : date);
    setIsShow(false);
  }, [date, onChangeDate]);

  const doClose = useCallback(() => {
    setIsShow(false);
  }, [isShow]);

  const calendarWrapperRef = useRef(null);
  useClickOutside(calendarWrapperRef, () => {
    setIsShow(false);
  });

  // const makeDateStr = (str: string, isInnerVar: boolean) => {
  //   if (isInnerVar) {
  //     if (str === 'all') {
  //       return '전체';
  //     }
  //     if (str === '') {
  //       return '날짜를 선택해 주세요.';
  //     }
  //     return moment(new Date(str)).format('YYYY.MM.DD');
  //   }
  //   if (str === '') {
  //     return '전체';
  //   }
  //   return moment(new Date(str)).format('YYYY.MM.DD');
  // };

  return (
    <Styles.Wrapper height={38} ref={calendarWrapperRef}>
      <Styles.CalendarButtonWrapper>
        <Styles.CalendarButton onClick={() => handleOnCalendarChange('all')}>날짜 초기화</Styles.CalendarButton>
        <Styles.CalendarButton onClick={showCalendar}>
          {selectDate ? moment(selectDate).format(DisplayDateFormat) : '날짜 선택'}
        </Styles.CalendarButton>
        {/* <Styles.CalendarButton>방문(희망)일: {makeDateStr(selectDate, false)}</Styles.CalendarButton> */}
        {/* <CalendarDateIcon /> */}
      </Styles.CalendarButtonWrapper>

      {isShow && (
        <Styles.CalendarWrapper>
          <Styles.Title>
            {/* 방문희망일: {makeDateStr(date, true)} */}
            <Styles.Close onClick={doClose}>
              <CloseIcon />
            </Styles.Close>
          </Styles.Title>
          <Styles.Body>
            {/* <Styles.LeftDiv> */}
            {/*  <Styles.Label htmlFor="radio-button1" onClick={() => handleOnCalendarChange('all')}> */}
            {/*    <Styles.RadioButton */}
            {/*      id="radio-button1" */}
            {/*      type="radio" */}
            {/*      name="todayReservationAvailable" */}
            {/*      checked={date === 'all'} */}
            {/*      onChange={() => handleOnCalendarChange('all')} */}
            {/*    /> */}
            {/*    전체 */}
            {/*  </Styles.Label> */}
            {/*  <Styles.Label htmlFor="radio-button2" onClick={() => handleOnCalendarChange('')}> */}
            {/*    <Styles.RadioButton */}
            {/*      id="radio-button2" */}
            {/*      type="radio" */}
            {/*      name="todayReservationAvailable" */}
            {/*      checked={date !== 'all'} */}
            {/*      onChange={() => handleOnCalendarChange('')} */}
            {/*    /> */}
            {/*    지정일 */}
            {/*  </Styles.Label> */}
            {/* </Styles.LeftDiv> */}
            <Styles.RightDiv>
              <CalendarBasic disabled={date === 'all'} selectDate={date} onChange={handleOnCalendarChange} />
              <Styles.ButtonDiv>
                <BasicButton onClick={doConfirm} disabled={date === ''} width={82} height={36}>
                  적용
                </BasicButton>
              </Styles.ButtonDiv>
            </Styles.RightDiv>
          </Styles.Body>
        </Styles.CalendarWrapper>
      )}
    </Styles.Wrapper>
  );
};

export default ChoiceCalendarButton;
