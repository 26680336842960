import React, { useCallback, useEffect, useState } from 'react';
import { range } from 'lodash';
import * as Styles from './styles';

interface PaginationProps {
  /** 현재 페이지 번호 (시작: 0) */
  pageNumber: number;
  /** 전체 게시물 수 */
  totalCnt: number;
  /** 한 페이지에 보여지는 게시물 수 */
  pageSize: number;
  onPageChange: (pageNum: number) => void;
}

const Pagination = ({ pageNumber = 0, totalCnt = 0, pageSize = 10, onPageChange }: PaginationProps) => {
  const [pageInfo, setPageInfo] = useState({ totalPage: 0, pageNum: 0, first: 0, last: 0 });

  useEffect(() => {
    const totalPage = Math.ceil((totalCnt || 1) / pageSize);
    let first = pageNumber - 5 > 0 ? pageNumber - 5 : 0;
    let last = first + pageSize;

    if (last > totalPage) last = totalPage;
    if (last - first < 10) {
      first = last - 10 > 0 ? last - 10 : 0;
    }

    setPageInfo((prev) => ({ ...prev, totalPage, first, last }));
  }, [pageNumber, pageSize, totalCnt]);

  const onClickPageNumber = useCallback(
    (pageNum: number) => {
      setPageInfo((prev) => ({ ...prev, pageNum }));
      onPageChange(pageNum);
    },
    [onPageChange],
  );

  return (
    <Styles.Wrapper>
      <ul>
        {pageNumber <= 0 && <li className="prevArrow disabled">{'<'}</li>}
        {pageNumber > 0 && (
          <Styles.PageLi className="prevArrow" onClick={() => onClickPageNumber(pageNumber - 1)}>
            {'<'}
          </Styles.PageLi>
        )}

        {range(pageInfo.first, pageInfo.last).map((pageNum) => (
          <Styles.PageLi
            key={pageNum}
            className={`${pageNumber === pageNum ? 'currentPage' : ''}`}
            onClick={() => onClickPageNumber(pageNum)}>
            {pageNum + 1}
          </Styles.PageLi>
        ))}

        {pageNumber >= pageInfo.totalPage - 1 && <li className="nextArrow disabled">{'>'}</li>}
        {pageNumber < pageInfo.totalPage - 1 && (
          <Styles.PageLi className="nextArrow" onClick={() => onClickPageNumber(pageNumber + 1)}>
            {'>'}
          </Styles.PageLi>
        )}
      </ul>
    </Styles.Wrapper>
  );
};

export default Pagination;
