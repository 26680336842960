import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { OperationType, Reservation, ReservationDetail, ReservationStatusType } from '../../model/reservation';
import { FieldKeyValue } from '../../model/common';
import { Pet } from '../../model/pet';
import { UserInfoDto, UserType } from '../../model/user';

export interface ScheduleState {
  // viewDate: Date;
  reservations: Reservation[];
  editReservation: ReservationDetail;
  editPet: Pet;
  editPetList: Pet[];
}
const initialState = {
  // viewDate: new Date(),
  // 새창 띄울떄
  editReservation: {
    // id: -1,
    user: {
      // id: -1,
      name: '',
      tel: '',
      birthday: '',
    },
    pet: {
      id: -1,
    },
    serviceType: OperationType.Diagnosis,
    serviceStatus: ReservationStatusType.Applied,
    reservationTime: '',
    message: '',
    userType: UserType.NON_USER,
    hospitalMember: { id: -1 },
  } as ReservationDetail,
  reservations: [] as Reservation[],
  editPetList: [] as Pet[],
  editPet: {
    id: -1,
    userId: -1,
    name: '',
    birthday: '',
    weight: '',
    // gender: 'M',
    // petType: PetType.DOG,
    species: '',
    petBreedName: '',
    isNeutralized: false,
  } as Pet,
};
const ScheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    initEdit(state) {
      // state.editReservation = { ...initialState.editReservation };
      return {
        ...state,
        editReservation: { ...initialState.editReservation },
        editPet: { ...initialState.editPet },
        editPetList: { ...initialState.editPetList },
      };
    },
    initEditPet(state) {
      return { ...state, editPet: { ...initialState.editPet } };
    },
    updateReservations: (state, action: PayloadAction<Reservation[]>) => {
      state.reservations = [...action.payload];
    },
    updateEditUser: {
      prepare: (payload) => ({ payload }),
      reducer: (state, action: PayloadAction<UserInfoDto>) => {
        const user = action.payload;
        // 사용자 정보
        state.editReservation.user = {
          id: user.id,
          name: user.name,
          tel: user.tel,
          birthday: _.isEmpty(user.birthday) || user.birthday === null ? '' : user.birthday.trim().replaceAll('-', ''),
        };
        state.editReservation.userType = user.userType;
        // 펫정보
        state.editPetList = [...user.pets];
      },
    },
    updateEditField: {
      prepare: (payload) => ({ payload }),
      reducer: (state, action: PayloadAction<FieldKeyValue>) => {
        const { key, value } = action.payload;
        state.editReservation = { ...state.editReservation, [key]: value };
      },
    },
    updateEditFet: {
      prepare: (payload) => ({ payload }),
      reducer: (state, action: PayloadAction<Pet>) => {
        state.editPet = { ...action.payload };
        // 항목선택
        state.editReservation.pet.id = state.editPet.id;
      },
    },
    updateEditFetField: {
      prepare: (payload) => ({ payload }),
      reducer: (state, action: PayloadAction<FieldKeyValue>) => {
        const { key, value } = action.payload;
        state.editPet = { ...state.editPet, [key]: value };
      },
    },
    addEditFetList: {
      prepare: (payload) => ({ payload }),
      reducer: (state, action: PayloadAction<Pet>) => {
        state.editPetList = [...state.editPetList, action.payload];
        state.editPet = action.payload;
        state.editReservation.pet.id = action.payload.id;
      },
    },
  },
});

export default ScheduleSlice;
export const { actions: scheduleAction } = ScheduleSlice;
