import React, { RefObject, useCallback, useEffect, useRef } from 'react';
import { HourPlusMinute, TimeType } from 'src/constants/workHours';
import * as Styles from './styles';

interface TimeBoxProps {
  name?: string;
  timeType: TimeType;
  initTime: string;
  disabled?: boolean;
  filterDates?: string[];
  onChangeTime: (type: TimeType, time: string) => void;
}

const TimeBox = ({ name, timeType, initTime, disabled, filterDates, onChangeTime }: TimeBoxProps) => {
  const timeRef = useRef<HTMLSelectElement>();

  if (timeRef.current && timeRef.current.value && timeRef.current.value !== initTime) {
    // timeRef이 setting된 상태에서 상위 레벨에서 initTime을 수정해서 내려보낸 경우 현재 값과 다르므로 sync 맞춤
    timeRef.current.value = initTime;
  }

  const handleChange = useCallback(() => {
    if (timeRef.current) {
      const time = timeRef.current.value;
      onChangeTime(timeType, `${time}`);
    }
  }, [onChangeTime, timeType]);

  // 셀렉트박스 초기값 셋팅
  useEffect(() => {
    if (timeRef.current) {
      timeRef.current.value = initTime || '00:00';
    }
  }, [timeRef, initTime]);

  return (
    <Styles.Wrapper>
      <Styles.TimeWrapper>
        {name && <Styles.Label>{name}</Styles.Label>}
        <Styles.Time
          disabled={disabled}
          ref={timeRef as RefObject<HTMLSelectElement>}
          role="button"
          onChange={handleChange}>
          {Object.keys(HourPlusMinute)
            .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
            .filter((time) => !filterDates?.includes(time))
            .map((key) => (
              <Styles.Option key={key} value={key}>
                {HourPlusMinute[key]}
              </Styles.Option>
            ))}
        </Styles.Time>
      </Styles.TimeWrapper>
    </Styles.Wrapper>
  );
};

export default TimeBox;
