export enum NavigationItems {
  ReservationStatus = 'ReservationStatus',
  ReservationSchedule = 'ReservationSchedule',
  // CustomerService = 'CustomerService',
  OperationManagement = 'OperationManagement',
  MemberManagement = 'MemberManagement',
  CustomerManagement = 'CustomerManagement',
  Statistic = 'Statistic',
  // Develop = 'Develop',
}
