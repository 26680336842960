import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { AxiosError } from 'axios';
import { QueryKey } from '../../constants/queryKey';
import { BASE_URL, HTTP } from '../../utils/api/http';
import { selectHospitalId } from '../../selectors';
import type { Reservation } from '../../model/reservation';
import { BlockReservationDTO, OperationType, ReservationStatusType } from '../../model/reservation';
import { UserType } from '../../model/user';
import { handleMutate } from '../../utils/query/mutateUtils';
import { ModalHandler } from '../../utils/ModalHandler';
import { ModalType } from '../../constants/modal';
import { Holiday } from '../../model/calendar';
import { HttpsError } from '../../model/HttpCode';

export const useGetBlockSchedule = () => {
  const hospitalId = useSelector(selectHospitalId);

  const { data, isLoading, error } = useQuery(
    [QueryKey.BLOCK_SCHEDULE_LIST],
    async () => {
      const response = await HTTP.get(`${BASE_URL}/api/hospitals/${hospitalId}/closed-time/vet`);
      return (response.data as Reservation[]).map((schedule) => ({
        ...schedule,
        userName: '',
        tel: '',
        petName: '',
        petBreedName: '',
        serviceType: OperationType.Consulting,
        serviceStatus: ReservationStatusType.Block,
        reservationTime: schedule?.closedTimeStart ?? '',
        createdAt: '',
        userType: UserType.USER,
        hospitalMemberId: schedule.hospitalMember?.id ?? -1,
        hospitalMemberName: schedule.hospitalMember?.name ?? '',
      }));
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  return { data, isLoading, error };
};

export const useSaveBlockSchedule = () => {
  const hospitalId = useSelector(selectHospitalId);
  const queryClient = useQueryClient();
  const queryKey = QueryKey.BLOCK_SCHEDULE_ADD;

  const onMutate = handleMutate({ queryClient, queryKey });

  return useMutation(
    async (blockReservation: BlockReservationDTO) => {
      const { memberId } = blockReservation;
      const response = await HTTP.post(
        `${BASE_URL}/api/hospitals/${hospitalId}/${memberId}/closed-time/vet`,
        blockReservation,
      );
      return response.data;
    },
    {
      onMutate,
      onSuccess: () => {
        ModalHandler.show(ModalType.Toast, {
          ToastMessage: '블락이 등록되었습니다.',
        });
        queryClient.refetchQueries([QueryKey.SCHEDULE_LIST], { active: true });
        queryClient.refetchQueries([QueryKey.BLOCK_SCHEDULE_LIST], { active: true });
        queryClient.refetchQueries([QueryKey.MEMBER_LIST], { active: true });
        queryClient.refetchQueries([QueryKey.RESERVATIONS], { active: true });
      },
      onError: (error: AxiosError<HttpsError>) => {
        ModalHandler.show(ModalType.Toast, {
          ToastMessage: error.response?.data.message || '예약이 실패하였습니다.',
        });
      },
    },
  );
};

export const useCancelBlockSchedule = () => {
  const queryClient = useQueryClient();

  return useMutation(
    QueryKey.BLOCK_SCHEDULE_DELETE,
    (ids: number[]) =>
      HTTP.delete(`${BASE_URL}/api/hospitals/closed-time`, {
        data: ids,
      }),
    {
      onSuccess: () => {
        ModalHandler.show(ModalType.Toast, {
          ToastMessage: '선택한 블락이 삭제되었습니다.',
        });
        queryClient.refetchQueries([QueryKey.SCHEDULE_LIST], { active: true });
        queryClient.refetchQueries([QueryKey.BLOCK_SCHEDULE_LIST], { active: true });
        queryClient.refetchQueries([QueryKey.MEMBER_LIST], { active: true });
        queryClient.refetchQueries([QueryKey.RESERVATIONS], { active: true });
      },
      onError: () => {
        ModalHandler.show(ModalType.Toast, {
          ToastMessage: '선택한 블락 삭제에 실패했습니다.',
        });
      },
    },
  );
};

/**
 * 병원 임시 휴무일
 */
export const useGetHospitalHoliday = () => {
  const hospitalId = useSelector(selectHospitalId);

  const { data, isLoading, error } = useQuery(
    [QueryKey.HOLIDAY_LIST],
    async () => {
      const response = await HTTP.get(`${BASE_URL}/api/hospitals/${hospitalId}/closed-time/holiday`);
      return (response.data ?? []) as Holiday[];
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  return { data, isLoading, error };
};
