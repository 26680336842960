// type WorkDayType = 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat' | 'Sun';

export enum TimeType {
  Start = 'start',
  End = 'end',
}

export enum DayType {
  Mon = 'MON',
  Tue = 'TUE',
  Wed = 'WED',
  Thu = 'THU',
  Fri = 'FRI',
  Sat = 'SAT',
  Sun = 'SUN',
}

export const dayMap = {
  [DayType.Mon]: '월요일',
  [DayType.Tue]: '화요일',
  [DayType.Wed]: '수요일',
  [DayType.Thu]: '목요일',
  [DayType.Fri]: '금요일',
  [DayType.Sat]: '토요일',
  [DayType.Sun]: '일요일',
};

interface HourObject {
  [key: string]: string;
}

interface MinuteObject {
  [key: string]: string;
}
export const Hour: HourObject = {
  '00': '00시',
  '01': '01시',
  '02': '02시',
  '03': '03시',
  '04': '04시',
  '05': '05시',
  '06': '06시',
  '07': '07시',
  '08': '08시',
  '09': '09시',
  '10': '10시',
  '11': '11시',
  '12': '12시',
  '13': '13시',
  '14': '14시',
  '15': '15시',
  '16': '16시',
  '17': '17시',
  '18': '18시',
  '19': '19시',
  '20': '20시',
  '21': '21시',
  '22': '22시',
  '23': '23시',
};

export const Minute: MinuteObject = {
  '00': '00분',
  '30': '30분',
};

export const HourPlusMinute: HourObject = {
  '00:00': '00:00',
  '00:30': '00:30',
  '01:00': '01:00',
  '01:30': '01:30',
  '02:00': '02:00',
  '02:30': '02:30',
  '03:00': '03:00',
  '03:30': '03:30',
  '04:00': '04:00',
  '04:30': '04:30',
  '05:00': '05:00',
  '05:30': '05:30',
  '06:00': '06:00',
  '06:30': '06:30',
  '07:00': '07:00',
  '07:30': '07:30',
  '08:00': '08:00',
  '08:30': '08:30',
  '09:00': '09:00',
  '09:30': '09:30',
  '10:00': '10:00',
  '10:30': '10:30',
  '11:00': '11:00',
  '11:30': '11:30',
  '12:00': '12:00',
  '12:30': '12:30',
  '13:00': '13:00',
  '13:30': '13:30',
  '14:00': '14:00',
  '14:30': '14:30',
  '15:00': '15:00',
  '15:30': '15:30',
  '16:00': '16:00',
  '16:30': '16:30',
  '17:00': '17:00',
  '17:30': '17:30',
  '18:00': '18:00',
  '18:30': '18:30',
  '19:00': '19:00',
  '19:30': '19:30',
  '20:00': '20:00',
  '20:30': '20:30',
  '21:00': '21:00',
  '21:30': '21:30',
  '22:00': '22:00',
  '22:30': '22:30',
  '23:00': '23:00',
  '23:30': '23:30',
};

export const StartTime = '00:00';
export const EndTime = '23:30';
export const End24Time = '23:59';
export const End24TimeDisplay = '00:00';

export const DateFormat = 'YYYY-MM-DD';
export const DisplayDateFormat = 'YYYY.MM.DD';

export const DateTimeFormat = 'YYYY-MM-DD[T]HH:mm:ss';
export const DisplayDateTimeFormat = 'YYYY-MM-DD(dd) HH:mm';
