import styled from '@emotion/styled';
import { GREY_1 } from '../../../../styles/colors';

export const Tr = styled.tr`
  height: 144px;
`;

export const Td = styled.td`
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: ${GREY_1};
`;
