import appboy from '@braze/web-sdk';
import { EventHandler } from './base';
import { EventType } from '../../model/tracker';

export class BrazeHandler implements EventHandler {
  private apiKey = process.env.REACT_APP_BRAZE_API_KEY || '';

  private baseUrl = process.env.REACT_APP_BRAZE_BASE_URL || '';

  initialize = () => {
    appboy.initialize(this.apiKey, {
      baseUrl: this.baseUrl,
      enableLogging: process.env.NODE_ENV !== 'production',
    });
  };

  setUserID = (userId: string) => {
    appboy.changeUser(userId);
  };

  handleEvent = (event: EventType, properties: Record<string, any>) => {
    const eventProperties = {
      ...properties,
    };
    appboy.logCustomEvent(event, eventProperties);
  };
}
