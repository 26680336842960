import React, { useCallback } from 'react';
import CloseIcon from 'src/components/icons/CloseIcon';
import { SimpleModalParams, ModalDuration, ModalType } from 'src/constants/modal';
import { ModalHandler } from 'src/utils/ModalHandler';
import { ModalWrapper } from '../ModalWrapper';
import * as Styles from './styles';

interface ConfirmedModalProps {
  isModalOpen: boolean;
  modalParams?: SimpleModalParams;
  clearModalParams: () => void;
}

const ConfirmedModal = ({ isModalOpen, modalParams, clearModalParams }: ConfirmedModalProps) => {
  const clearModal = useCallback(() => {
    ModalHandler.hide(ModalType.Confirmed);
  }, []);

  const handleClose = useCallback(() => {
    clearModal();
  }, [clearModal]);

  return (
    <ModalWrapper
      isModalOpen={isModalOpen}
      duration={ModalDuration.Slide}
      clearModalParam={clearModalParams}
      onClickBackground={handleClose}
      backgroundColor="rgba(255,255,255, 0.1)">
      <Styles.ModalContainer isShow={isModalOpen}>
        <Styles.ModalHead>
          <Styles.Title>{modalParams?.title}</Styles.Title>
          <Styles.CloseButton onClick={handleClose}>
            <Styles.IconWrapper>
              <CloseIcon />
            </Styles.IconWrapper>
          </Styles.CloseButton>
        </Styles.ModalHead>
        <Styles.ModalContent>{modalParams?.contents}</Styles.ModalContent>
      </Styles.ModalContainer>
    </ModalWrapper>
  );
};

export default ConfirmedModal;
