import React, { useState } from 'react';
import BreedStatistic from './BreedStatistic';
import AgeStatistic from './AgeStatstic';
import GenderStatistic from './GenderStatistic';
import * as StatisticStyles from '../styles';
import { PetType, PetTypeToString } from '../../../model/pet';

export default function PetStatistics() {
  const [petType, setPetType] = useState<PetType>(PetType.DOG);
  return (
    <StatisticStyles.Section>
      <h3>반려 동물 통계</h3>
      <StatisticStyles.PetTypeTabs>
        {Object.keys(PetTypeToString).map((key) => (
          <button
            key={key}
            type="button"
            className={petType === key ? 'active' : ''}
            onClick={() => setPetType(key as PetType)}>
            {PetTypeToString[key as PetType]}
          </button>
        ))}
      </StatisticStyles.PetTypeTabs>

      <StatisticStyles.Grid>
        <BreedStatistic petType={petType} />

        <AgeStatistic petType={petType} />

        <GenderStatistic petType={petType} />
      </StatisticStyles.Grid>
    </StatisticStyles.Section>
  );
}
