import React, { useCallback } from 'react';
import { AlertModalParams, ModalDuration, ModalType } from 'src/constants/modal';
import { ModalHandler } from 'src/utils/ModalHandler';
import { GREY_2, GREY_3, WHITE } from 'styles/colors';
import { ModalWrapper } from '../ModalWrapper';
import * as Styles from './styles';

interface AlertModalProps {
  isModalOpen: boolean;
  modalParams?: AlertModalParams;
  clearModalParams: () => void;
}

const AlertModal = ({ isModalOpen, modalParams, clearModalParams }: AlertModalProps) => {
  const clearModal = useCallback(() => {
    ModalHandler.hide(ModalType.Alert);
  }, []);

  const handleClickConfirm = useCallback(() => {
    clearModal();
    modalParams?.onConfirm?.();
  }, [clearModal, modalParams]);

  const handleClickCancel = useCallback(() => {
    clearModal();
    modalParams?.onCancel?.();
  }, [clearModal, modalParams]);

  return (
    <ModalWrapper isModalOpen={isModalOpen} duration={ModalDuration.Alert} clearModalParam={clearModalParams}>
      <Styles.ModalContainer isShow={isModalOpen}>
        <Styles.ModalContentWrapper>
          {modalParams?.title && <Styles.ModalTitle dangerouslySetInnerHTML={{ __html: modalParams.title || '' }} />}
          {modalParams?.content && (
            <Styles.ModalContent dangerouslySetInnerHTML={{ __html: modalParams?.content || '' }} />
          )}
        </Styles.ModalContentWrapper>
        <Styles.ModalButtons>
          {modalParams?.cancelText && (
            <Styles.ModalButton onClick={handleClickCancel} backgroundColor={WHITE} color={GREY_2} borderColor={GREY_3}>
              {modalParams.cancelText}
            </Styles.ModalButton>
          )}
          <Styles.ModalButton onClick={handleClickConfirm}>{modalParams?.confirmText || '확인'}</Styles.ModalButton>
        </Styles.ModalButtons>
      </Styles.ModalContainer>
    </ModalWrapper>
  );
};

export default AlertModal;
