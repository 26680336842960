import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { AHEAD_MINT, GREY_1, GREY_3, WHITE } from 'styles/colors';
import { Button } from './Button';

type BasicButtonProps = { canceled?: boolean; disabled?: boolean; width?: number; height?: number };

const backgroundColor = ({ canceled, disabled }: BasicButtonProps) => {
  if (disabled) {
    return GREY_3;
  }
  return canceled ? WHITE : AHEAD_MINT;
};

export const BasicButton = styled(Button)(
  ({ canceled, disabled, width = 128, height = 44 }: BasicButtonProps) => css`
    border: ${canceled ? `1px solid ${GREY_3}` : 'none'};
    background-color: ${backgroundColor({ canceled, disabled })};
    font-weight: ${canceled ? 400 : 700};
    color: ${canceled ? GREY_1 : WHITE};
    font-size: 16px;
    border-radius: 5px;
    width: ${width}px;
    height: ${height}px;
    &:disabled {
      cursor: default;
    }
  `,
);
