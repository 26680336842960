import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FieldKeyValue } from '../../model/common';
import { CustomerFieldKey, ICustomer, ICustomerParams } from '../../model/customer';

export interface ICustomerState {
  customerList: ICustomer[];
  customerParams: ICustomerParams;
}

const initialState: ICustomerState = {
  customerList: [],
  customerParams: {
    sort: '',
    keyWord: '',
    size: 10,
    page: 0,
  },
};

const CustomerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCustomerList: {
      prepare: (customerList: ICustomer[]) => ({
        payload: customerList,
      }),
      reducer: (state, action: PayloadAction<ICustomer[]>) => {
        state.customerList = action.payload;
      },
    },
    updateField: {
      reducer: (state, action: PayloadAction<FieldKeyValue>) => {
        const updateState = { ...state.customerParams, [action.payload.key]: action.payload.value };

        if (CustomerFieldKey.KeyWord === action.payload.key) {
          updateState.page = 0;
        }

        state.customerParams = updateState;
      },
      prepare: ({ key, value }: FieldKeyValue) => ({ payload: { key, value } }),
    },
    resetField(state) {
      Object.assign(state, initialState);
    },
  },
});

export const { actions: customerAction } = CustomerSlice;
export default CustomerSlice;
