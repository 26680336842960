import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { NavigationItems } from 'src/constants/navigation';
import { SideTabRoute } from 'src/model/route';

interface UseGetRouterInfoProps {
  menu: Record<NavigationItems, SideTabRoute>;
}

export const useGetRouterInfo = ({ menu }: UseGetRouterInfoProps) => {
  const routeName = Object.keys(menu);
  const location = useLocation();
  const currentPath = useMemo(() => location.pathname, [location.pathname]);
  const currentRouteKey = routeName.filter((item) => menu[item as NavigationItems].path === currentPath);
  const currentRoute = menu[currentRouteKey[0] as NavigationItems];

  return {
    routeName,
    currentPath,
    currentRouteKey,
    currentRoute,
  };
};
