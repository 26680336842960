import React, { useState, useCallback, useEffect } from 'react';
import { ModalType, ModalParams } from 'src/constants/modal';
import { ModalEventHandler, ModalHandler } from 'src/utils/ModalHandler';
import { TOAST_BACKGROUND_RGB } from 'styles/colors';
import AlertModal from './AlertModal';
import ConfirmedModal from './ConfirmedModal';
import DetailModalSlide from './DetailModalSlide';
import RegisterModal from './RegisterModal';
import RejectModal from './RejectModal';
import DetailModal from './DetailModal';
import Toast from './Toast';

export const ModalController = () => {
  const [openedModals, setOpenedModals] = useState<ModalType[]>([]);
  const [modalParams, setModalParams] = useState<ModalParams>({});

  const isOpened = useCallback((type: ModalType) => openedModals.includes(type), [openedModals]);

  const handleEvent: ModalEventHandler = useCallback((isShow, type, params) => {
    if (isShow) {
      setOpenedModals((prev) => [...prev, type]);
      setModalParams((prev) => ({
        ...prev,
        [type]: params,
      }));
    } else {
      setOpenedModals((prev) => prev.filter((modalType) => modalType !== type));
    }
  }, []);

  const handleClearParams = useCallback(() => {
    if (openedModals.length > 0) {
      setModalParams((prev: any) =>
        openedModals.reduce(
          (acc, type) => ({
            ...acc,
            [type]: { ...prev[type] },
          }),
          {},
        ),
      );
    } else {
      setModalParams({});
    }
  }, [openedModals]);

  useEffect(() => {
    ModalHandler.addModalEvent(handleEvent);
  }, [handleEvent]);

  return (
    <>
      <RegisterModal
        isModalOpen={isOpened(ModalType.Register)}
        modalParams={modalParams[ModalType.Register]}
        clearModalParams={handleClearParams}
      />
      <DetailModalSlide
        isModalOpen={isOpened(ModalType.DetailSlide)}
        modalParams={modalParams[ModalType.DetailSlide]}
        clearModalParams={handleClearParams}
      />
      <DetailModal
        isModalOpen={isOpened(ModalType.Detail)}
        modalParams={modalParams[ModalType.Detail]}
        clearModalParams={handleClearParams}
      />
      <AlertModal
        isModalOpen={isOpened(ModalType.Alert)}
        modalParams={modalParams[ModalType.Alert]}
        clearModalParams={handleClearParams}
      />
      <ConfirmedModal
        isModalOpen={isOpened(ModalType.Confirmed)}
        modalParams={modalParams[ModalType.Confirmed]}
        clearModalParams={handleClearParams}
      />
      <RejectModal
        isModalOpen={isOpened(ModalType.Reject)}
        modalParams={modalParams[ModalType.Reject]}
        clearModalParams={handleClearParams}
      />
      <Toast
        backgroundColor={TOAST_BACKGROUND_RGB}
        isModalOpen={isOpened(ModalType.Toast)}
        modalParams={modalParams[ModalType.Toast]}
        clearModalParams={handleClearParams}
      />
    </>
  );
};
