import React, { useMemo } from 'react';
import MaleIcon from 'src/components/icons/MaleIcon';
import { Pet } from 'src/model/pet';
import { getAge } from 'src/utils/ReservationUtils';
import moment from 'moment';
import * as Styles from './styles';
import { BASE_IMAGE_URL } from '../../../utils/api/http';
import { DateFormat } from '../../../constants/workHours';
import FemaleIcon from '../../icons/FemaleIcon';
import { defaultPetIcon } from '../../../utils/PetUtils';

const ReservationPet = ({ pet }: { pet: Pet }) => {
  const age = useMemo(() => {
    const duration = moment.duration(moment().diff(moment(pet.birthday, DateFormat)));
    const diffMomths = duration.asMonths();

    return getAge(Math.floor(diffMomths));
  }, [pet]);

  return (
    <Styles.Wrapper>
      <Styles.PetCard>
        {pet.petImageUrl ? (
          <Styles.StyledProfileIcon img={`${BASE_IMAGE_URL}/${pet.petImageUrl}`} />
        ) : (
          defaultPetIcon(pet.petType)
        )}
        <Styles.Info>
          <div className="name">
            {pet.name}
            {pet.gender === 'F' ? <FemaleIcon /> : <MaleIcon />}
          </div>
          <span>{pet.petBreedName}</span>
          <span>{age}</span>
          <span>{pet.weight}kg</span>
          <span>{pet.isNeutralized ? '중성화 완료' : '중성화 미완료'}</span>
        </Styles.Info>
      </Styles.PetCard>
    </Styles.Wrapper>
  );
};

export default ReservationPet;
