import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { AxiosError } from 'axios';
import { QueryKey } from '../../constants/queryKey';
import { BASE_URL, HTTP } from '../../utils/api/http';
import { selectHospitalId } from '../../selectors';
import { UserType, UserTypeToUrl } from '../../model/user';
import { Reservation, ReservationDetail } from '../../model/reservation';
import { Pet, PetBreeds, PetRegisterDto, PetType } from '../../model/pet';
import { handleMutate } from '../../utils/query/mutateUtils';
import { ModalHandler } from '../../utils/ModalHandler';
import { ModalType } from '../../constants/modal';
import { scheduleAction } from '../../features/schedule/scheduleSlice';

export const useGetScheduleReservations = () => {
  const hospitalId = useSelector(selectHospitalId);

  const { data, isLoading, error } = useQuery(
    [QueryKey.SCHEDULE_LIST],
    async () => {
      const [nonUserRes, userRes] = await Promise.all([
        HTTP.get<Reservation[]>(`${BASE_URL}/api/hospitals/non-user/${hospitalId}/reservations`),
        HTTP.get<Reservation[]>(`${BASE_URL}/api/hospitals/user/${hospitalId}/reservations`),
      ]);
      return [
        ...nonUserRes.data.map((r) => {
          return { ...r, userType: UserType.NON_USER };
        }),
        ...userRes.data.map((r) => {
          return { ...r, userType: UserType.USER };
        }),
      ] as Reservation[];
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  return {
    data,
    error,
    isLoading,
  };
};

export const useGetPetBreeds = () => {
  const { data, isLoading, error } = useQuery(
    [QueryKey.PET_BREED_LIST],
    async () => {
      const [dogBreeds, catBreed] = await Promise.all([
        HTTP.get<PetBreeds[]>(`${BASE_URL}/api/hospitals/breeds?keyword=&petType=D`),
        HTTP.get<PetBreeds[]>(`${BASE_URL}/api/hospitals/breeds?keyword=&petType=C`),
      ]);
      const result = _.orderBy(
        [
          ...dogBreeds.data.map((r) => {
            r.petType = PetType.DOG;
            return r;
          }),
          ...catBreed.data.map((r) => {
            r.petType = PetType.CAT;
            return r;
          }),
        ],
        ['petType', 'breedName'],
        ['asc', 'asc'],
      ) as PetBreeds[];
      // 기타항목 뒤로 빼기
      return [
        ...result.filter((r) => !r.breedName.startsWith('기타')),
        ...result.filter((r) => r.breedName.startsWith('기타')),
      ];
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );

  return {
    data,
    error,
    isLoading,
  };
};

export const useSaveSchedule = () => {
  const hospitalId = useSelector(selectHospitalId);
  const queryClient = useQueryClient();
  const queryKey = QueryKey.SCHEDULE_ADD;

  const onMutate = handleMutate({ queryClient, queryKey });

  return useMutation(
    async (reservation: ReservationDetail) => {
      const user: any = {
        ...reservation.user,
        tel: reservation.user.tel.replaceAll('-', ''),
        birthday: !_.isEmpty(reservation.user.birthday)
          ? reservation.user.birthday.replace(/^(\d{4})(\d{2})(\d{2})$/, `$1-$2-$3`)
          : '',
      };

      if (_.isEmpty(reservation.user.birthday)) {
        delete user.birthday;
      }

      const param = {
        ...reservation,
        user,
        pet: {
          id: reservation.pet.id,
        },
        reservationTime: reservation.reservationTime.trim().replaceAll(' ', 'T'),
      };

      const response = await HTTP.post(
        `${BASE_URL}/api/hospitals/${UserTypeToUrl[param.userType]}/${hospitalId}/reservations`,
        param,
      );
      return response.data;
    },
    {
      onMutate,
      onSuccess: () => {
        ModalHandler.hide(ModalType.Detail);
        ModalHandler.show(ModalType.Toast, {
          ToastMessage: `예약이 등록되었습니다.`,
        });
        queryClient.refetchQueries([QueryKey.SCHEDULE_LIST], { active: true });
        queryClient.refetchQueries([QueryKey.BLOCK_SCHEDULE_LIST], { active: true });
        queryClient.refetchQueries([QueryKey.MEMBER_LIST], { active: true });
        queryClient.refetchQueries([QueryKey.RESERVATIONS], { active: true });
      },
      onError: (error: AxiosError) => {
        console.info('error', error);
        ModalHandler.show(ModalType.Toast, {
          ToastMessage: `예약 등록에 실패했습니다.`,
        });
      },
    },
  );
};

export const useSavePet = () => {
  const queryClient = useQueryClient();
  const queryKey = QueryKey.PET_ADD;

  const onMutate = handleMutate({ queryClient, queryKey });
  const dispatch = useDispatch();

  return useMutation(
    async ({ data, userType }: { data: Pet; userType: UserType }) => {
      // const param = {
      //   ...data,
      //   birthday: data.birthday.replace(/^(\d{4})(\d{2})(\d{2})$/, `$1-$2-$3`),
      //   user: { id: data.userId },
      // };

      /**
       * 220425 펫 생성 서버측 DTO 수정 반영
       * 생성 시의 파라미터 값만 변경되었기에(조회는 변경 없음) post api 호출 전 DTO 에 맞게 변경
       */
      const param = {
        petName: data.name,
        petType: data.petType,
        breedCode: data.species,
        breedName: data.petBreedName,
        birthday: data.birthday.replace(/^(\d{4})(\d{2})(\d{2})$/, `$1-$2-$3`),
        gender: data.gender,
        weight: Number(data.weight),
        neutralization: data.isNeutralized,
        userId: data.userId,
      };

      const response = await HTTP.post<PetRegisterDto, PetRegisterDto>(
        `${BASE_URL}/api/hospitals/${UserTypeToUrl[userType]}/pet`,
        param,
      );
      const responseData = {
        ...response.data,
        species: response.data.breedCode,
        petBreedName: response.data.breedName,
        isNeutralized: response.data.neutralization,
        name: response.data.petName,
      };
      dispatch(scheduleAction.addEditFetList(responseData));
      return responseData;
    },
    {
      onMutate,
      onSuccess: () => {
        ModalHandler.show(ModalType.Toast, {
          ToastMessage: `반려동물 등록되었습니다.`,
        });
        // queryClient.refetchQueries([QueryKey.MEMBER_LIST], { active: true });
      },
      onError: (error: AxiosError) => {
        console.info('error', error);
        ModalHandler.show(ModalType.Toast, {
          ToastMessage: `반려동물 등록에 실패했습니다.`,
        });
      },
    },
  );
};
