import styled from '@emotion/styled';
import { AHEAD_MINT, WHITE } from 'styles/colors';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 227px;
  text-align: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Logo = styled.div`
  width: 100%;
  height: 100px;
  background-color: ${AHEAD_MINT};
  padding: 42px 57px 33px 58px;
  border-top-left-radius: 5px;
`;

export const Menu = styled.div`
  background-color: ${WHITE};
  width: 100%;
  height: 100%;
  padding: 54px 0 0 46px;
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.05);
`;
