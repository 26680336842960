import React from 'react';

const HospitalLogo = () => (
  <svg width="112" height="20" viewBox="0 0 112 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.125 3.4585C3.34167 3.4585 0 4.9409 0 7.58925C0 11.5368 4.39167 16.542 7.125 16.542C9.85833 16.542 14.25 11.5285 14.25 7.58925C14.25 4.9409 10.9083 3.4585 7.125 3.4585Z"
      fill="white"
    />
    <path
      d="M19.2667 6.54869V8.98883H23.875V11.7704H19.2667V16.4092H16.4333V3.76709H23.9584V6.54869H19.2667Z"
      fill="white"
    />
    <path
      d="M25.2916 4.95748C25.2916 4.0747 26.0166 3.3335 26.8833 3.3335C27.75 3.3335 28.475 4.0747 28.475 4.95748C28.475 5.84026 27.75 6.58147 26.8833 6.58147C26.0166 6.58147 25.2916 5.84859 25.2916 4.95748ZM25.5583 7.38097H28.2166V16.4087H25.5583V7.38097Z"
      fill="white"
    />
    <path
      d="M33.5251 9.97925V13.1772C33.5251 13.9518 34.1834 14.0267 35.3501 13.9518V16.4086C31.8751 16.7667 30.8667 15.7007 30.8667 13.1772V9.97925H29.4501V7.38087H30.8667V5.66528L33.5251 4.84912V7.37254H35.3501V9.97092H33.5251V9.97925Z"
      fill="white"
    />
    <path
      d="M60.2332 9.97925V13.1772C60.2332 13.9518 60.8915 14.0267 62.0582 13.9518V16.4086C58.5915 16.7667 57.5749 15.7007 57.5749 13.1772V9.97925H56.1582V7.38087H57.5749V5.66528L60.2332 4.84912V7.37254H62.0582V9.97092H60.2332V9.97925Z"
      fill="white"
    />
    <path
      d="M51.3583 14.2435C52.0833 14.2435 52.7083 13.9521 53.0583 13.5606L55.1833 14.8099C54.3166 16.0341 53.0083 16.667 51.325 16.667C48.3 16.667 46.4166 14.5933 46.4166 11.9033C46.4166 9.21335 48.3333 7.13965 51.125 7.13965C53.7083 7.13965 55.625 9.18004 55.625 11.9033C55.625 12.2864 55.5916 12.6445 55.5166 12.986H49.2333C49.575 13.9187 50.3833 14.2435 51.3583 14.2435ZM52.9916 10.9539C52.7083 9.90459 51.9083 9.52982 51.1166 9.52982C50.1083 9.52982 49.4333 10.0378 49.1833 10.9539H52.9916Z"
      fill="white"
    />
    <path
      d="M46.0665 8.10604C46.0665 10.5045 44.1749 12.4367 41.7249 12.4367H39.8665V16.4092H37.0332V3.76709H41.7249C44.1665 3.76709 46.0665 5.69922 46.0665 8.10604ZM43.2332 8.10604C43.2332 7.18162 42.5915 6.48206 41.7249 6.48206H39.8665V9.73003H41.7249C42.5915 9.73003 43.2332 9.02214 43.2332 8.10604Z"
      fill="white"
    />
    <path
      d="M70.1083 10.5705H64.0917V4.19113H65.9V5.96502H68.325V4.17447H70.1083V5.31543H72.375V3.3833H74.2V11.3034H72.375V9.54612H70.1083V10.5705ZM74.2583 14.0933C74.2583 15.7089 72.6 16.6583 69.8833 16.6583C67.1833 16.6583 65.525 15.7089 65.525 14.0933C65.525 12.4776 67.1833 11.5449 69.8833 11.5449C72.6 11.5449 74.2583 12.4859 74.2583 14.0933ZM68.325 7.41412H65.9V9.05476H68.325V7.41412ZM72.4333 14.0933C72.4333 13.3437 71.5583 12.9856 69.8917 12.9856C68.2333 12.9856 67.3583 13.3437 67.3583 14.0933C67.3583 14.8428 68.2333 15.2009 69.8917 15.2009C71.5583 15.2009 72.4333 14.8428 72.4333 14.0933ZM70.1083 6.82282V8.0304H72.375V6.82282H70.1083Z"
      fill="white"
    />
    <path
      d="M86.9333 14.9011V16.4168H77.4833V12.4693H79.25V10.6954C78.1333 10.7537 77.0667 10.7704 76.1083 10.7704L75.875 9.25463C78.2 9.23798 81.3 9.21299 83.9833 8.85488L84.1083 10.2124C83.1083 10.3956 82.0917 10.5122 81.0667 10.5871V13.0939H79.3083V14.9094H86.9333V14.9011ZM76.8083 6.19821C76.8083 4.78243 78.125 3.82469 79.9917 3.82469C81.8417 3.82469 83.1583 4.79076 83.1583 6.19821C83.1583 7.60566 81.8417 8.5634 79.9917 8.5634C78.1167 8.5634 76.8083 7.61399 76.8083 6.19821ZM78.5333 6.19821C78.5333 6.8478 79.125 7.18093 79.9833 7.18093C80.825 7.18093 81.425 6.8478 81.425 6.19821C81.425 5.54861 80.8333 5.20716 79.9833 5.20716C79.1333 5.20716 78.5333 5.55694 78.5333 6.19821ZM84.8333 11.0868V3.3833H86.6583V13.4354H84.8333V12.386H82.4417V11.0868H84.8333Z"
      fill="white"
    />
    <path
      d="M95.125 10.7538C93.675 10.3541 92.6917 9.54625 92.1083 8.50524C91.5417 9.65452 90.5333 10.5623 89.0667 10.9787L88.2167 9.50461C90.225 8.94663 91.125 7.55583 91.1667 6.14838H88.675V4.64931H91.1667V3.3418H92.9917V4.64931H95.5083V6.14838H92.9917C93.0333 7.44756 93.9167 8.77174 95.9417 9.28808L95.125 10.7538ZM95.3 13.0024C95.6417 13.9518 96.8667 14.9012 99.05 15.1761L98.4 16.6168C96.3833 16.3587 94.9667 15.5092 94.25 14.3849C93.5167 15.5092 92.1083 16.3253 90.075 16.6002L89.425 15.1594C91.65 14.9012 92.8583 13.9768 93.175 13.0024H89.9833V11.5034H98.5167V13.0024H95.3ZM100.033 6.34825V7.92227H98.3417V10.9287H96.5167V3.38344H98.3417V6.34825H100.033Z"
      fill="white"
    />
    <path
      d="M107.525 4.74917C107.525 8.81329 106.208 12.1279 101.683 14.4015L100.725 12.9024C103.975 11.2784 105.383 9.19639 105.65 6.26489H101.4V4.74917H107.525ZM111.308 3.3667V16.6334H109.467V3.3667H111.308Z"
      fill="white"
    />
  </svg>
);
export default HospitalLogo;
