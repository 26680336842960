import { combineReducers } from 'redux';
import CustomerSlice, { ICustomerState } from 'src/features/customer/customerSlice';
import StatisticSlice, { IStatisticState } from 'src/features/statistic/statisticSlice';
import ScheduleSlice, { ScheduleState } from '../features/schedule/scheduleSlice';
import ReservationSlice, { ReservationState } from '../features/reservation/reservationSlice';
import MemberSlice, { MemberState } from '../features/member/memberSlice';
import AccountSlice, { AccountState } from '../features/account/accountSlice';
import CommonSlice, { CommonState } from '../features/common/commonSlice';

export interface State {
  common: CommonState;
  schedule: ScheduleState;
  reservation: ReservationState;
  member: MemberState;
  account: AccountState;
  customer: ICustomerState;
  statistic: IStatisticState;
}

export const rootReducer = combineReducers({
  common: CommonSlice.reducer,
  schedule: ScheduleSlice.reducer,
  reservation: ReservationSlice.reducer,
  member: MemberSlice.reducer,
  account: AccountSlice.reducer,
  customer: CustomerSlice.reducer,
  statistic: StatisticSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
