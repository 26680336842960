import React, { useCallback } from 'react';
import { ModalType, ModalDuration, DetailModalParams } from 'src/constants/modal';
import CloseIcon from 'src/components/icons/CloseIcon';
import { ModalHandler } from 'src/utils/ModalHandler';
import { ModalWrapper } from '../ModalWrapper';
import * as Styles from './styles';

interface DetailModalProps {
  isModalOpen: boolean;
  modalParams?: DetailModalParams;
  clearModalParams: () => void;
}

const DetailModal = ({ isModalOpen, modalParams, clearModalParams }: DetailModalProps) => {
  const clearModal = useCallback(() => {
    ModalHandler.hide(ModalType.Detail);
  }, []);

  const handleClose = useCallback(() => {
    clearModal();
    modalParams?.onClose?.();
  }, [clearModal, modalParams]);

  // const handleClose = useCallback(() => {
  //   clearModal();
  //   modalParams?.onClose?.();
  // }, [clearModal, modalParams]);

  // const handleClickRegister = useCallback(() => {
  //   clearModal();
  //   modalParams?.onRegister?.();
  // }, [clearModal, modalParams]);

  // const handleClickCancel = useCallback(() => {
  //   clearModal();
  //   modalParams?.onCancel?.();
  // }, [clearModal, modalParams]);

  return (
    <ModalWrapper isModalOpen={isModalOpen} duration={ModalDuration.Register} clearModalParam={clearModalParams}>
      <Styles.ModalContainer isShow={isModalOpen} width={modalParams?.width} height={modalParams?.height}>
        <Styles.ModalHead>
          <Styles.Title>{modalParams?.title}</Styles.Title>
          <Styles.CloseButton onClick={handleClose}>
            <Styles.IconWrapper>
              <CloseIcon />
            </Styles.IconWrapper>
          </Styles.CloseButton>
        </Styles.ModalHead>
        <Styles.ModalContent>{modalParams?.contents}</Styles.ModalContent>
        {/* {modalParams?.registerText && ( */}
        {/*  // <Styles.ModalBottom> */}
        {/*  //   <Styles.ButtonWrapper> */}
        {/*  //     {modalParams?.cancelText && ( */}
        {/*  //       <BasicButton onClick={handleClickCancel} canceled> */}
        {/*  //         {modalParams?.cancelText} */}
        {/*  //       </BasicButton> */}
        {/*  //     )} */}
        {/*  //     <BasicButton onClick={handleClickRegister}>{modalParams?.registerText}</BasicButton> */}
        {/*  //   </Styles.ButtonWrapper> */}
        {/*  // </Styles.ModalBottom> */}
        {/* )} */}
      </Styles.ModalContainer>
    </ModalWrapper>
  );
};

export default DetailModal;
