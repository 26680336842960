import styled from '@emotion/styled';
import { WHITE, GREY_4 } from 'styles/colors';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

export const PetCard = styled.div`
  display: flex;
  border: 1px solid ${GREY_4};
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
  background-color: ${WHITE};
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  font-size: 14px;
  line-height: 18px;

  .name {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    margin-bottom: 2px;
  }
`;

export const StyledProfileIcon = styled.div<{ img: string }>`
  width: 89px;
  height: 89px;
  background-image: url(${(props) => props.img});
  border-radius: 50%;
  background-size: cover;
  margin-right: 17px;
`;
