import React from 'react';

const MainLogo = () => (
  <svg width="212" height="26" viewBox="0 0 212 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5315 0.237305C6.34635 0.237305 0 3.05263 0 8.08226C0 15.5792 8.34047 25.0849 13.5315 25.0849C18.7225 25.0849 27.063 15.5634 27.063 8.08226C27.063 3.05263 20.7167 0.237305 13.5315 0.237305Z"
      fill="#2B51A2"
    />
    <path
      d="M36.5904 6.10496V10.7392H45.3424V16.0219H36.5904V24.8316H31.2095V0.822266H45.5006V6.10496H36.5904Z"
      fill="#2B51A2"
    />
    <path
      d="M48.0327 3.0842C48.0327 1.40766 49.4096 0 51.0555 0C52.7015 0 54.0784 1.40766 54.0784 3.0842C54.0784 4.76075 52.7015 6.16841 51.0555 6.16841C49.4096 6.16841 48.0327 4.77656 48.0327 3.0842ZM48.5392 7.68679H53.5878V24.8318H48.5392V7.68679Z"
      fill="#2B51A2"
    />
    <path
      d="M63.6692 12.6209V18.6944C63.6692 20.1653 64.9195 20.3076 67.1352 20.1653V24.8311C60.5356 25.5112 58.6207 23.4867 58.6207 18.6944V12.6209H55.9302V7.68613H58.6207V4.42794L63.6692 2.87793V7.67031H67.1352V12.605H63.6692V12.6209Z"
      fill="#2B51A2"
    />
    <path
      d="M114.393 12.6209V18.6944C114.393 20.1653 115.643 20.3076 117.858 20.1653V24.8311C111.275 25.5112 109.344 23.4867 109.344 18.6944V12.6209H106.653V7.68613H109.344V4.42794L114.393 2.87793V7.67031H117.858V12.605H114.393V12.6209Z"
      fill="#2B51A2"
    />
    <path
      d="M97.5374 20.7209C98.9142 20.7209 100.101 20.1673 100.766 19.424L104.802 21.7964C103.156 24.1214 100.671 25.3235 97.474 25.3235C91.7291 25.3235 88.1523 21.3852 88.1523 16.2765C88.1523 11.1678 91.7924 7.22949 97.0942 7.22949C102 7.22949 105.64 11.1045 105.64 16.2765C105.64 17.004 105.577 17.6842 105.435 18.3326H93.5016C94.1505 20.1041 95.6857 20.7209 97.5374 20.7209ZM100.639 14.4734C100.101 12.4805 98.5819 11.7688 97.0784 11.7688C95.1634 11.7688 93.8815 12.7336 93.4067 14.4734H100.639Z"
      fill="#2B51A2"
    />
    <path
      d="M87.4878 9.06263C87.4878 13.6178 83.8952 17.2872 79.2423 17.2872H75.713V24.8316H70.332V0.822266H79.2423C83.8794 0.822266 87.4878 4.49168 87.4878 9.06263ZM82.1068 9.06263C82.1068 7.307 80.8882 5.97842 79.2423 5.97842H75.713V12.1468H79.2423C80.8882 12.1468 82.1068 10.8024 82.1068 9.06263Z"
      fill="#2B51A2"
    />
    <path
      d="M133.147 13.7424H121.72V1.62697H125.155V4.99587H129.76V1.59534H133.147V3.76219H137.452V0.0927734H140.918V15.1342H137.452V11.7969H133.147V13.7424ZM141.028 20.4327C141.028 23.5011 137.879 25.3042 132.72 25.3042C127.592 25.3042 124.442 23.5011 124.442 20.4327C124.442 17.3643 127.592 15.5929 132.72 15.5929C137.879 15.5929 141.028 17.3801 141.028 20.4327ZM129.76 7.74793H125.155V10.8638H129.76V7.74793ZM137.562 20.4327C137.562 19.0092 135.901 18.3291 132.735 18.3291C129.586 18.3291 127.924 19.0092 127.924 20.4327C127.924 21.8562 129.586 22.5363 132.735 22.5363C135.901 22.5363 137.562 21.8562 137.562 20.4327ZM133.147 6.62496V8.91834H137.452V6.62496H133.147Z"
      fill="#2EB4BD"
    />
    <path
      d="M165.1 21.9669V24.8455H147.153V17.3485H150.508V13.9796C148.388 14.0903 146.362 14.1219 144.542 14.1219L144.099 11.2434C148.514 11.2117 154.402 11.1643 159.498 10.4842L159.735 13.0622C157.836 13.4102 155.905 13.6316 153.958 13.774V18.5347H150.619V21.9827H165.1V21.9669ZM145.871 5.43873C145.871 2.74994 148.372 0.931045 151.917 0.931045C155.43 0.931045 157.931 2.76575 157.931 5.43873C157.931 8.11171 155.43 9.9306 151.917 9.9306C148.356 9.9306 145.871 8.12752 145.871 5.43873ZM149.147 5.43873C149.147 6.67241 150.271 7.30507 151.901 7.30507C153.499 7.30507 154.639 6.67241 154.639 5.43873C154.639 4.20505 153.515 3.55657 151.901 3.55657C150.287 3.55657 149.147 4.22086 149.147 5.43873ZM161.112 14.723V0.0927734H164.578V19.1832H161.112V17.1903H156.57V14.723H161.112Z"
      fill="#2EB4BD"
    />
    <path
      d="M180.657 14.0913C177.904 13.3321 176.036 11.7979 174.928 9.82084C173.852 12.0035 171.937 13.7275 169.152 14.5183L167.537 11.7188C171.352 10.6591 173.061 8.01776 173.14 5.34479H168.408V2.49783H173.14V0.0146484H176.606V2.49783H181.386V5.34479H176.606C176.685 7.81215 178.363 10.327 182.208 11.3076L180.657 14.0913ZM180.99 18.3617C181.639 20.1648 183.965 21.9679 188.112 22.4898L186.877 25.226C183.047 24.7357 180.357 23.1225 178.996 20.9872C177.603 23.1225 174.928 24.6725 171.067 25.1944L169.832 22.4582C174.058 21.9679 176.353 20.2122 176.954 18.3617H170.893V15.5148H187.099V18.3617H180.99ZM189.979 5.72438V8.71369H186.766V14.4234H183.3V0.0937294H186.766V5.72438H189.979Z"
      fill="#2EB4BD"
    />
    <path
      d="M204.207 2.689C204.207 10.4074 201.706 16.7024 193.113 21.0202L191.293 18.1733C197.465 15.0891 200.14 11.135 200.646 5.5676H192.575V2.689H204.207ZM211.392 0.0634766V25.2591H207.894V0.0634766H211.392Z"
      fill="#2EB4BD"
    />
  </svg>
);

export default MainLogo;
