import React, { useCallback } from 'react';
import { RegisterModalParams, ModalType, ModalDuration } from 'src/constants/modal';
import CloseIcon from 'src/components/icons/CloseIcon';
import { ModalHandler } from 'src/utils/ModalHandler';
import { BasicButton } from 'src/components/common/buttons/BasicButton';
import { ModalWrapper } from '../ModalWrapper';
import * as Styles from './styles';

interface RegisterModalProps {
  isModalOpen: boolean;
  modalParams?: RegisterModalParams;
  clearModalParams: () => void;
}

const RegisterModal = ({ isModalOpen, modalParams, clearModalParams }: RegisterModalProps) => {
  const clearModal = useCallback(() => {
    ModalHandler.hide(ModalType.Register);
  }, []);

  const handleClose = useCallback(() => {
    clearModal();
    modalParams?.onClose?.();
  }, [clearModal, modalParams]);

  const handleClickRegister = useCallback(() => {
    clearModal();
    modalParams?.onRegister?.();
  }, [clearModal, modalParams]);

  const handleClickCancel = useCallback(() => {
    clearModal();
    modalParams?.onCancel?.();
  }, [clearModal, modalParams]);

  return (
    <ModalWrapper isModalOpen={isModalOpen} duration={ModalDuration.Register} clearModalParam={clearModalParams}>
      <Styles.ModalContainer isShow={isModalOpen}>
        <Styles.ModalHead>
          <Styles.Title>{modalParams?.title}</Styles.Title>
          <Styles.CloseButton onClick={handleClose}>
            <Styles.IconWrapper>
              <CloseIcon />
            </Styles.IconWrapper>
          </Styles.CloseButton>
        </Styles.ModalHead>
        <Styles.ModalContent>{modalParams?.contents}</Styles.ModalContent>
        {modalParams?.registerText && (
          <Styles.ModalBottom>
            <Styles.ButtonWrapper>
              {modalParams?.cancelText && (
                <BasicButton onClick={handleClickCancel} canceled>
                  {modalParams?.cancelText}
                </BasicButton>
              )}
              <BasicButton onClick={handleClickRegister}>{modalParams?.registerText}</BasicButton>
            </Styles.ButtonWrapper>
          </Styles.ModalBottom>
        )}
      </Styles.ModalContainer>
    </ModalWrapper>
  );
};

export default RegisterModal;
