import React from 'react';
import TabMenu from 'src/components/common/TabMenu';
import Reservation from 'src/components/reservation/Reservation';
import useReservationTabList from 'src/hooks/reservation/useReservationTabList';
import * as Styles from './styles';

const ReservationsPage = () => {
  const { tabList } = useReservationTabList();

  return (
    <Styles.Wrapper>
      <TabMenu menu={tabList} />
      <Reservation />
    </Styles.Wrapper>
  );
};

export default ReservationsPage;
