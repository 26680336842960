import React from 'react';
import { RoutesIconProps } from 'src/model/route';
import { AHEAD_MINT, GREY_2 } from 'styles/colors';

const ReservationScheduleIcon = ({ isActive }: RoutesIconProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.8344 4.75625H17.1594V2.76562C17.1594 2.34277 16.8166 2 16.3937 2C15.9709 2 15.6281 2.34277 15.6281 2.76562V4.75625H7.97187V2.76562C7.97187 2.34277 7.6291 2 7.20625 2C6.78339 2 6.44062 2.34277 6.44062 2.76562V4.75625H2.76562C2.34277 4.75625 2 5.09902 2 5.52187V20.8344C2 21.2572 2.34277 21.6 2.76562 21.6H20.8344C21.2572 21.6 21.6 21.2572 21.6 20.8344V5.52187C21.6 5.09902 21.2572 4.75625 20.8344 4.75625ZM20.0687 20.0687H3.53125V9.73281H20.0687V20.0687ZM20.0687 8.20156H3.53125V6.2875H20.0687V8.20156Z"
      fill={isActive ? `${AHEAD_MINT}` : `${GREY_2}`}
    />
  </svg>
);

export default ReservationScheduleIcon;
