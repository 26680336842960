import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TabMenuType } from 'src/model/TabMenu';
import * as Styles from './styles';

interface TabMenuProps {
  menu: TabMenuType[];
}

const TabMenu = ({ menu }: TabMenuProps) => {
  const initKey = useMemo(() => menu[1].key, [menu]); // 최초 예약 대기 기본값
  const [selectKey, setSelectKey] = useState(initKey);

  const onChangeKey = useCallback((key: number, callbackFn: () => void) => {
    setSelectKey(key);
    callbackFn();
  }, []);

  // 최초 탭 셋팅
  useEffect(() => {
    menu[1].onClickHandler();
  }, []);

  return (
    <Styles.Wrapper>
      <Styles.TabMenuBar>
        {menu.map((m) => (
          <Styles.TabMenuItem
            key={m.text}
            isActive={m.key === selectKey}
            onClick={() => onChangeKey(m.key, m.onClickHandler)}>
            {m.text}
          </Styles.TabMenuItem>
        ))}
      </Styles.TabMenuBar>
    </Styles.Wrapper>
  );
};

export default TabMenu;
