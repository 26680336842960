import React from 'react';

const DefaultCatIcon = () => (
  <svg width="89" height="89" viewBox="0 0 89 89" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="44.5" cy="44.5" r="44" fill="#F8F8F8" stroke="#EFEFEF" />
    <path
      d="M44.8525 72.6842C30.9201 72.6842 18.9842 58.1862 18.9842 46.3181C18.9842 44.8119 17.8228 26.8185 17.8084 26.6368C17.7316 25.6757 18.1731 24.7624 18.9794 24.2125C19.7905 23.6626 20.7984 23.5813 21.6814 24.0069L32.9646 29.391C33.8045 29.7927 34.7643 29.8549 35.6714 29.5775C38.6086 28.669 41.6945 28.21 44.8525 28.21C48.0104 28.21 51.0964 28.669 54.0336 29.5775C54.9406 29.8596 55.9005 29.7927 56.7404 29.391L68.0235 24.0021C68.9066 23.5813 69.9193 23.6578 70.7303 24.2077C71.5366 24.7576 71.9782 25.6709 71.9014 26.6416C71.8918 26.8137 70.7255 44.8071 70.7255 46.3134C70.7207 58.1862 58.7849 72.6842 44.8525 72.6842ZM20.5104 25.1688C20.2608 25.1688 20.0113 25.2453 19.7953 25.3936C19.4114 25.6518 19.2098 26.0821 19.2434 26.5364C19.2914 27.2871 20.424 44.7689 20.424 46.3181C20.424 57.5407 31.6976 71.2497 44.8525 71.2497C58.0074 71.2497 69.2809 57.5407 69.2809 46.3181C69.2809 44.7689 70.4088 27.2871 70.4568 26.5459C70.4952 26.0821 70.2888 25.6518 69.9097 25.3936C69.5305 25.1353 69.0554 25.0971 68.6426 25.2979L57.3595 30.6869C56.1932 31.2415 54.859 31.3372 53.6016 30.9499C50.8036 30.0844 47.8616 29.6445 44.8477 29.6445C41.8337 29.6445 38.8917 30.0844 36.0938 30.9499C34.8411 31.3372 33.5069 31.2463 32.3407 30.6869L21.0623 25.2979C20.8848 25.2119 20.6976 25.1688 20.5104 25.1688Z"
      fill="#E0E0E0"
      stroke="#E0E0E0"
      strokeWidth="0.6"
    />
    <path
      d="M49.8252 56.6424C48.3326 55.0549 42.3575 55.0549 41.0617 56.6424C40.3514 57.5127 40.265 58.6268 42.2567 60.2143C44.2484 61.8018 44.9443 61.8018 45.4434 61.8018C45.9426 61.8018 46.6385 61.8018 48.6302 60.2143C50.6219 58.6268 50.5931 57.4601 49.8252 56.6424Z"
      fill="#E0E0E0"
    />
    <path
      d="M71.0896 56.7278H62.902C62.5037 56.7278 62.1821 56.4074 62.1821 56.0105C62.1821 55.6136 62.5037 55.2933 62.902 55.2933H71.0896C71.488 55.2933 71.8095 55.6136 71.8095 56.0105C71.8095 56.4074 71.488 56.7278 71.0896 56.7278Z"
      fill="#E0E0E0"
    />
    <path
      d="M71.0896 60.5583H62.902C62.5037 60.5583 62.1821 60.2379 62.1821 59.841C62.1821 59.4442 62.5037 59.1238 62.902 59.1238H71.0896C71.488 59.1238 71.8095 59.4442 71.8095 59.841C71.8095 60.2379 71.488 60.5583 71.0896 60.5583Z"
      fill="#E0E0E0"
    />
    <path
      d="M27.889 56.7278H19.7062C19.3079 56.7278 18.9863 56.4074 18.9863 56.0105C18.9863 55.6136 19.3079 55.2933 19.7062 55.2933H27.8938C28.2922 55.2933 28.6137 55.6136 28.6137 56.0105C28.6137 56.4074 28.2874 56.7278 27.889 56.7278Z"
      fill="#E0E0E0"
    />
    <path
      d="M27.8885 60.5588H19.7057C19.3074 60.5588 18.9858 60.2385 18.9858 59.8416C18.9858 59.4447 19.3074 59.1243 19.7057 59.1243H27.8933C28.2917 59.1243 28.6132 59.4447 28.6132 59.8416C28.6132 60.2385 28.2869 60.5588 27.8885 60.5588Z"
      fill="#E0E0E0"
    />
    <path
      d="M34.2415 52.7877C36.2321 52.7877 37.8458 51.18 37.8458 49.1967C37.8458 47.2134 36.2321 45.6057 34.2415 45.6057C32.2509 45.6057 30.6372 47.2134 30.6372 49.1967C30.6372 51.18 32.2509 52.7877 34.2415 52.7877Z"
      fill="#E0E0E0"
    />
    <path
      d="M55.4617 52.7878C57.4523 52.7878 59.066 51.18 59.066 49.1967C59.066 47.2135 57.4523 45.6057 55.4617 45.6057C53.4711 45.6057 51.8574 47.2135 51.8574 49.1967C51.8574 51.18 53.4711 52.7878 55.4617 52.7878Z"
      fill="#E0E0E0"
    />
  </svg>
);

export default DefaultCatIcon;
