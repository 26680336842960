import { ClosedTime } from '../model/operations';

export const DEFAULT_TEMP_HOLIDAY_DATE: ClosedTime = {
  // closedTimeStart: moment().format(DateFormat),
  // closedTimeEnd: moment().add(7, 'day').format(DateFormat),
  closedTimeStart: '',
  closedTimeEnd: '',
  // closedType: 'HOLIDAY',
};

// export const DEFAULT_TEMP_HOLIDAY_DATES = [DEFAULT_TEMP_HOLIDAY_DATE];
export const DEFAULT_TEMP_HOLIDAY_DATES = [];
