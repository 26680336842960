import React from 'react';
import { GREY_2 } from 'styles/colors';

export enum SortType {
  NORMAL = '',
  ASC = 'ASC',
  DESC = 'DESC',
}

interface ArrowIconProps {
  sortType?: SortType;
}

const GridSortArrowIcon = ({ sortType = SortType.NORMAL }: ArrowIconProps) => (
  <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    {sortType === SortType.NORMAL && (
      <>
        <path d="M5 9L0.669873 5.25L9.33013 5.25L5 9Z" fill={`${GREY_2}`} />
        <path d="M5 0L9.33013 3.75H0.669873L5 0Z" fill={`${GREY_2}`} />
      </>
    )}
    {sortType === SortType.ASC && <path d="M5 0L9.33013 3.75H0.669873L5 0Z" fill={`${GREY_2}`} />}
    {sortType === SortType.DESC && <path d="M5 9L0.669873 5.25L9.33013 5.25L5 9Z" fill={`${GREY_2}`} />}
  </svg>
);
export default GridSortArrowIcon;
