import { useQuery } from 'react-query';
import { QueryKey } from 'src/constants/queryKey';
import { useSelector } from 'react-redux';
import { Operation } from '../../model/operations';
import { OperationApi } from '../../api/operation';
import { selectHospitalId } from '../../selectors';

/** 운영관리 조회 */
export const useGetOperationDetail = () => {
  const hospitalId = useSelector(selectHospitalId);

  const { data, error, isLoading } = useQuery(
    QueryKey.OPERATIOIN_GET,
    async () => {
      const response = await OperationApi.getOperationDetail(hospitalId);
      return response as Operation;
    },
    { refetchOnWindowFocus: false, notifyOnChangeProps: 'tracked' },
  );

  return { data, error, isLoading };
};
