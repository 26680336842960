import { LocalStorageKey } from 'src/constants/localStorage';
import { Me, RequestRefreshToken, RequestSign, ResponseCustomer } from 'src/model/loginUser';
import { BASE_URL, HTTP, refreshTokenInstance, USE_MOCK_API } from 'src/utils/api/http';
import { LocalStorage } from 'src/utils/LocalStorage';

const HOST_URL = () => (USE_MOCK_API ? 'http://localhost:7001' : BASE_URL);

export const UserApi = {
  getUserMe: async () => {
    const response = await HTTP.get<Me>(`${HOST_URL()}/api/accounts/hospital-users/me/`);
    // const response = await HTTP.get<Me>(`${BASE_URL}/api/accounts/hospital-users/me/`);
    return response.data.hospitalId;
  },

  postSignIn: async (data: RequestSign) => {
    const res = await HTTP.post<RequestSign, ResponseCustomer>(`${BASE_URL}/sign-in`, {
      ...data,
    });

    return res;
  },
  postRefreshToken: async (refreshToken: RequestRefreshToken) => {
    refreshTokenInstance.defaults.headers.Authorization = refreshToken.refreshToken;

    const response = await refreshTokenInstance.post(`${BASE_URL}/refresh-token`, {
      ...refreshToken,
    });

    return response;
  },
};

export const UpdateToken = async () => {
  const refreshToken = LocalStorage.getValue(LocalStorageKey.REFRESHTOKEN);

  // 로딩바 사용 안함 설정
  // const dispatch = useDispatch();
  // dispatch(commonAction.setNotLoading());
  return UserApi.postRefreshToken({ refreshToken: refreshToken || '' });
};
