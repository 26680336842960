import { IGender } from '../model/statistic';
import { PetGender } from '../model/pet';

export const setGenderData = (
  data: IGender[] | undefined,
  key: 'petCount' | 'percentage',
  gender: PetGender,
  isNeutralization: 'true' | 'false',
): number => {
  if (data) {
    const temp = data.find((item) => item.gender === gender && item.neutralization === isNeutralization);

    if (temp) {
      return temp[key];
    }
    return 0;
  }

  return 0;
};

export const neutralizationPercentage = (data: IGender[] | undefined): number => {
  if (data) {
    const totalCount = data.reduce((prev, curr) => {
      return prev + curr.petCount;
    }, 0);

    const neutralizationCount = data.reduce((prev, curr) => {
      if (curr.neutralization === 'true') {
        return prev + curr.petCount;
      }
      return prev;
    }, 0);

    return Math.round((neutralizationCount / totalCount) * 100);
  }

  return 0;
};
