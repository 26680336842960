import styled from '@emotion/styled';
import { GREY_1, GREY_2, GREY_3, MINT, WHITE } from 'styles/colors';
import { css } from '@emotion/react';

interface GridStylesProps {
  width?: string;
  isSort?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${WHITE};
  border-radius: 0px 10px 10px 10px;
  padding: 48px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

export const TopDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const GridWrapper = styled.div`
  width: 100%;
  //height: 623px;
  overflow-y: hidden;
  overflow-x: auto;
`;

export const GridTable = styled.table`
  width: auto;
  border-spacing: 0;
  border-bottom: 1px solid ${GREY_3};
`;

export const Header = styled.th(
  ({ width = '150px', isSort = false }: GridStylesProps) => css`
    border-top: 1px solid ${GREY_3};
    border-bottom: 1px solid ${GREY_3};
    text-align: center;
    height: 100%;
    padding: 0 13px 0 13px;

    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.02em;
    color: ${GREY_2};
    min-width: ${width};
    background-color: ${WHITE};

    cursor: ${isSort ? 'pointer' : 'default'};
  `,
);

export const Thead = styled.thead`
  height: 48px;
`;

export const TBody = styled.tbody``;

export const HeaderTr = styled.tr`
  height: 48px;

  th:first-of-type {
    position: -webkit-sticky;
    position: sticky;
    background-color: white;
    width: 90px;
    min-width: 90px;
    max-width: 90px;
    left: 0;
  }
  th:nth-of-type(2) {
    position: -webkit-sticky;
    position: sticky;
    background-color: white;
    width: 101px;
    min-width: 101px;
    max-width: 101px;
    left: 90px;
  }
`;

export const BodyTr = styled.tr`
  height: 56px;
  cursor: pointer;

  :hover {
    background-color: ${MINT} !important;
    td {
      background-color: ${MINT} !important;
    }
  }

  td:first-of-type {
    position: -webkit-sticky;
    position: sticky;
    width: 90px;
    min-width: 90px;
    max-width: 90px;
    left: 0px;
    background-color: ${WHITE};
  }
  td:nth-of-type(2) {
    position: -webkit-sticky;
    position: sticky;
    width: 101px;
    min-width: 101px;
    max-width: 101px;
    left: 90px;
    background-color: ${WHITE};
  }
`;

export const EmptyBodyTr = styled.tr`
  height: 144px;
`;

export const Td = styled.td`
  text-align: center;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: ${GREY_1};
`;

export const PaginationDiv = styled.div`
  margin-top: 16px;
  width: 100%;
  height: 24px;
  text-align: right;
`;
