export enum QueryKey {
  // MEMBER = 'member',
  HOSPITAL_INFO = 'hospital/info',
  MEMBER_CHECK = 'member/check',
  MEMBER_CHECK_AVAILABLE_HOURS = 'member/check/available/hours',
  MEMBER_CHECK_AVAILABLE_DAYS = 'member/check/available/days',
  MEMBER_LIST = 'member/list',
  MEMBER_ADD = 'member/add',
  MEMBER_DETAIL = 'member/detail',
  MEMBER_DELETE = 'member/delete',
  MEMBER_UPDATE = 'member/update',
  SCHEDULE_LIST = 'schedule/list',
  SCHEDULE_ADD = 'schedule/add',
  PET_ADD = 'pet/add',
  PET_BREED_LIST = 'pet/breed/list',
  USER = 'user',
  USER_SING_IN = 'user/sing-in',
  USER_REFRESH = 'user/token/refresh',
  OPERATIOIN_GET = 'operation/get',
  OPERATIOIN_ADD = 'operation/add',
  OPERATIOIN_UPDATE = 'operation/update',
  // OPERATION_DETAIL = 'operation/detail',
  RESERVATIONS = 'reservations',
  TODAY_APPLIED_RESERVATIONS = 'today/applied/reservations',
  RESERVATION = 'reservation',
  AVAILABLEDAYS = 'availableDays',
  NON_USER_ADD = 'nonuser/add',
  NON_USER_LIST = 'nonuser/list',
  NON_USER_RESERVATIONS = 'nonuser/reservations',
  CUSTOMER_LIST = 'customer/list',
  RESERVATION_STATUS = 'statistic/reservation/status',
  NEW_RESERVATION = 'new/reservation',
  BLOCK_SCHEDULE_LIST = 'block-schedule/list',
  BLOCK_SCHEDULE_ADD = 'block-schedule/add',
  BLOCK_SCHEDULE_DELETE = 'block-schedule/delete',
  HOLIDAY_LIST = 'holiday/list',
}
