import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as Styles from './styles';
import useClickOutside from '../../../hooks/dom/useClickOutside';

export interface OptionValueProps {
  value: string;
  label: string;
}
interface SelectSearchBoxProps {
  value?: OptionValueProps;
  items?: OptionValueProps[];
  placeholder?: string;
  onChange: (selectValue: string, selectLabel?: string) => void;
  disabled?: boolean;
  readOnly?: boolean;
  width?: number;
  height?: number;
  optionHeight?: number;
}
const SelectSearchBox = ({
  onChange,
  value = { value: '', label: '' },
  items = [],
  placeholder = '선택해 주세요',
  disabled = false,
  readOnly = false,
  width,
  height,
  optionHeight,
}: SelectSearchBoxProps) => {
  const [text, setText] = useState('');
  const [select, setSelect] = useState(value);
  const [active, setActive] = useState(false);
  const filteredItems = useMemo(() => {
    const searchText = text.trim();
    return items?.filter((o) => {
      if (searchText !== '') {
        return o.label.includes(searchText);
      }
      return true;
    });
  }, [items, text]);

  useEffect(() => {
    setSelect(value);
  }, [value]);

  const displayLabel = useMemo(() => {
    if (active) {
      return text;
    }
    return select ? select.label : '';
  }, [active, text, select]);

  const toggleActive = useCallback(() => {
    if (!readOnly) {
      setActive(!active);
    }
  }, [active, readOnly]);

  const changeValue = useCallback(
    (item) => {
      setSelect(item);
      setActive(!active);
      setText('');
      onChange(item.value, item.label);
    },
    [active, onChange],
  );
  const handleKeyUp = useCallback(
    (e) => {
      // console.info('keyup', e.key);
      if (e.key === 'Escape') {
        e.currentTarget.blur();
        setActive(false);
        setText('');
      }
      // 한개일때 엔터 칠 경우 자동선택
      if (e.key === 'Enter') {
        if (filteredItems.length === 1) {
          changeValue(filteredItems[0]);
          e.currentTarget.blur();
        } else if (select?.value) {
          const idx = filteredItems.findIndex((item) => {
            return item.label === select?.label || item.value === select?.value;
          });
          if (idx !== -1) {
            changeValue(select);
            e.currentTarget.blur();
          }
        }
      }
      if (e.key === 'ArrowDown') {
        const idx = filteredItems.findIndex((item) => {
          return item.label === select?.label || item.value === select?.value;
        });
        // const idx = filteredItems.findIndex((item) => item === select);
        const nextItem = filteredItems[idx + 1];
        if (nextItem) {
          setSelect(nextItem);
          onChange(nextItem.value, nextItem.label);
        }
      } else if (e.key === 'ArrowUp') {
        // const idx = filteredItems.findIndex((item) => item === select);
        const idx = filteredItems.findIndex((item) => {
          return item.label === select?.label || item.value === select?.value;
        });
        const prevItem = filteredItems[idx - 1];
        if (prevItem) {
          setSelect(prevItem);
          onChange(prevItem.value, prevItem.label);
        }
      }
    },
    [filteredItems, select],
  );

  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => {
    setActive(false);
    setText('');
  });

  return (
    <Styles.Wrapper ref={wrapperRef}>
      <Styles.Input
        width={width}
        height={height}
        onChange={(e) => {
          setText(e.target.value);
        }}
        placeholder={placeholder}
        value={displayLabel}
        onClick={toggleActive}
        onKeyUp={(e) => handleKeyUp(e)}
        className={(active ? 'active ' : '') + (disabled ? 'disabled ' : '')}
        disabled={disabled}
        readOnly={readOnly}
      />
      {filteredItems && filteredItems.length > 0 && (
        <Styles.OptionWrapper className={active ? 'active' : ''} width={width} optionHeight={optionHeight}>
          <ul>
            {filteredItems.map((item) => (
              <Styles.Item
                key={item.value}
                className={`${select?.value === item.value || select?.label === item.label ? 'active' : ''}`}
                onClick={() => changeValue(item)}>
                {item.label}
              </Styles.Item>
            ))}
          </ul>
        </Styles.OptionWrapper>
      )}
    </Styles.Wrapper>
  );
};

export default SelectSearchBox;
