import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import FullCalendar, { DateSelectArg, DayCellMountArg, EventInput, SlotLaneMountArg } from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import tippy from 'tippy.js';
import dayjs from 'dayjs';
import * as Styles from './styles';
import CheckBoxIcon from '../../components/icons/CheckBoxIcon';
import { GREY_1, GREY_2, GREY_3, SCHEDULE_DOT_COLORS, WHITE } from '../../../styles/colors';
import useSchedule from '../../hooks/schedule/useSchedule';
import { selectSchedule } from '../../selectors';
import { Reservation, ReservationStatusType, ReservationStatusTypeToString } from '../../model/reservation';
import { useGetMemberList } from '../../query/Member/useMemberQuery';
import { useGetScheduleReservations } from '../../query/Schedule/useScheduleQuery';
import { scheduleAction } from '../../features/schedule/scheduleSlice';

import 'tippy.js/dist/tippy.css';
import { RootState } from '../../reducers';
import { DayTypeToNumber } from '../../model/workingDay';
import {
  useCancelBlockSchedule,
  useGetBlockSchedule,
  useGetHospitalHoliday,
  useSaveBlockSchedule,
} from '../../query/Schedule/useBlockScheduleQuery';
import { DateTimeFormat } from '../../constants/workHours';
import { ModalHandler } from '../../utils/ModalHandler';
import { ModalType } from '../../constants/modal';
import { accountAction } from '../../features/account/accountSlice';

const Schedule = () => {
  const params = useParams<{ day?: string }>();
  // console.info('params', params);
  const initialDate = useMemo(() => {
    const paramDate = moment(params.day, 'YYYY-MM-DD');
    if (paramDate.isValid()) {
      return moment(params.day).format('YYYY-MM-DD');
    }
    return moment().format('YYYY-MM-DD');
  }, [params]);

  const dispatch = useDispatch();
  const scheduleState = useSelector(selectSchedule);
  const hospital = useSelector((state: RootState) => state.account.hospital);
  // const hospitalId = useSelector(selectHospitalId);
  const reservations = useMemo(() => scheduleState.reservations, [scheduleState.reservations]);
  const saveBlock = useSaveBlockSchedule();
  const cancelBlock = useCancelBlockSchedule();

  const { openAddSchedule, openReservationDetailModal } = useSchedule();
  const { data: memberList } = useGetMemberList();
  const { data: scheduleResponse, isLoading } = useGetScheduleReservations();
  // const { data: reservationResponse, isLoading: reservationLoading } = useGetReservations(hospitalId, {});
  const { data: blockSchedule, isLoading: isBlockLoading } = useGetBlockSchedule();
  const { data: holiday, isLoading: isHolidayLoading } = useGetHospitalHoliday();
  const [businessHours, setBusinessHours] = useState<EventInput[]>([]);

  useEffect(() => {
    const hours: EventInput[] = hospital.workingDay?.map((wDay) => {
      return {
        daysOfWeek: [DayTypeToNumber[wDay.workDay]],
        startTime: wDay.workTimeStart,
        endTime: wDay.workTimeEnd,
      };
    });

    setBusinessHours(hours);
  }, [hospital]);

  useEffect(() => {
    if (!isLoading || !isBlockLoading) {
      dispatch(
        scheduleAction.updateReservations([...(scheduleResponse ?? []), ...(blockSchedule ?? [])] as Reservation[]),
      );
    }
  }, [scheduleResponse, blockSchedule, isLoading, isBlockLoading, dispatch]);

  useEffect(() => {
    if (isHolidayLoading || !holiday) return;
    dispatch(accountAction.setHospitalHoliday(holiday));
  }, [dispatch, holiday, isHolidayLoading]);

  // 탭 선택 멤버
  const [viewMembers, setViewMembers] = useState([] as number[]);
  // 예약 대기만 보기
  const [onlyWaiting, setOnlyWaiting] = useState(false);
  const calendarRef = useRef<FullCalendar>() as React.MutableRefObject<FullCalendar>;

  const toReservationLabel = useCallback(
    (reservation: Reservation) => {
      const start = moment(reservation.reservationTime).format('HH:mm');
      const end =
        reservation.serviceStatus === ReservationStatusType.Block
          ? moment(reservation.closedTimeEnd).format('HH:mm')
          : moment(reservation.reservationTime).add(30, 'minutes').format('HH:mm');

      const doctor = memberList?.find((m) => m.id === reservation.hospitalMemberId)?.name || '';
      const title = `${start} - ${end} [${doctor}] ${reservation.userName} ${reservation.petName} ${
        ReservationStatusTypeToString[reservation.serviceStatus]
      }`;
      return title;
    },
    [memberList],
  );

  // fullcalendar 에서 사용하는 예약 리스트
  const reservationList: EventInput[] = useMemo(() => {
    const memberIds = memberList ? memberList.map((m) => m.id) : [];
    return (
      reservations
        // .filter((reservation) => memberIds.includes(Number(reservation)))
        .filter((reservation) => {
          if (viewMembers.length === 0) {
            return true;
          }
          // 선택한 멤버 스케쥴만
          return viewMembers.includes(reservation.hospitalMemberId);
        })
        .filter((reservation) => {
          return (
            reservation.serviceStatus === ReservationStatusType.Applied ||
            reservation.serviceStatus === ReservationStatusType.Confirmed ||
            reservation.serviceStatus === ReservationStatusType.Block
          );
        })
        .filter((reservation) =>
          // 예약 대기만
          onlyWaiting ? reservation.serviceStatus === ReservationStatusType.Applied : true,
        )
        .map((reservation) => {
          // const doctor = memberList?.find((m) => m.id === reservation.hospitalMemberId);
          const title = toReservationLabel(reservation);
          const start = reservation.reservationTime;
          const end =
            reservation.serviceStatus === ReservationStatusType.Block
              ? moment(reservation.closedTimeEnd).format('YYYY-MM-DD HH:mm:00')
              : moment(start).add(30, 'minutes').format('YYYY-MM-DD HH:mm:00');

          const { hospitalMemberId } = reservation;
          const idx = memberIds.indexOf(hospitalMemberId);
          const backgroundColor =
            reservation.serviceStatus === ReservationStatusType.Block
              ? '#aaa'
              : SCHEDULE_DOT_COLORS[idx % SCHEDULE_DOT_COLORS.length];
          // noshow, 방문완료
          const isDisabled =
            reservation.serviceStatus === ReservationStatusType.NoShow ||
            reservation.serviceStatus === ReservationStatusType.Visited;
          const isConfirm =
            reservation.serviceStatus === ReservationStatusType.Confirmed ||
            reservation.serviceStatus === ReservationStatusType.Block;
          const item = {
            title,
            start,
            end,
            extendedProps: reservation,
            backgroundColor,
            textColor: isConfirm ? GREY_1 : GREY_2,
            borderColor: GREY_1,
          };
          return !isDisabled ? item : { ...item, backgroundColor: GREY_3, textColor: WHITE, borderColor: WHITE };
        })
    );
  }, [memberList, reservations, viewMembers, onlyWaiting, toReservationLabel]);

  const toggleMember = useCallback(
    (id) => {
      if (id === 0) {
        // 전체
        setViewMembers([]);
      } else if (viewMembers.includes(id)) {
        setViewMembers([...viewMembers.filter((uid) => uid !== id)]);
      } else {
        setViewMembers([...viewMembers, id]);
      }
    },
    [viewMembers],
  );

  const toggleOnlyWaiting = useCallback(() => {
    setOnlyWaiting(!onlyWaiting);
  }, [onlyWaiting]);

  const getColorChip = useCallback((idx) => SCHEDULE_DOT_COLORS[idx % SCHEDULE_DOT_COLORS.length], []);

  // 기존 이벤트 클릭 - 상세화면 팝업
  const eventClick = useCallback(
    (info) => {
      const { extendedProps: reservation } = info.event;
      // console.info('기존이벤트', reservation);
      if (reservation.serviceStatus === ReservationStatusType.Block) {
        cancelBlock.mutate([reservation.id]);
        return;
      }
      openReservationDetailModal(reservation);
    },
    [cancelBlock, openReservationDetailModal],
  );

  // 빈 날짜 클릭 이벤트 - 신규예약 팝업
  const dateClick = useCallback(
    (arg: DateClickArg) => {
      openAddSchedule(moment(arg.dateStr));
    },
    [openAddSchedule],
  );

  const selectHandler = (arg: DateSelectArg) => {
    const closedTimeEnd = arg.endStr;
    const closedTimeStart = arg.startStr;

    if (dayjs(closedTimeStart).isBefore(dayjs())) {
      ModalHandler.show(ModalType.Toast, {
        ToastMessage: '블락 시간은 현재시간보다\n이후여야 합니다.',
        duration: 300,
      });
      return;
    }

    if (!dayjs(arg.startStr).add(30, 'minute').isSame(dayjs(arg.endStr))) {
      if (viewMembers.length > 1 || !viewMembers.length) {
        ModalHandler.show(ModalType.Toast, {
          ToastMessage: '블락은 한 명의 의사만\n선택하여 사용 가능합니다.',
          duration: 300,
        });
        return;
      }

      saveBlock.mutate({
        memberId: viewMembers[0],
        closedTimeStart,
        closedTimeEnd,
      });
    }
  };

  const onEventDidMount = useCallback(
    (info) => {
      tippy(info.el, {
        content: toReservationLabel(info.event.extendedProps), // 이벤트 디스크립션을 툴팁으로 가져옵니다.
        onMount(instance) {
          const handler = () => instance.hide();
          document.getElementsByClassName('fc-scroller')[1].addEventListener('scroll', handler);

          setTimeout(() => {
            document.getElementsByClassName('fc-scroller')[1].removeEventListener('scroll', handler);
          }, 3000);
        },
      });
    },
    [toReservationLabel],
  );

  // 이전, 다음, 오늘 날짜 버튼 이벤트
  // const dateChange = useCallback((startDate: Date) => {
  //   // console.info('TODO dateChange', startDate);
  //   // getScheduleByMonth(moment(startDate).format('YYYY-MM'));
  // }, []);

  const slotLaneDidMount = useCallback((info: SlotLaneMountArg) => {
    /**
     * 마우스 오른쪽 버튼 클릭 시, 해당 위치의 좌표값과 시간 값을 postMessage 로 push
     * 동시에 날짜 값을 그려주고 있는 부모 element 의 z-index 를 높이기 위해 className 추가
     */
    info.el.addEventListener('contextmenu', (event) => {
      event.preventDefault();
      const activeMembers = Array.from(document.querySelectorAll('.userWrapper .active')).filter(
        (button) => button.innerHTML !== '전체',
      );
      if (!activeMembers.length || activeMembers.length > 1) {
        ModalHandler.show(ModalType.Toast, {
          ToastMessage: '블락은 한 명의 의사만\n선택하여 사용 가능합니다.',
          duration: 300,
        });
        return;
      }
      const activeMemberId = activeMembers[0].getAttribute('data-id');
      document.querySelector('.fc-timegrid-cols')?.classList.add('upIndex');
      window.postMessage(
        {
          calendar: `${event.pageX},${event.pageY}`,
          time: info.el.getAttribute('data-time'),
          memberId: activeMemberId,
        },
        window.location.origin,
      );
    });
  }, []);

  const dayCellDidMount = useCallback(
    (info: DayCellMountArg) => {
      // holiday 체크하여 calendar 에 회색 영역 표시
      if (!holiday) return;
      holiday.forEach((day) => {
        if (
          moment(info.date).startOf('day').isSameOrAfter(moment(day.closedTimeStart).startOf('day')) &&
          moment(info.date).startOf('day').isSameOrBefore(moment(day.closedTimeEnd).startOf('day'))
        ) {
          const targetDate = moment(info.date).format('YYYY-MM-DD');
          const targetDateElement: HTMLElement | null = document.querySelector(
            `td[data-date='${targetDate}'] .fc-timegrid-col-frame`,
          );
          if (targetDateElement) {
            targetDateElement.style.background = 'rgba(215,215,215,0.3)';
            targetDateElement.classList.add('bg-none');
          }
        }
      });

      // postMessage 로 보낸 좌표값을 message event 로 받아서 처리
      window.addEventListener('message', (event) => {
        if (!event.data?.calendar || !event.data?.time || !event.data?.memberId) return;
        const [pageX, pageY]: Array<string> = event.data.calendar.split(',');
        const element = document.elementFromPoint(Number(pageX), Number(pageY)); // 좌표 값에 있는 element 가져오기
        const targetDate = element?.parentElement?.parentElement?.getAttribute('data-date') ?? '';
        const targetTime = event.data.time;
        const closedTimeStart = moment(`${targetDate} ${targetTime}`).format(DateTimeFormat);
        const closedTimeEnd = moment(`${targetDate} ${targetTime}`).add(30, 'minutes').format(DateTimeFormat);

        document.querySelector('.fc-timegrid-cols')?.classList.remove('upIndex');

        if (!targetDate) return;

        if (moment(closedTimeStart).isBefore(moment())) {
          ModalHandler.show(ModalType.Toast, {
            ToastMessage: '블락 시간은 현재시간보다\n이후여야 합니다.',
            duration: 300,
          });
          return;
        }

        saveBlock.mutate({
          memberId: Number(event.data.memberId),
          closedTimeStart,
          closedTimeEnd,
        });
      });
    },
    [holiday, saveBlock],
  );

  return (
    <Styles.Wrapper>
      <Styles.TopWrapper>
        <Styles.UserWrapper className="userWrapper">
          <Styles.AllButton className={viewMembers.length === 0 ? 'active' : ''} onClick={() => toggleMember(0)}>
            전체
          </Styles.AllButton>
          {memberList &&
            memberList.map((member, idx) => (
              <Styles.UserButton
                key={member.id}
                colorChip={getColorChip(idx)}
                stringLength={member.name.length}
                onClick={() => toggleMember(member.id)}
                className={viewMembers.includes(member.id) ? 'active' : ''}
                data-id={member.id}>
                {member.name} 수의사
              </Styles.UserButton>
            ))}
        </Styles.UserWrapper>
        <Styles.CheckBoxWrap role="button" onClick={() => toggleOnlyWaiting()}>
          <CheckBoxIcon checked={onlyWaiting} />
          예약대기만 보기
        </Styles.CheckBoxWrap>
      </Styles.TopWrapper>
      {/* holiday 가 바인딩 된 상태에서 그려줘야 dayCellDidMount 에서 holiday 값에 따른 처리가 가능함 */}
      {holiday && (
        <Styles.CalendarWrapper>
          <FullCalendar
            ref={calendarRef}
            plugins={[timeGridPlugin, interactionPlugin, momentPlugin]}
            titleFormat="YYYY.MM.DD" /*: 'MMMM D, YYYY' // you can now use moment format strings! */
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'timeGridWeek,timeGridDay',
            }}
            initialView="timeGridWeek"
            height="100%"
            locale="ko"
            events={reservationList}
            timeZone="Asia/Seoul"
            allDaySlot={false}
            displayEventTime={false}
            initialDate={initialDate}
            buttonText={{
              today: '오늘',
              month: '월별',
              week: '주별',
              day: '일별',
              list: 'list',
            }}
            eventClick={(info) => eventClick(info)}
            dateClick={(date) => dateClick(date)}
            slotLaneDidMount={slotLaneDidMount}
            dayCellDidMount={dayCellDidMount}
            // slotMinTime="07:00:00"
            // slotMaxTime="24:00:00"
            nowIndicator
            nowIndicatorClassNames="nowIndicator"
            // eventMinHeight={15}
            // datesSet={(arg) => dateChange(arg.start)}
            eventDidMount={(info) => {
              onEventDidMount(info);
            }}
            scrollTime="09:00:00"
            businessHours={businessHours}
            selectable
            select={(data) => selectHandler(data)}
          />
        </Styles.CalendarWrapper>
      )}
    </Styles.Wrapper>
  );
};
export default Schedule;
