import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Styles from './styles';
import useClickOutside from '../../../hooks/dom/useClickOutside';

export interface OptionValueProps {
  value: string;
  label: string;
  isAvailable?: boolean;
}
interface SelectBoxProps {
  value?: OptionValueProps;
  items?: OptionValueProps[];
  placeholder?: string;
  onChange: (selectValue: string) => void;
  disabled?: boolean;
  width?: number;
  height?: number;
  top?: boolean; // 팝업창 위치
  optionHeight?: number;
  reservationAvailable?: boolean;
}
const SelectBox = ({
  onChange,
  value = { value: '', label: '' },
  items = [],
  placeholder = '선택해 주세요',
  disabled = false,
  width,
  height,
  top = false,
  optionHeight,
  reservationAvailable,
}: SelectBoxProps) => {
  const [select, setSelect] = useState(value);
  const [active, setActive] = useState(false);

  const toggleActive = useCallback(() => {
    setActive(!active);
  }, [active]);

  const changeValue = useCallback(
    (item) => {
      // if (item.isAvailable === false) {
      //   return;
      // }
      setSelect(item);
      setActive(false);
      onChange(item.value);
    },
    [onChange],
  );

  useEffect(() => {
    // console.info('change Value', value, select);
    if (value?.value !== select?.value && reservationAvailable) {
      const findItem = items.find((item) => item.value === value.value);
      if (findItem) {
        changeValue(findItem);
      }
    }
  }, [items, value, select, active, onChange, changeValue, reservationAvailable]);

  useEffect(() => {
    if (!value?.value && select?.value) {
      setSelect({
        value: '',
        label: '',
      });
    }
  }, [value, select]);

  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => {
    setActive(false);
  });

  useEffect(() => {
    if (value?.value) {
      setSelect(value);
    }
  }, [value]);

  useEffect(() => {
    if (items?.length === 1) {
      changeValue(items[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  return (
    <Styles.Wrapper ref={wrapperRef}>
      <Styles.Input
        readOnly
        width={width}
        height={height}
        placeholder={placeholder}
        value={select.value && select?.value !== ' ' ? select?.label : ''}
        onClick={toggleActive}
        className={(active ? 'active ' : '') + (disabled ? 'disabled ' : '')}
        disabled={disabled}
      />
      {items && items.length > 0 && (
        <Styles.OptionWrapper className={active ? 'active' : ''} width={width} optionHeight={optionHeight} isTop={top}>
          <ul>
            {items.map((item) => (
              <Styles.Item
                key={item.value}
                onClick={() => {
                  if (item.isAvailable !== false) {
                    changeValue(item);
                  }
                }}
                className={`${item.isAvailable === false ? 'disabled' : ''}`}>
                {item.label}
              </Styles.Item>
            ))}
          </ul>
        </Styles.OptionWrapper>
      )}
    </Styles.Wrapper>
  );
};

export default SelectBox;
