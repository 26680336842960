import React, { useMemo } from 'react';
import { ResponsivePie, PieCustomLayerProps } from '@nivo/pie';
import { ICustomer } from 'src/model/customer';
import * as CustomerStyles from '../styles';

interface IPieData {
  id: string;
  value: number;
  color?: string;
}

const CenteredMetric = ({ dataWithArc, centerX, centerY }: PieCustomLayerProps<IPieData>) => {
  let total = 0;
  dataWithArc.forEach((datum) => {
    total += datum.value;
  });

  return (
    <>
      <text
        x={centerX}
        y={centerY - 15}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: '12px',
          lineHeight: '14px',
        }}>
        총 예약
      </text>
      <text
        x={centerX}
        y={centerY + 5}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: '18px',
          lineHeight: '22px',
          fontWeight: 600,
        }}>
        {total}
      </text>
    </>
  );
};

interface ICustomerReservationHistoryProps {
  customer: ICustomer;
}

export default function CustomerReservationHistory({ customer }: ICustomerReservationHistoryProps) {
  const totalCount: number = useMemo(
    () => customer.visitedCount + customer.cancelledCount + customer.noShowCount,
    [customer],
  );
  const chartData = useMemo(
    () => [
      {
        id: '방문 완료',
        value: customer.visitedCount,
        color: '#2EB4BD',
      },
      {
        id: '예약 취소',
        value: customer.cancelledCount,
        color: '#868E96',
      },
      {
        id: '노쇼',
        value: customer.noShowCount,
        color: '#B3BDC7',
      },
    ],
    [customer],
  );

  return (
    <CustomerStyles.Article>
      <h3>예약 내역</h3>
      <p>전체 예약 내역 총 {totalCount}회</p>

      <div style={{ height: 190 }}>
        <ResponsivePie
          data={chartData}
          tooltip={() => null}
          margin={{
            bottom: 46,
          }}
          innerRadius={0.8}
          layers={['arcs', 'legends', CenteredMetric]}
          colors={({ data }) => data.color}
          legends={[
            {
              anchor: 'bottom',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: 32,
              itemsSpacing: 0,
              itemWidth: 100,
              itemHeight: 12,
              itemTextColor: '#434343',
              itemDirection: 'left-to-right',
              itemOpacity: 1,
              symbolSize: 8,
              symbolShape: 'square',
              data: chartData.map((item) => {
                return {
                  color: item.color,
                  id: item.id,
                  label: `${item.id} ${item.value}회`,
                };
              }),
            },
          ]}
        />
      </div>
    </CustomerStyles.Article>
  );
}
