import { BASE_URL, HTTP } from 'src/utils/api/http';
import { Operation } from '../model/operations';

export const OperationApi = {
  getOperationDetail: async (hospitalId: number) => {
    const result = await HTTP.get<Operation>(`${BASE_URL}/api/hospitals/${hospitalId}/operation`);
    return result.data;
  },
  postOperationDetail: async (hospitalId: number, data: Operation) => {
    const result = await HTTP.post<Operation, Operation>(`${BASE_URL}/api/hospitals/${hospitalId}/operation`, {
      ...data,
    });
    return result.data;
  },
  putOperationDetail: async (hospitalId: number, data: Operation) => {
    const result = await HTTP.put<Operation, Operation>(`${BASE_URL}/api/hospitals/${hospitalId}/operation`, {
      ...data,
    });
    return result.data;
  },
};
