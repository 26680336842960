import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import AddScheduleModal from 'src/components/schedule/AddScheduleModal';
import moment, { Moment } from 'moment';
import { ModalHandler } from '../../utils/ModalHandler';
import { ModalType } from '../../constants/modal';
import ReservationDetailModal from '../../components/reservation/ReservationDetailModal';
import { scheduleAction } from '../../features/schedule/scheduleSlice';
import { DayTypeNumberToDay } from '../../model/workingDay';
import type { RootState } from '../../reducers';
import type { Holiday } from '../../model/calendar';

const isHolidayDate = (date: Moment) => (h: Holiday) => {
  const cloneDate = date.clone();
  return (
    cloneDate.startOf('day').isSameOrAfter(moment(h.closedTimeStart).startOf('day')) &&
    cloneDate.isSameOrBefore(moment(h.closedTimeEnd).startOf('day'))
  );
};

const useSchedule = () => {
  const dispatch = useDispatch();
  const account = useSelector((state: RootState) => state.account);
  const workingDays = useMemo(() => {
    return account.hospital.workingDay;
  }, [account]);
  const holiday = useMemo(() => account.holiday, [account]);
  const location = useLocation();

  const openAddSchedule = useCallback(
    (date = moment(), skipValidation = false) => {
      // validation
      let message = '';
      // 병원 운영 시간
      const dayType = Number(date.format('e'));
      const findWorkDay = workingDays?.find((wDay) => wDay.workDay === DayTypeNumberToDay[dayType]);
      if (findWorkDay) {
        const yyyymmdd = date.format('YYYY-MM-DD');
        const isWorkingTime = moment(date)
          .add(1, 'minutes') // 시간 보정필요 마지막 운영시간 6시마감인데 6시~6시30분 사이 클릭하면 시간에 포함되서 통과가 되어 1분 더함
          .isBetween(
            moment(`${yyyymmdd} ${findWorkDay.workTimeStart}`),
            moment(`${yyyymmdd} ${findWorkDay.workTimeEnd}`),
            null,
            '[]',
          );
        if (!isWorkingTime) {
          message = `병원 운영시간이 아닙니다.`;
        }
      } else {
        message = `병원 운영시간이 아닙니다.`;
      }

      if (holiday.length && holiday.some(isHolidayDate(date))) {
        message = '병원 운영시간이 아닙니다.';
      }

      if (message === '' && date.isBefore(moment())) {
        message = `예약 시간은 현재시간보다\n이후여야 합니다.`;
      }
      if (message !== '' && !skipValidation) {
        ModalHandler.show(ModalType.Toast, {
          ToastMessage: message,
          duration: 300,
        });
        return;
      }

      dispatch(scheduleAction.initEdit());
      // dispatch(scheduleAction.updateEditField({ key: 'desiredDate', value: date.format('YYYY-MM-DD') }));
      // dispatch(scheduleAction.updateEditField({ key: 'desiredHour', value: date.format('HH:mm') }));
      dispatch(scheduleAction.updateEditField({ key: 'reservationTime', value: date.format('YYYY-MM-DD HH:mm') }));
      ModalHandler.show(ModalType.Detail, {
        title: '예약 생성',
        width: 856,
        // height: 678,
        contents: <AddScheduleModal isSchedulePage={location.pathname === '/'} />,
      });
    },
    [workingDays, holiday, dispatch, location.pathname],
  );

  const openReservationDetailModal = useCallback(
    (reservation) => {
      dispatch(scheduleAction.initEdit());
      ModalHandler.show(ModalType.Detail, {
        title: '예약 상세내역',
        width: 500,
        contents: <ReservationDetailModal reservation={reservation} />,
      });
    },
    [dispatch],
  );

  const alertSubscriptionInduction = useCallback((onConfirm, onCancel) => {
    // 팝업
    ModalHandler.show(ModalType.Alert, {
      title: '핏펫회원이 아니거나<br />정보제공 동의 전 상태의<br />회원입니다.',
      confirmText: '알림 발송',
      content:
        '앱 설치 후 신규 가입 또는 신규 약관 동의<br />이후에 예약 생성이 가능합니다.<br />앱 접근을 위한 알림을 발송하시겠습니까?',
      cancelText: '돌아가기',
      onConfirm,
      onCancel,
    });
  }, []);

  return { openAddSchedule, alertSubscriptionInduction, openReservationDetailModal };
};

export default useSchedule;
