import styled from '@emotion/styled';
import { AHEAD_MINT, GREY_2, GREY_3, WHITE } from 'styles/colors';

export const Wrapper = styled.div`
  display: flex;
  width: 280px;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    margin-top: 17px;
    color: ${GREY_2};
    font-size: 14px;
    margin-bottom: 40px;
  }
`;

export const LoginForm = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Input = styled.input`
  width: 100%;
  border: 1px solid ${GREY_3};
  border-radius: 5px;
  padding: 13px 13px 13px 12px;
  margin-bottom: 8px;
  background-color: ${WHITE};
  &::placeholder {
    color: ${GREY_3};
  }
`;

export const SubmitButton = styled.button`
  width: 100%;
  padding: 13px 106px 13px 107px;
  background-color: ${AHEAD_MINT};
  border-radius: 5px;
  color: ${WHITE};
  line-height: 20px;
  margin-top: 37px;
  font-size: 16px;
  font-weight: bold;
`;

export const CheckBox = styled.div`
  box-sizing: border-box;
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  line-height: 14px;

  span {
    margin-left: 8px;
  }
`;
