import React from 'react';
import { RoutesIconProps } from 'src/model/route';
import { AHEAD_MINT, GREY_2 } from 'styles/colors';

const UserManagementIcon = ({ isActive }: RoutesIconProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.4135 15.2885C7.4135 13.857 6.24886 12.6924 4.81735 12.6924C3.38583 12.6924 2.22119 13.857 2.22119 15.2885C2.22119 16.7201 3.38583 17.8847 4.81735 17.8847C6.24886 17.8847 7.4135 16.7201 7.4135 15.2885ZM4.81735 16.5866C4.10159 16.5866 3.51927 16.0043 3.51927 15.2885C3.51927 14.5728 4.10159 13.9905 4.81735 13.9905C5.5331 13.9905 6.11542 14.5728 6.11542 15.2885C6.11542 16.0043 5.5331 16.5866 4.81735 16.5866Z"
      fill={isActive ? `${AHEAD_MINT}` : `${GREY_2}`}
    />
    <path
      d="M6.76444 17.8845H2.87021C1.79657 17.8845 0.923096 18.758 0.923096 19.8316V23.0768H8.71156V19.8316C8.71156 18.758 7.83808 17.8845 6.76444 17.8845ZM7.41348 21.7788H2.22117V19.8316C2.22117 19.4738 2.51233 19.1826 2.87021 19.1826H6.76444C7.12232 19.1826 7.41348 19.4738 7.41348 19.8316V21.7788Z"
      fill={isActive ? `${AHEAD_MINT}` : `${GREY_2}`}
    />
    <path
      d="M21.7789 15.2885C21.7789 13.857 20.6142 12.6924 19.1827 12.6924C17.7512 12.6924 16.5865 13.857 16.5865 15.2885C16.5865 16.7201 17.7512 17.8847 19.1827 17.8847C20.6142 17.8847 21.7789 16.7201 21.7789 15.2885ZM19.1827 16.5866C18.4669 16.5866 17.8846 16.0043 17.8846 15.2885C17.8846 14.5728 18.4669 13.9905 19.1827 13.9905C19.8985 13.9905 20.4808 14.5728 20.4808 15.2885C20.4808 16.0043 19.8985 16.5866 19.1827 16.5866Z"
      fill={isActive ? `${AHEAD_MINT}` : `${GREY_2}`}
    />
    <path
      d="M21.1298 17.8845H17.2356C16.1619 17.8845 15.2885 18.758 15.2885 19.8316V23.0768H23.0769V19.8316C23.0769 18.758 22.2034 17.8845 21.1298 17.8845ZM21.7788 21.7788H16.5865V19.8316C16.5865 19.4738 16.8777 19.1826 17.2356 19.1826H21.1298C21.4877 19.1826 21.7788 19.4738 21.7788 19.8316V21.7788Z"
      fill={isActive ? `${AHEAD_MINT}` : `${GREY_2}`}
    />
    <path
      d="M14.5962 3.51925C14.5962 2.08773 13.4315 0.923096 12 0.923096C10.5685 0.923096 9.40387 2.08773 9.40387 3.51925C9.40387 4.95077 10.5685 6.1154 12 6.1154C13.4315 6.1154 14.5962 4.95077 14.5962 3.51925ZM12 4.81733C11.2843 4.81733 10.7019 4.23501 10.7019 3.51925C10.7019 2.80349 11.2843 2.22117 12 2.22117C12.7158 2.22117 13.2981 2.80349 13.2981 3.51925C13.2981 4.23501 12.7158 4.81733 12 4.81733Z"
      fill={isActive ? `${AHEAD_MINT}` : `${GREY_2}`}
    />
    <path
      d="M15.8942 8.06259C15.8942 6.98895 15.0208 6.11548 13.9471 6.11548H10.0529C8.97925 6.11548 8.10577 6.98895 8.10577 8.06259V11.3943H15.8942V8.06259ZM14.5962 10.0962H9.40385V8.06259C9.40385 7.70471 9.69501 7.41356 10.0529 7.41356H13.9471C14.305 7.41356 14.5962 7.70471 14.5962 8.06259V10.0962Z"
      fill={isActive ? `${AHEAD_MINT}` : `${GREY_2}`}
    />
    <path
      d="M12.6491 12.6924H11.351V15.8874L9.57941 16.9102L10.2284 18.0344L12 17.0116L13.7716 18.0344L14.4206 16.9102L12.6491 15.8874V12.6924Z"
      fill={isActive ? `${AHEAD_MINT}` : `${GREY_2}`}
    />
  </svg>
);

export default UserManagementIcon;
