import { useMutation, useQuery, useQueryClient } from 'react-query';
import { QueryKey } from 'src/constants/queryKey';
import {
  Reservation,
  ReservationDetail,
  ReservationsParams,
  ReservationStatusChangeParams,
  ReservationStatusType,
} from 'src/model/reservation';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { convertPetTypeForTracker } from 'src/utils/PetUtils';
import { DateFormat } from 'src/constants/workHours';
import moment from 'moment';
import { ModalHandler } from '../../utils/ModalHandler';
import { ModalType } from '../../constants/modal';
import { selectHospital, selectHospitalId } from '../../selectors';
import { BASE_URL, HTTP, USE_MOCK_API } from '../../utils/api/http';
import { UserType, UserTypeToUrl } from '../../model/user';
import { useTracker } from '../../context/EventTrackingProvider';
import { ReservationEvent } from '../../model/tracker';

const HOST_URL = () => (USE_MOCK_API ? 'http://localhost:7001' : BASE_URL);

/** 예약 목록 조회 */
export const useGetReservations = ({ pageNumber, pageSize, sort, ...others }: ReservationsParams) => {
  const hospitalId = useSelector(selectHospitalId);
  const { data, isLoading, error } = useQuery(
    [QueryKey.RESERVATIONS, hospitalId, others],
    async () => {
      const params = { ...others };
      if (params.serviceStatus === ReservationStatusType.All) {
        delete params.serviceStatus;
      }

      if (!params.search) {
        delete params.search;
      }

      if (!params.desiredDate) {
        delete params.desiredDate;
      }
      if (!params.startDate) {
        delete params.startDate;
      }
      if (!params.endDate) {
        delete params.endDate;
      }
      if (!params.hospitalMemberId) {
        delete params.hospitalMemberId;
      }

      const [nonUserRes, userRes] = await Promise.all([
        HTTP.get<Reservation[]>(`${BASE_URL}/api/hospitals/non-user/${hospitalId}/reservations`, {
          params,
        }),
        HTTP.get<Reservation[]>(`${BASE_URL}/api/hospitals/user/${hospitalId}/reservations`, {
          params,
        }),
      ]);
      return [
        ...nonUserRes.data.map((r) => {
          return { ...r, userType: UserType.NON_USER };
        }),
        ...userRes.data.map((r) => {
          return { ...r, userType: UserType.USER };
        }),
      ] as Reservation[];
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  return {
    data,
    error,
    isLoading,
  };
};

/** LNB 오늘 미확정 예약 5분마다 갱신 */
export const useGetTodayAppliedReservations = (params: ReservationsParams) => {
  const router = useHistory();
  const hospitalId = useSelector(selectHospitalId);
  const { data, isLoading, error } = useQuery(
    [QueryKey.TODAY_APPLIED_RESERVATIONS, hospitalId, params],
    async () => {
      const [nonUserRes, userRes] = await Promise.all([
        HTTP.get<Reservation[]>(`${BASE_URL}/api/hospitals/non-user/${hospitalId}/reservations`, {
          params,
        }),
        HTTP.get<Reservation[]>(`${BASE_URL}/api/hospitals/user/${hospitalId}/reservations`, {
          params,
        }),
      ]);
      return [
        ...nonUserRes.data.map((r) => {
          return { ...r, userType: UserType.NON_USER };
        }),
        ...userRes.data.map((r) => {
          return { ...r, userType: UserType.USER };
        }),
      ] as Reservation[];
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: 1000 * 60 * 10, // 5분
      onSuccess: (callbackData) => {
        const appliedCount = callbackData.filter(
          (reservation) => reservation.serviceStatus === ReservationStatusType.Applied,
        ).length;

        if (appliedCount && params.serviceStatus === ReservationStatusType.Applied) {
          window.confirm('미확정 예약 대기건이 있습니다.');
          router.push('/reservations');
        }
      },
    },
  );

  return {
    data,
    error,
    isLoading,
  };
};

/** 예약 상세 조회 */
export const useGetReservationDetail = (reservationId: number, userType: UserType) => {
  const hospitalId = useSelector(selectHospitalId);
  const { data, isLoading, error } = useQuery(
    [QueryKey.RESERVATION, hospitalId, reservationId],
    async () => {
      const response = await HTTP.get<ReservationDetail>(
        `${HOST_URL()}/api/hospitals/${UserTypeToUrl[userType]}/${hospitalId}/reservations/${reservationId}`,
      );

      response.data.userType = userType;

      return response.data;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  return {
    data,
    error,
    isLoading,
  };
};

/** 예약 상태 변경 (예약확정, 방문완료, 노쇼, 보류) */
export const usePutReservationStatusChange = () => {
  const queryClient = useQueryClient();
  const hospital = useSelector(selectHospital);
  const tracker = useTracker();
  return useMutation(
    async (param: ReservationStatusChangeParams) => {
      const response = await HTTP.put<ReservationStatusChangeParams, ReservationDetail>(
        `${HOST_URL()}/api/hospitals/${UserTypeToUrl[param.userType]}/${hospital.hospitalId}/reservations/${
          param.id
        }/reservation-status`,
        param,
      );

      return response.data;
    },
    {
      onSuccess: ({ serviceStatus, user, reservationTime, pet }) => {
        let ToastMessage;

        switch (serviceStatus) {
          case ReservationStatusType.Visited:
            ToastMessage = '방문 완료 처리되었습니다.';
            break;
          case ReservationStatusType.NoShow:
            ToastMessage = '노쇼 처리되었습니다.';
            break;
          case ReservationStatusType.Cancelled:
            ToastMessage = '예약이 취소되었습니다.';
            break;
          default:
            ToastMessage = '예약 확정이 완료되었습니다.';
            break;
        }

        ModalHandler.show(ModalType.Toast, {
          ToastMessage,
        });

        if (ToastMessage === '방문 완료 처리되었습니다.') {
          tracker.setUserID(`${user.id}`);
          tracker.handleEvent(ReservationEvent.COMPLETE_HOSPITALADMIN_VISIT, {
            location_id: hospital.hospitalId,
            location_name: hospital.hospital.name,
            location_type: '병원',
            pet_id: pet.id,
            pet_name: pet.name,
            pet_type: convertPetTypeForTracker(pet.petType),
            completed_reservation_date: moment().format(DateFormat),
          });
        }
        if (ToastMessage === '예약 확정이 완료되었습니다.') {
          tracker.setUserID(`${user.id}`);
          tracker.handleEvent(ReservationEvent.COMPLETE_HOSPITALADMIN_CONFIRM, {
            location_id: hospital.hospitalId,
            location_name: hospital.hospital.name,
            location_type: '병원',
            pet_id: pet.id,
            pet_name: pet.name,
            pet_type: convertPetTypeForTracker(pet.petType),
            confirmed_reservation_date: moment().format(DateFormat),
            reservation_date: reservationTime?.split('T')[0],
          });
        }

        queryClient.refetchQueries([QueryKey.RESERVATIONS], { active: true });
        queryClient.refetchQueries([QueryKey.SCHEDULE_LIST], { active: true });
      },
      onError: () => {
        ModalHandler.show(ModalType.Toast, {
          ToastMessage: `상태 변경에 실패했습니다.`,
        });
      },
    },
  );
};

/** 예약 상세 수정 */
export const usePutReservationUpdate = () => {
  const queryClient = useQueryClient();
  const hospitalId = useSelector(selectHospitalId);

  return useMutation(
    async (param: ReservationDetail) => {
      const response = await HTTP.put<ReservationDetail, ReservationDetail>(
        `${HOST_URL()}/api/hospitals/${UserTypeToUrl[param.userType]}/${hospitalId}/reservations/${param.id}`,
        param,
      );

      return response.data;
    },
    {
      onSuccess: () => {
        ModalHandler.show(ModalType.Toast, {
          ToastMessage: `예약이 변경 되었습니다.`,
        });

        queryClient.refetchQueries([QueryKey.RESERVATIONS], { active: true });
        queryClient.refetchQueries([QueryKey.SCHEDULE_LIST], { active: true });
      },
      onError: () => {
        ModalHandler.show(ModalType.Toast, {
          ToastMessage: `예약 변경에 실패했습니다.`,
        });
      },
    },
  );
};

/** 메모 등록 */
export const usePatchReservationMemo = () => {
  const queryClient = useQueryClient();
  const hospitalId = useSelector(selectHospitalId);

  return useMutation(
    async (param: ReservationStatusChangeParams) => {
      const response = await HTTP.patch<ReservationStatusChangeParams, ReservationDetail>(
        `${HOST_URL()}/api/hospitals/${UserTypeToUrl[param.userType]}/${hospitalId}/reservations/${param.id}/memo`,
        param,
      );

      return response.data;
    },
    {
      onSuccess: () => {
        ModalHandler.show(ModalType.Toast, {
          ToastMessage: `작성한 내용이 저장되었습니다.`,
        });

        queryClient.refetchQueries([QueryKey.RESERVATIONS], { active: true });
      },
      onError: () => {
        ModalHandler.show(ModalType.Toast, {
          ToastMessage: `메모 저장에 실패하였습니다.`,
        });
      },
    },
  );
};

/** 안내 사항 등록 */
export const usePatchReservationMessage = () => {
  const queryClient = useQueryClient();
  const hospitalId = useSelector(selectHospitalId);

  return useMutation(
    async (param: ReservationStatusChangeParams) => {
      const response = await HTTP.patch<ReservationStatusChangeParams, ReservationDetail>(
        `${HOST_URL()}/api/hospitals/${UserTypeToUrl[param.userType]}/${hospitalId}/reservations/${param.id}/message`,
        param,
      );

      return response.data;
    },
    {
      onSuccess: () => {
        ModalHandler.show(ModalType.Toast, {
          ToastMessage: `고객님께 안내사항이 발송되었습니다.`,
        });

        queryClient.refetchQueries([QueryKey.RESERVATIONS], { active: true });
      },
      onError: () => {
        ModalHandler.show(ModalType.Toast, {
          ToastMessage: `안내사항 저장에 실패하였습니다.`,
        });
      },
    },
  );
};
