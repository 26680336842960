import styled from '@emotion/styled';
import { GREY_3, GREY_11, AHEAD_MINT, WHITE } from 'styles/colors';

export const Wrapper = styled.div`
  h2 {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 1rem;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
  row-gap: 10px;
`;

export const Article = styled.article`
  padding-top: 8px;
  border-top: 1px solid ${GREY_3};

  h3 {
    font-size: 14px;
    line-height: 17px;
    margin: 0;

    + p {
      margin-top: 8px;
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 24px;
    }
  }
`;

export const Dl = styled.dl`
  display: grid;
  gap: 8px 19px;
  grid-template-columns: 60px 1fr;
  > * {
    font-size: 12px;
    line-height: 14px;
  }

  dt {
    font-weight: 600;
  }
`;

export const Tabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  margin: 16px 0;

  button.arrow {
    display: flex;
    svg {
      flex: 1;
    }
    &:first-of-type {
      margin-right: 4px;
    }
    &:last-child {
      margin-left: 4px;
    }
  }

  button:not(.arrow) {
    padding: 4px 8px;
    border-radius: 5px;
    background-color: ${GREY_11};
    font-size: 14px;
    line-height: 17px;
    max-width: 65px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.active {
      color: ${WHITE};
      background-color: ${AHEAD_MINT};
      font-weight: 700;
    }
  }
`;

export const HistoryList = styled.ol`
  counter-reset: item;
  margin-top: 1rem;
  list-style: none;

  li {
    display: grid;
    grid-template-columns: 24px 69px 93px auto;
    align-items: center;
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    span {
      font-size: 12px;
      line-height: 14px;

      &:first-of-type,
      &:nth-of-type(2) {
        font-weight: bold;
      }
    }
  }
`;
