import styled from '@emotion/styled';
import { GREY_1, GREY_3, GREY_5, WHITE } from 'styles/colors';
import { css } from '@emotion/react';

export const Wrapper = styled.div(
  ({ disabled }: { disabled: boolean }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    margin-top: 16px;
    position: relative;
    ${disabled &&
    css`
      color: ${GREY_3} !important;
    `}
  `,
);

// export const Wrapper = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   height: 50px;
//   margin-top: 16px;
//   position: relative;
// `;
export const Label = styled.label(
  ({ disabled }: { disabled: boolean }) => css`
    display: block;
    //margin-top: 56px;
    font-size: 14px;
    font-weight: 700;
    color: ${GREY_1};
    width: 120px;
    ${disabled &&
    css`
      color: ${GREY_3};
    `}
  `,
);

export const Required = styled.div`
  display: inline;
  margin-left: 2px;
`;

export const Text = styled.input<{ width?: number }>`
  width: ${({ width }) => width}px;
  height: 48px;
  background: ${WHITE};
  border: 1px solid ${GREY_3};
  border-radius: 4px;
  padding: 0 12px;

  &:disabled {
    color: ${GREY_3};
    background-color: ${GREY_5};
    cursor: default;
  }
  &:disabled::placeholder {
    color: ${GREY_3};
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 14px;
  right: 14px;
  cursor: pointer;
`;
